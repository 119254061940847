import RoutingViewContainer from '@/components/RoutingViewContainer.vue';
import ABTestList from './pages/ABTestList.vue';
import ABTestCreate from './pages/ABTestCreate.vue';
import ABTestEdit from './pages/ABTestEdit.vue';

export default {
  path: '/cockpit',
  component: RoutingViewContainer,
  name: 'cockpit',
  redirect: { name: 'cockpit-list' },
  children: [
    {
      path: '',
      component: ABTestList,
      name: 'cockpit-list',
      meta: {
        isPublic: true,
        browserTitle: 'A/B Tests',
      },
    },
    {
      path: 'create',
      component: ABTestCreate,
      name: 'cockpit-create',
      meta: {
        isPublic: true,
        browserTitle: 'Create A/B Test',
      },
    },
    {
      path: ':cockpitId',
      component: ABTestEdit,
      name: 'cockpit-edit',
      meta: {
        isPublic: true,
        browserTitle: 'Edit A/B Test',
      },
    },
  ],
};
