<template>
  <div class="dashboard-new-row px-3 py-2 grid grid--centered grid--direction-column">
    <h5 class="dashboard-new-row__title grid__col">
      Choose layout for new row
    </h5>

    <div class="dashboard-new-row__rows-selector grid__col">
      <DashboardRowPreview
        :cols="2"
        @chosen="addWidgetRow"
      />
      <DashboardRowPreview
        :cols="1"
        @chosen="addWidgetRow"
      />
      <DashboardRowPreview
        :cols="3"
        @chosen="addWidgetRow"
      />
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import DashboardRowPreview from './DashboardRowPreview.vue';

export default {
  name: 'DashboardNewRow',
  components: {
    DashboardRowPreview,
  },
  data() {
    return {
    };
  },
  methods: {
    ...mapMutations('dataMarts', ['addWidgetRow']),
  },
};
</script>

<style scoped lang="scss">
    .dashboard-new-row
    {
        height: var(--dashboard-row--height);
        border: 1px solid #ccc;
        background-color: #fcfcfc;

        &__title
        {
            margin: 20px 0 40px;
        }

        &__rows-selector
        {
            margin-bottom: 20px;
        }
    }
</style>
