const state = {
  initialSteps: [
    { value: 'auto', text: 'Auto' },
    { value: 5, text: '5 minutes' },
    { value: 15, text: '15 minutes' },
    { value: 30, text: '30 minutes' },
    { value: 60, text: '1 hour' },
    { value: 300, text: '5 hours' },
    { value: 1440, text: '1 day' },
    { value: 7200, text: '5 day' },
  ],
  steps: [],
  currentStep: 'auto',
  domains: [],
  currentDomain: {
    id: 0,
    name: 'All',
    labelType: 'all_sources',
  },
  routes: [],
  currentRoute: {
    id: 1,
    name: 'main',
  },
  date: [
    new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(), 0, 0),
    new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(), 23, 59),
  ],
  dateSingleDates: null,
};

const actions = {
  setDate({ commit }: any, date: any) {
    commit('updateDate', date);
    commit('updateSteps', date);
  },
  clearDate({ commit }: any) {
    const defaultDate = [new Date(), new Date()];
    commit('updateDate', defaultDate);
    commit('updateSteps', defaultDate);
  },
  setDomains({ commit }: any, domains: any) {
    const parsedDomains = domains.map((source: any) => ({
      id: source.id,
      name: source.host,
      labelType: source.id === 0 ? 'all_sources' : source.label ? 'label' : 'source',
      is_active: source.id === 0 || source.label ? 1 : source.is_active,
    }));

    commit('updateDomains', parsedDomains);
  },
  setCurrentDomain({ commit }: any, currentDomain: any) {
    commit('updateCurrentDomain', currentDomain);
  },
  setCurrentDomainByQueryParams({ commit, state }: any, params: any) {
    const defaultDomain = {
      id: 0,
      name: 'All',
      labelType: 'all_sources',
    };
    let domain;

    if (!!params && params.host && params.labeltype) {
      domain = state.domains.find((domain: any) => domain.id === Number(params.host) && domain.labelType === params.labeltype);
    }
    commit('updateCurrentDomain', domain || defaultDomain);
  },
  setRoutes({ commit }: any, routes: any) {
    const parsedRoutes = routes.map((source: any) => ({
      id: source.id,
      name: source.value,
    }));

    commit('updateRoutes', parsedRoutes);
  },
  setCurrentRoute({ commit }: any, currentRoute: any) {
    commit('updateCurrentRoute', currentRoute);
  },
  setCurrentRouteById({ commit, state }: any, id: any) {
    if (typeof id !== 'number') {
      id = parseInt(id, 10);
    }
    const defaultRoute = {
      id: 1,
      name: 'main',
    };
    const route = state.routes.find((route: any) => route.id === id);
    commit('updateCurrentRoute', route || defaultRoute);
  },
  setCurrentRouteByName({ commit, state }: any, name: any) {
    const route = state.routes.find((route: any) => route.name === name);

    if (route !== undefined) {
      commit('updateCurrentRoute', route);
    }
  },
  setCurrentStep({ commit }: any, currentStep: any) {
    commit('updateCurrentStep', currentStep);
  },
};

const getters = {
  getSteps(state: any) {
    return state.steps;
  },
  getCurrentStep(state: any) {
    return state.currentStep;
  },
  getDate(state: any) {
    return state.date;
  },
  getDateForParams(state: any) {
    return state.date ? {
      dateFrom: `${state.date[0].getFullYear()}-${state.date[0].getMonth() + 1}-${state.date[0].getDate()}`,
      dateTo: `${state.date[1].getFullYear()}-${state.date[1].getMonth() + 1}-${state.date[1].getDate()}`,
    } : null;
  },
  getDomains(state: any) {
    return state.domains;
  },
  getCurrentDomain(state: any) {
    return state.currentDomain;
  },
  getRoutes(state: any) {
    return state.routes;
  },
  getCurrentRoute(state: any) {
    return state.currentRoute;
  },
};

const mutations = {
  updateSteps(state: any, date: any) {
    if (date) {
      const diff = parseInt(((date[1] - date[0]) / 1000 / 60 / 60 / 24 as any), 10);
      const filteredSteps: any = [];

      state.initialSteps.map((item: any) => {
        if (item.value === 'auto') {
          filteredSteps.push(item);
        }

        if (diff === 0 && item.value <= 300) {
          filteredSteps.push(item);
        } else if (diff > 0 && diff <= 2 && item.value >= 60 && item.value <= 1440) {
          filteredSteps.push(item);
        } else if (diff > 2 && diff <= 6 && item.value >= 300 && item.value <= 7200) {
          filteredSteps.push(item);
        } else if (diff > 6 && item.value >= 1440 && item.value <= 7200) {
          filteredSteps.push(item);
        }
      });

      state.steps = filteredSteps;

      const isCurrentStepInFilteredSteps = state.steps.find((element: any) => element.value === state.currentStep);

      if (isCurrentStepInFilteredSteps === undefined) {
        state.currentStep = state.initialSteps[0].value;
      }
    } else {
      state.steps = state.initialSteps;
    }
  },
  updateDate(state: any, date: any) {
    state.date = date;
  },
  updateDomains(state: any, domains: any) {
    state.domains = domains;
  },
  updateCurrentDomain(state: any, currentDomain: any) {
    state.currentDomain = currentDomain;
  },
  updateRoutes(state: any, routes: any) {
    state.routes = routes;
  },
  updateCurrentRoute(state: any, currentRoute: any) {
    state.currentRoute = currentRoute;
  },
  updateCurrentStep(state: any, currentStep: any) {
    state.currentStep = currentStep;
  },
  updateAvailableSingleDates(state: any, dates: any) {
    state.dateSingleDates = dates;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
