export default {
    validations: {
        required: '{property} is required',
        minValue: '{property} must be at least {min}',
        maxValue: '{property} must not exceed {max}',
        minLength: '{property} must have at least {min} characters',
        maxLength: '{property} must have at most {max} letters',
        email: 'Please enter correct email address',
        latinNameOnly: "Only letters, -, ', ` and spaces are allowed",
        lettersNumbers: 'Only letters, numbers, -, _ are allowed',
        slug: 'The value should be in format: foo:bar',
        integer: 'Only integer numbers are allowed',
        slugPrefix: 'Only lowercase letters and numbers are allowed',
        upperCase: 'Must have at least one uppercase latin letter',
        lowerCase: 'Must have at least one lowercase latin letter',
        leastOneSymbol: 'Must have at least one symbol',
        leastOneNumber: 'Must have at least one number',
        noSpaces: 'Spaces are not allowed',
        notAllowedNumberSymbol: 'Some not allowed symbols where entered',
        sameAs: "Passwords don't match",
        noCyrillic: 'Must have only latin characters',
        onlyNumbers: '{property} must have only numbers',
        lettersNumbersWithPointsBrackets: 'This value only latin letters, numbers 0-9, spaces and symbols "-.()"',
        roleName: 'The value may only contain letters, numbers, dashes, underscores and spaces.',
        labelName:
            'Label name can contain only latin letters, numbers, dots (.), hyphens (-), underscores (_), colons (:) and hashes (#).',
        link: 'The value is not a valid link',
    },
};
