import { ApiV1 } from '@/api';

export default {
  getReportSettings(params: any) {
    return ApiV1().get('/report/settings', { params });
  },
  getReports(params: any) {
    return ApiV1().get('/reports', { params });
  },
  createReport(data: any) {
    return ApiV1({ cantCancel: true }).post('/reports', data);
  },
};
