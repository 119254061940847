<template>
  <div :class="['dashboard-widget', editState ? 'dashboard-widget--edit' : '']">
    <div
      class="dashboard-widget__header"
    >
      <h1
        v-b-tooltip.hover="{ placement: editState ? 'bottom' : 'right', delay: { show: 100, hide: 10 } }"
        class="dashboard-widget__title text-overflow--ellipsis"
        :title="widget.name"
      >
        {{ widget.name || '' }}
      </h1>
      <div
        v-if="editState"
        class="dashboard-widget__controls"
      >
        <b-button
          size="sm"
          variant="outline-primary"
          @click="$emit('edit')"
        >
          <i
            class="fa fa-pencil-alt"
            aria-hidden="true"
          />
        </b-button>
      </div>
    </div>
    <div :class="['dashboard-widget__content', editState ? 'dashboard-widget__content--edit' : '', 'loader-frame']">
      <div
        v-if="editState"
        class="dashboard-widget__disabled-layer"
      />
      <div
        v-if="errorMessage"
        class="error-message"
      >
        {{ errorMessage }}
      </div>
      <component
        :is="widgetComponent"
        v-else
        :widget="widget"
        :data="widgetData"
        class="dashboard-widget__widget-data"
        @error-message="errorMessage = $event"
      />
      <Loader :is-loading="isLoading" />
    </div>
  </div>
</template>

<script>
import {
  mapState, mapActions, mapGetters, mapMutations,
} from 'vuex';
import clone from 'rfdc';
import EChartComponent from '../echart/EChartComponent.vue';
import Loader from '../Loader.vue';
import { getAxisKeysFromData } from '../echart/options/line-chart/index';

export default {
  name: 'DashboardWidget',
  components: {
    'kmtx-chart': EChartComponent,
    Loader,
  },
  props: {
    widget: {
      type: Object,
      required: true,
    },
    dashboard: {
      type: Object,
      required: true,
    },
  },
  emits: ['edit'],
  data() {
    return {
      widgetChart: {},
      errorMessage: '',
      isLoading: false,
      widgetData: undefined,
      widgetComponent: null,
      widgetComponentFound: false,
      updateTimer: undefined,
      updateIntervalId: undefined,
    };
  },
  computed: {
    ...mapState('dataMarts', ['editState']),
    ...mapState('newFilters', ['filters', 'currentStep', 'exportValue']),
    ...mapGetters('dataMarts', ['getDashboardById']),
    filtersList() {
      return this.dashboard?.configuration?.filters_list || [];
    },
    widgetLoader() {
      if (!this.widget?.configuration?.report_type) {
        return null;
      }
      const type = this.widget.configuration.report_type.includes('chart') ? 'chart' : this.widget.configuration.report_type;
      return () => import(/* webpackMode: "eager" */`~/components/widgets/${type}`);
    },
  },
  watch: {
    async widget(to, from) {
      if (to.configuration.report_type !== from.configuration.report_type) {
        await this.loadWidget();
        this.fillChartData();
      } else {
        this.fillChartData();
      }
    },
    async filters(to) {
      await this.$nextTick();
      if (!this.editState && to.length) {
        this.fillChartData();
      }
    },
    currentStep() {
      if (this.widget.configuration.report_type === 'line-chart') {
        this.fillChartData();
      }
    },
    editState(to) {
      if (to) {
        this.fillChartData();
      }
    },
    updateTimer(to) {
      if (to === 0) {
        this.fillChartData(false);
      }
    },
    exportValue(to) {
      if (to === this.widget.id && this.widgetData) {
        this.downloadCsv();
        this.updateExportValue(null);
      }
    },
  },
  async created() {
    await this.loadWidget();

    if (!this.filtersList.find(({ filter_value }) => filter_value === 'domain_label') || this.editState) {
      this.fillChartData();
    }
  },
  beforeDestroy() {
    clearInterval(this.updateIntervalId);
  },
  methods: {
    ...mapMutations('newFilters', ['updateExportValue']),
    ...mapActions('dataMarts', ['getWidgetData']),
    turnTimerOn() {
      this.updateIntervalId = setInterval(() => {
        this.updateTimer -= 1;
      }, 1000);
    },
    loadWidget() {
      return this.widgetLoader().then(() => {
        this.errorMessage = '';
        this.widgetComponentFound = true;
        this.widgetComponent = () => this.widgetLoader();
      }).catch(() => {
        this.widgetComponentFound = false;
        this.errorMessage = 'Widget type not found';
      });
    },
    fillChartData(showLoader = true) {
      this.updateTimer = 60 * 30;
      if (this.widget.id && this.widgetComponentFound) {
        this.errorMessage = '';
        this.isLoading = showLoader;
        const params = {
          id: this.widget.id,
          dashboard_id: this.dashboard.id,
        };

        if (this.widget.configuration.report_type === 'table') {
          params.perPage = 10000;
        }

        if (this.filters.length && !this.editState) {
          let filters_list = clone()(this.filters);
          filters_list.forEach((filter) => {
            if (filter.filter_fields) {
              filter.filter_field_id = filter.filter_fields.find(({ widgetId }) => widgetId === this.widget.id)?.fieldId;
            }
            delete filter.filter_fields;
          });
          filters_list = filters_list.filter((filter) => filter.filter_value || filter.filter_value === 0 && !filter.filter_field_id);

          params.filters_list = filters_list;

          if (this.widget.configuration.report_type === 'line-chart' && this.currentStep !== 'auto') {
            params.x_axes_step_in_seconds = this.currentStep * 60;
          }
        }

        this.getWidgetData(params).then((data) => {
          this.widgetData = data;
          if (!this.updateIntervalId) {
            this.turnTimerOn();
          }
        }).catch(() => {
          this.errorMessage = 'Failed to load data from server.';
        }).finally(() => this.isLoading = false);
      }
    },
    showChartToolbox(doShow) {
      if (this.widgetChart?.toolbox) {
        this.widgetChart.toolbox.show = doShow;
      }
    },
    downloadCsv() {
      const data = this.parseDataForExport();
      const csvContent = `data:text/csv;charset=utf-8,${data.map((e) => e.join(';')).join('\n')}`;
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement('a');
      link.setAttribute('href', encodedUri);
      link.setAttribute('download', `${this.widget.name}-widget.csv`);
      document.body.appendChild(link); // Required for FF
      link.click();
    },
    parseDataForExport() {
      const config = this.widget.configuration;
      if (config.report_type === 'table'
                || config.report_type === 'singlestat') {
        return this.getRowsForKeyValueData();
      }
      if (config.report_type === 'line-chart') {
        return this.getRowsForSlicesData();
      }
      if (config.report_type === 'pie-chart'
                || config.report_type === 'bar-chart'
                || config.report_type === 'column-chart') {
        return this.getRowsForNameValueData();
      }
    },
    getRowsForSlicesData() {
      const { slices, dateType } = getAxisKeysFromData(this.widgetData);
      const config = this.widget.configuration;
      const sliceName = config.labels && config.labels[0].label || config.y_axes_field_name;
      const rowHeaders = [config.x_axes_field_name, ...slices.map((slice) => `${slice} - ${sliceName}`)];
      return [rowHeaders, ...this.widgetData.map((item) => [item[dateType], ...slices.map((slice) => item[slice])])];
    },
    getRowsForNameValueData() {
      const config = this.widget.configuration;
      const valuesLabel = config.labels && config.labels[0]?.label || config.y_axes_field_name;
      const rowHeaders = [config.x_axes_field_name, valuesLabel];
      return [rowHeaders, ...this.widgetData.map((item) => [item.barName, item.barValue])];
    },
    getRowsForKeyValueData() {
      const data = this.widgetData.data || this.widgetData;
      let rowHeaders = Object.keys(data[0]);
      rowHeaders = rowHeaders.filter((header) => header !== 'Children' && header !== '_showDetails');
      const rows = [];
      data.forEach((item) => {
        if (!item.Children) {
          rows.push(rowHeaders.map((header) => item[header]));
        }
        if (item.Children) {
          item.Children.forEach((child) => {
            rows.push(rowHeaders.map((header) => child[header]));
          });
        }
      });
      rowHeaders = this.getLabelsForHeaders(rowHeaders);
      rows.unshift(rowHeaders);
      return rows;
    },
    getLabelsForHeaders(rowHeaders) {
      const { labels } = this.widget.configuration;
      const fieldType = this.widget.configuration.report_type === 'table' ? 'columns' : 'stat';
      if (labels?.length) {
        labels.forEach((label) => {
          const labeledCol = this.widget.configuration[fieldType].find((col) => label.field_id === col.field_id);
          const rowIndexToChange = rowHeaders.findIndex((header) => header === labeledCol.field_name);
          if (rowIndexToChange > -1) {
            rowHeaders.splice(rowIndexToChange, 1, label.label);
          }
        });
      }
      return rowHeaders;
    },
  },
};
</script>

<style lang="scss">
    :root {
        --divideTo: 2;
        --page-header--height: 67px;
        --dashboard-row__header--height: 24px;
        --dashboard-row__header--height--edit: 39px;
        --dashboard-row--margin: 40px;
        --dashboard-row--min-height: 350px;
        --dashboard-row--height: calc((100vh - var(--navbar-height) - var(--page-header--height)) / var(--divideTo)
        - var(--dashboard-row--margin));
    }
    @media screen and (min-height: 1500px) {
        :root {
            --divideTo: 3;
        }
    }

    .dashboard-widget
    {
        height: var(--dashboard-row--height);
        min-height: var(--dashboard-row--min-height);
        margin-bottom: var(--dashboard-row--margin);

        &__header
        {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding-bottom: 5px;
        }

        &--edit {
            border: 1px solid #ccc;
            .dashboard-widget__header
            {
                padding: 5px 15px;
                border-bottom: 1px solid #ccc;
            }
        }

        &__disabled-layer
        {
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: rgba(118 , 121, 124, .2);
            z-index: 10;
            margin: -10px -15px;
        }

        &__title
        {
            font-size: 18px;
            font-weight: 600;
            letter-spacing: .1px;
            margin-bottom: 0;
            align-self: center;
        }

        &__controls
        {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            padding-left: 5px;
        }

        &__content
        {
            position: relative;
            height: calc(100% - var(--dashboard-row__header--height));
            &--edit
            {
                height: calc(100% - var(--dashboard-row__header--height--edit));
                padding: 10px 15px;
            }
        }
        &__widget-data, &__widget-data .kmtx-echart
        {
            height: 100%;
        }
        .error-message
        {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
</style>
