import { ApiV1 } from '@/api';

export default {
  getApiTokens(params: object) {
    return ApiV1().get(`/api-tokens`);
  },
  createApiToken(data = {}, options: any = {}) {
    options.cantCancel = true;
    return ApiV1(options).post(`/api-token`, data);
  },
  revokeApiToken(data: any = {}, options: any = {}) {
    options.cantCancel = true;
    return ApiV1(options).delete(`/api-token/${data.token_id}`, data);
  },
};
