import { ApiV1 } from '@/api';

export default {
  createLabelCategory(data: any, options: any = {}) {
    options.cantCancel = true;
    return ApiV1(options).post('/label-categories', data);
  },
  updateLabelCategory(id: any, params: any) {
    return ApiV1({ cantCancel: true }).put(`/label-categories/${id}`, params);
  },
  getLabelCategories(params: any) {
    return ApiV1().get('/label-categories', { params });
  },
};
