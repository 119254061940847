<template>
  <div class="content">
    <source-statistic-line />
  </div>
</template>
<script>
import SourceStatisticLine from './statistic/SiteStatisticLine.vue';

export default {
  name: 'DashboardComponent',
  components: { SourceStatisticLine },
  data() {
    return {
    };
  },
  created() {},
  methods: {},
};
</script>
