<template>
  <div class="al-search-input__wrapper">
    <KTextField
      class="al-search-input"
      :value="searchInput"
      :label="label"
      leadingIcon="search"
      :animatedLabel="false"
      :borderless="borderless"
      :size="size"
      @input="onChange"
    >
      <template #leading-icon>
        <KIcon icon="search" :size="size" />
      </template>
    </KTextField>
  </div>
</template>
<script>
import { KIcon, KTextField } from '@kilometrix/polyomino';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const debounce = require('just-debounce');

export default {
  name: 'SearchInput',
  components: {
    KIcon,
    KTextField,
  },
  props: {
    search: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: 'Search',
    },
    searchName: {
      type: String,
      default: 'search',
    },
    borderless: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      default: 'md',
    },
    debounceTimeout: {
      type: Number,
      default: 300,
    },
  },
  emits: ['change'],
  data() {
    return {
      searchInput: '',
    };
  },
  watch: {
    search: {
      handler(val) {
        this.searchInput = val;
      },
      immediate: true,
    },
  },
  created() {
    this.onChange = debounce(this.onChange, this.debounceTimeout);
  },
  methods: {
    onChange(val) {
      this.$emit('change', this.searchName, val);
    },
  },
};
</script>
<style lang="scss" scoped>
.al-search-input {
    width: 100%;
    &__wrapper {
        display: flex;
    }

    :deep(.k-text-field__leading-icon) {
        left: 6px;
        top: 8px
    }
}
</style>
