
import { mapGetters } from 'vuex';
import Vue from 'vue';

declare module 'vue/types/vue' {
    export interface Vue {
        $can: (permissionName?: string) => boolean;
        $has: (service: string, type: string) => boolean;
        $is: (roleName?: string) => boolean;
    }
}

export default Vue.extend({
  computed: {
    ...mapGetters('auth', ['permissionsSlugs', 'hasAnyPermissionToService', 'masterRole', 'currentAccountHighestRole']),
  },
  methods: {
    $can(permissionName: string):boolean {
      if (!permissionName) {
        return false;
      }
      return this.permissionsSlugs[permissionName.toString()] === true;
    },

    $has(service: string, type = 'view'):boolean {
      return this.hasAnyPermissionToService(service, type);
    },

    $is(roleName: string): boolean {
      if (!roleName) {
        return false;
      }
      const name = this.masterRole ? this.masterRole.name : this.currentAccountHighestRole?.name;
      return name === roleName;
    },
  },
});
