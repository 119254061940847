import LocalStorage from "@/services/LocalStorage";

export const COLUMN_WIDTH = {
  users: {
    expand: 3,
    date: 13,
    user: 15,
    editor: 15,
    changes: 10,
    before: 22,
    after: 22,
  },
  accounts: {
    expand: 3,
    date: 12,
    account: 12,
    editor: 12,
    changes: 13,
    before: 18,
    after: 18,
  },
  roles: {
    expand: 3,
    date: 12,
    role: 16,
    account: 12,
    editor: 12,
    changes: 13,
    before: 18,
    after: 18,
  },
  dsm: {
    expand: 3,
    date: 13,
    user: 18,
    editor: 12,
    affected_entity: 22,
    changes: 10,
    before: 18,
    after: 18,
  },
};
export const ACTIVITY_LOG_TABLE_FIELDS = {
  'iam-users': {
    fields: [
      {
        key: 'kmtx-expand',
        label: '',
        thClass: 'table-cell--expand',
        width: COLUMN_WIDTH.users.expand,
      },
      {
        key: 'date',
        label: 'Date',
        sortable: true,
        width: COLUMN_WIDTH.users.date,
      },
      {
        key: 'editor',
        label: 'Editor',
        sortable: true,
        width: COLUMN_WIDTH.users.editor,
      },
      {
        key: 'user',
        label: 'User',
        sortable: true,
        width: COLUMN_WIDTH.users.user,
      },
      {
        key: 'changes',
        label: 'Editable object',
        width: COLUMN_WIDTH.users.changes,
      },
      {
        key: 'before_edit',
        label: 'Before edit',
        width: COLUMN_WIDTH.users.before,
      },
      {
        key: 'after_edit',
        label: 'After edit',
        width: COLUMN_WIDTH.users.after,
      },
    ],

    nestedTableFields: [
      {
        key: '0',
        width: COLUMN_WIDTH.users.expand,
      },
      {
        key: 'date',
        width: COLUMN_WIDTH.users.date,
      },
      {
        key: 'editor',
        width: COLUMN_WIDTH.users.editor,
      },
      {
        key: 'user',
        width: COLUMN_WIDTH.users.user,
      },
      {
        key: 'changes',
        width: COLUMN_WIDTH.users.changes,
      },
      {
        key: 'before_edit',
        width: COLUMN_WIDTH.users.before,
      },
      {
        key: 'after_edit',
        width: COLUMN_WIDTH.users.after,
      },
    ],
    tableId: 'al-iam-users',
  },
  'iam-accounts': {
    fields: [
      {
        key: 'kmtx-expand',
        label: '',
        thClass: 'table-cell--expand',
        width: COLUMN_WIDTH.accounts.expand,
      },
      {
        key: 'date',
        label: 'Date',
        sortable: true,
        width: COLUMN_WIDTH.accounts.date,
      },
      {
        key: 'editor',
        label: 'Editor',
        sortable: true,
        width: COLUMN_WIDTH.accounts.editor,
      },
      {
        key: 'account',
        label: 'Account',
        sortable: true,
        width: COLUMN_WIDTH.accounts.account,
      },
      {
        key: 'changes',
        label: 'Editable object',
        width: COLUMN_WIDTH.accounts.changes,
      },
      {
        key: 'before_edit',
        label: 'Before edit',
        width: COLUMN_WIDTH.accounts.before,
      },
      {
        key: 'after_edit',
        label: 'After edit',
        width: COLUMN_WIDTH.accounts.after,
      },
    ],

    nestedTableFields: [
      {
        key: '0',
        width: COLUMN_WIDTH.accounts.expand,
      },
      {
        key: 'date',
        width: COLUMN_WIDTH.accounts.date,
      },
      {
        key: 'editor',
        width: COLUMN_WIDTH.accounts.editor,
      },
      {
        key: 'account',
        width: COLUMN_WIDTH.accounts.account,
      },
      {
        key: 'owner',
        width: COLUMN_WIDTH.accounts.owner,
      },
      {
        key: 'changes',
        width: COLUMN_WIDTH.accounts.changes,
      },
      {
        key: 'before_edit',
        width: COLUMN_WIDTH.accounts.before,
      },
      {
        key: 'after_edit',
        width: COLUMN_WIDTH.accounts.after,
      },
    ],
    tableId: 'al-iam-accounts',
  },
  'iam-roles': {
    fields: [
      {
        key: 'kmtx-expand',
        label: '',
        thClass: 'table-cell--expand',
        width: COLUMN_WIDTH.roles.expand,

      },
      {
        key: 'date',
        label: 'Date',
        sortable: true,
        width: COLUMN_WIDTH.roles.date,
      },
      {
        key: 'editor',
        label: 'Editor',
        sortable: true,
        width: COLUMN_WIDTH.roles.editor,
      },
      {
        key: 'role',
        label: 'Role',
        sortable: true,
        width: COLUMN_WIDTH.roles.role,
      },
      {
        key: 'account',
        label: 'Account',
        sortable: true,
        width: COLUMN_WIDTH.roles.account,
      },
      {
        key: 'changes',
        label: 'Editable object',
        width: COLUMN_WIDTH.roles.changes,
      },
      {
        key: 'before_edit',
        label: 'Before edit',
        width: COLUMN_WIDTH.roles.before,
      },
      {
        key: 'after_edit',
        label: 'After edit',
        width: COLUMN_WIDTH.roles.after,
      },
    ],
    nestedTableFields: [
      {
        key: '0',
        width: COLUMN_WIDTH.roles.expand,
      },
      {
        key: 'date',
        width: COLUMN_WIDTH.roles.date,
      },
      {
        key: 'editor',
        width: COLUMN_WIDTH.roles.editor,
      },
      {
        key: 'role',
        width: COLUMN_WIDTH.roles.role,
      },
      {
        key: 'account',
        width: COLUMN_WIDTH.roles.account,
      },
      {
        key: 'changes',
        width: COLUMN_WIDTH.roles.changes,
      },
      {
        key: 'before_edit',
        width: COLUMN_WIDTH.roles.before,
      },
      {
        key: 'after_edit',
        width: COLUMN_WIDTH.roles.after,
      },
    ],
    tableId: 'al-iam-roles',
  },
  'dsm-users': {
    fields: [
      {
        key: 'kmtx-expand',
        label: '',
        thClass: 'table-cell--expand',
        width: COLUMN_WIDTH.dsm.expand,
      },
      {
        key: 'date',
        label: 'Date',
        sortable: true,
        width: COLUMN_WIDTH.dsm.date,
      },
      {
        key: 'editor',
        label: 'Editor',
        sortable: true,
        width: COLUMN_WIDTH.dsm.editor,
      },
      {
        key: 'affected_entity',
        label: 'User',
        sortable: true,
        width: COLUMN_WIDTH.dsm.user,
      },
      {
        key: 'changes',
        label: 'Editable object',
        width: COLUMN_WIDTH.dsm.changes,
      },
      {
        key: 'before_edit',
        label: 'Before edit',
        width: COLUMN_WIDTH.dsm.before,
      },
      {
        key: 'after_edit',
        label: 'After edit',
        width: COLUMN_WIDTH.dsm.after,
      },
    ],

    nestedTableFields: [
      {
        key: '0',
        width: COLUMN_WIDTH.dsm.expand,
      },
      {
        key: 'date',
        width: COLUMN_WIDTH.dsm.date,
      },
      {
        key: 'editor',
        width: COLUMN_WIDTH.dsm.editor,
      },
      {
        key: 'user',
        width: COLUMN_WIDTH.dsm.user,
      },
      {
        key: 'changes',
        width: COLUMN_WIDTH.dsm.changes,
      },
      {
        key: 'before_edit',
        width: COLUMN_WIDTH.dsm.before,
      },
      {
        key: 'after_edit',
        width: COLUMN_WIDTH.dsm.after,
      },
    ],
    tableId: 'al-dsm-users',
  },
  'dsm-services': {
    fields: [
      {
        key: 'kmtx-expand',
        label: '',
        thClass: 'table-cell--expand',
        width: COLUMN_WIDTH.dsm.expand,
      },
      {
        key: 'date',
        label: 'Date',
        sortable: true,
        width: COLUMN_WIDTH.dsm.date,
      },
      {
        key: 'editor',
        label: 'Editor',
        sortable: true,
        width: COLUMN_WIDTH.dsm.editor,
      },
      {
        key: 'affected_entity',
        label: 'Service user',
        sortable: true,
        width: COLUMN_WIDTH.dsm.user,
      },
      {
        key: 'changes',
        label: 'Editable object',
        width: COLUMN_WIDTH.dsm.changes,
      },
      {
        key: 'before_edit',
        label: 'Before edit',
        width: COLUMN_WIDTH.dsm.before,
      },
      {
        key: 'after_edit',
        label: 'After edit',
        width: COLUMN_WIDTH.dsm.after,
      },
    ],

    nestedTableFields: [
      {
        key: '0',
        width: COLUMN_WIDTH.dsm.expand,
      },
      {
        key: 'date',
        width: COLUMN_WIDTH.dsm.date,
      },
      {
        key: 'editor',
        width: COLUMN_WIDTH.dsm.editor,
      },
      {
        key: 'user',
        width: COLUMN_WIDTH.dsm.user,
      },
      {
        key: 'changes',
        width: COLUMN_WIDTH.dsm.changes,
      },
      {
        key: 'before_edit',
        width: COLUMN_WIDTH.dsm.before,
      },
      {
        key: 'after_edit',
        width: COLUMN_WIDTH.dsm.after,
      },
    ],
    tableId: 'al-dsm-services',
  },
  'dsm-connections': {
    fields: [
      {
        key: 'kmtx-expand',
        label: '',
        thClass: 'table-cell--expand',
        width: COLUMN_WIDTH.dsm.expand,
      },
      {
        key: 'date',
        label: 'Date',
        sortable: true,
        width: COLUMN_WIDTH.dsm.date,
      },
      {
        key: 'editor',
        label: 'Editor',
        sortable: true,
        width: COLUMN_WIDTH.dsm.editor,
      },
      {
        key: 'affected_entity',
        label: 'Data source',
        sortable: true,
        width: COLUMN_WIDTH.dsm.user,
      },
      {
        key: 'changes',
        label: 'Editable object',
        width: COLUMN_WIDTH.dsm.changes,
      },
      {
        key: 'before_edit',
        label: 'Before edit',
        width: COLUMN_WIDTH.dsm.before,
      },
      {
        key: 'after_edit',
        label: 'After edit',
        width: COLUMN_WIDTH.dsm.after,
      },
    ],

    nestedTableFields: [
      {
        key: '0',
        width: COLUMN_WIDTH.dsm.expand,
      },
      {
        key: 'date',
        width: COLUMN_WIDTH.dsm.date,
      },
      {
        key: 'editor',
        width: COLUMN_WIDTH.dsm.editor,
      },
      {
        key: 'user',
        width: COLUMN_WIDTH.dsm.user,
      },
      {
        key: 'changes',
        width: COLUMN_WIDTH.dsm.changes,
      },
      {
        key: 'before_edit',
        width: COLUMN_WIDTH.dsm.before,
      },
      {
        key: 'after_edit',
        width: COLUMN_WIDTH.dsm.after,
      },
    ],
    tableId: 'al-dsm-connection',
  },
  'dsm-access-requests': {
    fields: [
      {
        key: 'kmtx-expand',
        label: '',
        thClass: 'table-cell--expand',
        width: COLUMN_WIDTH.dsm.expand,
      },
      {
        key: 'date',
        label: 'Date',
        sortable: true,
        width: COLUMN_WIDTH.dsm.date,
      },
      {
        key: 'editor',
        label: 'Creator',
        sortable: true,
        width: COLUMN_WIDTH.dsm.editor,
      },
      {
        key: 'affected_entity',
        label: 'Access request ID',
        sortable: true,
        width: COLUMN_WIDTH.dsm.user,
      },
      {
        key: 'changes',
        label: 'Editable object',
        width: COLUMN_WIDTH.dsm.changes,
      },
      {
        key: 'before_edit',
        label: 'Before edit',
        width: COLUMN_WIDTH.dsm.before,
      },
      {
        key: 'after_edit',
        label: 'After edit',
        width: COLUMN_WIDTH.dsm.after,
      },
    ],

    nestedTableFields: [
      {
        key: '0',
        width: COLUMN_WIDTH.dsm.expand,
      },
      {
        key: 'date',
        width: COLUMN_WIDTH.dsm.date,
      },
      {
        key: 'editor',
        width: COLUMN_WIDTH.dsm.editor,
      },
      {
        key: 'user',
        width: COLUMN_WIDTH.dsm.user,
      },
      {
        key: 'changes',
        width: COLUMN_WIDTH.dsm.changes,
      },
      {
        key: 'before_edit',
        width: COLUMN_WIDTH.dsm.before,
      },
      {
        key: 'after_edit',
        width: COLUMN_WIDTH.dsm.after,
      },
    ],
    tableId: 'al-dsm-access-requests',
  },
  'dsm-data-marts': {
    fields: [
      {
        key: 'kmtx-expand',
        label: '',
        thClass: 'table-cell--expand',
        width: COLUMN_WIDTH.dsm.expand,
      },
      {
        key: 'date',
        label: 'Date',
        sortable: true,
        width: COLUMN_WIDTH.dsm.date,
      },
      {
        key: 'editor',
        label: 'Editor',
        sortable: true,
        width: COLUMN_WIDTH.dsm.editor,
      },
      {
        key: 'changes',
        label: 'Editable object',
        width: COLUMN_WIDTH.dsm.changes,
      },
      {
        key: 'before_edit',
        label: 'Before edit',
        width: COLUMN_WIDTH.dsm.before,
      },
      {
        key: 'after_edit',
        label: 'After edit',
        width: COLUMN_WIDTH.dsm.after,
      },
    ],

    nestedTableFields: [
      {
        key: '0',
        width: COLUMN_WIDTH.dsm.expand,
      },
      {
        key: 'date',
        width: COLUMN_WIDTH.dsm.date,
      },
      {
        key: 'editor',
        width: COLUMN_WIDTH.dsm.editor,
      },
      {
        key: 'user',
        width: COLUMN_WIDTH.dsm.user,
      },
      {
        key: 'changes',
        width: COLUMN_WIDTH.dsm.changes,
      },
      {
        key: 'before_edit',
        width: COLUMN_WIDTH.dsm.before,
      },
      {
        key: 'after_edit',
        width: COLUMN_WIDTH.dsm.after,
      },
    ],
    tableId: 'al-dsm-data-mart',
  },
};

export const ACTIVITY_LOG_PAGES = [
  {
    id: 'iam-users',
    name: 'IAM users',
    permission: 'iam:users:tab:view',
    route: '/activity-log/iam-users',
  },
  {
    id: 'iam-accounts',
    name: 'IAM accounts',
    permission: 'iam:accounts:tab:view',
    route: '/activity-log/iam-accounts',
  },
  {
    id: 'iam-roles',
    name: 'IAM roles',
    permission: 'iam:roles:tab:view',
    route: '/activity-log/iam-roles',
  },
  {
    id: 'dsm-users',
    name: 'DSM users',
    permission: 'dw:dsm:users:view',
    route: '/activity-log/dsm-users',
  },
  {
    id: 'dsm-services',
    name: 'DSM services',
    permission: 'dw:dsm:users:view', // TODO: check
    route: '/activity-log/dsm-services',
  },
  {
    id: 'dsm-connections',
    name: 'DSM connections',
    permission: 'dw:dsm:data-sources:view',
    route: '/activity-log/dsm-connections',
  },
  {
    id: 'dsm-access-requests',
    name: 'DSM Access requests',
    permission: 'dw:dsm:access-requests:approve',
    route: '/activity-log/dsm-access-requests',
  },
];

// TODO add without LocalStorage when backend will work normal (r.kravtsov) 1 of 2 part
if(LocalStorage.get('activity-log-data-marts')) {
  ACTIVITY_LOG_PAGES.push({
    id: 'dsm-data-marts',
    name: 'DSM Datamarts',
    permission: 'dw:dsm:data-sources:view',
    route: '/activity-log/dsm-data-marts',
  })
}

export const ACTIVITY_LOG_PER_PAGE = [10, 25, 50];

export const ACTIVITY_LOG_SORTING = {
  sort_by: 'date',
  sort_direction: 'desc',
};

export const ACTIVITY_LOG_CHECKBOX = {
  accounts: {
    key: 'accounts',
    name: 'accounts',
    items: 'accountLabels',
    component: 'checkbox-group-filter',
  },
  actions: {
    key: 'actions',
    name: 'actions',
    items: 'actionLabels',
    component: 'checkbox-group-filter',
  },
  roles: {
    key: 'role_names',
    name: 'roles',
    items: 'rolesLabels',
    component: 'checkbox-group-filter',
  },
  custom_roles: {
    key: 'roles',
    name: 'custom roles',
    items: 'customRolesLabels',
    component: 'checkbox-group-filter',
  },
  sensitive: {
    key: 'sensitive',
    name: 'sensitive',
    items: 'sensitiveLabels',
    component: 'dropdown-filter',
  },
  data_source_name: {
    key: 'data_source_name',
    name: 'data_source',
    items: 'dataSourceLabels',
    component: 'dropdown-filter',
  },
};
