<template>
    <div
        class="table-header__header grid"
        :class="{ 'table-header__header--has-search': searchByField }"
    >
        <div class="table-header__header-inner">
            <SearchInput
                v-if="searchByField"
                class="table-header__search"
                :search="searchValue"
                :label="`Search ${searchLabel}`"
                :borderless="borderless"
                @change="onChange"
            />

            <div class="table-header__pagination grid grid--centered">
                <PaginationTotal :value="+pagination.total" />
                <KPagination
                    class="table-header__pagination"
                    :page="+pagination.page"
                    :pages="+pagination.pages || defaultOptions.pages"
                    :per-page="+pagination.limit"
                    :per-page-options="pagination.perPageOptions || defaultOptions.perPageOptions"
                    @perPageUpdated="(event) => onChange('limit', String(event.name))"
                    @submit="(event) => onChange('page', String(event))"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { KPagination } from '@kilometrix/polyomino';
import SearchInput from '@/components/SearchInput.vue';
import PaginationTotal from '../ui/PaginationTotal.vue';

export default {
    name: 'TableHeaderSection',
    components: {
        KPagination,
        SearchInput,
        PaginationTotal,
    },
    props: {
        pagination: {
            type: Object,
            default: () => null,
        },
        searchByField: {
            type: Boolean,
            default: false,
        },
        searchInputValue: {
            type: String,
            default: '',
        },
        searchLabel: {
            type: String,
            default: 'by login',
        },
        borderless: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            defaultOptions: {
                pages: 1,
                perPageOptions: [15, 25, 50],
            },
        };
    },

    computed: {
        searchValue() {
            const [searchQueryProp] = this.searchLabel.split(' ').filter((item) => item !== 'by') || [this.searchLabel];

            return this.$props.searchInputValue || this.$route.query[searchQueryProp];
        },
    },
    emits: ['change'],
    methods: {
        onChange(name, data) {
            this.$emit('change', name, data);
        },
    },
};
</script>
<style lang="scss" scoped>
@import '../../sass/_variables';

.table-header {
    $table-header: &;
    &__header {
        width: auto;
        padding: 1rem 0;
        &-inner {
            width: 100%;
            display: flex;
            justify-content: end;
            & > div {
                max-height: 40px;
            }
        }
        &--has-search {
            width: 100%;
            #{$table-header}__header-inner {
                justify-content: space-between;
            }
        }
    }
    &__search {
        width: 350px;
        :deep(.k-text-field__leading-icon) {
            left: 6px;
        }
    }
    &__pagination {
        display: flex;
        align-items: center;
        z-index: 9;

        :deep(.k-pagination__nav-badge) {
            height: 32px !important;
            line-height: 22px;
        }

        :deep(.pagination-total) {
            padding: 4px 16px 4px 0;
            margin-right: 16px;
            border-right: 1px solid $gray200;
        }
    }
}
</style>
