<template>
  <div
    ref="sunBurstContainer"
    class="routes-diagrams-container"
  >
    <div class="sunburst-breadcrumbs">
      <ul
        v-if="clickedPathInfo.length > 1 && !chartHovered"
        class="sunburst-breadcrumbs--clicked"
      >
        <li
          v-for="(item, index) in clickedPathInfoFiltered"
          :key="index"
          class="sunburst-breadcrumbs__crumb"
          :style="styleBreadCrumb(item.name)"
        >
          <span class="sunburst-breadcrumbs__crumb-text sunburst-text">{{ item.name }} <sup>{{ kFormatter(item.value) }}</sup></span>
        </li>
      </ul>

      <ul
        v-if="hoveredPathInfo.length > 1 && chartHovered"
        class="sunburst-breadcrumbs--hovered"
      >
        <li
          v-for="(item, index) in hoveredPathInfoFiltered"
          :key="index"
          class="sunburst-breadcrumbs__crumb"
          :style="styleBreadCrumb(item.name)"
        >
          <span class="sunburst-breadcrumbs__crumb-text sunburst-text">{{ item.name }} <sup>{{ kFormatter(item.value) }}</sup></span>
        </li>
      </ul>
    </div>

    <ul class="sunburst-legend">
      <li
        v-for="(value, name, index) in orderedLegend"
        :key="index"
        class="sunburst-legend__text"
        :style="`background:${value}`"
      >
        <span class="sunburst-text">{{ name }}</span>
      </li>
    </ul>

    <v-chart
      ref="routeTransitionSunburst"
      :options="sunBurstOptions"
      :init-options="initOptions"
      class="sunburst mt-4"
      @click="onClick"
      @mouseover="onHover"
      @mouseout="onLeave"
    />
  </div>
</template>
<script>
import ECharts from 'vue-echarts';
import 'echarts/lib/chart/sunburst';
import 'echarts/lib/component/tooltip';

export default {
  name: 'SunburstDiag',
  components: {
    'v-chart': ECharts,
  },
  data() {
    return {
      initOptions: {
        renderer: 'canvas',
      },
      chartHovered: false,
      clickedPathInfo: [],
      hoveredPathInfo: [],
      routes: [],
      legendMap: {},
      colorMap: {
        out: '#eee',
      },
      palette: [],
      sunBurstOptions: {
        tooltip: {
          trigger: 'item',
        },
        series: {
          name: 'Back',
          type: 'sunburst',
          sort: 'desc',
          highlightPolicy: 'ancestor',
          data: [],
          radius: ['4%', '100%'],
          tooltip: {
            formatter(param) {
              return (param.data.path !== undefined ? `${param.data.path} (${param.data.value})` : '');
            },
          },
          levels: [
            {
              itemStyle: {
                color: '#8DCBFF',
              },
              label: {
                color: 'blue',
                rotate: 'tangential',
              },
            },
          ],
          label: {
            rotate: 'radial',
            minAngle: 8,
          },
          itemStyle: {
            borderWidth: 2,
          },
          emphasis: {
            itemStyle: {
              color: 'red',
            },
          },
        },
      },
    };
  },
  computed: {
    orderedLegend() {
      const ordered = {};
      const unordered = this.legendMap;
      Object.keys(unordered).sort().forEach((key) => {
        ordered[key] = unordered[key];
      });
      return ordered;
    },
    clickedPathInfoFiltered() {
      return this.clickedPathInfo.filter((path, index) => index > 0);
    },
    hoveredPathInfoFiltered() {
      return this.hoveredPathInfo.filter((path, index) => index > 0);
    },
  },
  beforeDestroy() {
    if (this.$refs.routeTransitionSunburst.isDisposed) {
      this.$refs.routeTransitionSunburst.dispose();
    }
  },
  mounted() {
    this.calcHeight('.echarts');
    this.calcHeight('.sunburst-legend');
  },
  methods: {
    kFormatter(number) {
      if (number < 1e3) return number;
      if (number >= 1e3 && number < 1e6) return `${+(number / 1e3).toFixed(1)}K`;
      if (number >= 1e6 && number < 1e9) return `${+(number / 1e6).toFixed(1)}M`;
      if (number >= 1e9 && number < 1e12) return `${+(number / 1e9).toFixed(1)}B`;
      if (number >= 1e12) return `${+(number / 1e12).toFixed(1)}T`;
    },
    calcHeight(element) {
      const container = document.querySelector(element);
      if (container !== null) {
        const containerTopOffset = Math.round(container.getBoundingClientRect().top);
        container.style.height = `calc(100vh - ${containerTopOffset}px)`;
      }
    },
    setData(data) {
      this.sunBurstOptions.series.data = data;

      this.legendMap = {};
      this.clickedPathInfo = [];
      this.hoveredPathInfo = [];

      this.colorize();

      this.$refs.routeTransitionSunburst.resize();
    },
    styleBreadCrumb(itemName) {
      const color = Object.prototype.hasOwnProperty.call(this.colorMap, itemName) ? this.colorMap[itemName] : 'lightgray';
      return `background:${color};color:${color}`;
    },
    onClick(event) {
      if (event.dataIndex === 0) {
        this.clickedPathInfo.pop();
      } else {
        this.clickedPathInfo = event.treePathInfo;
      }
    },
    onHover(event) {
      this.hoveredPathInfo = event.treePathInfo;
      if (event.dataIndex !== 0) {
        this.chartHovered = true;
      }
    },
    onLeave() {
      this.hoveredPathInfo = [];
      this.chartHovered = false;
    },
    colorize() {
      // build unique routes
      this.dataIterator(this.countRoutes);

      // generate palette based on routes count
      this.generatePalette();

      // colorize all data
      this.dataIterator(this.colorizeItem);
    },
    dataIterator(parser) {
      for (let i = 0; i <= this.sunBurstOptions.series.data.length; i++) {
        this.parseObjectProperties(this.sunBurstOptions.series.data[i], parser);
      }
    },
    countRoutes(key, object) {
      if (key === 'name' && !this.routes.includes(object[key])) {
        this.routes.push(object[key]);
      }
    },
    generatePalette() {
      const routesLength = this.routes.length - 1;
      const colors = Math.round(360 / routesLength);
      let angle = 0;

      for (let i = 0; i < routesLength; i++) {
        angle = i * colors;
        this.palette.push(`hsl(${angle}, 50%, 50%)`);
      }
    },
    parseObjectProperties(object, parser) {
      for (const key in object) {
        if (typeof object[key] === 'object' && object[key] !== null) {
          this.parseObjectProperties(object[key], parser);
        } else if (Object.prototype.hasOwnProperty.call(object, key)) {
          parser(key, object);
        }
      }
    },
    colorizeItem(key, object) {
      let color = '';

      if (key === 'name') {
        if (Object.prototype.hasOwnProperty.call(this.colorMap, object[key])) {
          color = this.colorMap[object[key]];
        } else {
          const colorId = Math.floor(Math.random() * this.palette.length);
          color = this.palette[colorId];
          this.colorMap[object[key]] = color;
          this.palette.splice(colorId, 1);
        }

        object.itemStyle = { color };
        // object['itemStyle'] = {'color': this.sunBurstOptions.color[object.keys(this.colorMap).indexOf(object[key])]};
        this.legendMap[object[key]] = color;
      }
    },
  },
};
</script>

<style>
    .sunburst {
        width: 100% !important;
        height: 100vh;
    }

    .sunburst-legend {
        margin: 0;
        padding: 0;
        position: absolute;
        right: 30px;
        z-index: 1000;
        -webkit-overflow-scrolling: touch;
        overflow-y: auto;
    }

    .sunburst-legend__text {
        padding: 2px 4px;
        list-style-type: none;
        margin: 0 0 2px;
    }

    .sunburst-breadcrumbs {
        position: relative;
        min-height: 30px;
        margin-bottom: 1.5rem;
    }

    .sunburst-breadcrumbs--clicked,
    .sunburst-breadcrumbs--hovered {
        position: absolute;
        z-index: 1000;
        list-style-type: none;
        margin: 0;
        padding: 0 30px 0 0;
        display: flex;
        overflow: hidden;
    }

    .sunburst-breadcrumbs__crumb {
        color: white;
        text-decoration: none;
        padding: 2px 0 2px 25px;
        position: relative;
    }

    .sunburst-text {
        color: #fff;
        mix-blend-mode: overlay;
        font-weight: 500;
    }

    .sunburst-breadcrumbs__crumb:first-child {
        padding-left: 15px;
    }

    .sunburst-breadcrumbs__crumb::after,
    .sunburst-breadcrumbs__crumb::before {
        content: '';
        display: block;
        width: 0;
        height: 0;
        border-top: 25px solid transparent;
        border-bottom: 25px solid transparent;
        position: absolute;
        top: 50%;
        margin-top: -25px;
        left: 100%;
    }

    .sunburst-breadcrumbs__crumb::after {
        border-left: 15px solid;
        z-index: 2;
    }

    .sunburst-breadcrumbs__crumb::before {
        border-left: 15px solid white;
        margin-left: 1px;
        z-index: 1;
    }
</style>
