<template>
  <div class="dashboard-rows">
    <b-row
      v-if="editState && haveFilters"
      class="mb-4"
    >
      <b-col>
        Data in charts displayed despite filters
      </b-col>
    </b-row>
    <DashboardRow
      v-for="(row, index) in widgets"
      :key="index"
      :dashboard="dashboard"
    />

    <DashboardNewRow
      v-if="editState"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import DashboardRow from './DashboardRow.vue';
import DashboardNewRow from './DashboardNewRow.vue';

export default {
  name: 'DashboardRows',
  components: {
    DashboardRow,
    DashboardNewRow,
  },
  props: {
    dashboard: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapState('dataMarts', ['widgets', 'editState']),
    haveFilters() {
      return this.dashboard.configuration.filters_list?.length;
    },
  },
};
</script>

<style scoped lang="scss">
    .dashboard-rows
    {
        margin-bottom: 20px;
    }
</style>
