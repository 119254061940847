import RoutingViewContainer from '@/components/RoutingViewContainer.vue';

const ParametersPage = () => import(/* webpackChunkName: '[ParametersPage]' */ './pages/parameters/ParametersPage.vue');
const EventParameterPage = () => import(/* webpackChunkName: '[EventParameterPage]' */ './pages/event-parameter/EventParameterPage.vue');
const GlobalParameterPage = () =>
    import(/* webpackChunkName: '[GlobalParameterPage]' */ './pages/global-parameter/GlobalParameterPage.vue');

export default {
    path: '/tracking/parameters',
    name: 'parameters',
    component: RoutingViewContainer,
    redirect: { name: 'event-parameters' },
    children: [
        {
            path: 'event',
            name: 'event-parameters',
            component: RoutingViewContainer,
            redirect: { name: 'event-parameters-list' },
            children: [
                {
                    path: '',
                    name: 'event-parameters-list',
                    component: ParametersPage,
                    meta: {
                        permission: 'tracking:rules:var',
                        browserTitle: 'Event parameters - Tracking',
                    },
                },
                {
                    path: 'new',
                    name: 'new-event-parameter',
                    component: EventParameterPage,
                    meta: {
                        permission: 'tracking:rules:var:create',
                        browserTitle: 'Create event variable - Tracking',
                    },
                },
                {
                    path: ':id',
                    name: 'event-parameter',
                    component: EventParameterPage,
                    meta: {
                        permission: 'tracking:rules:var',
                        browserTitle: 'Edit event variable - Tracking',
                    },
                },
            ],
        },
        {
            path: 'global',
            name: 'global-parameters',
            component: RoutingViewContainer,
            redirect: { name: 'global-parameters-list' },
            children: [
                {
                    path: '',
                    name: 'global-parameters-list',
                    component: ParametersPage,
                    meta: {
                        permission: 'tracking:rules:var',
                        browserTitle: 'Global parameters - Tracking',
                    },
                },
                {
                    path: 'new',
                    name: 'new-global-parameter',
                    component: GlobalParameterPage,
                    meta: {
                        permission: 'tracking:rules:var:create',
                        browserTitle: 'Create global variable - Tracking',
                    },
                },
                {
                    path: ':id',
                    name: 'global-parameter',
                    component: GlobalParameterPage,
                    meta: {
                        permission: 'tracking:rules:var',
                        browserTitle: 'Edit global variable - Tracking',
                    },
                },
            ],
        },
    ],
};
