<template>
  <div
    class="big-number grid__col grid grid--centered grid--direction-column"
    :class="theme"
  >
    <header
      v-if="$slots.header"
      class="big-number__header"
    >
      <slot name="header" />
    </header>
    <main class="big-number__main">
      <p
        v-if="!value"
        class="big-number__default-text"
      >
        {{ defaultText }}
      </p>
      <p
        v-else
        class="big-number__value"
      >
        {{ calculatedValue }}
      </p>
    </main>
    <footer
      v-if="$slots.footer"
      class="big-number__footer"
    >
      <slot name="footer" />
    </footer>
  </div>
</template>

<script>
export default {
  name: 'BigNumber',

  props: {
    defaultText: {
      type: String,
      default: 'Loading...',
    },

    value: {
      required: true,
      validator: (prop) => typeof prop === 'string' || typeof prop === 'number' || prop === null,
    },

    variant: {
      type: String,
      default: 'default',
    },
  },

  computed: {
    theme() {
      return `big-number--${this.variant}`;
    },

    calculatedValue() {
      if (typeof this.value === 'string') {
        if (this.value == parseInt(this.value, 10)) {
          return this.filters.bigNumberFormat(parseInt(this.value, 10));
        }

        return this.value;
      }

      return this.filters.bigNumberFormat(this.value);
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/sass/_variables";

.big-number {
    background-color: $white-1;
    border-radius: .25rem;
    border-width: 1px 1px 1px 10px;
    border-style: solid;
    padding: 1.25rem;
    margin-bottom: 15px;
    width: calc(100% / 6);
    display: inline-flex;
    flex-grow: 0;

    &--default {
        border-color: rgba(0, 0, 0, .125);
    }

    &--info {
        border-color: $blue-2;
        background-color: $light-blue-2;
    }

    &--warning {
        border-color: $yellow-1;
        background-color: $light-yellow-1;
    }

    &--danger {
        border-color: $red-1;
        background-color: $light-red-1;
    }

    &--success {
        border-color: $green-1;
        background-color: $light-green-1;
    }

    &:not(:last-child) {
        margin-right: 15px;
    }

    &__default-text {
        margin-bottom: 0;
        min-height: 35px;
    }

    &__value {
        font: 28px/1.25 system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif;
        margin-bottom: 0;
    }
}
</style>
