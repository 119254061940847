<template>
  <b-row
    align-v="end"
    class="kmtx-filters-bar"
    mb-4
  >
    <!--    <b-row class="filters-bar">-->
    <kmtx-filter
      v-for="(filter, index) in filters"
      :key="index"
      :ref="filter.reference"
      :index="index"
      :class="kmtxFiltersBarCol"
      :filter="filter.filter"
      :label="filter.label"
      :placeholder="filter.placeholder"
      :value="filter.value"
      :data="filter.data"
      :type="filter.type"
      :reference="filter.reference"
      :options="filter.options"
      v-on="$listeners"
    />
  </b-row>
</template>

<script>
import _set from 'lodash.set';
import kmtxFilter from '../index.vue';

export default {
  name: 'KmtxFilterBuilder',
  components: {
    kmtxFilter,
  },
  props: {
    filters: {
      type: Array,
      required: true,
    },
    willGrow: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['init'],
  data() {
    return {
    };
  },
  computed: {
    kmtxFiltersBarCol() {
      return this.willGrow ? 'flex-grow-1' : 'flex-grow-0';
    },
  },
  mounted() {
    this.$emit('init');
  },
  methods: {
    setFilterProp(filters, filterReference, propName, propValue) {
      const filterId = this.getFilterIdByReference(filters, filterReference);

      if (filterId !== null) {
        if (propName.startsWith('options.')) {
          // eslint-disable-next-line no-undef
          _set(filters[filterId], propName, propValue);
        } else {
          this.$set(filters[filterId], propName, propValue);
        }
      }
    },
    getFilterProp(filters, filterReference, propName) {
      const filterId = this.getFilterIdByReference(filters, filterReference);

      if (filterId) {
        return filters[filterId][propName];
      }

      return null;
    },
    getFilterIdByReference(filters, filterReference) {
      let filterIndex = null;

      filters.find((filter) => {
        if (filter.reference === filterReference) {
          filterIndex = filters.indexOf(filter);
        }
      });

      return filterIndex;
    },
  },
};
</script>

<style>
    .kmtx-filters-bar .form-control,
    .kmtx-filters-bar .kmtx-filters-item {
        min-width: 10em;
    }
</style>
