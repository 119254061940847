import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router/composables';
import { omit, isEqual } from 'lodash';

function useQuesryFilter() {
    const route = useRoute();
    const router = useRouter();
    const queryFilters = computed(() => route.query);

    // TODO: move this logic to components when they will be rewritten on composition api
    const pagesWithFilters = ['activity-log', 'data-sources', 'data-source-users', 'iam-users'];

    async function reloadWithQuery() {
        const isNeedToClear = !!route.matched?.find((el) => pagesWithFilters.includes(el.name ?? ''));

        // removing context account and page from query or clear all if needed
        // TODO: we can keep/remove date or some other filters in future
        const query = isNeedToClear ? {} : omit(queryFilters.value, ['context-account']);

        // update url if needed
        if (!isEqual(route.query, query)) await router.replace({ query });

        // reload the page
        window.location.reload();
    }

    return {
        reloadWithQuery,
    };
}

export default useQuesryFilter;
