import SdkDebuggerService from '../../services/SdkDebugger';

// initial state
const state = {
  events: null,
  sources: []
};

// getters
const getters = {
  getEvent: (state: any) => state.event,
};

// actions
const actions = {
  fillEvents({ commit }: any, { params = {}, options = {} }) {
    SdkDebuggerService.getListEvents(params, options).then((response) => {
      commit('setEvents', response.data);
    });
  },

  fillSources({ commit }: any, { params = {}}) {
    SdkDebuggerService.getListSources(params).then((response) => {
      commit('setSources', response.data);
    });
  }
};

// mutations
const mutations = {
  setEvents(state: any, payload: any) {
    state.events = payload;
  },

  setSources(state: any, payload: any) {
    state.sources = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
