<template>
  <div class="dashboard-row-preview grid">
    <div
      v-for="id in cols"
      :key="id"
      class="dashboard-row-preview__col grid__col grid grid--centered"
      @click="$emit('chosen', cols)"
    >
      <span v-if="cols > 1">
        1/{{ cols }}
      </span>

      <span v-else>
        full width
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DashboardRowPreview',

  props: {
    cols: {
      type: Number,
      required: true,
    },
  },

  emits: ['chosen'],
};
</script>

<style scoped lang="scss">
@import "@/sass/_variables";

.dashboard-row-preview {
    display: inline-flex;
    position: relative;
    width: 10vw;
    height: 10vh;
    margin: 0 10px;
    cursor: pointer;

    &__col {
        background-color: $light-grey-3;
        border: 1px solid $light-grey-2;
        user-select: none;

        &:not(:only-child):not(:first-child) {
            border-left-width: 0;
        }
    }
}
</style>
