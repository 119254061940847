<template>
  <b-dropdown
    :data-user-id=currentUserId
    class="user-menu"
    no-caret
    right
  >
    <template #button-content>
      <div class="d-flex user-menu__button">
        <avatar class="kmtx-avatar--xs" />
      </div>
    </template>
    <b-dropdown-item disabled>
      <div class="user-menu__profile grid grid--direction-column grid--centered">
        <avatar class="user-menu__avatar kmtx-avatar--sm" />
        <div
          v-if="currentUser"
          class="d-flex flex-column align-items-center"
        >
          <span class="user-menu__name"> {{ currentUser.name || 'Your name' }} </span>
          <span class="user-menu__email"> {{ currentUser.email || 'Your email' }} </span>
          <span class="user-menu__id mt-1">ID: {{ currentUser.id || '' }} </span>
        </div>
      </div>
    </b-dropdown-item>
    <b-dropdown-item
      v-if="roles"
      disabled
      class="mb-2"
    >
      <b-dropdown-divider />
      <div class="d-flex flex-row align-items-center">
        <icon
          image="user.svg"
          label="Roles"
          class="user-menu__icon"
          :size="{ width: '16px', height: '16px' }"
        />
        <ul class="user-menu__roles">
          <li
            v-for="role in roles"
            :key="role"
            class="user-menu__role list-unstyled"
          >
            <span>
              {{ role }}
            </span>
          </li>
        </ul>
      </div>
    </b-dropdown-item>
    <b-dropdown-item
      v-if="currentUser && currentUser.timezone"
      class="mb-2"
      disabled
    >
      <div class="grid">
        <icon
          image="clock-fill.svg"
          label="Timezone"
          class="user-menu__icon"
          :size="{ width: '16px', height: '16px' }"
        />
        <span>
          {{ currentUser.timezone }}
        </span>
      </div>
    </b-dropdown-item>
    <b-dropdown-item
      class="mb-lg-4"
    >
      <b-dropdown-divider />
    </b-dropdown-item>
    <b-dropdown-item
      class="my-2"
      @click="goToProfile"
    >
      <btn
        class="w100 kmtx-button--secondary-outline kmtx-button--sm"
        text="User profile"
        icon-file="setting.svg"
      />
    </b-dropdown-item>
    <b-dropdown-item @click="onLogout">
      <btn
        class="w100 kmtx-button--secondary-outline kmtx-button--outline-none kmtx-button--sm"
        text="Log Out"
      />
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import { mapMutations, mapState, mapGetters } from 'vuex';
import EventBus from '@/event';
import { defaultModalOptions } from '@/helper';
import { USER_PROFILE_SIDEBAR } from '@/modules/user-profile/utils/constants';
import IconsComponent from './IconsComponent.vue';
import AvatarComponent from './AvatarComponent.vue';
import Button from './ui/buttons/button.vue';

export default {
  name: 'UserMenu',
  components: {
    icon: IconsComponent,
    avatar: AvatarComponent,
    btn: Button,
  },
  data: () => ({
    darkMode: false,
    userProfileMenu: USER_PROFILE_SIDEBAR,
  }),
  computed: {
    ...mapState('router', ['isRouteChangeAvailable']),
    ...mapState('auth', ['currentUser', 'token']),
    ...mapGetters('auth', ['currentAccount', 'masterRole', 'accounts', 'masterAccountId']),
    currentUserId() {
      return this.currentUser?.id || '';
    },
    roles() {
      let roles = [];
      if (this.currentAccount?.roles && this.currentAccount.roles?.length) {
        roles = this.currentAccount.roles.map((role) => role.name);
      }
      if (this.masterRole && this.currentAccount.parent_id) {
        roles.unshift(this.masterRole.name);
      }
      return roles;
    },
  },
  methods: {
    ...mapMutations('auth', ['destroyTokens']),
    onLogout() {
      if (!this.isRouteChangeAvailable) {
        const options = {
          title: `Confirm logging out`,
          okTitle: 'Yes, log out',
          cancelTitle: 'No, stay on the page',
          size: 'md',
          okVariant: 'danger',
        };

        this.$bvModal.msgBoxConfirm(
          'You have unsaved changes on the page. Are you sure you want to log out?',
          defaultModalOptions(options),
        ).then((result) => {
          if (result) {
            this.logout();
          }
        }).catch((error) => {
          this.errorMessage(error, 'Error');
        });
      } else {
        this.logout();
      }
    },
    logout() {
      EventBus.$emit('kmtx-cancel-requests');
      this.destroyTokens();
      EventBus.$emit('kmtx-sidebar-collapsed');
      this.$router.push('/login');
    },
    goToProfile() {
      if (this.userProfileMenu[0].name !== this.$route.name) {
        this.$router.push({ name: this.userProfileMenu[0].name });
      }
    },
    toggleDarkMode(e) {
      e.preventDefault();
      this.darkMode = !this.darkMode;
      document.documentElement.classList.toggle('dark-mode');
    },
  },
};
</script>

<style lang="scss">
/* stylelint-disable */
@import "../../src/sass/_variables.scss";

.user-menu {
    $parent: &;

    .dropdown-toggle.btn {
        border: unset;
        background-color: unset;
        padding: 0;
        box-shadow: unset;

        &:hover,
        &:focus:active {
            box-shadow: unset;
            background-color: unset;
        }
    }

    .dropdown-menu {
        min-width: 280px;
        max-width: 350px;
        margin-top: 12px;
        box-shadow: 0 9px 24px rgba(0, 0, 0, 0.24);
        border-radius: 0 0 4px 4px;
        border: unset;
    }

    .dropdown-item {
        font-family: $font-family-secondary;
        padding: 0 36px;
        color: $color-secondary-dark-light !important;
        font-size: 12px;
        line-height: 16px;

        &:hover {
            background: unset;
        }
    }

    .dropdown-divider {
        margin: 16px 0;
    }

    &__icon {
        color: $gray500;
        margin-right: 12px;
    }

    &__avatar {
        margin-bottom: 8px;
    }

    &__profile {
        padding: 20px 0 8px;
    }

    &__name {
        margin-bottom: 4px;
        font-size: 18px;
        line-height: 26px;
        font-weight: 500;
    }

    &__email {
        color: $color-secondary;
        font-size: $font-size-sm;
        line-height: $line-height-sm;
    }

    &__id {
        color: $color-secondary;
        font-size: $font-size-sm;
    }

    &__roles {
        padding-left: 0;
    }

    &__role {
        margin: 2px;

        span {
            padding: 0 5px 0 5px;
            border: 1px solid #E0E2ED;
            border-radius: 5px;
            display: inline-block;
        }
    }
}
</style>
