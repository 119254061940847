import UserProfilePage from '@/modules/user-profile/pages/UserProfilePage.vue';

export default {
  path: '/profile',
  component: UserProfilePage,
  name: 'profile',
  redirect: { name: 'user-profile-general' },
  children: [
    {
      path: 'general',
      component: () => import('@/modules/user-profile/components/UserProfileGeneral.vue'),
      name: 'user-profile-general',
      meta: {
        allowForMaster: true,
        browserTitle: 'User profile - General',
      },
    },
    {
      path: 'accounts',
      component: () => import('@/modules/user-profile/components/UserProfileAccounts.vue'),
      name: 'user-profile-accounts',
      meta: {
        allowForMaster: true,
        browserTitle: 'User profile - Accounts',
      },
    },
  ],
};
