const state = {
  initialSteps: [
    { value: 'auto', text: 'Auto' },
    { value: 5, text: '5 minutes' },
    { value: 15, text: '15 minutes' },
    { value: 30, text: '30 minutes' },
    { value: 60, text: '1 hour' },
    { value: 300, text: '5 hours' },
    { value: 1440, text: '1 day' },
    { value: 7200, text: '5 day' },
  ],
  steps: [],
  currentStep: 'auto',
  domains: [],
  currentDomain: {
    id: 0,
    name: 'All',
    labelType: 'all_sources',
  },
  filters: [],
  dates: [],
  exportValue: null,
};

const actions = {
  setDates({ commit }: any, dates: any) {
    commit('updateDates', dates);
    commit('updateSteps', dates);
  },
  clearDates({ commit }: any) {
    const defaultDates = [new Date(), new Date()];
    commit('updateDates', defaultDates);
    commit('updateSteps', defaultDates);
  },
  setDomains({ commit }: any, domains: any) {
    const parsedDomains = domains.map((source: any) => ({
      id: source.id,
      name: source.host,
      labelType: source.id === 0 ? 'all_sources' : source.label ? 'label' : 'source',
    }));

    commit('updateDomains', parsedDomains);
  },
  setCurrentDomain({ commit }: any, currentDomain: any) {
    commit('updateCurrentDomain', currentDomain);
  },
  setCurrentDomainByQueryParams({ commit, state }: any, params: any) {
    const defaultDomain = {
      id: 0,
      name: 'All',
      labelType: 'all_sources',
    };
    let domain;

    if (!!params && params.host && params.labeltype) {
      domain = state.domains.find((domain: any) => domain.id === Number(params.host) && domain.labelType === params.labeltype);
    }
    commit('updateCurrentDomain', domain || defaultDomain);
  },
};

const mutations = {
  updateSteps(state: any) {
    let filteredSteps: { value: string | number, text: string }[] = [];
    if (state.dates.length) {
      const diff = parseInt((new Date(state.dates[1]).getTime() - new Date(state.dates[0]).getTime() / 1000 / 60 / 60 / 24 as any), 10);

      state.initialSteps.map((item: any) => {
        if (item.value === 'auto') {
          filteredSteps.push(item);
        }

        if (diff === 0 && item.value <= 300) {
          filteredSteps.push(item);
        } else if (diff > 0 && diff <= 2 && item.value >= 60 && item.value <= 1440) {
          filteredSteps.push(item);
        } else if (diff > 2 && diff <= 6 && item.value >= 300 && item.value <= 7200) {
          filteredSteps.push(item);
        } else if (diff > 6 && item.value >= 1440 && item.value <= 7200) {
          filteredSteps.push(item);
        }
      });
    } else {
      filteredSteps = state.initialSteps;
    }

    state.steps = filteredSteps;

    const isCurrentStepInFilteredSteps = state.steps.find((element: any) => element.value === state.currentStep);

    if (isCurrentStepInFilteredSteps === undefined) {
      state.currentStep = state.initialSteps[0].value;
    }
  },
  updateStep(state: any, currentStep: any) {
    state.currentStep = currentStep;
  },
  updateDates(state: any, dates: any) {
    state.dates = dates;
  },
  updateDomains(state: any, domains: any) {
    state.domains = domains;
  },
  updateCurrentDomain(state: any, currentDomain: any) {
    state.currentDomain = currentDomain;
  },
  updateExportValue(state: any, value: any) {
    state.exportValue = value;
  },
  setFilterByIndex(state: any, { index, filter }: any) {
    state.filters[index] = filter;
  },
  clearFilters(state: any) {
    state.filters = [];
  },
  updateFilterByIndex(state: any, { index, filter }: any) {
    const updatedFilter = { ...state.filters[index], ...filter };
    state.filters.splice(index, 1, updatedFilter);
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
