<template>
  <div class="dashboard-row">
    <div
      v-if="editState"
      class="dashboard-row__header grid"
    >
      <h4 class="dashboard-row__title grid__col grid__col--no-grow">
        Row
      </h4>
      <div
        class="grid__col"
      >
        <b-button
          size="sm"
          variant="outline-primary"
          @click="onEditLayout()"
        >
          <i
            class="fa fa-pencil-alt"
            aria-hidden="true"
          />
        </b-button>
        <b-button
          size="sm"
          variant="outline-secondary"
          @click="showDeleteModal = true"
        >
          <i
            class="fa fa-trash"
            aria-hidden="true"
          />
        </b-button>
      </div>
    </div>
    <div
      class="dashboard-row__widgets grid"
      :class="`grid_size_${widgets[$vnode.key].length}`"
    >
      <div
        v-for="(widget, index) in widgets[$vnode.key]"
        :key="index"
        :class="['dashboard-row__col', 'grid__col', editState ? 'dashboard-row__col--edit' : '']"
      >
        <div
          v-if="editState && !widget.id"
          class="dashboard-row__new grid grid--centered"
          @click="selectWidget(index)"
        >
          <b-button
            variant="success"
          >
            Add new widget
          </b-button>
        </div>

        <DashboardWidget
          v-if="widget.id"
          :widget="widget"
          :dashboard="dashboard"
          @edit="selectWidget(index)"
        />
      </div>
    </div>

    <!-- Edit modal -->

    <b-modal
      id="row-edit"
      v-model="showEditModal"
      size="lg"
      centered
    >
      <template #modal-header>
        <h5 class="mb-0">
          Change row layout
        </h5>
      </template>

      <template #default>
        <div class="row-edit-layout grid">
          <DashboardRowPreview
            :cols="2"
            class="grid__col"
            @chosen="onChangeColsCount"
          />
          <DashboardRowPreview
            :cols="1"
            class="grid__col"
            @chosen="onChangeColsCount"
          />
          <DashboardRowPreview
            :cols="3"
            class="grid__col"
            @chosen="onChangeColsCount"
          />
        </div>
      </template>
    </b-modal>

    <!-- Delete modal-->

    <b-modal
      id="row-delete"
      v-model="showDeleteModal"
      size="sm"
      centered
      @ok="onRowDelete"
    >
      <template #modal-header>
        <h5 class="mb-0">
          Delete this row?
        </h5>
      </template>

      <template #default>
        <p>
          Please confirm that you want to delete this row and all containing widgets.
        </p>
        <p class="mb-0">
          <b>You can't undo this operation.</b>
        </p>
      </template>

      <template #modal-footer="{ ok, cancel }">
        <b-button
          size="sm"
          variant="secondary"
          @click="ok()"
        >
          Delete row
        </b-button>
        <b-button
          size="sm"
          variant="primary"
          @click="cancel()"
        >
          Keep row
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import DashboardWidget from './DashboardWidget.vue';
import DashboardRowPreview from './DashboardRowPreview.vue';
import { leaveModalOptions } from '../../helper';

export default {
  name: 'DashboardRow',
  components: {
    DashboardWidget,
    DashboardRowPreview,
  },
  props: {
    dashboard: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      showEditModal: false,
      showDeleteModal: false,
    };
  },
  computed: {
    ...mapState('dataMarts', ['editState', 'widgets', 'filtersFormDirty', 'widgetFormDirty', 'filtersBarVisible']),
  },
  mounted() {
  },
  methods: {
    ...mapMutations('dataMarts', ['setWidgetBarVisibleTo', 'setFiltersBarVisibleTo', 'changeColsCount', 'deleteRow', 'setSelectedWidget']),
    selectWidget(col) {
      if (this.filtersFormDirty || this.widgetFormDirty) {
        this.$bvModal.msgBoxConfirm('You can\'t undo this operation', leaveModalOptions()).then((doLeave) => {
          if (doLeave) {
            if (this.filtersBarVisible) {
              this.setFiltersBarVisibleTo(false);
            }
            this.setSelectedWidget([this.$vnode.key, col]);
            this.setWidgetBarVisibleTo(false);
            this.$nextTick().then(() => {
              this.setWidgetBarVisibleTo(true);
            });
          }
        });
      } else {
        this.setSelectedWidget([this.$vnode.key, col]);
        this.setWidgetBarVisibleTo(true);
      }
    },
    onChangeColsCount(cols) {
      this.changeColsCount({ rowId: this.$vnode.key, cols });
      this.showEditModal = false;
    },

    onRowDelete() {
      this.deleteRow(this.$vnode.key);
      this.showDeleteModal = false;
    },
    onEditLayout() {
      if (this.widgets[this.$vnode.key].find((widget) => widget.name)) {
        const bodyText = this.$createElement('div', ['You can\'t undo this operation']);
        const modalOptions = {
          title: 'You will lose created widgets',
          okTitle: 'Yes, edit',
        };
        return this.$bvModal.msgBoxConfirm(bodyText, leaveModalOptions(modalOptions)).then((doLeave) => {
          if (doLeave) {
            this.showEditModal = true;
          }
        });
      }
      this.showEditModal = true;
    },
  },
};
</script>

<style lang="scss">
    :root {
        --divideTo: 2;
        --page-header--height: 67px;
        --dashboard-row--margin: 40px;
        --dashboard-row--min-height: 350px;
        --dashboard-row--height: calc((100vh - var(--navbar-height) - var(--page-header--height)) / var(--divideTo)
        - var(--dashboard-row--margin));
    }
    .dashboard-row
    {
        &__header
        {
            margin-bottom: 10px;
        }

        &__title
        {
            margin: 0 20px 0 0;
        }

        &__col
        {
            background: #fff;
            width: 100%;
            position: relative;
            margin: 0 10px;

            &:first-child
            {
                margin-left: 0;
            }

            &:last-child
            {
                margin-right: 0;
            }
        }

        &__new
        {
            height: var(--dashboard-row--height);
            min-height: var(--dashboard-row--min-height);
            margin-bottom: var(--dashboard-row--margin);
            border: 1px solid #ccc;
            width: 100%;
            padding: 40px;

            &,
            .btn
            {
                cursor: copy;
            }
        }
    }

    @media screen and (min-height: 1500px) {
        :root {
            --divideTo: 3;
        }
    }
</style>
