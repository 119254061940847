export const skinList = [
  // 'android_atd',
  // 'android_pkw',
  'atd',
  'bvs',
  'direkt',
  'ersatz',
  'eu',
  // 'ios_atd',
  // 'ios_pkw',
  'kauf',
  'matd',
  'mbvs',
  'mdirekt',
  'mersatz',
  'meu',
  'mpkw',
  'mprf',
  'mtls',
  'mtop',
  'mtsp',
  'mxxl',
  'pkw',
  'ebay',
  'pkw',
  'prf',
  'tbn',
  'tkf',
  'tle',
  'tls',
  'top',
  'tsp',
  'xxl',
  'amazon',
  'ebay',
  'atd',
  'expert',
];

export const DATA_WIZARD_HELP_CHAT_LINK = 'https://slack.com/app_redirect?channel=C05NETJSJ6N';
