import clone from 'rfdc';
import { defaultOptions } from '../default-options';
import { kFormatter } from '../../../../helper';

const deepmerge = require('deepmerge');

const barChartOptions = {
  tooltip: {
    axisPointer: {
      type: 'shadow',
    },
  },

  legend: {
    type: 'scroll',
  },

  xAxis: {
    type: 'category',
    boundaryGap: true,
    axisPointer: {
      show: true,
    },
    axisLabel: {},
    data: [],
  },

  yAxis: {
    type: 'value',
    axisLabel: {},
  },
};

export class BarChartOptions {
  series: any;

  legend: any;

  tooltip: any;

  /**
     *
     * @param data
     * @param chartOptions
     * @param { {
     *  [sort]: 'asc' | 'desc',
     *  [direction]: 'xAxis' | 'yAxis',
     *  [stack]: Boolean
     * } } buildOptions
     */
  constructor(data: any, chartOptions = {}, buildOptions: any = {}) {
    chartOptions = clone()(chartOptions);
    Object.assign(this, deepmerge.all([this, defaultOptions, barChartOptions, chartOptions]));
    this._setChartOptionsByParams(buildOptions);
    this._setSeries(Object.keys(data.slices), buildOptions.stack);
    if (buildOptions.sort) {
      this.sortData(data, buildOptions);
    } else {
      this.convertChartData(data, buildOptions);
    }
  }

  /**
     * Set series from slices
     *
     * @param slices
     * @param stack
     * @private
     */
  _setSeries(slices: any, stack: any) {
    slices.forEach((slice: any, id: any) => {
      const seriesDefinedId = this.series.findIndex((item: any) => slice === item.id);
      if (seriesDefinedId >= 0) {
        this.series[seriesDefinedId] = { ...this.series[seriesDefinedId], ...this._setSeriesOptions(slice, stack || id, seriesDefinedId) };
        this.legend.data[seriesDefinedId] = slice;
      } else {
        this.series.push(this._setSeriesOptions(slice, stack || id));
        this.legend.data.push(slice);
      }
    });
  }

  /**
     * Set default options to series object
     *
     * @param slice
     * @param stack
     * @param definedId
     * @returns {{}} - series options
     * @private
     */
  _setSeriesOptions(slice: any, stack: any, definedId?: any) {
    const series: any = {};
    series.stack = stack === true ? 'oneColumn' : stack;
    series.type = this.series[definedId]?.type || 'bar';
    series.id = slice;
    series.name = slice;
    return series;
  }

  /**
     * Set prepared data to axis and series data
     *
     * @param {{ columns: [], slices: {} }} data
     * @param {{}} buildOptions
     */
  convertChartData(data: any, buildOptions: any) {
    // @ts-ignore
    this[buildOptions.direction || 'xAxis'].data = data.columns;
    Object.keys(data.slices).forEach((key) => {
      const series = this.series.find(({ id }: any) => key === id);
      series.data = data.slices[key];
    });
  }

  /**
     * Sort columns in chart
     *
     * @param columns
     * @param slices
     * @param { {} } buildOptions
     */
  sortData({ columns, slices }: any, buildOptions: any) {
    // todo should be able to sort chart data without passing data as func argument
    const columnsTotal: any = {};
    columns.forEach((column: any, colId: any) => {
      Object.keys(slices).forEach((slice) => {
        columnsTotal[column] = columnsTotal[column] || 0;
        columnsTotal[column] += slices[slice][colId];
      });
    });

    const sortedColumns = Object.fromEntries(
      Object.entries(columnsTotal).sort(([, a]: any, [, b]: any) => (buildOptions.sort === 'desc' ? b - a : a - b)),
    );

    const sortedData: any = {
      slices: {},
      columns: [],
    };
    Object.keys(sortedColumns).forEach((sortedColumn) => {
      const oldIndex = columns.findIndex((item: any) => item === sortedColumn);
      Object.keys(slices).forEach((slice) => {
        sortedData.slices[slice] = sortedData.slices[slice] || [];
        sortedData.slices[slice].push(slices[slice][oldIndex]);
      });
      sortedData.columns.push(sortedColumn);
    });
    this.convertChartData(sortedData, buildOptions);
  }

  _setChartOptionsByParams(buildOptions: any) {
    this._setAxisTypes(buildOptions.direction);
    this._setAxisLabelFormatter();
    if (!this.tooltip.formatter) {
      this._setTooltipLabel(buildOptions.direction);
    }
  }

  _setTooltipLabel(direction: any) {
    this.tooltip.formatter = (params: any) => {
      if (this.tooltip.trigger === 'item') {
        if (params.value) {
          return `${params.marker} ${params.seriesName}: ${params.value}`;
        }
        return '';
      }
      const body: string[] = [];
      const itemName = direction === 'yAxis' ? params[1].name : params[0].axisValue;
      body.push(itemName);
      params.forEach(({ seriesName, value, marker }: any, index: any) => {
        if (direction === 'yAxis' && index === 0) {
          return;
        }
        body.push(`${marker} ${seriesName || '-'}: ${kFormatter(value)}`);
      });
      return body.join(`<br />`);
    };
  }

  _setAxisTypes(direction = 'xAxis') {
    const possibleAxis = ['xAxis', 'yAxis'];
    possibleAxis.forEach((axis) => {
      // @ts-ignore
      this[axis].type = axis === direction ? 'category' : 'value';
    });
  }

  _setAxisLabelFormatter() {
    const possibleAxis = ['xAxis', 'yAxis'];
    possibleAxis.forEach((axis) => {
      // @ts-ignore
      if (this[axis].type === 'value') {
        // @ts-ignore
        this[axis].axisLabel.formatter = (params: any) => kFormatter(params);
      }
    });
  }
}

export default class BarChartWidgetOptions extends BarChartOptions {
  constructor(data: any, chartOptions = {}, buildOptions = {}) {
    const { wData, wBuildOptions } = convertWidgetDataForOptions(data, buildOptions);
    super(wData, chartOptions, wBuildOptions);
  }
}

export function convertWidgetDataForOptions(data: any, params: any) {
  const sliceName = params.widget.configuration.y_axes_field_name;
  const wData: any = {
    columns: data.map((item: any) => item.barName),
    slices: {},
  };

  wData.slices[sliceName] = data.map((item: any) => item.barValue);

  const wBuildOptions = {
    direction: params.widget.configuration.report_type === 'bar-chart' ? 'xAxis' : 'yAxis',
  };

  return { wData, wBuildOptions };
}

export function convertObjectsDataForOptions(data: any, slicesKey: any, columnKey: any, value: any, keyToMap: any) {
  const columns: any = [];
  const slices: any = {};
  const map: any = {};
  data.forEach((item: any) => {
    if (columns.indexOf(item[columnKey]) === -1) {
      columns.push(item[columnKey]);
      if (keyToMap) {
        map[item[columnKey]] = item[keyToMap];
      }
    }
    slices[item[slicesKey]] = slices[item[slicesKey]] || [];
  });
  data.forEach((item: any) => {
    Object.keys(slices).forEach((key) => {
      const slice = slices[key];
      const position = columns.indexOf(item[columnKey]);
      if (key === item[slicesKey]) {
        slice[position] = Number(item[value]) || 0;
      } else {
        slice[position] = slice[position] || 0;
      }
    });
  });
  const convertedData: any = { slices, columns };
  if (keyToMap) {
    convertedData.map = map;
  }
  return convertedData;
}
