import { ApiV1 } from '@/api';
import { TServiceOptions } from '@/shared/interfaces/services.d';
import {
  TEventItemData,
  TEventRequestParameters,
  TEventsRequestParameters,
} from '@/shared/interfaces/trackingEvents.d';

export default {
  getEvents(params: TEventsRequestParameters, options: TServiceOptions) {
    return ApiV1(options).get('/events', { params });
  },
  getEvent(id: number, params: TEventRequestParameters) {
    return ApiV1().get(`/events/${id}`, { params });
  },
  createEvent(data: TEventItemData) {
    return ApiV1({ cantCancel: true }).post('/events', data);
  },
  updateEvent(data: TEventItemData) {
    return ApiV1({ cantCancel: true }).put(`/events/${data.id}?with_trashed=1`, data);
  },
  restoreEvent(id: number) {
    return ApiV1({ cantCancel: true }).post(`/events/restore/${id}`);
  },
  deleteEvent(id: number, isForce = false) {
    const params = isForce ? '?force=true' : '';
    return ApiV1({ cantCancel: true }).delete(`/events/${id}${params}`);
  },
  publishEvent(id: number) {
    return ApiV1({ cantCancel: true }).post(`/draft/event/${id}`);
  },
  createDraftEvent(data: TEventItemData) {
    return ApiV1({ cantCancel: true }).put(`/draft/event/${data.id}`, data);
  },
  getEventStat(label_source: string, label_type: string, granularity: string, options: TServiceOptions) {
    return ApiV1(options).get('/event-metrics', { params: { label_source, label_type, granularity } });
  },
  getSourceEventTimeSeries(sourceId: number, granularity: string, options: TServiceOptions) {
    return ApiV1(options).get('/source-event-metrics', { params: { granularity, sourceId } });
  },
};
