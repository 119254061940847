import BrowsersService from '../../services/Browsers';

// initial state
const state = {
};

type TBrowserStatItem = {
  v: string | number;
  count: number;
  ratio?: string | number;
}

type TBrowserStat = TBrowserStatItem & {
  list: TBrowserStatItem[];
}

type TBrowserFullStat = {
  browserEngine: string;
  pageViewsCount: number;
  sessionsCount: number;
  browserStat: TBrowserStat[];
  pageViewsRatio: string | number;
  sessionsRatio: string | number;
}

type TBrowserInfo = {
  BrowserEngineList: string;
  BrowserMajorVersionList: number[];
  BrowserMinorVersionList: number[];
  OperatingSystemList: string[];
  PageViewsCount: string;
  SessionsCount: string;
  SessionsCountList: string[];
  SourceList: string[];
}

type TBrowserInfoResponse = {
  data: TBrowserInfo[];
}

/**
 * Converter will create from flat set of data nested Maps (lists where key is denominator and value is object
 * which contain needed info about denominator)
 *
 * @param list - list to which will be set data from current iteration
 * @param keys - key (names of denominators from current iteration)
 * @param fields - value (objects with data which should be set or added to the key in related nestLevel)
 * @param nestLevel - current recursion deepness level
 */
function converter(list: any, keys: any, fields: any, nestLevel = 0) {
  const has = list.has(keys[nestLevel]);
  const get = list.get(keys[nestLevel]);
  if (has) {
    if (get.list) {
      converter(get.list, keys, fields, nestLevel + 1);
    }
    const newItem = { ...fields[nestLevel] };
    newItem.count += get.count;
    if (get.list) {
      newItem.list = get.list;
    }
    list.set(keys[nestLevel], newItem);
  } else {
    const item = { ...fields[nestLevel] };
    list.set(keys[nestLevel], item);
  }
}

function listsToArray(list: any) {
  if ((Array.from(list.values()) as any)[0]?.list) {
    for (const entry of list) {
      entry[1].list = listsToArray(entry[1].list);
    }
  }
  return Array.from(list.values());
}

function countToRatio(count: number, allCount: number): string {
  return `${(100 / (allCount / count)).toFixed(2)}%`;
}

// actions
const actions = {
  getBrowsers({ commit }: any, { params, options }: any) {
    return BrowsersService.getBrowsersList(params, options).then((resp: TBrowserInfoResponse = { data: [] }) => {
      const browsersList: TBrowserFullStat[] = [];
      let allSessionsCount = 0;
      let allViewsCount = 0;

      // Convert data for each browser
      if (resp.data && resp.data.length) {
        resp.data.forEach((browser: TBrowserInfo) => {
          const browserStat: TBrowserFullStat = {
            browserEngine: browser.BrowserEngineList,
            pageViewsCount: Number(browser.PageViewsCount),
            sessionsCount: Number(browser.SessionsCount),
            browserStat: [],
            pageViewsRatio: 0,
            sessionsRatio: 0,
          };

          allSessionsCount += Number(browser.SessionsCount);
          allViewsCount += Number(browser.PageViewsCount);

          const majorList = new Map();

          // Collect data for each operation system
          browser.SourceList.forEach((source: string, id: number) => {
            // declare variables for denominator of each level of nesting
            const osName = browser.OperatingSystemList[id] || 'Unidentified OS';
            const majorV = browser.BrowserMajorVersionList[id] || 'Unidentified version';

            // declare fields related to each denominator consequently by nesting level
            // and declare a list property if current denominator have nested denominator
            const osItem = {
              v: osName,
              count: Number(browser.SessionsCountList[id]),
            };
            const majorItem = {
              v: majorV,
              count: Number(browser.SessionsCountList[id]),
              list: new Map([[osName, osItem]]),
            };
            const fields = [
              majorItem,
              osItem,
            ];

            // keys - are values of declared denominators
            // queue is related to fields queue!
            const keys = [majorV, osName];

            converter(majorList, keys, fields);
          });
          // at this moment majorList (top level denominator) is filled with data and expected nested denominators with their data
          for (const entry of majorList) {
            // recursively convert lists from Map to Array
            entry[1].list = listsToArray(entry[1].list);
            browserStat.browserStat.push(entry[1]);
          }
          browsersList.push(browserStat);
        });
      }
      // set ratios
      browsersList.forEach((browser: TBrowserFullStat) => {
        browser.pageViewsRatio = countToRatio(browser.pageViewsCount, allViewsCount);
        browser.sessionsRatio = countToRatio(browser.sessionsCount, allSessionsCount);

        browser.browserStat.forEach((stat: TBrowserStat) => {
          stat.ratio = countToRatio(stat.count, allSessionsCount);
          stat.list.forEach((item: TBrowserStatItem) => item.ratio = countToRatio(item.count, allSessionsCount));
        });
      });

      return browsersList;
    });
  },
};

// mutations
const mutations = {
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
