export default function isQueryEqual(obj1, obj2) {
  // Get the object keys
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  // Check if the number of keys is the same
  if (keys1.length !== keys2.length) {
    return false;
  }

  // Iterate over the keys
  for (const key of keys1) {
    // Check if the key exists in both objects
    if (!obj2.hasOwnProperty(key)) {
      return false;
    }

    // Get the values for the current key
    const val1 = obj1[key];
    const val2 = obj2[key];

    // Recursively compare nested objects
    if (typeof val1 === 'object' && typeof val2 === 'object') {
      if (!isQueryEqual(val1, val2)) {
        return false;
      }
    } else {
      // Compare the values
      if (val1 != val2) {
        return false;
      }
    }
  }

  return true;
}
