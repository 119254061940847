<template>
  <img
    :src="src"
    :alt="alt"
    class="kmtx-avatar"
  >
</template>

<script>
export default {
  name: 'AvatarComponent',
  props: {
    src: {
      type: String,
      default: '/images/avatar.jpg',
    },
    alt: {
      type: String,
      default: 'User avatar',
    },
  },
};
</script>

<style lang="scss">
@import "@/sass/_variables";

.kmtx-avatar {
    width: 188px;
    height: 188px;
    border-radius: 50%;
    border: 2px solid $gray100;
    overflow: hidden;

    &--sm {
        width: 64px;
        height: 64px;
    }

    &--xs {
        width: 32px;
        height: 32px;
        border: 1px solid $gray100;
        &:hover {
            border-color: $color-primary-light;
        }
        &:active {
            border-color: $color-primary;
        }
    }
}
</style>
