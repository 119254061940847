import { TVariable, TStorageVariable } from '@/components/tracking-catalog/services/interfaces';
import VariableService from '../../services/Variable';
import SourceService from '../../services/Source';

export interface IVariableState {
    variables: TVariable[];
    variable: TVariable;
    storageVariables: { text: string; value: number }[];
    labels: IVariableLabels[];
}

export interface IVariableLabels {
    id: number;
    label_category: number;
    name: string;
}

// initial state
const state = {
    variables: [],
    variable: {},
    storageVariables: [],
    labels: [],
};

// getters
const getters = {
    getVariable: (state: IVariableState) => state.variable,
    getStorageVariables: (state: IVariableState) => state.storageVariables,
};

// actions
const actions = {
    fillVariables({ commit }: any, params: any = {}) {
        const isUpdateState = params?.isUpdateState;

        delete params.isUpdateState;

        return VariableService.getVariables(params).then((response) => {
            if (isUpdateState) {
                commit('setVariables', response.data.data);
            }
            return response;
        });
    },

    fillVariable({ commit }: any, options: any = {}) {
        return VariableService.getVariable(options.id, options.params).then((response) => {
            commit('setVariable', response.data.data);
            return response;
        });
    },

    createVariable({ commit }: any, data: any) {
        return VariableService.createVariable(data).then((resp) => resp);
    },

    updateVariable({ commit }: any, event: any) {
        return VariableService.updateVariable(event).then(() => {
            commit('setVariable', event);
        });
    },

    createDraftVariable({ commit }: any, event: any) {
        return VariableService.createDraftVariable(event).then((resp) => {
            commit('setVariable', event);
            return resp;
        });
    },

    deleteVariable({ commit }: any, params: { id: number; isForce: boolean }) {
        return VariableService.deleteVariable(params).then(() => {
            commit(
                'setVariables',
                state.variables.filter((variable: TVariable) => variable.id !== params.id)
            );
        });
    },

    fillStorageVariables({ commit }: any) {
        return VariableService.getStorageVariables().then((resp) => {
            commit('setStorageVariables', resp.data.data);
            return resp;
        });
    },

    publishVariable({ commit }: any, id: number) {
        return VariableService.publishVariable(id).then(() => {
            commit(
                'setVariables',
                state.variables.filter((variable: TVariable) => variable.id !== id)
            );
        });
    },

    fillVariableLabels({ commit }: any) {
        return SourceService.getLabels({ category_id: 1 }).then((response) => {
            commit('setLabels', response.data.data);
            return response;
        });
    },
};

// mutations
const mutations = {
    setVariables(state: IVariableState, payload: TVariable[]) {
        state.variables = payload;
    },
    setVariable(state: IVariableState, payload: TVariable) {
        state.variable = payload;
    },
    setStorageVariables(state: IVariableState, payload: TStorageVariable[]) {
        state.storageVariables = payload.map((i: TStorageVariable) => ({
            text: `${i.alias} (${i.type})`,
            value: i.id,
        }));
    },

    setLabels(state: IVariableState, payload: IVariableLabels[]) {
        state.labels = payload;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
