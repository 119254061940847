<template>
  <b-form-group :label="label">
    <date-picker
      ref="datepicker"
      v-model="dateOptions"
      type="date"
      class="input-datepicker"
      range-separator=" – "
      range
      :clearable="false"
      :placeholder="placeholder"
      :lang="lang"
      :disabled="$attrs.disabled"
      :disabled-date="disabledDates"
      :format="format"
      @change="setDate"
    >
      <template #sidebar="{ emit }">
        <button
          class="mx-btn mx-btn-text"
          @click="selectToday(emit)"
        >
          Today
        </button>
        <button
          class="mx-btn mx-btn-text"
          @click="selectYesterday(emit)"
        >
          Yesterday
        </button>
        <button
          class="mx-btn mx-btn-text"
          @click="selectDatesRange(emit, 2 * 24 * 3600 * 1000)"
        >
          Last 3 days
        </button>
        <button
          class="mx-btn mx-btn-text"
          @click="selectDatesRange(emit, 6 * 24 * 3600 * 1000)"
        >
          Last 7 days
        </button>
        <button
          class="mx-btn mx-btn-text"
          @click="selectThisMonth(emit)"
        >
          This month
        </button>
      </template>
    </date-picker>
  </b-form-group>
</template>

<script>
import { mapMutations, mapActions } from 'vuex';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import { getFormattedDate } from '../../../../helper';

export default {
  name: 'KmtxDatepicker',
  components: { DatePicker },
  props: {
    index: {
      type: Number,
      required: true,
    },
    label: {
      type: String,
      default: 'Dates range',
    },
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: Array,
      required: false,
      default: () => [],
    },
    reference: {
      type: String,
      default: 'kmtx-datepicker',
    },
    filterOptions: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      lang: {
        formatLocale: {
          firstDayOfWeek: 1,
        },
        monthBeforeYear: true,
      },
      format: 'YYYY-MM-DD',
      dateOptions: [new Date(), new Date()],
    };
  },
  computed: {
    disabledDates: {
      get() {
        return (date) => date > new Date();
      },
    },
  },
  created() {
    if (this.value.length) {
      this.dateOptions = [new Date(this.value[0]), new Date(this.value[1])];
    }
    this.setDates(this.dateOptions);
  },
  beforeDestroy() {
    this.clearDates();
  },
  methods: {
    ...mapMutations('newFilters', ['updateFilterByIndex']),
    ...mapActions('newFilters', ['setDates', 'clearDates']),
    selectToday(emit) {
      const end = new Date();
      const date = [end, end];
      emit(date);
    },
    selectYesterday(emit) {
      const end = new Date();
      end.setTime(end.getTime() - 3600 * 1000 * 24);
      const date = [end, end];
      emit(date);
    },
    selectDatesRange(emit, offset) {
      const start = new Date();
      const end = new Date();
      start.setTime(start.getTime() - offset);
      const date = [start, end];
      emit(date);
    },
    selectThisMonth(emit) {
      const start = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
      const end = new Date();
      const date = [start, end];
      emit(date);
    },
    setDate(date) {
      const formattedStart = getFormattedDate(date[0]);
      const formattedEnd = getFormattedDate(date[1]);
      const filter = {
        filter_value: [formattedStart, formattedEnd],
        filter_options: this.filter_options,
      };
      this.setDates(date);
      this.updateFilterByIndex({ index: this.index, filter });
    },
  },
};
</script>

<style>
    .kmtx-filters-bar .mx-datepicker-range,
    .kmtx-filters-bar .mx-datepicker.mx-datepicker-range {
        width: 220px;
    }
</style>
