import { render, staticRenderFns } from "./WelcomeNew.vue?vue&type=template&id=4e945d1d&scoped=true&"
import script from "./WelcomeNew.vue?vue&type=script&lang=ts&setup=true&"
export * from "./WelcomeNew.vue?vue&type=script&lang=ts&setup=true&"
import style0 from "./WelcomeNew.vue?vue&type=style&index=0&id=4e945d1d&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e945d1d",
  null
  
)

export default component.exports