import { reactive } from 'vue';
import useForm from '@/composables/useForm';
import { required, minLength, maxLength, labelName } from '@/shared/utils/validators';

export interface ILabelForm {
    key: string;
    value: string;
}
function useLabelForm(initialState: ILabelForm | null) {
    const value = reactive<ILabelForm>(initialState ?? {key: '', value: ''});

    const rules = {
        key: {
            required: required(),
            minLength: minLength(1),
            labelName,
            maxLength: maxLength(50),
        },
        value: {
            required: required(),
            minLength: minLength(1),
            labelName,
            maxLength: maxLength(50),
        },
    };

    return useForm(value, rules);
}

export default useLabelForm;
