import { ApiDataWizard } from '@/api';
import { ILabelComponent } from '@/modules/label-management/interfaces/Label';

interface Params {
    limit?: number;
    filters?: {
        name?: string;
    };
}

const options = { showLoader: true };

export default {
    getLabels(params: Params = { limit: 1000 }) {
        return ApiDataWizard(options)
            .get('lm/labels/', { params })
            .then((res) => res.data)
            .catch((error: any) => {
                if (!error.cancelReason) throw error;
            });
    },

    getLabel(id: string) {
        return ApiDataWizard(options)
            .get(`lm/labels/${id}`)
            .then((res) => res.data.data)
            .catch((error: any) => {
                if (!error.cancelReason) throw error;
            });
    },

    createLabel(label: Partial<ILabelComponent>) {
        return ApiDataWizard(options)
            .post(`lm/labels/`, label)
            .then((res) => res.data)
            .catch((error: any) => {
                if (!error.cancelReason) throw error;
            });
    },

    updateLabel(id: string, label: Partial<ILabelComponent>) {
        return ApiDataWizard(options)
            .patch(`lm/labels/${id}`, label)
            .then((res) => res.data)
            .catch((error: any) => {
                if (!error.cancelReason) throw error;
            });
    },

    deleteLabel(id: string) {
        return ApiDataWizard(options)
            .delete(`lm/labels/${id}`)
            .then((res) => res.data)
            .catch((error: any) => {
                if (!error.cancelReason) throw error;
            });
    },
};
