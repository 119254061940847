<template>
  <b-container
    class="routes-component"
    fluid
  >
    <kmtx-filter-builder
      ref="filters"
      :filters="filtersOptions"
      @datepicker-change="onDateSelected"
      @domainlist-change="fillRouteTransition"
      @domainlist-init="fillRouteTransition"
      @routelist-change="fillRouteTransition"
      @button-submit="downloadCsv"
    />

    <sankey-diag
      ref="routeTransitionSankey"
      :transition-list="routeTransitionList"
      @select="onRouteSelect"
    />
  </b-container>
</template>
<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import StatService from '../../services/Statistic';
import SankeyDiag from './SankeyComponent.vue';
import kmtxFilterBuilder from '../filters/builder/index.vue';

export default {
  name: 'SankeyWrapper',
  components: {
    SankeyDiag,
    kmtxFilterBuilder,
  },
  data() {
    return {
      filtersOptions: [
        {
          filter: 'datepicker',
          label: 'Dates',
        },
        {
          filter: 'domainlist',
          label: 'Domain/Label',
        },
        {
          filter: 'routelist',
          label: 'Route',
          reference: 'routelist',
        },
        {
          filter: 'button',
          value: 'Export to CSV',
        },
      ],
      title: 'Route transition',
      routeTransitionList: [],
    };
  },
  computed: {
    ...mapGetters('filters', {
      getCurrentDomain: 'getCurrentDomain',
      getCurrentRoute: 'getCurrentRoute',
      getDateForParams: 'getDateForParams',
    }),
    ...mapState('filters', {
      routeList: (state) => state.routes,
    }),
  },
  created() {
  },
  mounted() {
  },
  methods: {
    ...mapActions('filters', {
      setCurrentRouteById: 'setCurrentRouteById',
    }),
    downloadCsv() {
      const rows = [
        ['From Route', 'To Route', 'Page views', ' Relative %'],
      ];

      for (let i = 0; i < this.routeTransitionList.length; i++) {
        rows.push([
          this.routeTransitionList[i].from.replace('Go to: ', ''),
          this.routeTransitionList[i].to.replace('Go to: ', ''),
          this.routeTransitionList[i].value,
          this.routeTransitionList[i].percent,
        ]);
      }
      const csvContent = `data:text/csv;charset=utf-8,${
        rows.map((e) => e.join(',')).join('\n')}`;
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement('a');
      link.setAttribute('href', encodedUri);
      const params = this.setStaticParams();

      link.setAttribute('download', `${params.r}_${params.dateFrom}_${params.dateTo}_route_transition.csv`);
      document.body.appendChild(link); // Required for FF

      link.click();
    },
    onRouteSelect(node) {
      const nodeFromId = this.routeList.find((route) => route.name === node.from);

      if (nodeFromId === undefined) {
        return;
      }

      this.routeValue = nodeFromId.id;
      this.setCurrentRouteById(nodeFromId.id);
      this.changeRouteQueryParams();

      this.fillRouteTransition();
    },
    changeRouteQueryParams() {
      this.$router.replace({
        name: this.$route.name,
        query: {
          ...this.$route.query,
          route: this.getCurrentRoute.id,
        },
      });
    },
    onDateSelected() {
      this.fillRouteTransition();
    },
    setStaticParams() {
      const params = {};
      params.label_source = [this.getCurrentDomain.id];
      params.label_type = this.getCurrentDomain.labelType;

      params.dateFrom = this.getDateForParams.dateFrom;
      params.dateTo = this.getDateForParams.dateTo;
      params.r = this.getCurrentRoute.id;

      return params;
    },
    fillRouteTransition() {
      const showLoader = true;
      const params = this.setStaticParams();
      return StatService.getRouteTransitionData(params, { showLoader }).then(() => {
        this.routeTransitionList = [{
          from: 'External',
          to: 'main',
          value: 173376,
          labelText: '63.41% or 173376 page views ',
          percent: 63.41,
        }, {
          from: 'Direct',
          to: 'main',
          value: 69064,
          labelText: '25.26% or 69064 page views ',
          percent: 25.26,
        }, {
          from: 'Refresh',
          to: 'main',
          value: 9043,
          labelText: '3.31% or 9043 page views ',
          percent: 3.31,
        }, {
          from: 'cart',
          to: 'main',
          value: 4470,
          labelText: '1.63% or 4470 page views ',
          percent: 1.63,
        }, {
          from: 'Less then 1% ',
          to: 'main',
          value: 17488,
          labelText: '6.40% or 17488 page views ',
          percent: 6.4,
        }];
        this.$refs.routeTransitionSankey.setupData(this.routeTransitionList);
      }).catch((error) => {
        if (!error.cancelReason) throw error;
      });
    },
  },
};
</script>
