<template>
  <b-col>
    <component
      :is="component"
      v-if="component"
      v-bind="$attrs"
      :data="data"
      v-on="$listeners"
    >
      <template
        v-for="(_, name) in $slots"
        #[name]="slotData"
      >
        <slot
          :name="name"
          v-bind="slotData"
        />
      </template>
    </component>
  </b-col>
</template>

<script>
export default {
  name: 'KmtxFilter',
  components: {
  },
  props: {
    filter: {
      type: String,
      default: 'default',
      required: true,
    },
    // eslint-disable-next-line vue/require-default-prop
    data: {
      type: [Array, Object],
    },

  },
  data() {
    return {
      component: null,
    };
  },
  computed: {
    loader() {
      if (!this.filter) {
        return null;
      }
      // return () => import(/* webpackMode: "eager" */`./templates/${this.filter}`);
      return () => import(/* webpackMode: "eager" */`./templates/${this.filter}/index.vue`);
    },
  },
  mounted() {
    this.loader()
      .then(() => {
        this.component = () => this.loader();
      })
      .catch(() => {
        this.component = () => import('./templates/default/index.vue');
      });
  },
  methods: {
  },
};
</script>
