import { Route } from 'vue-router';
import { updateActivityLogUsers } from '@/router/store-updates';
import store from '@/store';
import EventBus from '@/event';
import UserManagementService from '@/modules/user-management/services/UserManagement';

export interface ICheckRoute {
    routesList: {
        any: string[];
        enter?: string[];
        leave?: string[];
    };
    method: object;
}

export default function useRouter() {
    // change something in store if you went to a certain route
    function updateStore(to: Route, from: Route): void {
        const isLeaving = (routesList: string[]) =>
            !to.matched.find(({ path }) => path.includes(routesList.join(', '))) &&
            from.matched.find(({ path }) => path.includes(routesList.join(', ')));
        const isEntering = (routesList: string[]) => to.matched.find(({ path }) => routesList.join(' , ').includes(path));

        const checkMoves = ({ routesList, method }) => {
            if (isEntering(routesList.any || routesList.enter)) method();
            if (isLeaving(routesList.any || routesList.leave)) method(true);
        };

        const checkRoutes: ICheckRoute[] = [{ routesList: { any: ['tracking/'] }, method: updateActivityLogUsers }];
        checkRoutes.forEach((route: ICheckRoute) => checkMoves(route));
    }

    // Saves the data to VT extension if exist cross_auth_env in session storage
    function updateCrossAuthState(regExp) {
        const cross_auth_env = sessionStorage.getItem('cross_auth_env');

        if (cross_auth_env && regExp.test(cross_auth_env)) {
            const channel = new BroadcastChannel('vt2etx');
            const payload = { action: 'write', key: `${cross_auth_env}_auth_token`, value: `Bearer ${store.state.auth.token}` };
            channel.postMessage(payload);
            channel.close();

            sessionStorage.removeItem('cross_auth_env');
        }
    }

    const checkPermission = (permission) => {
        if (Array.isArray(permission)) {
            return permission.some((item) => store.getters['auth/hasAnyPermissionToService'](item));
        }
        return permission ? store.getters['auth/hasAnyPermissionToService'](permission) : true;
    };

    function pageGuard(to: Route, from: Route, next, context, checkContextAccount, regExp) {
        const meta = to.meta || {};

        const can = async (passed: boolean) => {
            const accounts = await store.getters['auth/accounts'];

            // check context account
            checkContextAccount(from, to, accounts);
            if (!context.value.id && !context.value.hasAccess && to.query['context-account']) {
                return next({ path: '/', query: {} });
            }

            // if no accesses
            if (!passed) {
                EventBus.$emit('kmtx:menu:update');
                return next({ path: '/' });
            }

            updateStore(to, from);
            EventBus.$emit('kmtx:menu:update');

            // Save auth data to VT extension if need
            updateCrossAuthState(regExp);
            next();
        };

        const goToPath = () => {
            const currentAccount = store.getters['auth/currentAccount'];
            const isSystemAccount = UserManagementService.isSystemAccount(currentAccount);
            const isBusinessMasterAccount = !currentAccount.parent_id && !isSystemAccount;
            const masterAccountAccess = meta.masterAccountOnly ? !currentAccount.parent_id : true;

            // check permissions
            let accountCan: boolean = false;
            let havePermission: boolean = checkPermission(meta.permission);

            if (masterAccountAccess) {
                if (isBusinessMasterAccount) {
                    accountCan = !meta.childAccountOnly && (meta.allowForMaster || true);
                    if (meta.childAccountOnly) {
                        havePermission = false;
                    }
                } else {
                    accountCan = true;
                }
            }

            if (!havePermission) {
                EventBus.$emit(
                    'kmtx-toast',
                    'The page is unavailable for you or does not exist.',
                    'The page you are trying to reach is unavailable for you or does not exist. Please contact admin',
                    'error'
                );
            }

            can(accountCan && havePermission);
        };

        if (meta?.onlyServiceMaster === true && store.getters['auth/currentUser']?.type === 1) {
          goToPath();
        } else if (meta.isPublic && !meta.permission) {
          can(true);
        } else if (!meta.permission && !meta.allowForMaster) {
          can(false);
        } else {
          goToPath();
        }
    }

    return {
        pageGuard,
    };
}
