export const defaultOptions = {
  title: {
    text: '',
  },
  grid: {
    left: '2%',
    right: '2%',
    top: '60px',
    bottom: '60px',
    containLabel: true,
  },
  tooltip: {
    trigger: 'axis',
  },
  axisPointer: {
    link: {
      xAxisIndex: 'all',
    },
    label: {
      backgroundColor: '#777',
    },
  },
  legend: {
    left: 'center',
    bottom: 0,
    itemGap: 20,
    emphasis: {
      selectorLabel: {
        show: true,
      },
    },
    data: [],
  },
  toolbox: {
    show: false,
    feature: {
      dataZoom: {
        yAxisIndex: 'none',
        show: true,
        title: {
          zoom: 'Area zooming',
          back: 'Restore area zooming',
        },
      },
      restore: {
        show: true,
        title: 'Restore chart',
      },
      saveAsImage: {
        show: true,
        pixelRatio: 2,
        title: 'Save as image',
      },
      dataView: {
        readOnly: true,
        title: 'Data view',
        lang: [
          'Charts Data view',
          'Exit',
          'Refresh',
        ],
      },
    },
  },
  color: ['#0f83f6', '#11de8d', '#fda82d', '#fe4159', '#6c53c7'],
  series: [],
};
