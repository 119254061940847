import { ApiV1, ApiV1Scoring } from '@/api';

export default {
  list(params: any, options: any) {
    return ApiV1(options).get('/stat/scoring', { params });
  },
  topBlockedIp(params: any, options: any) {
    return ApiV1(options).get('/stat/scoring/top-blocked-ip', { params });
  },
  topBlockedBySource(params: any, options: any) {
    return ApiV1(options).get('/stat/scoring/top-blocked-by-source', { params });
  },
  blockedByList(params: any, options: any) {
    return ApiV1(options).get('/stat/scoring/blocked-by-list', { params });
  },
  // IGNORE LISTS
  listIgnoreConfig(params: any, options: any) {
    return ApiV1Scoring(options).get('/ignore-list', { params });
  },
  createIgnoreListConfig(listId: any, options: any) {
    return ApiV1Scoring(options).post('/ignore-list', { list_id: listId });
  },
  destroyIgnoreListConfig(listId: any, options: any) {
    return ApiV1Scoring(options).delete(`/ignore-list/${listId}`);
  },
  scoringStaticList(params: any) {
    return ApiV1Scoring().get('/static-list', { params });
  },
  addIpToScoringBlockList(data: any) {
    return ApiV1Scoring({ cantCancel: true }).post('/static-list', data);
  },
  deleteFRomScoringBlockList(id: any) {
    return ApiV1Scoring({ cantCancel: true }).delete(`/static-list/${id}`);
  },
  // By Black list statistics
  listTimeSeries(list: any, params: any, options: any) {
    return ApiV1(options).get(`/stat/scoring/list/${list}`, { params });
  },
  listByFeaturesStat(list: any, params: any, options: any) {
    return ApiV1(options).get(`/stat/scoring/list-by-features/${list}`, { params });
  },
  listGeneralStat(list: any, params: any, options: any) {
    return ApiV1(options).get(`/stat/scoring/list-stat/${list}`, { params });
  },
};
