/* eslint-disable */
<template>
  <div class="col-md-8 order-md-1">
    <b-form @submit="submit">
      <b-row>
        <b-col>
          <b-form-group>
            <b-form-group label="Report Name">
              <b-form-input
                v-model="name"
                :required="true"
              />
            </b-form-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group>
            <b-form-group label="Data source">
              <b-select
                v-model="selectedSource"
                :options="reportSettings"
                :required="true"
              />
            </b-form-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Dates">
            <!--                        <vue-rangedate-picker @selected="onDateSelected"-->
            <!--                                              :presetRanges="datePickerConfig.presets"-->
            <!--                                              i18n="EN"-->
            <!--                                              format="YYYY-MM-DD"-->
            <!--                                              ref="rangeDatePicker"-->
            <!--                        >-->
            <!--                        </vue-rangedate-picker>-->
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Domains">
            <b-select
              v-model="selectedSources"
              :options="sources"
              text-field="host"
              value-field="id"
              :required="true"
              :multiple="true"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-button
            type="submit"
            variant="primary"
          >
            Create
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
// import VueRangedatePicker from 'vue-rangedate-picker';
import { mapActions, mapGetters } from 'vuex';
import SourceService from '../services/Source';
// import ReportService from '../services/Report';
// const dc = require('../datepicker_conf');

export default {
  name: 'ReportsComponent',
  data() {
    return {
      // datePickerConfig: dc.config,
      reportSettings: {},
      selectedSource: null,
      selectedSources: [],
      selectedColumns: [],
      name: '',
      sources: [],
    };
  },
  computed: {
    ...mapGetters('filters', {
      getDate: 'getDate',
    }),
  },
  mounted() {
    this.getReportSettings({}).then((response) => {
      this.reportSettings = response.data.sourceList ? response.data.sourceList : [];
    }).catch((error) => { if (!error.cancelReason) throw error; });

    SourceService.getSources().then((response) => {
      this.sources = response.data.data ? response.data.data : [];
      for (const v in this.sources) {
        this.selectedSources.push(this.sources[v].id);
      }
    }).catch((error) => { if (!error.cancelReason) throw error; });

    this.$refs.rangeDatePicker.dateRange = { start: this.getDate[0], end: this.getDate[1] };
  },
  methods: {
    ...mapActions('report', [
      'getReportSettings', 'createReport',
    ]),

    onChangeSourceId(event) {
      this.siteId = event.id;
    },
    submit(evt) {
      evt.preventDefault();

      this.createReport({
        columns: ['all'],
        sites: this.selectedSources,
        source: this.selectedSource,
        account_id: 1,
        name: this.name,
        date_from: this.getDate[0].getTime() / 1000,
        date_to: this.getDate[1].getTime() / 1000,
      }).then(() => {
        this.$router.push('/reports/raw/export');
      });
    },
  },
  // components: { VueRangedatePicker }
};
</script>
