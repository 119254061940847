
import Vue from 'vue';
import Icon from '@/components/IconsComponent.vue';

export default Vue.extend({
  name: 'TooltipMenu',
  components: {
    Icon,
  },
  props: {
    menuItem: {
      type: Object,
      default: () => {},
    },
    target: {
      type: String,
      default: '',
    },
  },
  methods: {
    hasSideMenu(item: any): boolean {
      return item.sidebarMenu && item.sidebarMenu.items.length > 1;
    },
    hideTooltip() {
      this.$root.$emit('bv::hide::tooltip');
    },
  },
});
