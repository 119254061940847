<template>
  <div class="content">
    <b-row>
      <b-col md="12">
        <b-col md="12">
          <h2 class="h2">
            Blocked by scoring list
          </h2>
          <table-component
            :id="byBlockReasonId"
            hover
            :per-page="byBlockListPagination.per_page"
            :fields="blackListFields"
            :items="blockedByReason"
          >
            <template #cell(actions)="row">
              <b-button
                variant="success"
                size="sm"
                @click="$router.push(`/scoring/list/${row.item.List}/${row.item.Id}`)"
              >
                statistics
              </b-button>
              <b-form-checkbox
                v-if="canEdit"
                v-model="row.item.IsEnabled"
                class="reports-table__switch float-right"
                name="check-button"
                switch
                :value="true"
                :unchecked-value="false"
                @change="onEnableListChange(row.item)"
              >
                On
              </b-form-checkbox>
            </template>
          </table-component>
        </b-col>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import Permissions from '@/mixins/Permissions.vue';
import Toast from '@/mixins/Toast.vue';
import TableComponent from '../TableComponent.vue';
import Scoring from '../../services/Scoring';

const IAM_SERVICE_URN = 'service-tool:scoring:settings';

export default {
  name: 'BlockListSettingComponent',
  components: {
    TableComponent,
  },
  mixins: [Permissions, Toast],
  data() {
    return {
      byBlockReasonId: 'top-block-reason-table',
      blockedByReason: [],
      byBlockListPagination: {
        per_page: 10,
      },
      blackListFields: [
        {
          key: 'Id', label: 'ID', sortable: false, class: 'text-center',
        },
        {
          key: 'List', label: 'List', sortable: false, class: 'text-center',
        },
        {
          key: 'Version', label: 'Version', sortable: false, class: 'text-center',
        },
        {
          key: 'Identities', label: 'Identities blocked for the last 30 days', sortable: false, class: 'text-center',
        },
        { key: 'actions', label: 'Actions' },
      ],
      pagination: {
        per_page: 30,
      },
      tableId: 'static-black-list-table',
      fields: ['IP', 'Comment', 'CreatedAt', 'Actions'],
    };
  },
  computed: {
    canEdit() {
      return this.$has(IAM_SERVICE_URN, 'edit');
    },
  },
  created() {
    this.fillBlockedList();
  },
  methods: {
    ...mapMutations('loader', ['addPendingLoader', 'subtractPendingLoader']),
    onEnableListChange(item) {
      if (this.canEdit === false) {
        this.errorMessage(`You don't have permission for this operation`, 'Failed to change status.');
        return;
      }

      if (item.IsEnabled) {
        Scoring.destroyIgnoreListConfig(item.Id);
      } else {
        Scoring.createIgnoreListConfig(item.Id);
      }
    },
    fillBlockedList(params, options) {
      const methods = [
        Scoring.blockedByList,
        Scoring.listIgnoreConfig,
      ];
      this.addPendingLoader();
      Promise.all(methods.map((method) => method(params, options)))
        .then((responses) => {
          this.blockedByReason = [];
          responses[0].data.data.map((item) => {
            const ignoreListData = responses[1].data || [];
            item.IsEnabled = !ignoreListData.find((r) => r.list_id === item.Id);
            this.blockedByReason.push(item);
          });
        })
        .catch((error) => {
          if (!error.cancelReason) throw error;
        })
        .finally(() => {
          this.subtractPendingLoader();
        });
    },
  },
};
</script>
