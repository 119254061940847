<template>
  <div class="content">
    <div class="row mt-2">
      <div class="col">
        <router-link
          :to="kmtxPreviousRoutePath"
        >
          Back
        </router-link>
      </div>
    </div>
    <div class="row mb-3 mt-2 align-items-center">
      <div class="col">
        <h1 class="h1">
          Source details
        </h1>
      </div>
    </div>
    <div>
      <kmtx-chart
        ref="eventColumn"
        :options="chartOptions"
      />
    </div>
    <div>
      <h4>Rule event count</h4>
      <table-component
        :id="tableId"
        hover
        :per-page="pagination.per_page"
        :items="items"
        :fields="fields"
      />
    </div>
  </div>
</template>

<script>
import { BarChartOptions, convertObjectsDataForOptions } from '@/components/echart/options/bar-chart';
import { kFormatter } from '@/helper';
import EChartComponent from '@/components/echart/EChartComponent.vue';
import PreviousRoute from '@/mixins/PreviousRoute.vue';
import TableComponent from '../TableComponent.vue';
import MonitoringService from '../../services/Monitoring';

export default {
  name: 'SourceDetailComponent',
  components: {
    TableComponent,
    'kmtx-chart': EChartComponent,
  },
  mixins: [
    PreviousRoute,
  ],
  data() {
    return {
      sourceId: this.$route.query.host,
      items: [],
      pagination: {
        per_page: 50,
      },
      tableId: 'rule-events-table',
      fields: [],
      chartOptions: {},
    };
  },
  created() {
    MonitoringService.getSourceDetails(this.sourceId, { showLoader: true }).then((res) => {
      this.fillColumn(res.data.data);
      this.fillTable(res.data.data);
    }).catch((error) => { if (!error.cancelReason) throw error; });
  },
  methods: {
    fillColumn(dt) {
      const data = convertObjectsDataForOptions(dt, 'EventName', 'RuleName', 'eventCount');
      const chartOptions = {
        toolbox: {
          show: true,
        },
      };
      this.chartOptions = new BarChartOptions(data, chartOptions, { sort: 'desc', stack: true });
      this.chartOptions.tooltip.formatter = (params) => {
        const body = [];
        body.push(params[0].axisValue);
        params.forEach(({ seriesName, value, marker }) => {
          if (value) {
            body.push(`${marker} ${seriesName || 'no name'}: ${kFormatter(value)}`);
          }
        });
        return body.join(`<br />`);
      };
    },
    fillTable(items) {
      const eventMap = {};
      const ruleMap = {};
      this.fields.push({
        key: 'Rule',
        sortable: true,
      });
      this.fields.push({
        key: 'Event',
        sortable: true,
      });
      this.fields.push({
        key: 'Count',
        sortable: true,
      });
      for (const i in items) {
        ruleMap[items[i].EventName] = items[i].RuleName;
        if (!eventMap[items[i].EventName]) {
          eventMap[items[i].EventName] = 0;
        }
        eventMap[items[i].EventName] += items[i].eventCount;
      }
      for (const i in eventMap) {
        this.items.push({
          Rule: ruleMap[i],
          Event: i,
          Count: eventMap[i],
        });
      }
    },
  },
};
</script>
