import store from '@/store';

export function convertToUTCTime(date: any) {
    const utc = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate());

    return new Date(utc);
}

/**
 * Format big numbers into smaller readable string format
 *
 * @param number - value to format
 * @param cutFrom - number from witch start formatting number to string
 * @returns {string|*}
 */
export function kFormatter(number: any, cutFrom = 1e3) {
    if (typeof number === 'number' && !isNaN(number)) {
        const toFixedTrunc = (num: any, n: any) => (parseInt(`${(num / n) * 10}`) / 10).toFixed(1);
        if (number < 1e3 || number <= cutFrom) return number;
        if (number >= 1e3 && number < 1e6) return `${toFixedTrunc(number, 1e3)}k`;
        if (number >= 1e6 && number < 1e9) return `${toFixedTrunc(number, 1e6)}M`;
        if (number >= 1e9 && number < 1e12) return `${toFixedTrunc(number, 1e9)}G`;
        if (number >= 1e12 && number < 1e15) return `${toFixedTrunc(number, 1e12)}T`;
        if (number >= 1e15 && number < 1e18) return `${toFixedTrunc(number, 1e15)}P`;
        if (number >= 1e18 && number < 1e21) return `${toFixedTrunc(number, 1e18)}E`;
        if (number >= 1e21 && number < 1e24) return `${toFixedTrunc(number, 1e21)}Z`;
        if (number >= 1e24) return `${toFixedTrunc(number, 1e24)}Y`;
    }
    return isNaN(number) ? '-' : number;
}

export function defaultModalOptions(customOptions: any) {
    const defaultOptions: any = {
        size: 'sm',
        buttonSize: 'sm',
        okTitle: 'Ok',
        cancelTitle: 'Cancel',
        okVariant: 'secondary',
        cancelVariant: 'primary',
        headerClass: 'px-4 py-2',
        footerClass: 'px-4 py-2 border-top-1 flex-direction--reverse',
        bodyClass: 'p-4',
        noCloseOnEsc: true,
        hideHeaderClose: true,
        centered: true,
    };
    if (customOptions) {
        for (const key in customOptions) {
            if (Object.prototype.hasOwnProperty.call(customOptions, key)) {
                defaultOptions[key] = customOptions[key];
            }
        }
    }
    return defaultOptions;
}

export function leaveModalOptions(customOptions: any) {
    const defaultLeaveOptions: any = {
        title: 'Do you want to leave changes without save?',
        okTitle: 'Yes, leave',
        cancelTitle: 'No, stay',
    };
    if (customOptions) {
        for (const key in customOptions) {
            if (Object.prototype.hasOwnProperty.call(customOptions, key)) {
                defaultLeaveOptions[key] = customOptions[key];
            }
        }
    }
    return defaultModalOptions(defaultLeaveOptions);
}

export function getFormattedDate(date: any, time = false) {
    function pad(n: any) {
        return n < 10 ? `0${n}` : n;
    }
    if (date) {
        return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}${
            time ? ` ${pad(date.getHours())}:${pad(date.getMinutes())}` : ''
        }`;
    }
    return date;
}

export function getFormattedDateOrDashes(date: any, time = true): string {
    return date ? getFormattedDate(new Date(date), time) : '-- --';
}

export function getKeyTextArray(array: any, value = 'id', text = 'name') {
    return array.map((item: any) => ({ value: item[value], text: item[text] }));
}

/**
 * Get field failed validations and map them to corresponding error messages.
 *
 * @param $v - validation field
 * @param errors{object} - Object where property name - is validation rule name; value - is error message for that rule
 * @returns {string[]} - Array of errors
 */
export function getValidationErrors($v: any, errors: any) {
    return (
        Object.keys($v.$params)
            .filter((param) => !$v[param])
            .map((rule) => errors[rule] || rule) || ["Can't recognize errors."]
    );
}

/**
 * Delay touch validation of inputs from vuelidate 'validations' options
 *
 * @param $v - validation field
 * @param touchMap {WeakMap} - Map to destroy old timeouts
 */
export function delayTouch($v: any, touchMap: any) {
    $v.$reset();
    if (touchMap.has($v)) {
        clearTimeout(touchMap.get($v));
    }
    touchMap.set($v, setTimeout($v.$touch, 700));
}

export const formattedDate = (date: string) => {
    const userTimeZone = store.state.auth.currentUser?.timezone;
    if (!date) return '-- --';
    const dt = new Date(date).toLocaleString('en', { timeZone: userTimeZone });
    return getFormattedDate(new Date(dt), true);
};

export function isDateStringAtom(str: string): boolean {
    // Checking if the incoming string is a date string in ATOM format
    // example: 2024-02-07T10:38:48+00:00
    const atomRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}[+-]\d{2}:\d{2}$/;
    return atomRegex.test(str);
}

export function getLastUpdateTime(time: string): string {
    if (!time) {
        return '';
    }

    const seconds = (new Date().getTime() - new Date(time).getTime()) / 1000;
    const days = (seconds / 216000).toFixed();
    const hours = (seconds / 3600).toFixed();
    const minutes = (seconds / 60).toFixed();

    if (+days) {
        return `(Updated ${days}days ago)`;
    }
    if (+hours) {
        return `(Updated ${hours}h ago)`;
    }
    if (+minutes) {
        return `(Updated ${minutes}min ago)`;
    }
    return `(Updated ${seconds.toFixed()}sec ago)`;
}
