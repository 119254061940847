import Vue from 'vue';
import BlockUI from 'vue-blockui';
import {
    BAlert,
    BBadge,
    BButton,
    BButtonGroup,
    BCard,
    BCol,
    BContainer,
    BDropdown,
    BDropdownDivider,
    BDropdownItem,
    BForm,
    BFormCheckbox,
    BFormInput,
    BFormGroup,
    BFormInvalidFeedback,
    BFormTextarea,
    BFormSelect,
    BFormSelectOption,
    BFormText,
    BInputGroup,
    BInputGroupText,
    BInputGroupAppend,
    BInputGroupPrepend,
    BLink,
    BListGroup,
    BModal,
    BNav,
    BNavItem,
    BNavItemDropdown,
    BNavbarNav,
    BPagination,
    BRow,
    BTable,
    BToast,
    BTooltip,
    FormSelectPlugin,
    ModalPlugin,
    ToastPlugin,
    TooltipPlugin,
    VBModal,
    VBTooltipPlugin,
    PopoverPlugin,
} from 'bootstrap-vue';
import PortalVue from 'portal-vue';
import Vuelidate from 'vuelidate';
import VueTagsInput from '@johmun/vue-tags-input';
import store from './store';
import router from './router';
import Permissions from './mixins/Permissions.vue';
import Toast from './mixins/Toast.vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import './sass/_variables.scss';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import './sass/app.scss';
import '@kilometrix/polyomino/dist/scss/themes/light.scss';
import '@kilometrix/polyomino/dist/scss/shared.scss';
import App from './components/App.vue';
import LocalStorage from './services/LocalStorage';

import packageJson from '../package.json';
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

// require('./bootstrap');

window.Vue = require('vue');

Vue.component('b-alert', BAlert);
Vue.component('BAlert', BAlert);
Vue.component('b-badge', BBadge);
Vue.component('BBadge', BBadge);
Vue.component('b-button', BButton);
Vue.component('BButton', BButton);
Vue.component('b-button-group', BButtonGroup);
Vue.component('BButtonGroup', BButtonGroup);
Vue.component('b-card', BCard);
Vue.component('BCard', BCard);
Vue.component('b-col', BCol);
Vue.component('BCol', BCol);
Vue.component('b-container', BContainer);
Vue.component('b-dropdown', BDropdown);
Vue.component('BDropdown', BDropdown);
Vue.component('b-dropdown-divider', BDropdownDivider);
Vue.component('BDropdownDivider', BDropdownDivider);
Vue.component('b-dropdown-item', BDropdownItem);
Vue.component('BDropdownItem', BDropdownItem);
Vue.component('b-form', BForm);
Vue.component('BForm', BForm);
Vue.component('b-form-checkbox', BFormCheckbox);
Vue.component('BFormCheckbox', BFormCheckbox);
Vue.component('b-form-group', BFormGroup);
Vue.component('BFormGroup', BFormGroup);
Vue.component('b-form-input', BFormInput);
Vue.component('BFormInput', BFormInput);
Vue.component('b-form-invalid-feedback', BFormInvalidFeedback);
Vue.component('BFormInvalidFeedback', BFormInvalidFeedback);
Vue.component('b-form-textarea', BFormTextarea);
Vue.component('BFormTextarea', BFormTextarea);
Vue.component('b-form-select-option', BFormSelectOption);
Vue.component('BFormSelectOption', BFormSelectOption);
Vue.component('b-form-select', BFormSelect);
Vue.component('BFormSelect', BFormSelect);
Vue.component('b-form-text', BFormText);
Vue.component('BFormText', BFormText);
Vue.component('b-input-group', BInputGroup);
Vue.component('BInputGroup', BInputGroup);
Vue.component('b-input-group-text', BInputGroupText);
Vue.component('BInputGroupText', BInputGroupText);
Vue.component('b-input-group-append', BInputGroupAppend);
Vue.component('BInputGroupAppend', BInputGroupAppend);
Vue.component('b-input-group-prepend', BInputGroupPrepend);
Vue.component('BInputGroupPrepend', BInputGroupPrepend);
Vue.component('b-link', BLink);
Vue.component('BLink', BLink);
Vue.component('b-list-group', BListGroup);
Vue.component('BListGroup', BListGroup);
Vue.component('b-modal', BModal);
Vue.component('BModal', BModal);
Vue.component('b-nav', BNav);
Vue.component('BNav', BNav);
Vue.component('b-nav-item', BNavItem);
Vue.component('BNavItem', BNavItem);
Vue.component('b-nav-item-dropdown', BNavItemDropdown);
Vue.component('BNavItemDropdown', BNavItemDropdown);
Vue.component('b-navbar-nav', BNavbarNav);
Vue.component('BNavbarNav', BNavbarNav);
Vue.component('b-pagination', BPagination);
Vue.component('BPagination', BPagination);
Vue.component('b-row', BRow);
Vue.component('BRow', BRow);
Vue.component('b-table', BTable);
Vue.component('BTable', BTable);
Vue.component('b-toast', BToast);
Vue.component('BToast', BToast);
Vue.component('b-tooltip', BTooltip);
Vue.component('BTooltip', BTooltip);

Vue.directive('b-modal', VBModal);

Vue.use(ToastPlugin);
Vue.use(TooltipPlugin);
Vue.use(VBTooltipPlugin);
Vue.use(ModalPlugin);
Vue.use(FormSelectPlugin);
Vue.use(PopoverPlugin);

Vue.use(BlockUI);
Vue.use(Vuelidate);
Vue.use(PortalVue);
Vue.component('vue-tags-input', VueTagsInput);
Vue.component('VueTagsInput', VueTagsInput);

Vue.mixin(Permissions);
Vue.mixin(Toast);

Vue.prototype.filters = {
    bigNumberFormat(value: string | number) {
        if (!Number.isInteger(parseInt(value as string))) {
            return;
        }
        return new Intl.NumberFormat('ru-RU').format(value as number);
    },
    capitalize(value: string): string {
        if (value.length > 0) {
            const items = value.split('_');
            for (const i in items) {
                items[i] = items[i].charAt(0).toUpperCase() + items[i].slice(1);
            }
            return items.join(' ');
        }
        return '';
    },
};

Vue.config.errorHandler = (err: any) => {
    if (!err.cancelReason) throw err;
};

function getVersion() {
    return packageJson.version;
}

LocalStorage.set('version', getVersion());
window.getVersion = getVersion;

new Vue({
    el: '#app',
    store,
    router,
    components: { App },
    render: (h) => h(App),
});
