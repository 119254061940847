import { getCurrentInstance } from 'vue';
import { Store } from 'vuex';
import allStore, { RootState } from '@/store';

function useStore() {
    const instance = getCurrentInstance();
    if (!instance) {
        return { state: allStore.state ?? {}, getters: allStore.getters ?? {}, actions: {} };
    }
    const store: any = instance.proxy.$store;
    const { state, getters, _actions } = store;
    return { state, getters, actions: _actions };
}

export default useStore;
