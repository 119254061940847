<template>
  <div class="content">
    <b-row
      class="routes-component__view"
      fluid
    >
      <router-view />
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'RoutesComponent',
};
</script>
