import { ApiV1 } from '@/api';

export default {
  // Returns the list of dashboard objects
  getDashboards(params: any, options: any) {
    return ApiV1(options).get('/data-marts/dashboards', { params });
  },
  getDashboard(id: any, params: any) {
    return ApiV1().get(`/data-marts/dashboards/${id}`, { params });
  },
  // Creates dashboard
  createDashboard(data: any) {
    return ApiV1({ cantCancel: true }).post('/data-marts/dashboards', data);
  },
  editDashboard(data: any, params: any) {
    // console.log('DataMarts.js - editDashboard data.configuration', data.configuration);
    // console.log('editDashboard data.configuration.filters_list', data.configuration?.filters_list);
    return ApiV1({ cantCancel: true }).put(`/data-marts/dashboards/${data.id}`, data, { params });
  },
  deleteDashboard(dashboardId: any) {
    return ApiV1({ cantCancel: true }).delete(`/data-marts/dashboards/${dashboardId}`);
  },
  getDataMarts(params: any) {
    return ApiV1().get('/data-marts', { params });
  },
  // Returns a list of reports.
  getWidgets() {
    return ApiV1().get('/data-marts/widgets');
  },
  // Returns the configuration for specified report
  getWidget(widgetId: any) {
    return ApiV1().get(`/data-marts/widgets/${widgetId}`);
  },
  // Returns the data for specified report.
  getWidgetData(widgetId: any, data: any) {
    return ApiV1().post(`/data-marts/widgets/${widgetId}/data`, data);
  },
  validateWidget(data: any) {
    return ApiV1({ cantCancel: true }).post('/data-marts/validate-widget-configuration', data);
  },
  // Create a report based on DataMart object and Report Configuration
  createWidget(data: any) {
    return ApiV1({ cantCancel: true }).post('/data-marts/widgets', data);
  },
  // Edit widget
  editWidget(report: any) {
    return ApiV1({ cantCancel: true }).put(`/data-marts/widgets/${report.id}`, report);
  },
  attachWidgetToDashboard(position: any, query: any) {
    return ApiV1({ cantCancel: true }).post(`/data-marts/dashboards/${query.dashboardId}/report/${query.widgetId}`, { position });
  },
  // Returns a detailed object of data mart
  getDataMart(dataMartId: any, params: any) {
    return ApiV1().get(`/data-marts/${dataMartId}`, { params });
  },
  getWidgetTypes() {
    return ApiV1().get(`/data-marts/widgets/types`);
  },
  getAggregationTypes() {
    return ApiV1().get(`/data-marts/widgets/aggregates`);
  },
  getFilters() {
    return ApiV1().get('/data-marts/filters/types');
  },
  getDictionariesList() {
    return ApiV1().get('/dictionary_list');
  },
};
