import { ApiV2 } from '@/api';

const cantCancel = { cantCancel: true };
const contentTypeForm = { 'Content-Type': 'application/x-www-form-urlencoded' };
export const refreshUrl = 'auth/refresh';

export default {
    getUserByToken(token: any) {
        return ApiV2().get(`iam/users/${token}`);
    },

    getCurrentUser() {
        return ApiV2().get('iam/users/me');
    },

    registerUserByToken(token: any, data: any) {
        const headers = {
            headers: contentTypeForm,
        };
        const params = new URLSearchParams();
        params.append('name', data.name);
        params.append('password', data.password);
        return ApiV2({ ...cantCancel, withoutAccountId: true }, headers).patch(`iam/users/accept-invitation/${token}`, params);
    },

    login(data: any) {
        return ApiV2({ ...cantCancel, withoutVersion: true, withoutAccountId: true }).post('auth/sign-in', data);
    },

    oAuthLogin(driver: string, code: string, redirect_uri: string) {
        return ApiV2({ ...cantCancel, withoutVersion: true, withoutAccountId: true }).post(`auth/providers/${driver}/sign-in`, {
            code,
            redirect_uri,
        });
    },

    refreshTokens(refresh_token: string): Promise<any> {
        return ApiV2({ ...cantCancel, withoutVersion: true, withoutAccountId: true }).post(refreshUrl, { refresh_token });
    },

    resetPasswordByToken(token: string, password: string) {
        const headers = {
            headers: contentTypeForm,
        };
        const params = new URLSearchParams();
        params.append('password', password);
        return ApiV2({ ...cantCancel, withoutAccountId: true }, headers).patch(`iam/users/reset-password/${token}`, params);
    },
    resetPasswordByEmail(resetEmail: string): Promise<any> {
        return ApiV2({ ...cantCancel, withoutVersion: false, withoutAccountId: true }).post('iam/users/reset-password', {
            email: resetEmail,
        });
    },
};
