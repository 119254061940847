import { ApiDataWizard } from '@/api';
import getObjectWithOutEmptyProp from '@/shared/utils/getObjectWithOutEmptyProp';
import { IArchiveReason, IDsUser, IMergeRemoteUserRequest, IMetaRequests } from './interfaces';
import { IDataSourceUser } from '../composables/connections/useUserProfileDataSources';

export default {
    getDataSources(
        params: {
            limit: number;
        } = { limit: 1000 },
        filters: Partial<{
            storage: string;
            connectionStatus: string;
            frontliner: boolean;
        }> = {},
        options: any = {}
    ) {
        return ApiDataWizard(options)
            .get(`dsm/connections/`, { params: { ...params, filters: JSON.stringify(filters) } })
            .catch((error: any) => {
                if (!error.cancelReason) throw error;
            });
    },

    getDataSourcesStorages(params = { limit: 1000 }) {
        return ApiDataWizard()
            .get('dsm/connections/storages', { params })
            .catch((error: any) => {
                if (!error.cancelReason) throw error;
            });
    },

    getDataSourcesStatus() {
        return ApiDataWizard()
            .get('dsm/connections/statuses')
            .catch((error: any) => {
                if (!error.cancelReason) throw error;
            });
    },

    createCheckConnection(payload: any) {
        const options = {
            showLoader: true,
            cantCancel: true,
        };
        return ApiDataWizard(options)
            .post('dsm/connections/check', payload)
            .then((resp: any) => resp)
            .catch((error: any) => {
                if (!error.cancelReason) throw error;
            });
    },

    archiveDataSource(id: string, payload: IArchiveReason, options?: { showLoader: boolean }) {
        return ApiDataWizard(options)
            .delete(`dsm/connections/${id}`, { data: payload })
            .then((resp: any) => resp)
            .catch((error: any) => {
                if (!error.cancelReason) throw error;
            });
    },

    getExistConnection(connectionId: string) {
        const options = {
            showLoader: true,
        };
        return ApiDataWizard(options)
            .get(`dsm/connections/${connectionId}`)
            .then((resp: any) => resp)
            .catch((error: any) => {
                if (!error.cancelReason) throw error;
            });
    },

    getClustersList(connectionId: string) {
        return ApiDataWizard()
            .get(`dsm/connections/${connectionId}/clusters`)
            .then((resp: any) => resp)
            .catch((error: any) => {
                if (!error.cancelReason) throw error;
            });
    },

    activateConnection(payload: any) {
        const options = {
            showLoader: true,
        };
        return ApiDataWizard(options)
            .post('dsm/connections', payload)
            .then((resp: any) => resp)
            .catch((error: any) => {
                if (!error.cancelReason) throw error;
            });
    },

    updateConnection(connectionId: any, payload: any) {
        const options = {
            showLoader: true,
        };
        return ApiDataWizard(options)
            .patch(`dsm/connections/${connectionId}`, payload)
            .then((resp: any) => resp)
            .catch((error: any) => {
                if (!error.cancelReason) throw error;
            });
    },

    archiveConnection(connectionId: any) {
        const options = {
            cantCancel: true,
            showLoader: true,
        };
        return ApiDataWizard(options)
            .delete(`dsm/connections/${connectionId}`)
            .then((resp: any) => resp)
            .catch((error: any) => {
                if (!error.cancelReason) throw error;
            });
    },

    getConnectionRoles(connectionId: string) {
        const options = {
            showLoader: true,
        };
        return ApiDataWizard(options)
            .get(`dsm/connections/${connectionId}/roles`)
            .then((resp: any) => resp)
            .catch((error: any) => {
                if (!error.cancelReason) throw error;
            });
    },

    createRole(connectionId: string, payload: any, options: any = {}) {
        const cantCancel = true;
        const showLoader = true;
        options = { ...options, cantCancel, showLoader };
        return ApiDataWizard(options)
            .post(`dsm/connections/${connectionId}/roles`, payload)
            .then((resp: any) => resp)
            .catch((error: any) => {
                if (!error.cancelReason) throw error;
            });
    },

    updateRole(connectionId: string, roleId: string, payload: any) {
        const options = {
            showLoader: true,
        };

        return ApiDataWizard(options)
            .patch(`dsm/connections/${connectionId}/roles/${roleId}`, getObjectWithOutEmptyProp(payload))
            .then((resp: any) => resp)
            .catch((error: any) => {
                if (!error.cancelReason) throw error;
            });
    },

    // filters={%sensitiveData%22:1}

    getDataSourceUsersList(params: any) {
        const options = {
            showLoader: true,
        };
        return ApiDataWizard(options)
            .get('dsm/users/', { params })
            .then((resp) => resp.data)
            .catch((error: any) => {
                if (!error.cancelReason) throw error;
            });
    },

    getUsersShortDSM() {
        return ApiDataWizard()
            .get('dsm/users/short')
            .then((resp) => resp.data)
            .catch((error: any) => {
                if (!error.cancelReason) throw error;
            });
    },

    createDataSourceUser(payload: IDsUser) {
        const options = {
            showLoader: true,
        };
        return ApiDataWizard(options)
            .post(`dsm/users/`, payload)
            .then((resp: any) => resp)
            .catch((error: any) => {
                if (!error.cancelReason) throw error;
            });
    },

    getExistUser(userId: string) {
        return ApiDataWizard()
            .get(`dsm/users/${userId}`)
            .then((resp: any) => resp)
            .catch((error: any) => {
                if (!error.cancelReason) throw error;
            });
    },

    getDataSourceUserStatusName() {
        return ApiDataWizard()
            .get(`dsm/users/data-source-status`)
            .then((resp: any) => resp)
            .catch((error: any) => {
                if (!error.cancelReason) throw error;
            });
    },

    archiveDataSourceUser(userId: any) {
        const options = {
            cantCancel: true,
        };
        return ApiDataWizard(options)
            .delete(`dsm/users/${userId}`)
            .then((resp: any) => resp)
            .catch((error: any) => {
                if (!error.cancelReason) throw error;
            });
    },

    updateDataSourceUser(userId: any, payload: any) {
        const options = {
            showLoader: true,
        };
        return ApiDataWizard(options)
            .patch(`dsm/users/${userId}`, payload)
            .then((resp: any) => resp)
            .catch((error: any) => {
                if (!error.cancelReason) throw error;
            });
    },

    getDSMUsersListByIAMUserId(idpUserId: string) {
        return ApiDataWizard()
            .get<{ data: IDataSourceUser[]; meta: IMetaRequests }>(`dsm/users/idp/${idpUserId}/list`)
            .then((resp) => resp.data || { data: [], meta: {} })
            .catch((error) => {
                if (!error.cancelReason) throw error;
            });
    },

    isUserApprover() {
        return ApiDataWizard()
            .get(`dsm/users/me/is_approver`)
            .then((resp) => resp.data.data)
            .catch((error) => {
                if (!error.cancelReason) throw error;
            });
    },

    syncDataSourceConnection(connectionId: string) {
        const options = {
            showLoader: true,
        };
        return ApiDataWizard(options)
            .post(`dsm/connections/${connectionId}/sync`)
            .then((resp: any) => resp)
            .catch((error: any) => {
                if (!error.cancelReason) throw error;
            });
    },
    totalResyncDataSourceConnection(connectionId: string) {
        return ApiDataWizard()
            .post(`dsm/connections/${connectionId}/total-resync`)
            .then((resp: any) => resp)
            .catch((error: any) => {
                if (!error.cancelReason) throw error;
            });
    },
    checkDataSourceConnection(connectionId: string) {
        return ApiDataWizard()
            .patch(`dsm/connections/${connectionId}/update-availability`)
            .then((resp: any) => resp)
            .catch((error: any) => {
                if (!error.cancelReason) throw error;
            });
    },
    syncDataSource(userId: string, userDataSourceId: string) {
        return ApiDataWizard()
            .patch(`dsm/users/${userId}/uds/${userDataSourceId}/sync`)
            .then((resp: any) => resp)
            .catch((error: any) => {
                if (!error.cancelReason) throw error;
            });
    },
    syncDataSourceRole(dataSourceId: string, roleId: string, force: boolean) {
        return ApiDataWizard()
            .patch(`dsm/connections/${dataSourceId}/roles/${roleId}/sync`, { force })
            .then((resp: any) => resp)
            .catch((error: any) => {
                if (!error.cancelReason) throw error;
            });
    },
    getDataSourcesRolesStatusList() {
        return ApiDataWizard()
            .get(`dsm/connections/roles/statuses`)
            .then((resp: any) => resp)
            .catch((error: any) => {
                if (!error.cancelReason) throw error;
            });
    },

    getConnectionRemoteUsers(connectionId: string, params) {
        const options = {
            showLoader: true,
        };
        return ApiDataWizard(options)
            .get(`dsm/connections/${connectionId}/remote-users`, { params })
            .then((resp: any) => resp)
            .catch((error: any) => {
                if (!error.cancelReason) throw error;
            });
    },
    getConnectionUsers(connectionId: string, params) {
        const options = {
            showLoader: true,
        };
        return ApiDataWizard(options)
          .get(`dsm/connections/${connectionId}/users`, { params: { ...params, filters: JSON.stringify(params.filters) } })
          .then((resp: any) => resp)
          .catch((error: any) => {
              if (!error.cancelReason) throw error;
          });
    },
    getExternalUsers(connectionId: string) {
        return ApiDataWizard()
            .get(`dsm/connections/${connectionId}/remote-users/external`)
            .then((resp: any) => resp)
            .catch((error: any) => {
                if (!error.cancelReason) throw error;
            });
    },
    getConnectionRemoteUser(connectionId: string, login: string) {
        const options = {
            showLoader: true,
        };
        return ApiDataWizard(options)
            .get(`dsm/connections/${connectionId}/remote-user?login=${login}`)
            .then((resp: any) => resp)
            .catch((error: any) => {
                if (!error.cancelReason) throw error;
            });
    },

    // DS Services
    getDataSourceServiceList(params: any) {
        const options = {
            showLoader: true,
        };
        return ApiDataWizard(options)
            .get('dsm/services/', { params })
            .then((resp) => resp.data)
            .catch((error: any) => {
                if (!error.cancelReason) throw error;
            });
    },

    createDataSourceService(payload: IDsUser) {
        const options = {
            showLoader: true,
        };
        return ApiDataWizard(options)
            .post(`dsm/services/`, payload)
            .then((resp: any) => resp)
            .catch((error: any) => {
                if (!error.cancelReason) throw error;
            });
    },

    getDataSourceService(id: string) {
        return ApiDataWizard()
            .get(`dsm/services/${id}`)
            .then((resp: any) => resp)
            .catch((error: any) => {
                if (!error.cancelReason) throw error;
            });
    },

    updateDataSourceService(id: string, payload: any) {
        const options = {
            showLoader: true,
        };
        return ApiDataWizard(options)
            .patch(`dsm/services/${id}`, payload)
            .then((resp: any) => resp)
            .catch((error: any) => {
                if (!error.cancelReason) throw error;
            });
    },

    mergeRemoteUser(user: string, payload: IMergeRemoteUserRequest) {
        const options = {
            showLoader: true,
        };
        return ApiDataWizard(options)
            .patch(`dsm/users/${user}/merge`, payload)
            .then((resp: any) => resp)
            .catch((error: any) => {
                if (!error.cancelReason) throw error;
            });
    },
};
