<template>
  <b-form-group :label="label">
    <b-form-select
      :ref="reference"
      :options="options"
      :value="selected"
      class="kmtx-filters-item"
      @change="onChange"
    />
  </b-form-group>
</template>

<script>
export default {
  name: 'KmtxSelect',
  props: {
    label: {
      type: String,
      default: 'Select',
    },
    data: {
      type: Array,
      default: () => [],
    },
    reference: {
      type: String,
      default: 'kmtx-select',
    },
    value: {
      type: String,
      default: '',
    },
  },
  emits: ['select-change'],
  data() {
    return {
    };
  },
  computed: {
    options() {
      return this.data;
    },
    selected() {
      return this.value;
    },
  },
  methods: {
    onChange(value) {
      this.$emit('select-change', {
        reference: this.reference,
        value,
      });
    },
  },
};
</script>
