<template>
  <div class="content">
    <table-component
      :id="tableId"
      hover
      :per-page="pagination.per_page"
      :items="items"
      :fields="fields"
    />
  </div>
</template>

<script>
import MonitoringService from '../../services/Monitoring';
import TableComponent from '../TableComponent.vue';

export default {
  name: 'UnmarkedEventComponent',
  components: {
    TableComponent,
  },
  data() {
    return {
      eventId: this.$route.query.host,
      items: [],
      pagination: {
        per_page: 50,
      },
      tableId: 'unmarked-events-table',
      fields: [],
      series: [{
        name: 'Income',
        type: 'column',
        data: [1.4, 2, 2.5, 1.5, 2.5, 2.8, 3.8, 4.6],
      }, {
        name: 'Cashflow',
        type: 'column',
        data: [1.1, 3, 3.1, 4, 4.1, 4.9, 6.5, 8.5],
      }, {
        name: 'Revenue',
        type: 'line',
        data: [20, 29, 37, 36, 44, 45, 50, 58],
      }],
      chartOptions: {
        title: {
          text: 'Custom event chart',
        },
        chart: {
          stacked: true,
          stackType: '100%',
        },
        responsive: [{
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0,
            },
          },
        }],
        xaxis: {
          type: 'datetime',
        },
        fill: {
          opacity: 1,
        },

        legend: {
          position: 'right',
          offsetX: 0,
          offsetY: 50,
        },
      },
    };
  },
  created() {
    MonitoringService.getUnmarketEventList(this.eventId, { showLoader: true }).then((res) => {
      this.fillTable(res.data);
    }).catch((error) => { if (!error.cancelReason) throw error; });
  },
  methods: {
    fillColumn(dt) {
      const data = {};
      const ruleMap = {};
      const dateMap = {};
      for (const i in dt) {
        dateMap[dt[i].ServerEventDate] = 1;
      }

      for (const i in dt) {
        const item = dt[i];
        ruleMap[item.RuleName] = ruleMap[item.RuleName] || {};
        ruleMap[item.RuleName][item.ServerEventDate] = ruleMap[item.RuleName][item.ServerEventDate] || 0;

        ruleMap[item.RuleName][item.ServerEventDate] += item.eventCount;
      }

      for (const i in ruleMap) {
        data[i] = {
          name: i,
          type: 'column',
          data: [],
        };

        for (const d in dateMap) {
          data[i].data.push((ruleMap[i][d] ? ruleMap[i][d] : 0));
        }
      }

      this.series = Object.values(data);
      this.$refs.eventColumn.options.labels = Object.keys(dateMap);
      this.$refs.eventColumn.refresh();
    },
    fillTable(items) {
      this.fields.push({
        key: 'ID',
        sortable: true,
      }, {
        key: 'Site',
        sortable: true,
      });

      for (let i in items) {
        if (Object.prototype.hasOwnProperty.call(items, i)) {
          this.items.push(
            {
              Site: items[i].value,
              ID: ++i,
            },
          );
        }
      }
    },
  },
};
</script>
