import { computed, ref } from 'vue';
import { cloneDeep } from 'lodash';
import LocalStorage from '@/services/LocalStorage';
import EventBus from '../event';

interface ContextAccount {
  currentAccount: string | null;
  id?: string | null;
  query: any;
  hasAccess: boolean;
}

const context = ref<ContextAccount>({
  currentAccount: null,
  id: null,
  query: {},
  hasAccess: true,
});

function setSession() {
  if (!context.value.id) return;
  sessionStorage.setItem('context_account_id', context.value.id);
}

function getFromSession() {
  return sessionStorage.getItem('context_account_id');
}

function clearContextAcc() {
  context.value.id = null;
  sessionStorage.removeItem('context_account_id');
}

const query = computed(() => {
  const contextQuery = getFromSession();
  return contextQuery ? { 'context-account': getFromSession() } : {};
});

const isInContextAccount = computed(() => {
  const idFromSession = context.value.id || getFromSession();
  const contextAccId = idFromSession;
  return contextAccId ? contextAccId !== context.value.currentAccount : false;
});

const checkContextAccount = (from, to, accounts) => {
  if (!context.value.currentAccount) {
    return;
  }
  if (!context.value.id) return;
  const hasAccessToContextAcc = accounts?.find((el) => el.id === context.value.id);
  if (!hasAccessToContextAcc) {
    EventBus.$emit(
      'kmtx-toast',
      'The account is unavailable for you or does not exist.',
      'You are not allowed to go to the page of accounts you do not have permission to access.',
      'error',
    );
    clearContextAcc();
  }
  context.value.hasAccess = !!hasAccessToContextAcc;
};

const manageContextAccount = (from, to) => {
  if (context.value.id && context.value.id === from.query['context-account'] && !to.query['context-account']) {
    context.value.query = cloneDeep({ ...to.query, 'context-account': from.query['context-account'] });
    return;
  }
  context.value.query = to.query;
  context.value.id = to.query['context-account'] ?? getFromSession();
  context.value.currentAccount = LocalStorage.get('account_id');
  if (context.value.id !== context.value.currentAccount) setSession();
};

export default function useContextAccount() {
  return {
    query,
    context,
    clearContextAcc,
    isInContextAccount,
    manageContextAccount,
    checkContextAccount,
  };
}
