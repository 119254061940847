import useStore from './useStore';

function usePermission() {
    const { getters } = useStore();

    function $can(permissionName: string): boolean {
        if (!permissionName || !getters['auth/permissionsSlugs']) {
            return false;
        }

        return !!getters['auth/permissionsSlugs'][permissionName.toString()];
    }

    function $has(service: string, type = 'view'): boolean {
        return getters['auth/hasAnyPermissionToService'](service, type);
    }

    function $is(roleName: string): boolean {
        if (!roleName) {
            return false;
        }
        const name = getters['auth/masterRole'] ? getters['auth/masterRole'].name : getters['auth/currentAccountHighestRole']?.name;
        return name === roleName;
    }

    return { $can, $has, $is };
}

export default usePermission;
