<template>
  <b-col>
    <component
      :is="component"
      v-bind="$attrs"
      v-if="component"
      class="kmtx-filters-bar__filter"
    >
      <template
        v-for="(_, name) in $slots"
        #[name]="slotData"
      >
        <slot
          :name="name"
          v-bind="slotData"
        />
      </template>
    </component>
  </b-col>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  name: 'KmtxFilter',
  components: {
  },
  props: {
    filter: {
      type: String,
      default: 'default',
      required: true,
    },
  },
  data() {
    return {
      component: null,
    };
  },
  computed: {
    loader() {
      if (!this.filter) {
        return null;
      }
      return () => import(/* webpackMode: "eager" */`./templates/${this.filter}/index.vue`);
    },
  },
  mounted() {
    this.loadComponent();
  },
  methods: {
    ...mapMutations('newFilters', ['setFilterByIndex']),
    loadComponent() {
      this.loader()
        .then(() => {
          this.component = () => this.loader();
          if (this.filter !== 'export') {
            const payload = {
              index: this.$attrs.index,
              filter: {
                filter_type_id: this.$attrs.filter_type_id,
                filter_options: this.$attrs.filter_options,
                filter_value: this.$attrs.value,
                filter_fields: this.$attrs.filter_fields,
              },
            };
            this.setFilterByIndex(payload);
          }
        })
        .catch(() => {
          this.component = () => import('./templates/default/index.vue');
        });
    },
  },
};
</script>
