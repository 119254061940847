import axios from 'axios';
import store from '@/store';

const { CancelToken } = axios;

class CancelAxios {
  source: any = null;

  constructor() {
    this.source = CancelToken.source();
    store.commit('request/setCancelToken', this.source.token);
  }

  cancelRequests() {
    if (this.source) {
      this.source.cancel('Request was cancelled');
      this.resetToken();
    }
  }

  resetToken() {
    this.source = CancelToken.source();
    store.commit('request/setCancelToken', this.source.token);
  }
}

export default CancelAxios;
