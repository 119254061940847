<template>
  <div>
    <kmtx-filter-builder
      v-if="searchFilters"
      ref="filters"
      :filters="searchFilters"
      @autosuggest-selected="onAutosuggestSelected"
      @button-submit="searchForUrls()"
    />

    <b-row class="mb-4">
      <b-col>
        URL's basic analytic statistic
      </b-col>
    </b-row>

    <kmtx-filter-builder
      :filters="tableFilters"
      @datepicker-change="fetchTableData"
      @button-submit="downloadCsv"
    />

    <table-component
      :id="tableId"
      show-empty
      hover
      fixed
      responsive
      stacked="md"
      :per-page="pagination.per_page"
      :fields="fields"
      :items="tableData"
    >
      <template #cell(URL)="data">
        <a
          :href="data.value"
          target="_blank"
          rel="noopener"
        >{{ data.value }}</a>
      </template>
    </table-component>
  </div>
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex';
import KmtxFilterBuilder from '../filters/builder/index.vue';
import Toast from '../../mixins/Toast.vue';
import ConvertDate from '../../mixins/ConvertDate.vue';
import TableComponent from '../TableComponent.vue';
import StatService from '../../services/Statistic';
import SourceService from '../../services/Source';

export default {
  name: 'SkinUrl',
  components: {
    'kmtx-filter-builder': KmtxFilterBuilder,
    TableComponent,
  },
  mixins: [
    Toast,
    ConvertDate,
  ],
  beforeRouteLeave(to, from, next) {
    if (to.name !== 'url-skin') {
      this.clearState();
    }
    next();
  },
  data() {
    return {
      searchFilters: null,
      tableId: 'urls-table',
      pagination: {
        per_page: 20,
      },
      tableFilters: [
        {
          filter: 'datepicker',
          label: 'Dates',
        },
        {
          filter: 'button',
          value: 'Export to CSV',
          reference: 'export',
          options: {
            show: true,
          },
        },
      ],
      fields: [
        {
          key: 'URL', label: 'URL', sortable: true, thStyle: { width: '50%' }, class: ['overflow-auto'],
        },
        {
          key: 'events', label: 'Page view total', sortDirection: 'desc', sortable: true,
        },
        { key: 'sessions', label: 'Sessions count', sortable: true },
        { key: 'visits', label: 'Unique visits', sortable: true },
      ],

      route: '',
      skin: '',
      skins: [],
      routes: [],
    };
  },
  computed: {
    ...mapGetters('filters', ['getDateForParams']),
    ...mapState('filters', ['date']),
    ...mapState('urlRouteSkinTool', [
      'tableRoute',
      'tableSkin',
      'tableData',
      'tableDate',
    ]),
  },
  created() {
    this.searchFilters = [
      {
        filter: 'autosuggest',
        label: 'Route',
        reference: 'routes',
        placeholder: 'Type route',
        value: this.tableRoute,
      },
      {
        filter: 'autosuggest',
        label: 'Skin',
        placeholder: 'Search skin',
        reference: 'skins',
        value: this.tableSkin,
      },
      {
        filter: 'button',
        value: 'Find',
        options: {
          variant: 'primary',
        },
      },
    ];
    this.getSkinList();
    this.getRouteList();
    this.route = this.tableRoute;
    this.skin = this.tableSkin;
  },
  mounted() {
    if (this.tableDate) {
      this.updateDate(this.tableDate);
    }
  },
  methods: {
    ...mapMutations('urlRouteSkinTool', ['changeValueByRef', 'setTableData', 'clearState']),
    ...mapMutations('filters', ['updateDate']),
    searchForUrls() {
      const { routes, skins } = this.$refs.filters?.$refs;
      const routesElValue = routes && routes[0].$children[0].selected;
      const skinsElValue = skins && skins[0].$children[0].selected;

      if (this.route.length && this.skin.length) {
        this.fetchTableData();
      } else {
        const fieldsEmpty = typeof routesElValue === 'string' && typeof skinsElValue === 'string'
                                    && (!routesElValue || !skinsElValue);
        const title = fieldsEmpty ? 'All fields are required.' : 'Fields not selected from list.';
        const message = fieldsEmpty ? 'Please fill all fields!' : 'You have to select fields from the list.';
        this.danger(title, message);
      }
    },
    fetchTableData() {
      if (this.route.length && this.skin.length) {
        const params = {
          timeFrom: this.convertDateFormat(this.getDateForParams.dateFrom, '00:00:00'),
          timeTo: this.convertDateFormat(this.getDateForParams.dateTo, '23:59:59'),
          skin: this.skin,
          route: this.route,
        };
        StatService.getRssBy(params, { showLoader: true }).then((resp) => {
          this.changeValueByRef({ reference: 'tableRoute', value: this.route });
          this.changeValueByRef({ reference: 'tableSkin', value: this.skin });
          this.setTableData({ data: resp.data, date: this.date });
        }).catch((error) => {
          if (!error.cancelReason) {
            this.errorMessage(error);
          }
          this.setTableData({ data: [] });
        });
      }
    },
    getSkinList() {
      return SourceService.getSkins({}, { showLoader: true }).then((r) => {
        this.skins = r.data.map(({ id, value: name }) => ({ id, name }));
        this.updateAutosuggestData({ data: '', reference: 'skins' });
      });
    },
    getRouteList() {
      return SourceService.getRoutes({}, { showLoader: true }).then((r) => {
        this.routes = r.data.data.map(({ id, value: name }) => ({ id, name }));
        this.updateAutosuggestData({ data: '', reference: 'routes' });
      });
    },
    onAutosuggestSelected({ data, reference }) {
      this[`${reference.slice(0, -1)}`] = data.name;
    },
    updateAutosuggestData({ data, reference }) {
      this.$refs.filters.setFilterProp(this.searchFilters, reference, 'data', this[reference].filter((item) => item.name.includes(data)));
    },
    downloadCsv() {
      const rows = [
        ['Url', 'Page view total', 'Session Count', 'Uniq Visits'],
      ];

      for (let i = 0; i < this.tableData.length; i++) {
        rows.push([
          this.tableData[i].URL,
          this.tableData[i].events,
          this.tableData[i].sessions,
          this.tableData[i].visits,
        ]);
      }

      const csvContent = `data:text/csv;charset=utf-8,${rows.map((e) => e.join(';')).join('\n')}`;
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement('a');
      link.setAttribute('href', encodedUri);
      link.setAttribute('download', `${this.getDateForParams.dateFrom}_${
        this.getDateForParams.dateTo}_search_stats.csv`);
      document.body.appendChild(link); // Required for FF

      link.click();
    },
  },
};
</script>
