export const ABFormLabels = {
  price_setting: {
    planned_pi_under: 'Planned PI underpriced',
    planned_pi_over: 'Planned PI overpriced',
    multiplier: 'Multiplier',
    min_marginality: 'Minimal marginality',
    pi_jump_up: 'PI Jump Up',
    pi_jump_down: 'PI Jump Down',
    price_jump_up: 'Price Jump Up',
    price_jump_down: 'Price Jump Down',
    platform: 'Platform',
    markets: 'Markets',
    categories: 'Categories',
    country: 'Country',
  },
  ab_setting: {
    key_metric: 'Key metric',
    key_metric_percent: 'Key metric percent expected change',
    fp_error: 'FP error',
    fn_error: 'FN error',
  },
};

export default { ABFormLabels };
