import RoutingViewContainer from '@/components/RoutingViewContainer.vue';

const SchemaMonitorList = () => import(/* webpackChunkName: "[ScemaMonitorList]" */ '@/modules/schema-monitor/pages/SchemaMonitorList.vue');
const SchemaMonitorCreate = () => import(/* webpackChunkName: "[ScemaMonitorCreate]" */ '@/modules/schema-monitor/pages/SchemaMonitorCreate.vue');
const SchemaMonitorEdit = () => import(/* webpackChunkName: "[ScemaMonitorEdit]" */ '@/modules/schema-monitor/pages/SchemaMonitorEdit.vue');

export default
{
  path: '/schema-monitor',
  component: RoutingViewContainer,
  name: 'schema-monitor',
  redirect: { name: 'schema-monitor-list' },
  children: [
    {
      path: '',
      component: SchemaMonitorList,
      name: 'schema-monitor-list',
      meta: {
        permission: 'schema-monitor:connections:connection',
        browserTitle: 'Schema-Monitor List',
      },
    },
    {
      path: 'create',
      component: SchemaMonitorCreate,
      name: 'schema-monitor-create',
      meta: {
        permission: 'schema-monitor:connections:connection:create',
        browserTitle: 'Create Schema-Monitor Connection',
      },
    },
    {
      path: ':connectionId',
      component: SchemaMonitorEdit,
      name: 'schema-monitor-edit',
      meta: {
        permission: 'schema-monitor:connections:connection',
        browserTitle: 'Edit Schema-Monitor Connection',
      },
    },
  ],
};
