import LabelCategoriesService from '../../services/LabelCategories';
import SourceService from '../../services/Source';

// initial state
const state = {
  labels: [],
  labelCategories: [],
};

// getters
const getters = {
  getLabel: (state: any) => (id: any) => state.labels.find((i: any) => i.id === id),
  getLabelCategory: (state: any) => (id: any) => state.labelCategories.find((i: any) => i.id === id),
};

// actions
const actions = {
  fillLabels({ commit }: any, params: any) {
    return SourceService.getLabels(params).then((response) => {
      commit('setLabels', response.data.data);
      return response;
    });
  },
  fillLabelCategories({ commit }: any, params: any) {
    return LabelCategoriesService.getLabelCategories(params).then((response) => {
      commit('setLabelCategories', response.data.data);
      return response;
    });
  },
  createLabelCategory({ commit }: any, { params, options }: any) {
    return LabelCategoriesService.createLabelCategory(params, options);
  },
  updateLabelCategory({ commit }: any, params: any) {
    if (parseInt(params.id || 0) === 0) {
      return false;
    }

    const reqData = {
      labels: params.labels.map((l: any) => l.text),
    };

    return LabelCategoriesService.updateLabelCategory(params.id, reqData);
  },
};

// mutations
const mutations = {
  setLabels(state: any, payload: any) {
    state.labels = payload;
  },
  setLabelCategories(state: any, payload: any) {
    payload = payload.map((s: any) => {
      s.labels = s.labels ? s.labels : [];
      s.labels = s.labels.map((l: any) => ({
        text: l.name,
      }));
      return s;
    });
    state.labelCategories = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
