<template>
  <div
    class="big-numbers grid"
    :class="{ 'grid--wrap': itemsWrap }"
    :style="customCSSVars"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'BigNumbers',

  props: {
    itemsCount: {
      type: [Number, String],
      default: 'auto',
    },

    itemsWrap: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    customCSSVars() {
      const itemsCount = typeof this.itemsCount === 'string' ? this.itemsCount : `calc(100% / ${this.itemsCount} - 15px)`;
      return {
        '--itemsCount': itemsCount,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.big-numbers {
    justify-content: space-between;

    .big-number {
        width: var(--itemsCount);
        flex-grow: 1;
    }
}
</style>
