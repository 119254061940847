<template>
  <b-row
    :key="forceKey"
    align-v="end"
    class="kmtx-new-filters-bar"
    mb-4
  >
    <!--    <b-row class="filters-bar">-->
    <kmtx-filter
      v-for="(filter, index) in filters"
      :key="index"
      :ref="filter.reference"
      :index="index"
      :filter="hardcodedFilterAlias[filter.filter_value]"
      :filter_options="defaultFilterOptions(filter.filter_value, filter.filter_options)"
      :filter_fields="filter.filter_fields"
      :filter_type_id="filter.filter_type_id"

      :value="filter.value || defaultFilterValues(filter.filter_value, filter.filter_options)"
      :label="getLabel(filter)"
      :placeholder="filter.placeholder"
      :data="filter.data"
      :type="filter.type"
      :disabled="$attrs.disabled"
      v-on="$listeners"
    />
  </b-row>
</template>

<script>
import { getFormattedDate } from '@/helper';
import { mapState, mapMutations } from 'vuex';
import _set from 'lodash.set';
import kmtxFilter from '../index.vue';

export default {
  name: 'KmtxFilterBuilder',
  components: {
    kmtxFilter,
  },
  props: {
    filters: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      // todo get rid of this aliases
      hardcodedFilterAlias: {
        date: 'date-single',
        domain_label: 'domainlist',
        dict: 'autosuggest',
        input: 'input',
        datepicker: 'datepicker',
        step: 'steps',
        export: 'export',
      },
      forceKey: 0,
    };
  },
  computed: {
    ...mapState('newFilters', ['currentDomain']),
  },
  watch: {
    filters() {
      this.clearFilters();
      this.forceKey += 1;
    },
  },
  beforeDestroy() {
    this.clearFilters();
  },
  methods: {
    ...mapMutations('newFilters', ['clearFilters']),
    getLabel(filter) {
      return filter.filter_options.label || filter.filter_name || (filter.filter_options.compare ? 'Compare date' : undefined);
    },
    defaultFilterOptions(filter, options = {}) {
      const filters = {
        'date-single': { ...options },
        domainlist: { ...options, label_type: this.currentDomain.labelType },
        autosuggest: { ...options },
        input: {},
        datepicker: {},
      };
      return filters[this.hardcodedFilterAlias[filter]];
    },
    defaultFilterValues(filter, options = {}) {
      const { startDate, endDate } = this.getDates();
      const filters = {
        'date-single': options.compare ? undefined : getFormattedDate(new Date()),
        domainlist: this.currentDomain.id,
        autosuggest: '',
        datepicker: [getFormattedDate(startDate), getFormattedDate(endDate)],
      };
      return filters[this.hardcodedFilterAlias[filter]];
    },

    getDates() {
      const startDate = new Date();
      const endDate = new Date();
      startDate.setTime(startDate.getTime() - 6 * 24 * 3600 * 1000);
      return { startDate, endDate };
    },

    setFilterProp(filters, filterReference, propName, propValue) {
      const filterId = this.getFilterIdByReference(filters, filterReference);

      if (filterId !== null) {
        if (propName.startsWith('options.')) {
          // eslint-disable-next-line no-undef
          _set(filters[filterId], propName, propValue);
        } else {
          this.$set(filters[filterId], propName, propValue);
        }
      }
    },
    getFilterProp(filters, filterReference, propName) {
      const filterId = this.getFilterIdByReference(filters, filterReference);

      if (filterId) {
        return filters[filterId][propName];
      }

      return null;
    },
    getFilterIdByReference(filters, filterReference) {
      let filterIndex = null;

      filters.find((filter) => {
        if (filter.reference === filterReference) {
          filterIndex = filters.indexOf(filter);
        }
      });

      return filterIndex;
    },
  },
};
</script>

<style>
    .kmtx-new-filters-bar .col
    {
        flex-grow: 0;
    }

    .kmtx-new-filters-bar .form-control,
    .kmtx-new-filters-bar .kmtx-filters-item
    {
        min-width: 10em;
    }
</style>
