<template>
  <div class="content">
    <div class="mb-5 mt-3">
      <div
        class="mb-5 mt-3"
      >
        <big-number
          v-for="(item, key) in versionTotalList"
          :key="key"
          :value="item[1]"
          variant="danger"
        >
          <template #header>
            Blocked Identities (V{{ item[0] }})
          </template>
        </big-number>
      </div>
    </div>
    <kmtx-filter-builder
      ref="filters"
      :filters="filtersOptions"
      @domainlist-change="fillChartData"
      @domainlist-init="fillChartData"
      @button-submit="fillChartData"
    />

    <b-row class="mb-4">
      <b-col md="12">
        <kmtx-chart
          ref="timeSeriesBlackList"
          :options="timeSeriesChartOptions"
          :width="{ width: '100%' }"
          class="time-series-chart"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col
        xs="12"
        md="12"
        lg="12"
      >
        <h3>Blocked by</h3>
      </b-col>
      <b-col
        xs="12"
        md="6"
        lg="4"
      >
        <h4 class="h4 text-center">
          Browser
        </h4>
        <kmtx-chart
          ref="themeBrowserBlock"
          :options="pipeBrowserChart"
          class="visit-pie-chart"
        />
      </b-col>
      <b-col
        xs="12"
        md="6"
        lg="4"
      >
        <h4 class="h4 text-center">
          OS
        </h4>
        <kmtx-chart
          ref="themeOsBlock"
          :options="pipeOsChart"
          class="visit-pie-chart"
        />
      </b-col>
      <b-col
        xs="12"
        md="6"
        lg="4"
      >
        <h4 class="h4 text-center">
          Country
        </h4>
        <kmtx-chart
          ref="themeOsBlock"
          :options="pipeCountryChart"
          class="visit-pie-chart"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <h2 class="h2">
          General stat by blocked Identities
        </h2>
        <table-component
          :id="ipsTableId"
          hover
          :items="listStat"
        />
      </b-col>
      <b-col
        xs="12"
        md="6"
        lg="4"
      >
        <h4 class="h4 text-center">
          Duration
        </h4>
        <column-chart
          :widget="options"
          :data="durationColumnChartData"
        />
      </b-col>
      <b-col
        xs="12"
        md="6"
        lg="4"
      >
        <h4 class="h4 text-center">
          Click
        </h4>
        <column-chart
          :widget="options"
          :data="clickColumnChartData"
        />
      </b-col>
      <b-col
        xs="12"
        md="6"
        lg="4"
      >
        <h4 class="h4 text-center">
          Page depth
        </h4>
        <column-chart
          :widget="options"
          :data="pageViewChartData"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Scoring from '../../services/Scoring';

import kmtxFilterBuilder from '../filters/builder/index.vue';
import bigNumber from '../widgets/bigNumber/index.vue';
import TableComponent from '../TableComponent.vue';
import EChartComponent from '../echart/EChartComponent.vue';
import { PieChartOptions } from '../echart/options/pie-chart';
import ColumnChart from '../widgets/chart/index.vue';
import LineChartOptions from '../echart/options/line-chart/index';

export default {
  name: 'BlackListComponent',
  components: {
    kmtxFilterBuilder,
    TableComponent,
    bigNumber,
    'kmtx-chart': EChartComponent,
    'column-chart': ColumnChart,
  },
  data() {
    return {
      durationColumnChartData: [],
      clickColumnChartData: [],
      pageViewChartData: [],
      options: {
        configuration: {
          report_type: 'column-chart',
          y_axes_field_name: 'Percent identities',
          type: 'bar',
          height: 350,
          stacked: true,
          stackType: '100%',
          chart: {
            type: 'bar',
            height: 350,
            stacked: true,
            stackType: '100%',
          },
          plotOptions: {
            bar: {
              horizontal: true,
            },
          },
        },
        chart: {
          type: 'bar',
          height: 350,
          stacked: true,
          stackType: '100%',
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
      },
      filtersOptions: [
        {
          filter: 'datepicker',
          label: 'Dates',
        },
        {
          filter: 'domainlist',
          label: 'Domain/Label',
        },
        {
          filter: 'button',
          value: 'Filter',
        },
      ],
      timeSeries: [],
      versionTotalList: [],
      listStat: [],
      listDescriptionStatMap: {
        avgCountClicks: 'Avg. clicks',
        countIdentities: 'Uniq identities',
        avgSessions: 'Avg. sessions',
        avgCountSources: 'Avg. visited sources',
        avgPageDepth: 'Avg. page depth',
        avgUniqRoutes: 'Avg. visited routes',
        effectiveEventPercentage: 'Effect event percentage of each identities',
        loggedInPercentage: 'Percentage of logged in of each identities',
        medianDuration: 'Median duration in seconds',
      },
      ipsPagination: {
        per_page: 10,
      },
      ipsTableId: 'top-blocked-ips-table',
      timeSeriesChartOptions: {},
      pipeBrowserChart: {},
      pipeOsChart: {},
      pipeCountryChart: {},
      columnOsChart: {},
    };
  },
  computed: {
    ...mapGetters('filters', {
      getDate: 'getDate',
      getDateForParams: 'getDateForParams',
      getCurrentDomain: 'getCurrentDomain',
    }),
  },
  methods: {
    ...mapActions('filters', {
      setDate: 'setDate',
    }),
    fillChartData() {
      const params = this.setStaticParams();
      const options = { showLoader: true };
      const methods = [
        this.fillTimeSeries,
        this.fillBlockedList,
        this.fillListStat,
      ];
      Promise.all(methods.map((method) => method(params, options))).catch((error) => {
        if (!error.cancelReason) throw error;
      });
    },
    fillBlockedList(params, options) {
      return Scoring.listByFeaturesStat(this.$route.params.id, params, options).then((resp) => {
        const browserStat = resp.data.data.browser_engine.filter((i) => i.name !== '');
        this.pipeBrowserChart = new PieChartOptions({
          labels: browserStat.map((i) => i.name),
          slices: {
            visits: browserStat.map((i) => i.value),
          },
        });
        const countryStat = resp.data.data.country.filter((i) => i.name !== '');
        this.pipeCountryChart = new PieChartOptions({
          labels: countryStat.map((i) => i.name),
          slices: {
            visits: countryStat.map((i) => i.value),
          },
        });
        const operationSystemStat = resp.data.data.operation_system.filter((i) => i.name !== '');
        this.pipeOsChart = new PieChartOptions({
          labels: operationSystemStat.map((i) => i.name),
          slices: {
            visits: operationSystemStat.map((i) => i.value),
          },
        });
      });
    },
    fillListStat(params, options) {
      return Scoring.listGeneralStat(this.$route.params.id, params, options).then((resp) => {
        const stat = resp.data;
        this.listStat = [];
        for (const i in this.listDescriptionStatMap) {
          this.listStat.push({
            Stat: this.listDescriptionStatMap[i],
            Value: stat[i],
          });
        }
        stat.durationHist.forEach((i) => {
          this.durationColumnChartData.push({
            barName: `${Math.round(i[0])} - ${Math.round(i[1])}`,
            barValue: Math.round((i[2] / stat.countIdentities) * 100),
          });
        });
        stat.countClicksHist.forEach((i) => {
          this.clickColumnChartData.push({
            barName: `${Math.round(i[0])} - ${Math.round(i[1])}`,
            barValue: Math.round((i[2] / stat.countIdentities) * 100),
          });
        });
        stat.pageViewDepthHist.forEach((i) => {
          this.pageViewChartData.push({
            barName: `${Math.round(i[0])} - ${Math.round(i[1])}`,
            barValue: Math.round((i[2] / stat.countIdentities) * 100),
          });
        });
      });
    },
    fillTimeSeries(params, options) {
      return Scoring.listTimeSeries(this.$route.params.id, params, options).then((resp) => {
        this.timeSeries = [];
        const versionList = Object.create({});
        this.versionTotalList = [];

        this.totalUnique = this.scoredUnique = 0;
        const data = resp.data.data.map(({ Version, UniqIdentities, t }) => {
          if (!versionList[Version]) {
            versionList[Version] = { name: Version, total: 0 };
          }
          UniqIdentities = parseInt(UniqIdentities);
          versionList[Version].total += UniqIdentities;
          return { Score: UniqIdentities, DateTime: t };
        });
        for (const i in versionList) {
          this.versionTotalList.push([versionList[i].name, versionList[i].total]);
        }
        this.timeSeriesChartOptions = new LineChartOptions(data, {}, params);
      });
    },
    setStaticParams() {
      const params = Object.create(null);
      params.label_source = [this.getCurrentDomain.id];
      params.label_type = this.getCurrentDomain.labelType;
      params.dateFrom = this.getDateForParams.dateFrom;
      params.dateTo = this.getDateForParams.dateTo;

      return params;
    },
  },
};
</script>
