
import Vue from 'vue';
import { Route } from 'vue-router';

export default Vue.extend({
  name: 'PreviousRoute',
  beforeRouteEnter(to: Route, from: Route, next) {
    next((vm: any) => {
      vm.kmtxPreviousRoute = from;
      return vm;
    });
  },
  data() {
    const kmtxPreviousRoute: Route | { fullPath?: string } = { };
    return {
      kmtxPreviousRoute,
    };
  },
  computed: {
    kmtxPreviousRoutePath(): string {
      return this.kmtxPreviousRoute.fullPath || '/default';
    },
  },
});
