import { CancelToken } from 'axios';
import { Module } from 'vuex';
import { RootState } from '..';

export interface IRequestState {
    cancelToken: CancelToken | null;
    connectionFailed: boolean;
    connectionFailedList: { [key: string]: boolean };
}

function removeIDAndParams(input: string): string {
    let cleanedString = input.replace(/[0-9A-Z]{25,}.*/g, '');
    cleanedString = cleanedString.replace(/\?.*/g, '');
    return cleanedString.trim();
}

const state = {
    cancelToken: null,
    connectionFailed: false,
    connectionFailedList: {},
};

const getters = {
    isConnectionFailed: (currentState: IRequestState) => currentState.connectionFailed,
};

const mutations = {
    setCancelToken(currentState: IRequestState, token: CancelToken) {
        currentState.cancelToken = token;
    },
    setConnectionFailed(currentState: IRequestState, requestInfo: { [key: string]: boolean }) {
        const [key, value] = Object.entries(requestInfo)[0];
        const url = removeIDAndParams(key);
        if (value) {
            delete currentState.connectionFailedList[url];
        } else {
            currentState.connectionFailedList[url] = value;
        }
        currentState.connectionFailed = Object.keys(currentState.connectionFailedList).length > 0;
    },
    clearFailedList(currentState: IRequestState) {
        currentState.connectionFailed = false;
        currentState.connectionFailedList = {};
    },
};

const requestModule: Module<IRequestState, RootState> = {
    namespaced: true,
    state,
    mutations,
    getters,
};

export default requestModule;
