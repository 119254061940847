import RoutingViewContainer from '@/components/RoutingViewContainer.vue';

function setQueryParamsForEvents(to, from, next) {
    const defaultQueryParams = {
        search_value: '',
        search_by: 'name',
        status: 1,
        type: 0,
        page: 1,
        per_page: 10,
        rules: '',
    };

    const queryParamsKeys = Object.keys(to.query);

    if (Object.keys(defaultQueryParams).every((key) => queryParamsKeys.includes(key))) {
        next();

        return;
    }

    if (queryParamsKeys.length) {
        next({
            name: 'events-list',
            query: {
                ...defaultQueryParams,
                ...to.query,
            },
        });

        return;
    }

    next({
        name: 'events-list',
        query: { ...defaultQueryParams },
    });
}

export default {
    path: '/tracking/events',
    name: 'events',
    component: RoutingViewContainer,
    redirect: { name: 'events-list' },
    children: [
        {
            path: '',
            name: 'events-list',
            beforeEnter: setQueryParamsForEvents,
            component: () => import(/* webpackChunkName: '[EventsPage]' */ './pages/events/EventsPage.vue'),
            meta: {
                permission: ['tracking:rules:rule', 'tracking:rules:event'],
                browserTitle: 'Events - Tracking',
            },
        },
        {
            path: 'new',
            name: 'new-event',
            component: () => import(/* webpackChunkName: '[NewEventPage]' */ './pages/event/NewEventPage.vue'),
            meta: {
                permission: ['tracking:rules:rule', 'tracking:rules:event:create'],
                browserTitle: 'New event - Tracking',
            },
        },
        {
            path: ':id',
            name: 'event',
            component: () => import(/* webpackChunkName: '[EventPage]' */ './pages/event/EventPage.vue'),
            meta: {
                permission: ['tracking:rules:rule', 'tracking:rules:event'],
                browserTitle: 'Event - Tracking',
            },
        },
    ],
};
