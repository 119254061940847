import RoutingViewContainer from '@/components/RoutingViewContainer.vue';

export default [
    {
        path: '/validation-rules',
        name: 'validation-rules',
        redirect: { name: 'datasets' },
    },
    {
        path: '/validation-rules/datasets',
        component: RoutingViewContainer,
        name: 'datasets',
        redirect: { name: 'datasets-list' },
        children: [
            {
                path: '',
                component: () => import('@/modules/validation-rules/pages/DatasetsList.vue'),
                name: 'datasets-list',
                meta: {
                    permission: 'vr:datasets:dataset:view',
                    browserTitle: 'Datasets',
                },
            },
            {
                path: 'create',
                component: () => import('@/modules/validation-rules/pages/DatasetCreate.vue'),
                name: 'dataset-create',
                meta: {
                    permission: 'vr:datasets:dataset:create',
                    browserTitle: 'Create Dataset',
                },
            },
            {
                path: ':id',
                component: () => import('@/modules/validation-rules/pages/DatasetEdit.vue'),
                name: 'dataset-edit',
                meta: {
                    permission: 'vr:datasets:dataset:edit',
                    browserTitle: 'Edit Dataset',
                },
            },
        ],
    },
    {
        path: '/validation-rules/validation-suites',
        component: RoutingViewContainer,
        name: 'validation-suites',
        redirect: { name: 'validation-suites-list' },
        children: [
            {
                path: '',
                component: () => import('@/modules/validation-rules/pages/ValidationSuitesList.vue'),
                name: 'validation-suites-list',
                meta: {
                    permission: 'vr:suites:suite:view',
                    browserTitle: 'Validation suites',
                },
            },
            {
                path: 'create',
                component: () => import('@/modules/validation-rules/pages/ValidationSuiteCreate.vue'),
                name: 'validation-suites-create',
                meta: {
                    permission: 'vr:suites:suite:create',
                    browserTitle: 'Create validation suite',
                },
            },
            {
                path: ':id',
                component: () => import('@/modules/validation-rules/pages/ValidationSuiteEdit.vue'),
                name: 'validation-suites-edit',
                meta: {
                    permission: 'vr:suites:suite:edit',
                    browserTitle: 'Update validation suite',
                },
            },
        ],
    },
    {
        path: '/validation-rules/validation-suites/validation-suite',
        component: RoutingViewContainer,
        name: 'validation-suite-view',
        redirect: { name: 'validation-suite' },
        children: [
            {
                path: ':id',
                component: () => import('@/modules/validation-rules/pages/ValidationSuite.vue'),
                name: 'validation-suite',
                meta: {
                    permission: 'vr:suites:suite:view',
                    browserTitle: 'Validation suite',
                },
            },
            {
                path: ':validationSuiteId/create-business-rule',
                component: () => import('@/modules/validation-rules/pages/BusinessRuleCreate.vue'),
                name: 'create-business-rule',
                meta: {
                    permission: 'vr:suites:rule:create',
                    browserTitle: 'Business Rule Create',
                },
            },
            {
                path: ':validationSuiteId/edit-business-rule/:id',
                component: () => import('@/modules/validation-rules/pages/BusinessRuleEdit.vue'),
                name: 'edit-business-rule',
                meta: {
                    permission: 'vr:suites:rule:edit',
                    browserTitle: 'Business Rule Edit',
                },
            },
            {
                path: ':id/business-rule/:ruleId/check-create',
                component: () => import('@/modules/validation-rules/pages/CheckCreate.vue'),
                name: 'check-create',
                meta: {
                    permission: 'vr:suites:check:create',
                    browserTitle: 'Create Check',
                },
            },
            {
                path: ':id/business-rule/:ruleId/check/:checkId',
                component: () => import('@/modules/validation-rules/pages/CheckEdit.vue'),
                name: 'check-edit',
                meta: {
                    permission: 'vr:suites:check:edit',
                    browserTitle: 'Edit Check',
                },
            },
        ],
    },
];
