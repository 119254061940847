import { ApiV1, ApiV2 } from '@/api';

const showLoader = { showLoader: true };

export default {
  getActivities(params: object) {
    return ApiV1().get('/activities', { params });
  },

  getUserManagementActivities(type: string, id: string, params = { limit: 10000 }) {
    return ApiV2().get(`/activity-log/${type}/${id}`, { params });
  },

  getActivityLog(type: string, params: object) {
    return ApiV2(showLoader).get(`/${type}/${params}`);
  },
};
