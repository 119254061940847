<template>
  <div style="color:red">
    Component type not defined
  </div>
</template>

<script>
export default {
  name: 'KmtxDefault',
};
</script>
