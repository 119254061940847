var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.inFormGroup ? 'b-form-group' : 'div',{tag:"component",attrs:{"label":_vm.label}},[_c('div',{on:{"mouseover":function($event){_vm.formGroupHovered = true},"mouseleave":function($event){_vm.formGroupHovered = false}}},[_c('vue-autosuggest',{ref:_vm.reference,staticClass:"kmtx-filter-autosuggest",attrs:{"component-attr-id-autosuggest":'kmtx-autosuggest-' + _vm.index,"input-props":{
        id: 'autosuggest-' + _vm.index + '__input',
        class: 'form-control',
        placeholder: _vm.placeholder,
        required: _vm.required,
        disabled: _vm.disabled,
      },"suggestions":_vm.filteredData,"limit":_vm.options.limit},on:{"selected":_vm.onSelectHandler,"input":_vm.onInputChange},scopedSlots:_vm._u([(_vm.showClearBtn)?{key:"before-input",fn:function(){return [_c('b-button',{staticClass:"autosuggest-list__clear-btn",attrs:{"size":"sm","variant":"outline-light"},on:{"click":function($event){return _vm.onSelectHandler(_vm.defaultSuggestion)}}},[_c('icon',{staticClass:"domain-list__clear-icon",attrs:{"image":"close.svg","label":"Close","size":{ width: '12px', height: '12px' }}})],1)]},proxy:true}:null,{key:"default",fn:function({ suggestion }){return [_c('div',{staticClass:"d-flex flex-column"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.emNeedle(suggestion.item.name, _vm.selected, suggestion))}}),(suggestion.item.secondary_text)?_c('span',{staticClass:"secondary-text"},[_vm._v(" "+_vm._s(suggestion.item.secondary_text)+" ")]):_vm._e()])]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }