function convertEmptyStringToNull(obj) {
  if (Array.isArray(obj)) {
    return obj.map((item) => (typeof item === 'string' && item === '' ? null : item));
  }
  const result = {};

  for (const key in obj) {
    if (Object.hasOwn(obj, key)) {
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        result[key] = convertEmptyStringToNull(obj[key]);
      } else {
        result[key] = obj[key] === '' ? null : obj[key];
      }
    }
  }

  return result;
}

export default convertEmptyStringToNull;
