<!--
  default background-color var(--k-gray-50)
  set prop bg-color(string) if containers background has another color

  tables container should be display: flex

  if needed table with flexible height - set prop max-height
 -->
<template>
    <div
        class="kmtx-table-light-container"
        :class="{ nested: nestedComponent }"
    >
        <div
            class="kmtx-table-light-outer"
            :class="{ nested: nestedComponent }"
        >
            <BTable
                v-bind="$attrs"
                ref="table"
                class="kmtx-table-light"
                :class="{ 'k-scrollbar': !nestedComponent }"
                fixed
                show-empty
                no-border-collapse
                :items="items"
                :fields="fields"
                :sticky-header="true"
                :no-sort-reset="true"
                :table-class="[
                    { 'kmtx-table-light__headless': headless },
                    { 'kmtx-table-light__rounded': rounded && !nestedComponent },
                    { 'kmtx-table-light__uncollapsed': uncollapsed },
                ]"
                :style="tableStyle"
                v-on="$listeners"
                @scroll.native="(event) => $emit('scroll', event)"
            >
                <template #table-colgroup="scope">
                    <col
                        v-for="field in scope.fields"
                        :key="field.key"
                        :style="field.width ? { width: field.width + '%' } : ''"
                    />
                </template>
                <template #head()="{ label, field: { key, sortable } }">
                    <template v-if="sortable">
                        <KFlexContainer
                            wrap="nowrap"
                            align-items="center"
                        >
                            <span
                                ref="lightTableScrollTo"
                                class="d-flex mr-1"
                            >
                                {{ label }}
                            </span>
                            <KIcon
                                size="xs"
                                inverse
                                :icon="getSortIcon(key)"
                                class="d-flex"
                            />
                        </KFlexContainer>
                    </template>
                    <template v-else>
                        <KFlexContainer
                            wrap="nowrap"
                            align-items="center"
                        >
                            <span ref="lightTableScrollTo">{{ label }}</span>
                        </KFlexContainer>
                    </template>
                </template>
                <template
                    v-for="(_, name) in $scopedSlots"
                    #[name]="slotData"
                >
                    <slot
                        :name="name"
                        v-bind="slotData"
                    />
                </template>
                <template #table-busy>
                    <KLoader size="xl" />
                </template>
            </BTable>
        </div>
    </div>
</template>

<script>
import { KIcon, KFlexContainer, KLoader } from '@kilometrix/polyomino';
import { isEqual } from 'lodash';

export default {
    name: 'TableComponent',
    components: {
        KLoader,
        KIcon,
        KFlexContainer,
    },
    emits: ['scroll'],
    props: {
        items: {
            type: Array,
            required: false,
            default: null,
        },
        fields: {
            type: Array,
            required: false,
            default: null,
        },
        nestedComponent: {
            type: Boolean,
            default: false,
        },
        sortBy: {
            type: String,
            default: '',
        },
        sortDesc: {
            type: Boolean,
            default: false,
        },
        rounded: {
            type: Boolean,
            default: true,
        },
        bgColor: {
            type: String,
            default: '',
        },
        maxHeight: {
            type: String,
            default: '100%',
        },
        headless: {
            type: Boolean,
            default: false,
        },
        uncollapsed: {
            type: Boolean,
            default: false,
        },
        withoutAutoScroll: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        tableStyle() {
            const style = {
                maxHeight: this.maxHeight,
            };
            if (this.bgColor) {
                style.backgroundColor = this.bgColor;
            }
            return style;
        },
    },

    watch: {
        items(val, prev) {
            if (this.withoutAutoScroll || isEqual(prev, val)) return;
            // Scrolling to the top of the table every time data changes (except multiple edits)
            const scrollTo = this.$refs.lightTableScrollTo;
            if (!scrollTo) return;
            const positionFromTopOfScrollableDiv = scrollTo.offsetTop;

            const scrollableDivElement = document.querySelector('div.kmtx-table-light.k-scrollbar');
            if (!scrollableDivElement) return;
            scrollableDivElement.scrollTop = positionFromTopOfScrollableDiv;
        },
    },

    methods: {
        getSortIcon(key) {
            if (this.sortBy === key) {
                return this.sortDesc ? 'sort-down' : 'sort-up';
            }
            return 'sort';
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/sass/_variables';
.k-scrollbar::-webkit-scrollbar-track {
    margin-top: 56px;
}

.kmtx-table-light {
    background-color: var(--k-gray-50);
    &-container:not(.nested) {
        position: relative;
        display: flex;
        flex: 1 0 auto;
    }
    &-outer {
        width: 100%;
        &:not(.nested) {
            position: absolute;
            height: 100%;
        }
        &.nested {
            .kmtx-table-light {
                margin-bottom: 0;
            }
        }
    }
    ::v-deep {
        .table.b-table {
            font-family: var(--k-polyomino-font-family);
            font-size: 14px;
            line-height: 20px;
            letter-spacing: -0.01em;
            background-color: inherit;
            border-collapse: separate;
            td {
                word-break: break-word;
                background-image: none !important;
            }
            thead:not(.sr-only) {
                position: sticky;
                top: 0;
                z-index: 2;
                box-shadow: 0 4px 10px rgba(0, 0, 0, 0.08);
                th {
                    position: sticky !important;
                    background-image: none !important;
                    background-color: var(--k-bg-primary);
                    border: none;
                    word-break: break-word;
                    color: var(--k-text-secondary);
                    user-select: none;
                    font-weight: 500;
                    padding: 14px 16px;
                    vertical-align: middle;
                }
            }
            tbody {
                td {
                    font-weight: 400;
                    color: var(--k-text-list);
                    background-color: var(--k-bg-primary);
                    padding: 14px 16px;
                    border-top: 1px solid var(--dialog-bg);
                }
                tr {
                    &:first-of-type {
                        td {
                            border-top: none;
                        }
                    }
                    &:hover {
                        td {
                            background-color: var(--k-gray-50);
                        }
                    }
                    &.b-table-has-details {
                        td {
                            &:first-of-type {
                                border-top-left-radius: 10px;
                                border-left: 1px solid var(--dialog-bg);
                            }
                            &:last-of-type {
                                border-top-right-radius: 10px;
                                border-right: 1px solid var(--dialog-bg);
                            }
                        }
                        &:hover {
                            td {
                                background-color: var(--k-bg-primary);
                            }
                        }
                    }
                    &.b-table-details {
                        &:hover {
                            td {
                                background-color: var(--k-bg-primary);
                            }
                        }
                        & > td {
                            border: 1px solid var(--dialog-bg);
                            border-top: none;
                            border-radius: 0 0 10px 10px;
                            padding: 0;
                            td {
                                border-color: transparent;
                            }
                        }
                        & + tr {
                            td {
                                border-top: none;
                            }
                        }
                    }
                }
            }
            .b-table-busy-slot {
                background-color: inherit;
                td {
                    min-height: 56px;
                    background-color: inherit !important;
                    > * {
                        margin: auto;
                    }
                }
            }

            &.kmtx-table-light__headless {
                thead {
                    border: 0;
                    clip: rect(0, 0, 0, 0);
                    height: 1px;
                    margin: -1px;
                    overflow: hidden;
                    padding: 0;
                    position: absolute;
                    width: 1px;
                }
            }
            &.kmtx-table-light__uncollapsed {
                tbody {
                    tr {
                        &.b-table-has-details {
                            td {
                                &:first-of-type {
                                    border-top-left-radius: 0;
                                }
                                &:last-of-type {
                                    border-top-right-radius: 0;
                                }
                            }
                        }
                        &.b-table-details {
                            & > td {
                                border-radius: 0;
                                td {
                                    border-radius: 0 !important;
                                }
                            }
                        }
                    }
                }
            }
            &.kmtx-table-light__rounded {
                border-radius: 10px 10px 0 0;
                thead {
                    background-color: inherit;

                    &:after {
                        content: '';
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        background-color: inherit;
                        height: 2px;
                    }
                    th {
                        &:first-of-type {
                            border-radius: 10px 0 0 0;
                        }
                        &:last-of-type {
                            border-radius: 0 10px 0 0;
                        }
                    }
                }
                tbody {
                    tr {
                        &:last-of-type {
                            td {
                                &:first-of-type {
                                    border-radius: 0 0 0 10px;
                                }
                                &:last-of-type {
                                    border-radius: 0 0 10px 0;
                                }
                            }
                            &.b-table-details {
                                & > td {
                                    border-radius: 0 0 10px 10px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
