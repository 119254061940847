<template>
  <kmtx-chart
    :ref="widget.name"
    :options="chartOptions"
    class="widget-chart"
    @hover="showChartToolbox(true)"
    @blur="showChartToolbox(false)"
  />
</template>

<script>
import { mapState } from 'vuex';
import EChartComponent from '../../echart/EChartComponent.vue';

export default {
  name: 'ChartWidget',

  components: {
    'kmtx-chart': EChartComponent,
  },

  props: {
    data: {
      type: Array,
      default: () => [],
    },
    widget: {
      type: Object,
      required: true,
    },
  },

  emits: ['error-message'],

  data() {
    return {
      chartOptions: {},
    };
  },

  computed: {
    ...mapState('newFilters', ['currentStep']),
    optionsLoader() {
      if (!this.widget?.configuration?.report_type) {
        return null;
      }
      return () => import(/* webpackMode: "eager" */`~/components/echart/options/${this.widget.configuration.report_type}`);
    },
  },

  watch: {
    data: {
      handler: 'setChartOptions',
      immediate: true,
    },
  },

  methods: {
    setChartOptions() {
      if (!this.data.length) {
        this.$emit('error-message', 'No data found.');
      } else {
        this.optionsLoader().then((module) => {
          const params = {
            widget: this.widget,
          };
          if (this.widget.configuration.report_type === 'line-chart' && this.currentStep !== 'auto') {
            params.r = this.currentStep;
          }
          this.chartOptions = new module.default(this.data, {}, params);
          if (this.widget.configuration.labels?.length) {
            this.setLabels();
          }
        }).catch(() => {
          this.$emit('error-message', 'Couldn\'t load chart.');
        });
      }
    },

    setLabels() {
      const config = this.widget.configuration;
      this.chartOptions.series.map((series, index) => {
        // todo need to add way to identify connection between label and series in line charts
        if (config.report_type === 'line-chart' && config.labels.length) {
          series.name = `${config.labels[0].label} - ${series.name}`;
        } else {
          const { label } = config.labels?.find((label) => label.field_id === config.y_axes_field_id);
          if (label) {
            series.name = label;
          }
        }
        this.chartOptions.legend.data[index] = series.name;
      });
    },

    showChartToolbox(doShow) {
      if (this.chartOptions?.toolbox) {
        this.chartOptions.toolbox.show = doShow;
      }
    },
  },
};
</script>
