import { AxiosResponse } from 'axios';
import { ApiV2 } from '@/api';
import userManagementStore from '@/modules/user-management/user-management.store';

const showLoader = { showLoader: true };
const cantCancel = { cantCancel: true };
const withoutVersion = { withoutVersion: true };

interface BaseHead {
    'cache-control': 'no-cache, private';
    'content-type': 'application/json';
}

type Response<Headers, Data> = Promise<
    AxiosResponse<
        {
            headers: Headers;
            data: Data;
            request: XMLHttpRequest;
            status: number;
            statusText: string;
            config: {
                adapter: () => {};
                baseURL: string;
                data: string;
                headers: {
                    Accept: string;
                    Authorization: string;
                    'Content-Type': string;
                    'X-IAM-ACCOUNT-ID': string;
                    'X-Requested-With': string;
                };
                maxBodyLength: number;
                maxContentLength: number;
                method: 'post';
                timeout: number;
                transformRequest: [() => {}];
                transformResponse: [() => {}];
                transitional: { silentJSONParsing: boolean; forcedJSONParsing: boolean; clarifyTimeoutError: boolean };
                url: string;
                validateStatus: () => {};
                withCredentials: boolean;
                xsrfCookieName: 'XSRF-TOKEN';
                xsrfHeaderName: 'X-XSRF-TOKEN';
            };
        },
        ErrorMessage
    >
>;

interface ResponseData<T> {
    data: T;
}

type ErrorMessage = string;

export interface Params {
    limit: number;
}

export interface GetUsersParams {
    limit?: number;
    filters?: string;
}

interface Role {
    id: string;
}

export interface RoleFull extends Role {
    name: string;
    scope: number;
}

interface Account {
    id: string;
    roles: Role[];
}

interface AccountUser extends Account {
    name: string;
    roles: RoleFull[];
    parent_id?: string;
    type?: 1 | 2;
}

export interface IAccountFull extends Account {
    administrators: { id: string; name: string }[];
    archived_at: string;
    created_at: string;
    created_by: string;
    description: string;
    name: string;
    owner: { id: string; name: string } | null;
    parent_id: string | null;
    status: 0 | 1 | 2 | 3;
    updated_at: string;
    roles: RoleFull[];
    type?: 1 | 2;
    invitation_token?: string;
}

export interface UserBase {
    accounts: Account[];
    email: string;
    name: string;
    timezone: string;
    type: string | number;
    password?: string;
}

export interface UserCreate extends UserBase {
    type: string;
}

export interface UserUpdate extends UserCreate {
    id: string;
}

export interface UserInvite {
    accounts: Account[];
    email: string;
}

export interface UserGetResponse extends UserBase {
    accounts: AccountUser[];
    labels: string[];
    type: number;
    archived_at: string;
    avatar: string;
    created_at: string;
    created_by: string;
    id: string;
    master_account_id: string;
    status: number;
    updated_at: string;
    updated_by: string;
    department: string;
    job_title: string;
    manager_id: string;
}

interface LinkResponse {
    notification_sent: boolean;
    token: string;
}

interface Timezone {
    name: string;
    value: string;
}

type TimezonesResponse = Timezone[];

interface CheckUserResponse {
    is_service: boolean;
}

export interface IAuthDataRequest {
    redirect_uri: string;
    invitation_token?: string;
}

export default {
    getUsers(params: GetUsersParams, options?: { showLoader: boolean }) {
        return ApiV2({ ...showLoader, ...options }).get(`iam/users/`, { params });
    },
    getIAMUsers(filters?: Partial<{ permissions: string[]; accounts: string[]; users: string[]; type: 0 | 1 | 2 }>) {
        const filter = filters ? `?filters=${JSON.stringify(filters)}` : '';
        return ApiV2({ ...withoutVersion, ...showLoader }).get(`auth/clients${filter}`);
    },
    getUsersShortList(params: Params) {
        return ApiV2(showLoader).get(`iam/users/`, { params: { view: 'short', ...params } });
    },
    getUser(id: string) {
        return ApiV2(showLoader).get<{ data: UserGetResponse }>(`iam/users/${id}`);
    },
    createUser(user): Response<BaseHead & { 'x-entity-id': string }, {}> {
        return ApiV2({ ...cantCancel, ...showLoader }).post(`iam/users/`, user);
    },
    inviteUser(user: UserInvite, userId: string) {
        return ApiV2({ ...cantCancel, ...showLoader }).post(`iam/users/${userId}/assign`, user);
    },
    updateUser(user) {
        return ApiV2({ ...cantCancel, ...showLoader }).put(`iam/users/${user.id}`, user);
    },
    updateUserPassword(user: { id: string; password: string }) {
        return ApiV2({ ...cantCancel, ...showLoader }).put(`iam/users/${user.id}`, user);
    },
    updateUserProfile(userId: string, data: Partial<UserBase>) {
        return ApiV2({ ...cantCancel, ...showLoader }).put(`iam/users/${userId}`, data);
    },
    deleteUser(id: string) {
        return ApiV2(cantCancel).delete(`iam/users/${id}`);
    },
    getInviteLink(id: string) {
        return ApiV2(cantCancel).post<{ data: LinkResponse }>(`iam/users/${id}/invitation-token`);
    },
    getResetLink(id: string) {
        return ApiV2(cantCancel).post<{ data: LinkResponse }>(`iam/users/${id}/password-reset-token`);
    },
    getUserStatuses() {
        return ApiV2(showLoader).get(`iam/users/statuses`);
    },
    getAccountStatuses() {
        return ApiV2(showLoader).get(`iam/accounts/statuses`);
    },
    getTimeZones(): Response<BaseHead, TimezonesResponse> {
        return ApiV2(showLoader).get(`timezones`);
    },
    checkUserId(id: string) {
        return ApiV2()
            .get(`iam/users/${id}/check`)
            .catch((error) => {
                if (!error.cancelReason) throw error;
            });
    },
    signUpUserOAuth(params: { OAuthCode: string; provider: string; requestParams: IAuthDataRequest }) {
        const { OAuthCode, provider, requestParams } = params;
        const code = new URLSearchParams(OAuthCode).toString();
        return ApiV2({ ...cantCancel, withoutVersion: true, withoutAccountId: true }).post(
            `auth/providers/${provider}/sign-up?code=${code}`,
            requestParams
        );
    },
    // Accounts
    getAccountsList(params: Params) {
        return ApiV2({ ...showLoader }).get(`iam/accounts/`, { params });
    },
    getAccount(id: string) {
        return ApiV2({ ...showLoader }).get<{ data: IAccountFull }>(`iam/accounts/${id}`);
    },
    createAccount(account: any) {
        return ApiV2({ ...cantCancel, ...showLoader }).post(`iam/accounts/`, account);
    },
    editAccount(id: string, account: any) {
        return ApiV2({ ...cantCancel, ...showLoader }).patch(`iam/accounts/${id}`, account);
    },
    deleteAccount(id: string) {
        return ApiV2({ ...cantCancel, ...showLoader }).delete(`iam/accounts/${id}`);
    },
    activateAccount(id: string) {
        return ApiV2({ ...showLoader }).patch(`iam/accounts/${id}/restore`);
    },

    isSystemAccount(account: IAccountFull) {
        let type: 1 | 2 | undefined;

        if ((<any>Object).hasOwn(account, 'type') && typeof account.type === 'number') {
            type = account.type;
        } else {
            const { accounts } = userManagementStore.state;
            const parent = (accounts as AccountUser[]).find((account) => account.id === account.parent_id);
            type = parent?.type;
        }
        const { marketTypes } = userManagementStore.state;
        return type ? marketTypes[type] === 'service' : false;
    },

    getInvitationToken(accountId: string) {
        return ApiV2().post(`iam/accounts/${accountId}/invitation-token`);
    },

    // Roles
    getRoles(params: Params = { limit: 1000 }) {
        return ApiV2({ ...showLoader }).get(`iam/roles/`, { params });
    },
    getPermissions(params: Params = { limit: 1000 }) {
        return ApiV2().get('iam/permissions/', { params });
    },
    getPermissionsCategories(params: Params = { limit: 1000 }) {
        return ApiV2().get('iam/permissions/categories/', { params });
    },

    // Admin-Api route with labels collection
    getLabels(params: Params = { limit: 1000 }) {
        return ApiV2().get('labels', { params });
    },
};
