/**
 * LocalStorage service
 */

const LocalStorage = {
    // Getting value from localStorage by key
    get(key: string): any {
        const data = localStorage.getItem(key);
        if (data && data.length) {
            try {
                return JSON.parse(data);
            } catch (error) {
                return data;
            }
        }
        return null;
    },

    // Setting key with value to localStorage
    set(key: string, value: any) {
        localStorage.setItem(key, typeof value === 'object' ? JSON.stringify(value) : value);
    },

    // Removing the localStorage item by key
    remove(key: string) {
        localStorage.removeItem(key);
    },

    // Clearing the localStorage
    clear() {
        localStorage.clear();
    },
};

export default LocalStorage;
