import {
  ref, computed,
} from 'vue';

export interface IPagination {
    page: number;
    pages: number;
    limit: number;
    total: number;
}
export default function usePagination(initPagination: Partial<IPagination>) {
  const pagination = ref(initPagination);

  const onChangePaginationPage = (page: number): void => { pagination.value.page = page; };
  const onPerPageUpdated = (limit: number): void => {
    pagination.value.page = 1;
    pagination.value.limit = limit;
  };
  const setPagination = (newPaginationData: Partial<IPagination>): void => {
    pagination.value = { ...newPaginationData };
  };

  const pageAndLimit = computed(() => {
    const { page, limit } = pagination.value;
    return { page, limit };
  });
  return {
    pagination,
    pageAndLimit,
    onChangePaginationPage,
    onPerPageUpdated,
    setPagination,
  };
}
