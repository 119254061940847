import store from '@/store';

export function updateActivityLogUsers(clearState = false) {
  if (clearState) {
    store.commit('activityLog/clearUsers');
  } else if (!store.state.activityLog.users.length) {
    store.dispatch('activityLog/fetchUsers');
  }
}

export function updateDataSourcesList(clearState = false) {
  if (clearState) {
    store.commit('dataSources/clearDataSources');
  }
}
