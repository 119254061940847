<template>
  <div>
    <table-component
      :id="tableId"
      class="table-responsive"
      :per-page="pagination.per_page"
      :provider-source="providerSource"
      :fields="fields"
      hover
    >
      <template #cell(url)="row">
        <a
          v-if="row.item.filename"
          :href="`${getDownloadLink(row.item)}`"
        >Download</a>
        <span v-else>There is no generated report yet.. 😢</span>
      </template>
      <template #cell(date_from)="row">
        {{ dateFromUTS(row.item.date_from) }}
      </template>
      <template #cell(date_to)="row">
        {{ dateFromUTS(row.item.date_to) }}
      </template>
    </table-component>
    <b-row>
      <b-col>
        <b-button
          variant="primary"
          class="float-right"
          :to="{ name: newReportRouteName }"
        >
          Create new report
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { baseURL } from '@/api';
import TableComponent from './TableComponent.vue';

const { mapActions } = createNamespacedHelpers('report');

export default {
  name: 'ReportsComponent',
  components: {
    TableComponent,
  },
  data() {
    return {
      newReportRouteName: 'reports-export-create',
      pagination: {
        per_page: 15,
      },
      tableId: 'reports-table',
      fields: [
        { key: 'id', label: 'Report ID', sortable: false },
        {
          key: 'name', label: 'Name', sortable: true, sortDirection: 'desc',
        },
        {
          key: 'source', label: 'Source', sortable: true, sortDirection: 'desc',
        },
        { key: 'status', label: 'Status', sortable: false },
        { key: 'date_from', label: 'Date From', sortable: false },
        { key: 'date_to', label: 'Date To', sortable: false },
        { key: 'url', label: 'Link', sortable: false },
      ],
    };
  },
  computed: {
    providerSource() {
      return this.fillReports;
    },
  },
  methods: {
    ...mapActions(['fillReports']),
    dateFormat(int) {
      return new Date(int * 1000).toLocaleString();
    },
    getDownloadLink(item) {
      return `${baseURL}/report/download/${item.id}`;
    },
  },
};
</script>
