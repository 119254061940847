<template>
  <b-form-group :label="label">
    <b-form-select
      :ref="reference"
      v-model="currentStep"
      :options="getSteps"
      class="kmtx-steps"
      @change="onChange"
    />
  </b-form-group>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'KmtxSteps',
  props: {
    label: {
      type: String,
      default: 'Steps',
    },
    reference: {
      type: String,
      default: 'kmtx-steps',
    },
  },
  emits: ['step-change'],
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters('filters', {
      getSteps: 'getSteps',
      storeStep: 'getCurrentStep',
    }),
    currentStep: {
      get() {
        return this.storeStep;
      },
      set(newStep) {
        return newStep;
      },
    },
  },
  methods: {
    ...mapActions('filters', {
      setCurrentStep: 'setCurrentStep',
    }),
    onChange(step) {
      this.setCurrentStep(step);
      this.$emit('step-change', step);
    },
  },
};
</script>

<style scoped>
.kmtx-steps {
  min-width: 10em;
}
</style>
