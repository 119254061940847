// initial state
const state = {
  pendingRequests: 0,
  pendingLoaders: 0,
};

const getters = {
  isLoading: (state: any) => !!state.pendingRequests || !!state.pendingLoaders,
};

// mutations
const mutations = {
  addPendingRequest(state: any) {
    state.pendingRequests += 1;
  },
  subtractPendingRequest(state: any) {
    state.pendingRequests -= 1;
  },
  addPendingLoader(state: any) {
    state.pendingLoaders += 1;
  },
  subtractPendingLoader(state: any) {
    if (state.pendingLoaders > 0) {
      state.pendingLoaders -= 1;
    } else if (process.env.VUE_APP_ENV !== 'production') {
      console.error('Tried to subtract loader, while there is no pending loaders');
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
