<template>
  <b-form-group :label="label">
    <div
      @mouseover="formGroupHovered = true"
      @mouseleave="formGroupHovered = false"
    >
      <vue-autosuggest
        :ref="reference"
        v-model="selected"
        :component-attr-id-autosuggest="'kmtx-autosuggest-' + index"
        :input-props="{
          id: 'autosuggest-' + index + '__input',
          class: ['form-control', 'input-autosuggest'],
          placeholder: placeholder,
          required: true,
        }"
        :suggestions="filteredData"

        :limit="filter_options.limit"

        class="kmtx-filter-autosuggest"
        @selected="onSelectHandler"
        @input="onInputChange"
      >
        <template
          v-if="showClearBtn"
          #before-input
        >
          <b-button
            class="autosuggest-list__clear-btn"
            size="sm"
            variant="outline-light"
            @click="onSelectHandler(defaultSuggestion, true)"
          >
            <icon
              image="close.svg"
              class="domain-list__clear-icon"
              label="Close"
              :size="{ width: '12px', height: '12px' }"
            />
          </b-button>
        </template>
        <template #default="{ suggestion }">
          <!-- eslint-disable-next-line vue/no-v-html -->
          <span v-html="emNeedle(suggestion.item.name, selected)" />
        </template>
      </vue-autosuggest>
    </div>
  </b-form-group>
</template>

<script>
import { VueAutosuggest } from 'vue-autosuggest';
import { ApiV1 } from '@/api';
import { mapMutations, mapState } from 'vuex';
import IconsComponent from '../../../IconsComponent.vue';

export default {
  name: 'KmtxAutosuggest',
  components: {
    VueAutosuggest,
    icon: IconsComponent,
  },
  props: {
    index: {
      type: Number,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    data: {
      type: Array,
      default: () => [],
    },
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: 'Type to search...',
    },
    reference: {
      type: String,
      default: 'kmtx-autosuggest',
    },
    filterOptions: {
      type: Object,
      default: () => ({
        limit: Infinity,
      }),
    },
  },
  data() {
    return {
      selected: '',
      fetchedData: [],
      filteredData: [],
      formGroupHovered: false,
      timerId: null,
    };
  },
  computed: {
    ...mapState('dataMarts', ['editState']),
    showClearBtn() {
      return this.formGroupHovered && this.selected;
    },
    defaultSuggestion() {
      return { item: { id: null, name: '' } };
    },
  },
  watch: {
    data: {
      handler(newData, oldData) {
        if (newData !== oldData && !this.filter_options.dictionary_url) {
          this.filteredData = [{ data: this.sortData(newData, this.selected) }];
        }
      },
      immediate: true,
    },
    fetchedData: {
      handler(newData, oldData) {
        if (newData !== oldData && this.filter_options.dictionary_url) {
          this.filteredData = [{ data: this.sortData(newData, this.selected) }];
        }
      },
      immediate: true,
    },
  },
  created() {
    if (this.value) {
      this.selected = this.value;
    }
    if (this.filter_options.dictionary_url) {
      if (!this.editState) {
        this.fetchData(this.selected);
      }
    }
  },
  methods: {
    ...mapMutations('newFilters', ['updateFilterByIndex']),
    fetchData(value) {
      clearTimeout(this.timerId);

      this.timerId = setTimeout(() => {
        const params = {};
        params.value = value;
        return ApiV1({ baseUrl: `${process.env.VUE_APP_API_BASE}${this.filter_options.dictionary_url}` }).get('', { params }).then((resp) => {
          this.fetchedData = resp.data.map(({ id, value: name }) => ({ name, id }));
        });
      }, 300);
    },
    onSelectHandler(selected, resetData = false) {
      if (selected !== null) {
        this.selected = selected.item.name;
        this.formGroupHovered = false;
        const filter = {
          filter_value: selected.item.name,
          filter_options: { ...this.filter_options },
        };
        this.updateFilterByIndex({ index: this.index, filter });
        if (resetData && this.filter_options.dictionary_url) {
          this.fetchData();
        }
      }
    },
    onInputChange(value = '') {
      this.selected = value;
      value = value.trim().toLowerCase();

      if (this.filter_options.dictionary_url) {
        this.fetchData(value);
      } else if (this.data.length > 0) {
        let filteredData = this.data.filter((item) => {
          if (item.name !== undefined) {
            return item.name.trim().toLowerCase().indexOf(value) > -1;
          }
        });
        filteredData = this.sortData(filteredData, value);

        this.filteredData = [{ data: filteredData }];
      }
    },
    sortData(data = [], input) {
      input = input.trim().toLowerCase();
      const alphabetSorted = data.sort((a, b) => a.name.localeCompare(b.name));

      const preSort = [];
      const postSort = [];

      alphabetSorted.forEach((item) => {
        const name = item.name.trim().toLowerCase();
        if (name.indexOf(input) === 0) {
          preSort.push(item);
        } else if (name.indexOf(input) > 0) {
          postSort.push(item);
        }
      });

      return preSort.concat(postSort);
    },
    emNeedle(string, needle) {
      needle = needle.toLowerCase();

      const startEm = '<b>';
      const endEm = '</b>';

      const needleLength = needle.length;
      const needleStartPosition = string.toLowerCase().indexOf(needle);
      const needleEndPosition = needleStartPosition + needleLength + startEm.length;

      let newString = '';

      newString = this.insertSubstring(string, startEm, needleStartPosition);

      newString = this.insertSubstring(newString, endEm, needleEndPosition);

      return newString;
    },
    insertSubstring(string, substring, startPosition) {
      return string.slice(0, startPosition) + substring + string.slice(startPosition);
    },
  },
};
</script>

<style lang="scss">
.kmtx-filter-autosuggest
{
    min-width: 210px;
    position: relative;
}

.autosuggest-list__clear-btn
{
    position: absolute;
    height: calc(100% - 2px);
    bottom: 1px;
    right: 1px;
    &.btn
    {
        background-color: #fff;
    }

    .domain-list__clear-icon
    {
        transform: scale(.8);
    }
}
</style>
