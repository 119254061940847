<template>
  <div>
    <b-form-checkbox
      name="checkbox"
      @change="onChange('compare', $event)"
    >
      Compare
    </b-form-checkbox>
  </div>
</template>

<script>
export default {
  name: 'DateOptions',
  props: {
    filter: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['change'],
  data() {
    return {
      filterOptions: {},
    };
  },
  methods: {
    onChange(filter, event) {
      this.filterOptions[filter] = event;
      this.$emit('change', this.filterOptions);
    },
  },
};
</script>
