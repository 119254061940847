import clone from 'rfdc';
import { kFormatter } from '../../../../helper';
import { defaultOptions } from '../default-options';

const deepmerge = require('deepmerge');

const lineChartOptions = {
  xAxis: {
    type: 'category',
    boundaryGap: false,
    axisPointer: {
      show: true,
    },
    axisLabel: {
      interval: 'auto',
    },
    data: [],
  },
  yAxis: {
    type: 'value',
    axisLabel: {
      interval: 'auto',
      formatter: (params: any) => kFormatter(params),
    },
  },
};

export default class LineChartOptions {
  series: any;

  legend: any;

  xAxis: any;

  tooltip: any;

  xAxisConverters: any = {
    DateTime: (item: any, params: any) => {
      this._setLabels(params);
      return item;
    },
    timeSlot: (item: any) => {
      this._setTooltipLabel();
      return item;
    },
  };

  dateRegEx: any = {
    hour: /(\d{2}:\d{2}:\d{2})+/,
    day: /(\d{4}-\d{2}-\d{2})+/,
    hourDay: /(\d{2}-\d{2} \d{2}:\d{2})+/,
  };

  dateFormatters: any = {
    // return format 'hh-mm'
    hour: (item: any, type: any) => item.substr(item.search(this.dateRegEx[type]), 5),
    // return format 'dd-MM'
    day: (item: any, type: any) => item.substr(item.search(this.dateRegEx[type]), 10).slice(-5),
    // return format 'dd-MM hh-mm'
    hourDay: (item: any, type: any) => item.slice(item.search(this.dateRegEx[type]), 16).split(' ').reverse().join(' '),
  };

  // expecting that 'series' property will be provided for each chart
  constructor(data = [], chartOptions = {}, buildOptions = {}) {
    chartOptions = clone()(chartOptions);
    Object.assign(this, deepmerge.all([this, defaultOptions, lineChartOptions, chartOptions]));

    const { slices, dateType } = getAxisKeysFromData(data);
    this._setSeries(slices);
    this.convertChartData(data, { dateType, slices, buildOptions });
  }

  /**
     * Set series from slices
     *
     * @param slices
     * @private
     */
  _setSeries(slices: any) {
    slices.forEach((slice: any) => {
      const seriesDefinedId = this.series.findIndex((item: any) => slice === item.id);
      if (seriesDefinedId >= 0) {
        this.series[seriesDefinedId] = { ...this.series[seriesDefinedId], ...this._setSeriesOptions(slice, slices.length, seriesDefinedId) };
        this.legend.data[seriesDefinedId] = slice;
      } else {
        this.series.push(this._setSeriesOptions(slice, slices.length));
        this.legend.data.push(slice);
      }
    });
  }

  /**
     * Set default options to series object
     *
     * @param slice
     * @param seriesLength
     * @param seriesDefinedId
     * @returns {{}} - series options
     * @private
     */
  _setSeriesOptions(slice: any, seriesLength: any, seriesDefinedId?: any) {
    const series: any = {};
    const gradientColors = [
      ['rgba(0,143,251, 0.65)', 'rgba(128,199,253,0.5)'],
      ['rgba(0,227,150,0.65)', 'rgba(128,241,203,0.5)'],
      ['rgba(254,176,25,0.65)', 'rgba(255,216,140,0.5)'],
    ];
    const gradient = {
      type: 'linear',
      x: 0,
      y: 0.5,
      x2: 0,
      y2: 1,
      colorStops: [{
        offset: 0, color: gradientColors[seriesDefinedId || this.series.length][0],
      }, {
        offset: 1, color: gradientColors[seriesDefinedId || this.series.length][1],
      }],
    };
    const isGradientArea = seriesDefinedId || seriesDefinedId === 0 ? !this.series[seriesDefinedId].areaStyle : true;
    series.areaStyle = isGradientArea ? { color: gradient } : series.areaStyle;
    series.showSymbol = !isGradientArea;
    series.z = seriesLength - this.series.length;
    series.smooth = series.smooth || true;
    series.type = 'line';
    series.id = slice;
    series.name = slice;
    return series;
  }

  /**
     * Converts data from api and fills it to class props
     *
     * @param {Array} data - chart data which has to be converted
     * @param {string} dateType - xAxis data type
     * @param {string} x_axes_field - xAxis property name
     * @param {Array} slices - series items
     * @param {object} buildOptions - filters for chart selected by user
     */
  convertChartData(data: any, { dateType, slices, buildOptions = {} }: any) {
    const xAxisData: any = [];
    const yAxisData: any = {};
    slices = slices.filter((slice: any) => slice);

    // prepare properties for series
    slices.forEach((slice: any, index: any) => {
      yAxisData[index] = [];
    });

    data.map((item: any, ind: any) => {
      // fill xAxisData data
      xAxisData.push(this.xAxisConverters[dateType](item[dateType], buildOptions) || ind);
      // fill corresponding slice with its data
      slices.forEach((slice: any, index: any) => {
        yAxisData[index].push(parseInt(item[slice]));
      });
    });

    // set prepared data to the chart options
    this.xAxis.data = xAxisData;
    slices.forEach((slice: any, index: any) => {
      this.series[index].data = yAxisData[index];
    });
  }

  /**
     * Convert xAxis item data to acceptable format by chart
     *
     * @param {object} params - filters for chart selected by user
     * @private
     * @returns {string|*}
     */
  _setLabels(params: any) {
    const offset = ((new Date(params.dateTo) as any) - (new Date(params.dateFrom) as any)) / 1000 / 60 / 60 / 24;
    this._setTooltipLabel(offset >= 7 || params.r >= 1440 ? 'day' : 'hourDay');
    this._setXAxisLabel(offset >= 7 || params.r >= 1440 ? 'day' : 'hour');
  }

  /**
     * Format labels at the bottom of xAxis
     *
     * @param {string} type - type of expected format
     * @private
     */
  _setXAxisLabel(type: any) {
    this.xAxis.axisLabel.formatter = (params: any) => this.dateFormatters[type](params, type);
  }

  /**
     * Format tooltips appeared on cursor hover
     *
     * @param {string} type - type of expected format
     * @private
     */
  _setTooltipLabel(type = '') {
    this.tooltip.formatter = (params: any) => {
      const body: string[] = [];
      const dateLabel = type ? this.dateFormatters[type](params[0].axisValue, type) : params[0].axisValue;
      body.push(dateLabel);
      params.forEach(({ seriesName, value }: any) => body.push(`${seriesName}: ${kFormatter(value)}`));

      return body.join(`<br />`);
    };
  }
}

/**
 * Get keys for xAxis type and yAxis slices names
 *
 * @param data
 * @returns {{slices: [], dateType: string}}
 * @private
 */
export function getAxisKeysFromData(data: any) {
  const possibleDateTypes = ['timeSlot', 'DateTime'];
  let dateType = '';
  const slices: any = [];
  if (data.length) {
    Object.keys(data[0]).forEach((key) => {
      if (possibleDateTypes.includes(key)) {
        dateType = key;
      } else {
        slices.push(key);
      }
    });
  }
  return { slices, dateType };
}
