<template>
  <b-form-group :label="label">
    <b-form-input
      :ref="reference"
      v-model="input"
      :placeholder="placeholder"
      :type="config.type"
      :disabled="disabled"
      :debounce="config.debounce"
      class="kmtx-filters-item"
      @keyup.enter="onEnter()"
      @input="onInput()"
      @update="onUpdate()"
    />
  </b-form-group>
</template>

<script>
export default {
  name: 'KmtxInput',
  props: {
    label: {
      type: String,
      default: 'Input',
    },
    placeholder: {
      type: String,
      default: 'Type something...',
    },
    reference: {
      type: String,
      default: 'kmtx-input',
    },
    value: {
      type: String,
      default: null,
    },
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['input-submit', 'input-input', 'input-update'],
  data() {
    return {
      input: null,
    };
  },
  computed: {
    config() {
      return {
        disabled: false,
        type: 'search',
        debounce: 0,
        ...this.options,
      };
    },
    disabled() {
      return this.config.disabled;
    },
  },
  watch: {
    value(to) {
      this.input = to;
    },
  },
  created() {
    this.input = this.value;
  },
  methods: {
    onEnter() {
      this.$emit('input-submit', this.input);
    },
    onInput() {
      this.$emit('input-input', { value: this.input, reference: this.reference });
    },
    onUpdate() {
      this.$emit('input-update', this.input);
    },
  },
};
</script>
