<template>
  <div>
    <b-form-group
      label="Filter name"
      :disabled="!dataSources.length"
    >
      <b-form-input
        id="dictionary-name"
        v-model="filterName"
        placeholder="Enter filter name"
        trim
        autocomplete="off"
        @change="$emit('change', { filter_name: filterName, filter_options: { name: filterName } })"
      />
    </b-form-group>
    <b-form-group
      label="Data source"
      label-for="data_marts"
      :disabled="!dataSources.length"
    >
      <kmtx-auto-suggest
        id="data_marts"
        ref="data_marts"
        placeholder="Search for data source"
        label=""
        :data="autoSuggestFilteredData.map(({ text: name, value }) => { return { name, value }; })"
        :value="selectedDataSource"
        :in-form-group="false"
        :filter-by-value="false"
        required
        @autosuggest-selected="onAutosuggestSelected($event)"
      />
    </b-form-group>
    <b-form-group
      label="Field to filter by"
      label-for="field-id"
    >
      <kmtx-auto-suggest
        id="field-id"
        placeholder="Search for field to filter by"
        label=""
        :data="dataSourceFields.map(({ name, id: value }) => { return { name, value }; })"
        :value="filterOptions['field_id']"
        :in-form-group="false"
        :filter-by-value="false"
        required
        :disabled="!filterOptions['data_mart_id'] || !dataSourceFields.length"
        @autosuggest-selected="filterOptions['field_id'] = $event.data.value || null"
      />
    </b-form-group>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import KmtxAutoSuggest from '@/components/filters/templates/autosuggest/index.vue';

export default {
  name: 'DictionaryOptions',
  components: {
    'kmtx-auto-suggest': KmtxAutoSuggest,
  },
  props: {
    filter: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['change'],
  data() {
    return {
      dataSources: [],
      autoSuggestFilteredData: [],
      selectedDataSource: '',
      dataSourceFields: [],
      filterOptions: {},
      filterName: '',
    };
  },
  created() {
    this.getDataSources();
  },
  methods: {
    ...mapActions('dataMarts', ['fetchDataMarts', 'fetchDataMart']),
    getDataSources() {
      this.fetchDataMarts({ perPage: 10000 }).then((dataSources) => {
        this.dataSources = dataSources.sort((a, b) => a.text.localeCompare(b.text));
        this.autoSuggestFilteredData = this.dataSources;
      }).catch((error) => {
        if (!error.cancelReason) throw error;
      });
    },
    getDataToDisplay(id) {
      const params = { expand: 'fields' };
      this.fetchDataMart({ id, params }).then((data) => {
        this.dataSourceFields = data.fields;
      });
    },
    onAutosuggestSelected({ data = {} }) {
      if (data.value) {
        this.selectedDataSource = data.name;
        this.filterOptions.data_mart_id = data.value;
        this.filterOptions.field_id = undefined;
        this.$emit('change', { filter_options: this.filterOptions });
        this.getDataToDisplay(data.value);
      }
    },
  },
};
</script>
