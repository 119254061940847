<template>
  <div
    class="logo"
    :class="[size ? 'logo--' + size : null]"
    @click="$emit('close-navigation')"
  >
    <router-link
      v-if="link"
      :to="{ name: 'welcome' }"
      class="logo__link"
    >
      <img
        :src="imagePath"
        class="logo__image"
        alt="Kilometrix"
      >
    </router-link>

    <img
      v-else
      :src="imagePath"
      class="logo__image"
      alt="Kilometrix"
    >
  </div>
</template>

<script>
export default {
  name: 'Logo',

  props: {
    size: {
      type: String,
      default: '',
    },
    imagePath: {
      type: String,
      default: '/images/logo.png',
    },
    link: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['close-navigation'],
};
</script>

<style lang="scss">
@import "@/sass/_variables";

.logo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 138px;
    margin: 0 auto;

    &--sm {
        width: 40px;
    }

    &__link {
        width: inherit;
    }

    &__image {
        width: 100%;
    }
}
</style>
