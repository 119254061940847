import SaleDynamicService from '../../services/SaleDynamic';

// initial state
const state = {
  series: [],
};

// actions
const actions = {
  fillTimeSeries({ commit }: any, params: any) {
    return SaleDynamicService.getTimeSeries(params).then((response) => {
      commit('setTimeSeries', response.data.data);
      return response;
    });
  },
};

const mutations = {
  setTimeSeries(state: any, payload: any) {
    state.series = payload;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
