<template>
  <b-form-group>
    <b-dropdown
      :ref="reference"
      right
      :text="'Export to ' + exportFormat"
      variant="secondary"
      class="kmtx-export-dropdown input-dropdown"
    >
      <b-dropdown-text>Choose which widget to export.</b-dropdown-text>
      <b-dropdown-divider />
      <b-dropdown-item
        v-for="(widget, index) in data"
        :key="index"
        @click="updateExportValue(widget.id)"
      >
        {{ widget.name }}
      </b-dropdown-item>
    </b-dropdown>
  </b-form-group>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  name: 'KmtxExport',
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    value: {
      type: String,
      default: null,
    },
    reference: {
      type: String,
      default: 'kmtx-export',
    },
    exportFormat: {
      type: String,
      default: 'csv',
    },
  },
  beforeDestroy() {
    this.updateExportValue(null);
  },
  methods: {
    ...mapMutations('newFilters', ['updateExportValue']),
  },
};
</script>

<style scoped>
.kmtx-export-dropdown {
  white-space: nowrap;
}
</style>
