import ReportService from '../../services/Report';

// initial state
const state = {
  reports: [],
};

// getters
const getters = {};

// actions
const actions = {
  getReportSettings({ commit }: any, params: any) {
    params = params || {};
    return ReportService.getReportSettings(params);
  },
  createReport({ commit }: any, report: any) {
    return ReportService.createReport(report).then(() => {
      commit('setReport', report);
    });
  },
  fillReports({ commit }: any, params: any) {
    return ReportService.getReports(params).then((response) => {
      commit('setReports', response.data.data);
      return response;
    });
  },
};

// mutations
const mutations = {
  setReports(state: any, payload: any) {
    state.reports = payload;
  },
  setReport(state: any, payload: any) {
    state.report = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
