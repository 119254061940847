import ToastsService from '@/services/Toasts';
import { IKToast } from '@kilometrix/polyomino';

function useToast() {
    function addToast(args, type: IKToast['type']) {
        const [heading, message, dataAttrs] = args;
        ToastsService.add({ heading, message, type, dataAttrs });
    }

    function info(...args) {
        addToast(args, 'info');
    }

    function success(...args) {
        addToast(args, 'success');
    }

    function warning(...args) {
        addToast(args, 'warning');
    }

    function danger(...args) {
        addToast(args, 'error');
    }

    function errorMessage(...args) {
        const [error, heading, attrs] = args;

        const title = heading || 'Something went wrong!';
        let body = 'Try again later.';

        if (error && typeof error === 'string') {
            body = error;
        } else if (error.message) {
            body = error.message;
        }

        addToast([title, body, attrs], 'error');
    }

    return {
        info,
        success,
        warning,
        danger,
        errorMessage,
    };
}

export default useToast;
