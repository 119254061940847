<template>
  <b-row
    align-v="end"
    class="traffic-filters"
  >
    <b-col>
      <b-form-group label="Traffic type">
        <b-form-select
          v-model="trafficType"
          text-field="value"
          value-field="id"
          :options="trafficTypeOptions"
          @change="setTrafficType"
        />
      </b-form-group>
    </b-col>
    <b-col v-if="trafficType === 3">
      <b-form-group label="External traffic type">
        <b-form-select
          v-model="externalTrafficType"
          text-field="value"
          value-field="id"
          :options="externalTrafficTypeOptions"
          @change="setExternalTrafficType"
        />
      </b-form-group>
    </b-col>
    <b-col v-if="trafficType === 3 && externalTrafficType > 0 && externalTrafficType < 3">
      <b-form-group label="Ads type">
        <b-form-select
          v-model="adsType"
          text-field="value"
          value-field="id"
          :options="adsTypeOptions"
          @change="setAdsType"
        />
      </b-form-group>
    </b-col>
  </b-row>
</template>
<script>
import DictionaryService from '../../../../services/Dictionary';

export default {
  name: 'KmtxTraffic',
  props: {},
  emits: ['traffic-select', 'traffic-init'],
  data() {
    return {
      trafficTypeOptions: [],
      trafficType: null,

      externalTrafficTypeOptions: [],
      externalTrafficType: null,

      adsTypeOptions: [],
      adsType: null,

    };
  },
  mounted() {
    this.fillTrafficTypes();
  },
  methods: {
    setAdsType(data) {
      this.adsType = data;
      this.fireEvent();
    },
    setTrafficType(data) {
      this.trafficType = data;
      if (this.trafficType !== 3) {
        this.externalTrafficType = null;
        this.adsType = null;
      }
      this.fireEvent();
    },
    setExternalTrafficType(data) {
      this.externalTrafficType = data;
      if (this.externalTrafficType < 3) {
        this.adsType = null;
      }
      this.fireEvent();
    },
    fireEvent() {
      this.$emit('traffic-select', {
        trafficType: this.trafficType,
        externalTrafficType: this.externalTrafficType,
        adsType: this.adsType,
      });
    },
    fillTrafficTypes() {
      this.$emit('traffic-init', DictionaryService.getDictData('traffic_type').then((res) => {
        this.trafficTypeOptions = [{ id: null, value: 'All' }];
        this.trafficTypeOptions = this.trafficTypeOptions.concat(res.data.traffic_type || []);

        this.externalTrafficTypeOptions = [{ id: null, value: 'All' }];
        this.externalTrafficTypeOptions = this.externalTrafficTypeOptions.concat(res.data.external_traffic_type || []);

        this.adsTypeOptions = [{ id: null, value: 'All' }];
        this.adsTypeOptions = this.adsTypeOptions.concat(res.data.ads_type || []);
      }).catch((error) => { if (!error.cancelReason) throw error; }));
    },
  },

};
</script>

<style>
.traffic-filters,
.row.traffic-filters {
  flex-wrap: nowrap;
}

.traffic-filters .custom-select {
  min-width: 10em;
}
</style>
