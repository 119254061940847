<template>
  <b-form-group :label="label">
    <date-picker
      :ref="reference"
      v-model="dateOptions"
      :type="type"
      :placeholder="placeholder"
      :clearable="clearable"
      :lang="lang"
      :format="format"
      :value="value"
      :show-time-panel="showTimePanel"
      :disabled-date="disabledDates"
      :disabled-time="disabledTime"
      :show-minute="!options.splitTime || !showHour"
      :show-hour="!options.splitTime || showHour"
      @change="onChange"
      @pick="onPick"
      @close="onClose"
    >
      <template
        v-if="type === 'datetime' && showTimePanel && options.splitTime"
        #header
      >
        <span>
          {{ showHour ? 'select hour' : 'select minute' }}
        </span>
      </template>
      <template
        v-if="type === 'datetime'"
        #footer
      >
        <button
          class="mx-btn mx-btn-text"
          @click="toggleTimePanel"
        >
          {{ showTimePanel ? 'select date' : 'select time' }}
        </button>
      </template>
    </date-picker>
  </b-form-group>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'KmtxDateSingle', // @pkozda DatePicker with datetime option
  components: { DatePicker },
  props: {
    label: {
      type: String,
      default: 'Choose date',
    },
    placeholder: {
      type: String,
      default: '',
    },
    reference: {
      type: String,
      default: 'kmtx-date-single',
    },
    type: {
      type: String,
      default: 'date',
    },
    options: {
      type: Object,
      default: () => ({
        splitTime: false,
      }),
    },
    value: Date,
  },
  emits: ['date-single-change'],
  data() {
    return {
      dateOptions: null,
      lang: {
        formatLocale: {
          firstDayOfWeek: 1,
        },
        monthBeforeYear: true,
      },
      showTimePanel: false,
      selectedDate: null,
      lastSelectedType: null,
      showHour: true,
    };
  },
  computed: {
    ...mapState('filters', ['dateSingleDates']),
    clearable() {
      return this.type === 'datetime';
    },
    format() {
      return this.type === 'date' ? 'YYYY-MM-DD' : 'YYYY-MM-DD HH:mm';
    },
    disabledDates: {
      get() {
        return (date) => {
          if (!this.dateSingleDates) {
            return false;
          }
          return !this.dateSingleDates.find((availableDate) => {
            const getDate = (date) => `${new Date(date).getFullYear()}-${new Date(date).getMonth()}-${new Date(date).getDate()}`;
            return getDate(availableDate) === getDate(date);
          });
        };
      },
    },
    disabledTime: {
      get() {
        return (date) => {
          if (!this.dateSingleDates || !this.showTimePanel) {
            return false;
          }
          return !this.dateSingleDates.find((availableDate) => {
            const getDate = (date) => `${new Date(date).getFullYear()}-${new Date(date).getMonth()}-${new Date(date).getDate()}`;
            if (getDate(availableDate) === getDate(date)) {
              const getHours = (date) => `${new Date(date).getHours()}`;
              const getMinutes = (date) => `${new Date(date).getMinutes()}`;

              if (this.lastSelectedType === 'date') {
                return getHours(availableDate) === getHours(date);
              } if (this.lastSelectedType === 'hour' || this.lastSelectedType === 'minute') {
                if (getHours(date) === getHours(this.selectedDate)) {
                  return getHours(availableDate) === getHours(this.selectedDate) && getMinutes(availableDate) === getMinutes(date);
                }
              } else {
                return getHours(availableDate) === getHours(date) || getMinutes(availableDate) === getMinutes(date);
              }
            }
          });
        };
      },
    },
  },
  created() {
    if (this.value !== undefined) {
      this.dateOptions = this.value;
    }
  },
  beforeDestroy() {
    this.updateAvailableSingleDates(null);
  },
  methods: {
    ...mapMutations('filters', ['updateAvailableSingleDates']),
    toggleTimePanel() {
      this.showTimePanel = !this.showTimePanel;
      this.showHour = true;
      this.lastSelectedType = 'date';
    },
    onPick(date) {
      if (this.type === 'datetime') {
        this.showTimePanel = true;
        this.selectedDate = date;
        this.lastSelectedType = 'date';
      }
    },
    onChange(date, type) {
      this.lastSelectedType = type;
      this.selectedDate = date;
      if (type === 'hour') {
        this.showHour = false;
      }
      this.$emit('date-single-change', {
        reference: this.reference,
        date,
      });
    },
    onClose() {
      this.showTimePanel = false;
      this.showHour = true;
      this.lastSelectedType = null;
    },
  },
};
</script>
