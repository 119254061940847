<template>
  <div class="widget-putter pb-2">
    <hr class="mb-0">
    <b-form
      @submit="onSubmit"
    >
      <div class="pt-3 configurable-block">
        <b-form-group
          id="w-title-fieldset"
          label="Title"
          label-for="d-title"
        >
          <b-form-input
            id="d-title"
            v-model="widget.name"
            placeholder="Enter widget title"
            trim
            required
            autocomplete="off"
            autofocus
            @focus="activeBlock = 'root'"
            @input="setWidgetFormDirtyTo(true)"
          />
        </b-form-group>

        <b-form-group
          label="Data source"
          :disabled="!dataSources.length"
        >
          <vue-autosuggest
            v-model="selects['data_mart_id']"
            :input-props="{
              id: 'autosuggest-data-source__input',
              class: 'form-control',
              type: 'search',
              placeholder: 'Search for data source',
              required: true,
            }"
            :suggestions="[{ data: autoSuggestFilteredData.dataSources }]"
            @selected="onSelectHandler($event, 'data_mart_id')"
            @input="onInputChange($event, 'dataSources'); setWidgetFormDirtyTo(true)"
            @focus="activeBlock = 'root'"
          >
            <template #default="{ suggestion }">
              <div class="d-flex flex-column">
                <span>
                  {{ suggestion.item.text }}
                </span>
                <span
                  v-if="suggestion.item.secondary_text"
                  class="secondary-text"
                >
                  {{ suggestion.item.secondary_text }}
                </span>
              </div>
            </template>
          </vue-autosuggest>
        </b-form-group>
      </div>

      <hr class="my-0">

      <div
        class="pt-3 configurable-block"
        @mouseover="widgetTypeHovered = true"
        @mouseout="widgetTypeHovered = false"
      >
        <b-form-group
          id="w-widget-type-fieldset"
          label="Widget type"
          label-for="w-widget-type"
        >
          <b-form-select
            id="w-widget-type"
            v-model="widget.configuration.report_type"
            :options="widgetTypes"
            required
            :disabled="!widget.configuration.data_mart_id || !dataSources.length"
            @focus="activeBlock = 'widget-type'"
            @change="setWidgetFormDirtyTo(true)"
          >
            <template #first>
              <b-form-select-option
                :value="null"
                disabled
              >
                Please select type
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>

        <component
          :is="formComponent"
          :widget-configuration="widget.configuration"
          :data-sources="dataSources"
          :data-source-fields="dataSourceFields"
          :aggregation-types="aggregationTypes"
          :block-hovered="widgetTypeHovered"
          @change="widgetFormChange($event)"
          @touch-form="setWidgetFormDirtyTo(true)"
        />
      </div>

      <hr class="my-0">

      <div class="pt-3 configurable-block">
        <b-form-group
          id="w-exportable-fieldset"
          label="Exportable"
          label-for="w-exportable"
        >
          <b-form-checkbox
            id="w-exportable"
            v-model="widget.configuration.flags.exportable"
            name="exportable-checkbox"
            @change="setWidgetFormDirtyTo(true)"
          >
            Can be downloaded
          </b-form-checkbox>
        </b-form-group>
        <b-form-group
          id="w-description-fieldset"
          label="Description"
          label-for="w-description"
        >
          <b-form-textarea
            id="textarea"
            v-model="widget.configuration.description"
            placeholder="Enter description"
            rows="3"
            max-rows="6"
            @input="setWidgetFormDirtyTo(true)"
          />
        </b-form-group>
      </div>

      <hr class="my-0">

      <template v-if="editedFiltersFields.length">
        <div class="pt-3 configurable-block">
          <div class="font-weight-bold mb-2">
            Select the field that should be filtered
          </div>

          <b-form-group
            v-for="(filter, index) in editedFiltersFields"
            :key="index"
            :label="filter.label"
            :label-for="'field' + index"
          >
            <b-form-select
              :id="'field' + index"
              v-model="filter.value"
              text-field="name"
              value-field="id"
              :options="dataSourceFields"
              required
              @change="setWidgetFormDirtyTo(true); fieldsDirty = true"
            >
              <template #first>
                <b-form-select-option
                  :value="null"
                  disabled
                >
                  Please select aggregation type
                </b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
        </div>

        <hr class="my-0">
      </template>

      <div class="mt-4">
        <b-col>
          <b-button
            ref="widget-submit"
            type="submit"
            variant="primary"
            block
            :disabled="putProcess"
          >
            {{ widget.id ? 'Save changes' : 'Save and apply' }}
          </b-button>
        </b-col>
      </div>
    </b-form>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import { VueAutosuggest } from 'vue-autosuggest';
import clone from 'rfdc';
import ChartForm from './widget-type-forms/chart/index.vue';

export default {
  name: 'WidgetPutter',
  components: {
    VueAutosuggest,
    'chart-form': ChartForm,
  },
  props: {
    filtersList: {
      type: Array,
      default: () => [],
    },
    dashboardId: {
      type: Number,
      required: true,
    },
  },
  emits: ['update-error', 'update'],
  data() {
    return {
      selects: {
        data_mart_id: '',
      },
      putProcess: false,
      dataSources: [],
      timelines: [],
      widgetTypes: [],
      aggregationTypes: [],
      fieldsDirty: false,
      widget: {
        configuration: {
          description: null,
          flags: {
            exportable: false,
          },
        },
      },
      editedFiltersFields: [],
      dataSourceFields: [],
      formComponent: null,
      activeBlock: '',
      widgetTypeHovered: false,
    };
  },
  computed: {
    ...mapState('dataMarts', ['selectedWidgetBody', 'selectedWidgetPosition', 'widgetFormDirty', 'independentFilters']),
    autoSuggestData() {
      return {
        dataSources: this.dataSources,
      };
    },
    autoSuggestFilteredData() {
      return {
        dataSources: this.dataSources,
      };
    },
    widgetType() {
      return this.widget.configuration?.report_type;
    },
    formLoader() {
      if (!this.widget?.configuration?.report_type) {
        return null;
      }
      const type = this.widget.configuration.report_type.includes('chart') ? 'chart' : this.widget.configuration.report_type;
      return () => import(/* webpackMode: "eager" */`./widget-type-forms/${type}`);
    },
  },
  watch: {
    selectedWidgetPosition: {
      handler() {
        this.initWidget();
        this.initFiltersFields();
      },
      immediate: true,
    },
    widgetType: {
      handler(to, from) {
        if (to && to !== from) {
          this.loadForm();
        }
      },
      immediate: true,
    },
  },
  beforeDestroy() {
    this.setWidgetFormDirtyTo(false);
  },
  methods: {
    ...mapActions('dataMarts', [
      'fetchDataMarts',
      'fetchDataMart',
      'fetchWidgetTypes',
      'fetchAggregationTypes',
      'validateWidget',
      'createWidget',
      'editWidget',
    ]),
    ...mapMutations('dataMarts', [
      'attachSelectedWidget',
      'setWidgetBarVisibleTo',
      'updateFiltersFields',
      'setWidgetFormDirtyTo',
    ]),
    loadForm() {
      if (this.formLoader) {
        return this.formLoader().then(() => {
          this.formComponent = () => this.formLoader();
        });
      }
    },
    widgetFormChange(widget_type_fields) {
      const {
        data_mart_id, description, report_type, flags,
      } = this.widget.configuration;
      this.widget.configuration = {
        data_mart_id, description, report_type, flags, ...widget_type_fields,
      };
    },
    initFiltersFields() {
      this.editedFiltersFields = [];
      this.filtersList
        .filter(({ filter_value }) => !this.independentFilters.includes(filter_value))
        .forEach((filter) => {
          const field = filter.filter_fields?.find((field) => field.widgetId === this.widget.id);
          this.editedFiltersFields.push({ value: field?.fieldId, label: filter?.filter_value });
        });
    },
    initWidget() {
      if (Object.keys(this.selectedWidgetBody).length) {
        this.widget = clone()(this.selectedWidgetBody);
      }
      this.getFieldsData();
    },
    getFieldsData() {
      this.fetchDataMarts({ perPage: 10000, expand: 'dataMartConnection' }).then((dataSources) => {
        this.dataSources = dataSources.sort((a, b) => a.text.localeCompare(b.text));
        this.autoSuggestFilteredData.dataSources = this.dataSources;

        if (this.widget.configuration.data_mart_id) {
          this.selects.data_mart_id = this.dataSources.find(({ value }) => value === this.widget.configuration.data_mart_id)?.text;
          this.fillOptionsData(this.widget.configuration.data_mart_id);
        } else {
          this.selects.data_mart_id = '';
        }
      }).catch((error) => {
        if (!error.cancelReason) throw error;
      });
    },
    onSelectHandler(event, property) {
      if (event?.item) {
        const { value, id, text } = event.item;
        this.widget.configuration[property] = value || id;
        this.selects[property] = text;

        if (property === 'data_mart_id') {
          this.fillOptionsData(this.widget.configuration.data_mart_id);
        }
      }
    },
    onInputChange(input, source) {
      input = input.trim().toLowerCase();
      if (this.autoSuggestData[source].length) {
        this.autoSuggestFilteredData[source] = this.autoSuggestData[source].filter((item) => {
          if (item.text !== undefined) {
            return item.text.trim().toLowerCase().indexOf(input) > -1;
          }
        });
      }
    },
    fillOptionsData(dataSourceId) {
      Promise.all([
        this.getDataToDisplay(dataSourceId),
        this.getWidgetTypes(),
        this.getAggregationTypes(),
      ]).catch((error) => {
        if (!error.cancelReason) throw error;
      });
    },
    getDataToDisplay(id) {
      const params = { expand: 'fields' };
      this.fetchDataMart({ id, params }).then((data) => {
        this.dataSourceFields = data.fields;
      });
    },
    getWidgetTypes() {
      this.fetchWidgetTypes().then((widgetTypes) => {
        this.widgetTypes = widgetTypes.sort((a, b) => a.text.localeCompare(b.text));
      });
    },
    getAggregationTypes() {
      this.fetchAggregationTypes().then((aggregationTypes) => {
        this.aggregationTypes = aggregationTypes;
      });
    },
    isTableColumnsUnique() {
      const groupColumnIds = [];
      const aggregatedColumnIds = [];
      this.widget.configuration.columns.forEach((col) => {
        if (col.aggregation_function) {
          aggregatedColumnIds.push(col.field_id);
        } else {
          groupColumnIds.push(col.field_id);
        }
      });
      return aggregatedColumnIds.some((col) => groupColumnIds.includes(col));
    },
    onSubmit(event) {
      event.preventDefault();
      if (this.widget.configuration.report_type === 'table' && this.isTableColumnsUnique()) {
        this.onUpdateError('You can\'t group and aggregate one field at the same time');
        return;
      }
      this.putProcess = true;
      this.validateWidget(this.widget.configuration).then(() => {
        this.setWidgetFormDirtyTo(false);
        !this.widget.id ? this.onCreateWidget() : this.onEditWidget();
      }).catch(() => {
        const h = this.$createElement;
        const errorMsg = h(
          'div',
          { class: ['d-flex', 'flex-column'] },
          [
            h('span', 'The given data was invalid!'),
            h('span', 'Make sure you filled form correctly.'),
          ],
        );
        this.onUpdateError(errorMsg);
      });
    },
    onCreateWidget() {
      this.createWidget(this.widget).then((widget) => {
        if (this.fieldsDirty) {
          this.updateFilters(widget.id, true);
        }
        this.onWidgetUpdated('created', widget);
      }).catch((error) => {
        this.onUpdateError(error);
      });
    },
    onEditWidget() {
      this.editWidget(this.widget).then((widget) => {
        if (this.fieldsDirty) {
          this.updateFilters(widget.id);
        }
        this.onWidgetUpdated('updated', widget);
      }).catch((error) => this.onUpdateError(error));
    },
    updateFilters(widgetId, newWidget = false) {
      const params = {
        newWidget,
        widgetId,
        dashboardId: this.dashboardId,
        fields: this.editedFiltersFields.map(({ value }) => value),
      };
      this.updateFiltersFields(params);
    },
    onUpdateError(error) {
      this.putProcess = false;
      this.$emit('update-error', { error });
    },
    onWidgetUpdated(action, widget) {
      if (!this.widget.id) {
        this.widget.id = widget.id;
      }
      this.widget.dashboard_id = this.dashboardId;
      this.putProcess = false;
      this.attachSelectedWidget(this.widget);
      this.$emit('update', { message: `Widget "${this.widget.name}" ${action}` });
      this.setWidgetBarVisibleTo(false);
    },
  },
};
</script>

<style scoped lang="scss">
.configurable-block {
    padding: 0 1rem .01rem;
    background-color: #f8f9fa;
    transition: 0.2s background-color;
    &:hover {
        background-color: #fff;
    }
}

hr {
    margin: 1rem 0;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.widget-type {
    padding-top: 1rem;
}

.secondary-text
{
    color: #94989c;
    font-size: 10px;
    font-weight: 300;
    line-height: 1;
}
</style>
