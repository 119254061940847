import SourceService from '../../services/Source';
import { skinList } from '../../const';

// initial state
const state = {
    sources: [],
    sourceLabels: [],
    labels: [],
    routes: [],
    themeMap: {},
};

// getterslabels
const getters = {
    getLabel: (state: any) => (id: any) => state.labels.find((i: any) => i.id === id),
};

// actions
const actions = {
    runIntegrationMode({ commit }: any, params: any) {
        return SourceService.createIntegrationMode(params).then((response) => {
            commit('setIntegrationMode', {
                source: params,
                integration_mode: response.data.data,
            });

            return response;
        });
    },

    fillSourceLabels({ commit }: any, params: any) {
        return SourceService.getSourceLabels(params).then((response) => {
            commit('setSourceLabels', response.data.data);

            return response;
        });
    },

    fillClearSources({ commit }: any, params: any) {
        return SourceService.getSourceLabels(params).then((response) => {
            commit('setClearSource', response.data.data);

            return response;
        });
    },

    fillLabels({ commit }: any, params: any) {
        return SourceService.getLabels(params).then((response) => {
            commit('setLabels', response.data.data);

            return response;
        });
    },

    fillThemeMap({ commit }: any, params: any) {
        params = params || {};
        params.expand = 'sources';

        return SourceService.getLabels(params).then((response) => {
            commit('setThemeMap', response.data.data);

            return response;
        });
    },

    updateSourceLabels({ commit }: any, payload: any) {
        const reqData = { labels: payload.labels.map((l: any) => l.text) };

        SourceService.updateSources(payload.id, reqData);
        commit('updateSourceLabels', payload);
    },

    fillRoutes({ commit }: any, params: any) {
        return SourceService.getRoutes(params).then((response) => {
            commit('setRoutes', response.data.data);

            return response;
        });
    },

    createSources(_: any, { params, options }: any) {
        return SourceService.createSources(params, options);
    },

    updateSourceState(_: any, { id, stateObj }: any) {
        return SourceService.updateSources(id, stateObj);
    },
};

// mutations
const mutations = {
    setSourceLabels(state: any, payload: any) {
        const sLabels = Object.create(null);
        state.sourceLabels = Object.create(null);
        for (const p in payload) {
            if (!Object.prototype.hasOwnProperty.call(payload, p)) {
                continue;
            }

            if (typeof sLabels[payload[p].host] === 'undefined') {
                sLabels[payload[p].host] = {
                    id: payload[p].id,
                    host: payload[p].host,
                    is_active: payload[p].is_active,
                };
            }

            if (payload[p].labels) {
                // iterate labels for each source
                for (const l in payload[p].labels) {
                    if (!Object.prototype.hasOwnProperty.call(payload[p].labels, l)) {
                        continue;
                    }

                    const labelName = payload[p].labels[l].name;

                    if (typeof sLabels[labelName] === 'undefined') {
                        sLabels[labelName] = {
                            id: payload[p].labels[l].id,
                            host: payload[p].labels[l].name,
                            label: true,
                        };
                    }
                }
            }
        }
        sLabels.All = {
            id: 0,
            host: 'All',
        };
        const sourceLabels = Object.keys(sLabels).map((key) => {
            // [1,1,2,3,3,3,4,5,5] => [1,2,3,4,5]
            sLabels[key].hosts = [...new Set(sLabels[key].hosts)];
            return sLabels[key];
        });

        state.sourceLabels = sourceLabels;
        return sourceLabels;
    },
    setLabels(state: any, payload: any) {
        state.labels = payload;
    },
    setClearSource(state: any, payload: any) {
        payload = payload.map((s: any) => {
            s.labels = s.labels ? s.labels : [];
            s.labels = s.labels.map((l: any) => ({
                text: l.name,
            }));
            return s;
        });
        state.sources = payload;
    },
    setRoutes(state: any, payload: any) {
        state.routes = payload;
    },
    setIntegrationMode(state: any, payload: any) {
        const sourceId = payload.source.site_id || payload.source.source_id;
        const source = state.sources.find((i: any) => i.id === sourceId);
        source.integration_mode = payload.integration_mode;
    },
    setThemeMap(state: any, payload: any) {
        state.themeMap = {};
        for (const i in payload) {
            const label = payload[i];
            if (skinList.indexOf(label.name) < 0) {
                continue;
            }
            if (label.sources.length > 0) {
                label.sources.map((s: any) => {
                    if (state.themeMap[s.id]) {
                        state.themeMap[s.id].push(label.name);
                    } else {
                        state.themeMap[s.id] = [label.name];
                    }
                });
            }
        }
    },
    updateSourceLabels(state: any, payload: any) {
        const source = state.sources.find((i: any) => i.id === payload.id);
        source.labels = payload.labels;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
