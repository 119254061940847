<template>
  <router-view />
</template>

<script>
import EventBus from '../event';

export default {
  name: 'SaleDynamicComponent',
  emits: ['kmtx-filters-datepicker-set-week'],
  data() {
    return { };
  },
  mounted() {
    // default date range - last week
    // when datepicker will be created, we will set that range
    const datepickerCreated = () => EventBus.$emit('kmtx-filters-datepicker-set-week');
    EventBus.$once('kmtx-filters-datepicker-created', datepickerCreated);
  },
};
</script>
