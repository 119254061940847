import { IKToast } from '@kilometrix/polyomino';

type TToast = Partial<IKToast>;
export interface TToastExt extends TToast {
    dataAttrs: Record<string, string>;
}
export interface IToast extends TToast {
    id: number;
}
class ToastsService {
    private toasts: IToast[] = [];

    private index = 0;

    getAll() {
        return this.toasts;
    }

    add(info: TToastExt, closeDelay = 5000) {
        this.index++;
        const tost = { ...info, id: this.index };
        this.toasts.unshift(tost);

        setTimeout(() => {
            this.close(tost.id, closeDelay);
        }, 10);
    }

    close(id, delay = 0) {
        const element = document.getElementById(`kmtx-notification-${id}`);

        if (!element) return;
        const deleteNotification = () => {
            this.delete(id);
            element.removeEventListener('mouseenter', onMouseEnter);
            element.removeEventListener('mouseleave', onMouseLeave);
        };

        if (delay === 0) {
            deleteNotification();
            return;
        }

        const generateTimeout = () =>
            setTimeout(() => {
                if (!element.matches(':hover')) {
                    deleteNotification();
                }
            }, delay);

        let timeoutId = generateTimeout();

        function onMouseEnter() {
            clearTimeout(timeoutId);
        }

        function onMouseLeave() {
            clearTimeout(timeoutId);
            timeoutId = generateTimeout();
        }

        element.addEventListener('mouseenter', onMouseEnter, false);
        element.addEventListener('mouseleave', onMouseLeave, false);
    }

    delete(toastId: number) {
        const toastIdx = this.toasts.findIndex((toast) => toast.id === toastId);
        this.toasts.splice(toastIdx, 1);
    }
}

export default new ToastsService();
