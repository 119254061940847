export default function convertStringsToNumbers(obj, withArray?: boolean) {
    // Create a new object to store the converted values
    const convertedObj = {};

    // Iterate over the keys of the object
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            // Get the current value
            const value = obj[key];

            // Check if the value is a string
            if (typeof value === 'string') {
                // Attempt to convert the string to a number
                const parsedValue = parseFloat(value);

                // Check if the conversion was successful
                if (!isNaN(parsedValue)) {
                    // Assign the converted value to the new object
                    convertedObj[key] = parsedValue;
                } else {
                    // Assign the original value to the new object if the conversion failed
                    convertedObj[key] = value;
                }
            } else if (typeof value === 'object' && !Array.isArray(value)) {
                // Recursively convert nested objects
                convertedObj[key] = convertStringsToNumbers(value);
            } else if (Array.isArray(value) && withArray) {
                // Recursively convert arrays
                convertedObj[key] = value.map((a) => ((+a).toString() === a ? +a : a));
            } else {
                // Assign non-string values to the new object directly
                convertedObj[key] = value;
            }
        }
    }

    return convertedObj;
}
