<template>
  <div class="url-skin">
    <b-row class="mb-4">
      <b-col class="max-w-500">
        <b-row
          align-v="center"
          class="flex-nowrap"
        >
          <b-col class="url-col">
            <kmtx-input
              placeholder="Type url you want to parse"
              label="URL"
              :value="urlInput"
              :options="{ type: 'text' }"
              @input-input="urlInput = $event.value.trim()"
            />
          </b-col>
          <b-col class="no-grow arrow">
            <icon
              image="synchronize.svg"
              width="16"
              height="16"
              :size="{ width: '16px', height: '16px' }"
              class="synchronize-icon"
            />
          </b-col>
        </b-row>
      </b-col>
      <b-col>
        <b-row
          align-v="end"
          class="flex-nowrap"
        >
          <b-col class="route-col">
            <kmtx-input
              label="Route"
              :value="route"
              disabled
              placeholder=""
            />
          </b-col>
          <b-col class="skin-col">
            <kmtx-input
              label="Skin"
              disabled
              :value="skin"
              placeholder=""
            />
          </b-col>
          <b-col class="submit-col">
            <kmtx-button
              :options="{ variant: 'primary' }"
              value="Find"
              @button-submit="searchByUrl()"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row
      v-if="skin && urlExist"
      class="mb-4"
    >
      <b-col>
        Basic analytic statistic <a
          class="link font-weight-bold"
          @click="toggleExtend()"
        > {{ urlStatExtended ? 'less' : 'more' }}</a>
      </b-col>
    </b-row>
    <b-row
      v-if="urlExist === false"
      class="mb-4"
    >
      <b-col>
        Route and skin not found.
      </b-col>
    </b-row>

    <template v-if="urlStatExtended && skin">
      <b-row class="mb-4">
        <b-col>
          <kmtx-datepicker
            class="kmtx-filters-bar"
            :disabled="url !== urlInput"
            label="Dates"
            @datepicker-change="fetchStatData"
          />
        </b-col>
      </b-row>

      <b-row class="mb-5 mt-3">
        <b-col
          md="2"
          class="grid-margin stretch-card"
        >
          <div class="card card-statistics">
            <div class="card-body">
              <div class="clearfix">
                <div class="float-left">
                  <i class="fa fa-eye text-info icon-lg" />
                </div>
                <div class="float-right">
                  <p class="mb-0 text-right">
                    Page view total
                  </p>
                  <div class="fluid-container">
                    <h3 class="font-weight-medium text-right mb-0">
                      {{ filters.bigNumberFormat(events) }}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-col>
        <b-col
          md="2"
          class="grid-margin stretch-card"
        >
          <div class="card card-statistics">
            <div class="card-body">
              <div class="clearfix">
                <div class="float-left">
                  <i class="fa fa-hand-spock text-info icon-lg" />
                </div>
                <div class="float-right">
                  <p class="mb-0 text-right">
                    Sessions count
                  </p>
                  <div class="fluid-container">
                    <h3 class="font-weight-medium text-right mb-0">
                      {{ filters.bigNumberFormat(sessions) }}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-col>
        <b-col
          md="2"
          class="grid-margin stretch-card"
        >
          <div class="card card-statistics">
            <div class="card-body">
              <div class="clearfix">
                <div class="float-left">
                  <i class="fa fa-fingerprint text-info icon-lg" />
                </div>
                <div class="float-right">
                  <p class="mb-0 text-right">
                    Unique visits
                  </p>
                  <div class="fluid-container">
                    <h3 class="font-weight-medium text-right mb-0">
                      {{ filters.bigNumberFormat(visits) }}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>

      <b-row class="mb-5">
        <b-col>
          <kmtx-chart
            ref="sourceViewsChart"
            :options="sourceStatisticChart"
            class="source-views-chart"
          />
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex';
import EChartComponent from '../echart/EChartComponent.vue';
import LineChartOptions from '../echart/options/line-chart/index';
import KmtxInput from '../filters/templates/input/index.vue';
import KmtxButton from '../filters/templates/button/index.vue';
import KmtxDatePicker from '../filters/templates/datepicker/index.vue';
import StatService from '../../services/Statistic';
import IconsComponent from '../IconsComponent.vue';
import Toast from '../../mixins/Toast.vue';
import ConvertDate from '../../mixins/ConvertDate.vue';

export default {
  name: 'UrlSkin',
  components: {
    'kmtx-input': KmtxInput,
    'kmtx-button': KmtxButton,
    'kmtx-datepicker': KmtxDatePicker,
    'kmtx-chart': EChartComponent,
    icon: IconsComponent,
  },
  mixins: [
    Toast,
    ConvertDate,
  ],
  beforeRouteLeave(to, from, next) {
    if (to.name !== 'skin-url') {
      this.clearState();
    }
    next();
  },
  data() {
    return {
      urlInput: '',
      sourceStatisticChart: {},
    };
  },
  computed: {
    ...mapGetters('filters', ['getDateForParams']),
    ...mapState('filters', ['date']),
    ...mapState('urlRouteSkinTool', [
      'url',
      'skin',
      'route',
      'visits',
      'events',
      'sessions',
      'urlStatExtended',
      'chartData',
      'chartDate',
      'urlExist',
    ]),
  },
  watch: {
    chartData: 'buildChart',
  },
  mounted() {
    this.urlInput = this.url;
    if (this.chartData.length) {
      this.buildChart();
    }
    if (this.chartDate) {
      this.updateDate(this.chartDate);
    }
  },
  methods: {
    ...mapMutations('urlRouteSkinTool', [
      'clearRouteSkin',
      'toggleUrlStatExtended',
      'setSingleStats',
      'setChartData',
      'changeValueByRef',
      'clearState',
      'onUrlChanged',
    ]),
    ...mapMutations('filters', ['updateDate']),
    searchByUrl() {
      this.clearRouteSkin();
      this.getRouteSkin();
    },
    toggleExtend() {
      this.toggleUrlStatExtended();
      if (this.urlStatExtended) {
        this.fetchStatData();
      }
    },
    getRouteSkin() {
      const params = {
        source: this.urlInput,
      };
      StatService.getRssBy(params, { showLoader: true }).then((resp) => {
        this.onUrlChanged(!!resp.data);
        const { route, skin } = resp?.data;
        this.changeValueByRef({ reference: 'route', value: route });
        this.changeValueByRef({ reference: 'skin', value: skin });
        this.changeValueByRef({ reference: 'url', value: this.urlInput });
        if (this.chartDate) {
          this.updateDate(this.chartDate);
        }
        this.fetchStatData();
      }).catch((error) => {
        if (!error.cancelReason) {
          this.errorMessage(error, 'Failed to parse url.');
        }
      });
    },
    fetchStatData() {
      if (this.urlStatExtended && this.skin) {
        const options = {
          showLoader: true,
        };
        const params = {
          url: this.url,
          timeFrom: this.convertDateFormat(this.getDateForParams.dateFrom, '00:00:00'),
          timeTo: this.convertDateFormat(this.getDateForParams.dateTo, '23:59:59'),
        };
        const datesCount = (new Date(this.getDateForParams.dateTo) - new Date(this.getDateForParams.dateFrom)) / 1000 / 60 / 60 / 24;
        if (datesCount >= 1) {
          params.r = 1440 * 60;
        } else {
          params.r = 3600;
        }
        return StatService.getRssStat(params, options).then((resp) => {
          let data = resp.data?.data.map((item) => {
            item.DateTime = item.UTCEventTime;
            delete item.UTCEventTime;
            return item;
          });
          data = data.sort((a, b) => new Date(a.DateTime) - new Date(b.DateTime));
          this.setChartData({ data, date: this.date });
          this.setSingleStats(resp?.data?.totals);
        }).catch((error = {}) => {
          if (!error.cancelReason) {
            this.errorMessage(error, 'Failed to get data.');
          }
          this.setChartData({ data: [] });
        });
      }
    },
    buildChart() {
      const events = 'Page views total';
      const visits = 'Unique visits';
      const sessions = 'Sessions count';
      const chartParams = {
        dateTo: this.getDateForParams.dateTo,
        dateFrom: this.getDateForParams.dateFrom,
        r: (new Date(this.getDateForParams.dateTo) - new Date(this.getDateForParams.dateFrom)) / 1000 / 60,
      };
      const chartOptions = {
        grid: {
          left: '0',
        },
        title: {
          left: '0',
        },
      };
      this.sourceStatisticChart = new LineChartOptions(this.chartData, chartOptions, chartParams);
      if (this.sourceStatisticChart.series.length) {
        this.sourceStatisticChart.series[0].name = events;
        this.sourceStatisticChart.series[1].name = sessions;
        this.sourceStatisticChart.series[2].name = visits;
      }
      this.sourceStatisticChart.title.text = `Url visits statistic`;
      this.sourceStatisticChart.legend.data = [events, sessions, visits];
    },
  },
};
</script>

<style scoped>
.no-grow
{
    flex-grow: 0;
}

.max-w-500
{
    max-width: 500px;
}

.arrow
{
    margin-bottom: -10px;
}

.url-col
{
    min-width: 20em;
}

.route-col, .skin-col
{
    min-width: 10em;
    max-width: 20em;
}

.link
{
    cursor: pointer;
}
</style>
