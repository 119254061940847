<template>
  <div class="grid grid--centered grid--direction-column w-100 welcome-page">
    <h5>
      Welcome to Kilometrix!
    </h5>
    <h6
      v-if="!(currentAccount.roles && currentAccount.roles.length) && !masterRole"
      class="mt-4 w-75 text-center"
    >
      You don`t have any role assigned to your user profile, please get in touch with your account owner.
    </h6>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'WelcomePage',
  computed: {
    ...mapGetters('auth', ['currentAccount', 'masterRole']),
  },
};
</script>

<style scoped>
.welcome-page {
    height: calc(100vh - 67px);
}
</style>
