import { ref } from 'vue';
import { IPagination } from '@/composables/usePagination';
import { PER_PAGE_OPTIONS } from '../services/constants';
import ABTestService, { IABTestItem } from '../services/Cockpit';

export default function useABTestList() {
  const statuses = {
    running: {
      name: 'Running',
      value: 1,
    },
    in_check: {
      name: 'In check',
      value: 2,
    },
    valid: {
      name: 'Valid',
      value: 3,
    },
    done: {
      name: 'Done',
      value: 3,
    },
    stopped: {
      name: 'Stopped',
      value: 4,
    },
    invalid: {
      name: 'Invalid',
      value: 5,
    },
  };

  function mappedList(data: IABTestItem[]) {
    const mapped = data.map((el) => {
      const status = statuses[el.status];
      return { ...el, status };
    });
    return mapped;
  }

  const testList = ref<IABTestItem[]>([]);
  const paginationServerData = ref<IPagination>({
    page: 1,
    pages: 1,
    limit: PER_PAGE_OPTIONS[0],
    total: 0,
  });
  const getTestList = (params: Partial<IPagination> = paginationServerData.value): Promise<void> => ABTestService.getTestList(params)
    .then(({ data, meta }) => {
      testList.value = mappedList(data) || [];
      paginationServerData.value = {
        page: meta.current_page,
        pages: meta.last_page,
        limit: meta.per_page,
        total: meta.total,
      };
    }).catch((error) => {
      console.error('Error: ', error);
    });

  const launchTest = (id: number) => ABTestService.launchABTest(id);
  const stopTest = (id: number) => ABTestService.stopABTest(id);
  const deleteTest = (id: number) => ABTestService.deleteABTest(id);

  return {
    paginationServerData,
    testList,
    getTestList,
    launchTest,
    stopTest,
    deleteTest,
  };
}
