import Vue from 'vue';
import VueRouter, { RouteConfig, RouteRecord } from 'vue-router';
import qs from 'query-string';

import deepClone from '@/shared/utils/deepClone';

// eslint-disable-next-line import/no-cycle
import useRouter from './useRouter';
import useContextAccount from './contextAccount';
import store from '../store';
import EventBus from '../event';
import Routes from './main.route';

const { pageGuard } = useRouter();
const { context, checkContextAccount, manageContextAccount } = useContextAccount();

const routes: Array<RouteConfig> = Routes;
const crossAuthValidationRegex = /^[A-Za-z]+$/;

if (process.env.NODE_ENV !== 'test') Vue.use(VueRouter);

const index = new VueRouter({
    routes,
    mode: 'history',
    parseQuery: (data) => qs.parse(data, { arrayFormat: 'bracket' }),
    stringifyQuery: (data) => {
        const params = qs.stringify(data, { arrayFormat: 'bracket' });
        return params && `?${params}`;
    },
});

index.beforeEach(async (to, from, next) => {
    if (from.fullPath !== to.fullPath) EventBus.$emit('kmtx-cancel-requests');
    if (to.path !== from.path) store.commit('request/clearFailedList');

    // Reset cross_auth_env query parameter
    if (to?.query?.cross_auth_env && crossAuthValidationRegex.test(`${to.query.cross_auth_env}`)) {
        sessionStorage.setItem('cross_auth_env', `${to.query.cross_auth_env}`);
        next({ path: to.path });
        return;
    }

    // Context Account
    manageContextAccount(from, to);

    // Check LogIn
    const isLoggedIn = store.state.auth.token;
    const userExist = store.state.auth.currentUser;
    const noAuthCheck: boolean = to.matched.some((record: RouteRecord) => record.meta.noAuthCheck);
    const requiresGuest: boolean = to.matched.some((record: RouteRecord) => record.meta.requiresGuest);

    if (!isLoggedIn) {
        if (noAuthCheck || requiresGuest) {
            next();
        } else {
            sessionStorage.setItem('redirectPath', to.fullPath);
            next({ path: '/login' });
        }
    } else if (requiresGuest) {
        next({ path: '/' });
    } else {
        await getUserData();
    }

    async function getUserData() {
        if (!userExist) {
            await store
                .dispatch('auth/getUser')
                .then(() => pageGuard(to, from, next, context, checkContextAccount, crossAuthValidationRegex))
                .catch((error) => console.debug(`store.dispatch('auth/getUser') error`, error));
        } else pageGuard(to, from, next, context, checkContextAccount, crossAuthValidationRegex);
    }
});

index.afterEach((to, from) => {
    // 'title' and 'tabs' for pages with past design
    store.dispatch('router/setCurrentTitle', to.meta?.header?.title);
    to.meta?.header?.tab
        ? store.dispatch('router/setCurrentTabs', deepClone(to.matched[0].meta?.header?.tabs))
        : store.dispatch('router/clearTabs');

    // clear accounts and users list from userManagement store module if user leaves user-management route
    if (from.matched[0]?.name === 'user-management' && to.matched[0]?.name !== 'user-management') {
        store.commit('userManagement/clearAccounts');
        store.commit('userManagement/clearUsers');
    }

    document.title = to?.meta?.browserTitle ? `${to.meta.browserTitle} - Kilometrix` : 'Kilometrix';
});

export default index;
