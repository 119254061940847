import { computed, ref } from 'vue';
import { IPagination } from '@/composables/usePagination';
import { PER_PAGE_OPTIONS } from '../services/constants';
import LMService from '../services/LabelManagement';

interface ILabelItem {
    id: string;
    account_id: string;
    status: number;
    data_sources: string[];
    creator_id: string;
    created_at: string;
    updater_id?: string;
    updated_at?: string;
    limit?: number;
    page?: number;
}

export default function useLabelsList() {
    const labelsList = ref<ILabelItem[]>([]);
    const paginationServerData = ref<IPagination>({
        page: 1,
        pages: 1,
        limit: PER_PAGE_OPTIONS[0],
        total: 0,
    });
    const selectedLabel = ref<string>('');

    const getLabelsList = (params: Partial<IPagination & { account_id: string }> = paginationServerData.value): Promise<void> =>
        LMService.getLabels(params)
            .then(({ data, meta }) => {
                labelsList.value = data || [];
                paginationServerData.value = {
                    page: meta.current_page,
                    pages: meta.last_page,
                    limit: meta.per_page,
                    total: meta.total,
                };
            })
            .catch((error) => {
                console.error('Error: ', error);
            });

    function selectLabel(id: string) {
        selectedLabel.value = id;
    }

    return {
        paginationServerData,
        labelsList,
        getLabelsList,
        selectLabel,
        selectedLabel: computed(() => selectedLabel.value),
    };
}
