import { ApiV1 } from '@/api';

export default {
  getDictData(code: any, params: any) {
    return ApiV1().get(`/dictionary/${code}`, { params });
  },
  getTimezones(params: any) {
    params = params || {};
    return ApiV1().get(`/timezones`, { params });
  },
};
