import clone from 'rfdc';
import { defaultOptions } from '../default-options';

const deepmerge = require('deepmerge');

const barChartOptions = {
  tooltip: {
    trigger: 'item',
    formatter: '{b}: {c} ({d}%)',
  },
  legend: {
    type: 'scroll',
  },
};

export class PieChartOptions {
  series: any;

  tooltip: any;

  legend: any;

  /**
     *
     * @param data
     * @param chartOptions
     * @param { {
     * } } buildOptions
     */
  constructor(data: any, chartOptions = {}) {
    chartOptions = clone()(chartOptions);
    Object.assign(this, deepmerge.all([this, defaultOptions, barChartOptions, chartOptions]));
    this._setSeries(Object.keys(data.slices));
    this.convertChartData(data);
  }

  /**
     * Set series from slices
     *
     * @param slices
     * @private
     */
  _setSeries(slices: any) {
    slices.forEach((slice: any) => {
      const seriesDefinedId = this.series.findIndex((item: any) => slice === item.id);
      if (seriesDefinedId >= 0) {
        this.series[seriesDefinedId] = { ...this.series[seriesDefinedId], ...this._setSeriesOptions(slice, seriesDefinedId) };
      } else {
        this.series.push(this._setSeriesOptions(slice));
      }
    });
  }

  /**
     * Set default options to series object
     *
     * @param slice
     * @param definedId
     * @returns {{}} - series options
     * @private
     */
  _setSeriesOptions(slice: any, definedId?: any) {
    const series: any = {};
    series.type = this.series[definedId]?.type || 'pie';
    series.id = this.series[definedId]?.id || slice;
    series.name = this.series[definedId]?.name || slice;
    const label = {
      formatter: '{d}%',
    };
    series.label = { ...label, ...this.series[definedId]?.label };
    return series;
  }

  /**
     * Set prepared data to axis and series data
     *
     * @param {{ labels: [], slices: {} }} data
     */
  convertChartData(data: any) {
    this.legend.data = data.labels;
    Object.entries(data.slices).forEach(([key, value]: any) => {
      const series = this.series.find(({ id }: any) => key === id);
      series.data = data.labels.map((label: any, id: any) => ({ value: value[id], name: label }));
    });
  }
}

export default class PieChartWidgetOptions extends PieChartOptions {
  constructor(data: any, chartOptions = {}) {
    const wData = convertWidgetDataForOptions(data);
    super(wData, chartOptions);
  }
}

function convertWidgetDataForOptions(data: any) {
  return {
    labels: data.map((item: any) => item.barName),
    slices: {
      '': data.map((item: any) => item.barValue),
    },
  };
}
