<template>
  <b-row
    align-v="end"
    class="traffic-filters"
  >
    <b-col>
      <b-form-group label="Traffic origin">
        <b-form-select
          v-model="trafficOrigin"
          text-field="value"
          value-field="id"
          :options="trafficOriginOptions"
          @change="setTrafficOrigin"
        />
      </b-form-group>
    </b-col>
  </b-row>
</template>
<script>
export default {
  name: 'KmtxTrafficOrigin',
  props: {
    reference: {
      type: String,
      default: 'traffic-origin',
    },
  },
  emits: ['traffic-origin-select'],
  data() {
    return {
      trafficOriginOptions: [],
      trafficOrigin: null,
    };
  },
  mounted() {
    this.fillTrafficOrigins();
  },
  methods: {

    setTrafficOrigin(data) {
      this.trafficOrigin = data;
      this.fireEvent();
    },

    fireEvent() {
      this.$emit('traffic-origin-select', {
        trafficOrigin: this.trafficOrigin,
      });
    },
    fillTrafficOrigins() {
      this.trafficOriginOptions = [{ id: null, value: 'All' }, { id: 1, value: 'Mobile Web' }, { id: 0, value: 'Desktop' }];
    },
  },
};
</script>

<style>
.traffic-filters,
.row.traffic-filters {
  flex-wrap: nowrap;
}

.traffic-filters .custom-select {
  min-width: 10em;
}
</style>
