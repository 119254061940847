import { ApiV1 } from '@/api';

export default {
  fillOrderTimeline(params: any, options: any) {
    params = params || {};
    return ApiV1(options).get('/stat/source/order-stats', { params });
  },
  getOrdersTimeline(params: any, options: any) {
    params = params || Object.create(null);
    return ApiV1(options).get('/stat/orders/timeseries', { params });
  },
  getOrderPaidCount(params: any, options: any) {
    params = params || {};
    const cp = { ...params };
    cp.isPaid = 1;
    return ApiV1(options).get('/stat/source/order-count', { params: cp });
  },
  getCartCount(params: any, options: any) {
    params = params || {};
    return ApiV1(options).get('/stat/source/cart-by-source', { params });
  },
  getUniqueVisits(params: any, options: any) {
    params = params || {};
    return ApiV1(options).get('/stat/source/traffic-count', { params });
  },
  getOrderByThemes(params: any, options: any) {
    params = params || {};
    return ApiV1(options).get('/stat/source/order-by-source', { params });
  },
  getVisitByThemes(params: any, options: any) {
    params = params || {};
    return ApiV1(options).get('/stat/source/visit-by-source', { params });
  },
  getCartBySource(params: any, options: any) {
    params = params || {};
    return ApiV1(options).get('/stat/cart', { params });
  },
};
