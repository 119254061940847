import ApiTokens from '../../services/ApiTokens';

// initial state
const state = {
  tokens: [],
};

// actions
const actions = {

  fillApiTokens({ commit }: any, params: object) {
    return ApiTokens.getApiTokens(params).then((response) => {
      commit('setApiTokens', response.data);
      return { data: { data: response.data, meta: { total: response.data.length } } };
    });
  },

  createApiToken({ commit }: any, { params, options }: any) {
    return ApiTokens.createApiToken(params, options).then((response) => response);
  },

  revokeApiToken({ commit }: any, { params, options }: any) {
    return ApiTokens.revokeApiToken(params, options).then((response) => response);
  },
};

const mutations = {
  setApiTokens(state: any, payload: any) {
    state.tokens = payload;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
