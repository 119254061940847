import LocalStorage from '@/services/LocalStorage';

export default [
    {
        title: 'Data wizard',
        icon: 'icon-dsm.svg',
        subMenu: [
            {
                name: '/dsm/data-sources',
                title: 'Data sources',
                permission: 'dw:dsm:data-sources',
                childAccountOnly: true,
            },
            {
                name: '/dsm/users',
                title: 'Users',
                permission: 'dw:dsm:users',
                childAccountOnly: true,
            },
            {
                name: '/dsm/services',
                title: 'Services',
                permission: 'dw:dsm:users',
                childAccountOnly: true,
            },
            {
                name: '/dsm/access-requests',
                title: 'Access requests',
                saveQueryParams: true,
            },
            {
                name: '/dsm/my-accesses',
                title: 'My Accesses',
                childAccountOnly: true,
            },
        ],
    },
];
