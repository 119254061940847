<template>
  <b-form-group :label="label">
    <b-form-select
      :ref="reference"
      v-model="step"
      :options="steps"
      class="kmtx-steps input-select"
      @change="onChange"
    />
  </b-form-group>
</template>

<script>
import { mapMutations, mapState } from 'vuex';

export default {
  name: 'KmtxSteps',
  props: {
    label: {
      type: String,
      default: 'Step',
    },
    reference: {
      type: String,
      default: 'kmtx-steps',
    },
  },
  emits: ['step-change'],
  data() {
    return {
    };
  },
  computed: {
    ...mapState('newFilters', ['steps', 'currentStep']),
    step: {
      get() {
        return this.currentStep;
      },
      set(newStep) {
        return newStep;
      },
    },
  },
  created() {
    this.updateSteps();
  },
  beforeDestroy() {
    this.updateStep('auto');
  },
  methods: {
    ...mapMutations('newFilters', ['updateStep', 'updateSteps']),
    onChange(step) {
      this.updateStep(step);
    },
  },
};
</script>

<style scoped>
.kmtx-steps {
  min-width: 10em;
}
</style>
