import { ApiV1 } from '@/api';

const defaultOptions = {
    baseUrl: `${process.env.VUE_APP_API_SDK}/api/v1`,
    notUseBearer: true,
};

export default {
    getListSources(params) {
        return ApiV1().get('/sources', { params });
    },

    getListEvents(params, options = {}) {
        return ApiV1({ ...defaultOptions, ...options }).get('/custom_event', { params });
    },

    getListRules(params) {
        return ApiV1(defaultOptions).get('/rule', { params });
    },

    createRule(options) {
        return ApiV1(defaultOptions).post('/rule', options.params);
    },

    updateRule(options) {
        return ApiV1(defaultOptions).put(`/rule/${options.id}`, options.params);
    },

    setScreenForCustomEvent(ce_id: number, screen_id: number) {
        return ApiV1(defaultOptions).post(`/custom_event/${ce_id}/screen/${screen_id}`);
    },

    unsetScreenForCustomEvent(ce_id: number, screen_id: number) {
        return ApiV1(defaultOptions).delete(`/custom_event/${ce_id}/screen/${screen_id}`);
    },

    createCustomEvent(data) {
        return ApiV1(defaultOptions).post('/custom_event', data);
    },

    updateCustomEvent(ce_id: number, data) {
        return ApiV1(defaultOptions).put(`/custom_event/${ce_id}`, data);
    },

    createBodyParameter(ce_id: number, data) {
        return ApiV1(defaultOptions).post(`/custom_event/${ce_id}/binding_body`, data);
    },

    updateBodyParameter(ce_id: number, b_id: number, data) {
        return ApiV1(defaultOptions).put(`/custom_event/${ce_id}/binding_body/${b_id}`, data);
    },

    createUrlParameter(ce_id: number, data) {
        return ApiV1(defaultOptions).post(`/custom_event/${ce_id}/binding_url`, data);
    },

    updateUrlParameter(ce_id: number, b_id: number, data) {
        return ApiV1(defaultOptions).put(`/custom_event/${ce_id}/binding_url/${b_id}`, data);
    },

    createCustomEventFilter(ce_id: number, b_id: number, data) {
        return ApiV1(defaultOptions).post(`/custom_event/${ce_id}/binding/${b_id}/filter`, data);
    },

    updateCustomEventFilter(ce_id: number, b_id: number, f_id: number, data) {
        return ApiV1(defaultOptions).put(`/custom_event/${ce_id}/binding/${b_id}/filter/${f_id}`, data);
    },
};
