<template>
  <button
    class="kmtx-button text-overflow--ellipsis"
    @click="onClick"
  >
    <icon
      v-if="iconFile"
      :image="iconFile"
      class="kmtx-button__icon"
    />
    <slot>
      {{ text }}
    </slot>
  </button>
</template>

<script>
import IconsComponent from '../../IconsComponent.vue';

export default {
  name: 'Button',
  components: {
    icon: IconsComponent,
  },
  props: {
    text: {
      type: String,
      default: 'Ok',
    },
    reference: {
      type: String,
      default: 'kmtx-button',
    },

    iconFile: {
      type: String,
      default: '',
    },
  },

  emits: ['button-click'],

  methods: {
    onClick(event) {
      this.$emit('button-click', {
        reference: this.reference,
        event,
      });
    },
  },
};
</script>

<style lang="scss">
@import "@/sass/variables";

.kmtx-button {
    $button: &;

    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    padding: 8px 16px;
    border: unset;
    border-radius: 6px;
    font-family: $font-family-secondary;
    font-weight: bold;
    font-size: $btn-font-size;
    line-height: $btn-line-height;

    &--primary {
        color: $color-white;
        background-color: $color-primary;

        &:hover {
            background-color: $color-primary-light;
        }

        &:active {
            background-color: $color-primary-dark;
        }

        &:disabled {
            color: $color-secondary-ultra-light;
            background: $gray100;
        }

        &:focus:not(:hover):not(:active) {
            box-shadow: 0 0 0 4px $color-primary-light;
        }
    }

    &--primary-outline {
        background-color: unset;
        color: $color-primary;
        border: 1px solid $color-primary;

        &:hover {
            background-color: rgba($color-primary-light, .15);
        }

        &:active {
            background-color: $color-primary-active-lighter;
            border-color: $color-primary;
        }

        &:disabled {
            background: unset;
            border-color: $color-secondary-ultra-light;
        }

        &:focus:not(:hover):not(:active) {
            border-color: transparent;
            box-shadow: 0 0 0 4px $color-primary-light;
        }
    }

    &--secondary-outline {
        background-color: unset;
        color: $color-secondary-dark-light;
        border: 1px solid $gray500;

        &:hover {
            background-color: $gray100;
        }

        &:active {
            background-color: $gray300;
        }

        &:disabled {
            background: unset;
            border-color: $color-secondary-ultra-light;
        }

        &:focus:not(:hover):not(:active) {
            border-color: transparent;
            box-shadow: 0 0 0 4px $color-primary-light;
        }
    }

    &--outline-none {
        border: unset;
    }

    &--outline-thin {
        border: 1px solid $color-secondary-ultra-light;

        &:hover {
            border-color: $color-primary;
        }
    }

    &--sm {
        height: $btn-height-sm;
        font-size: $font-size-sm;
        line-height: $line-height-sm;
    }

    &--xs {
        @extend #{$button}--sm;

        height: $btn-height-xs;
    }

    &--text-left {
        justify-content: start;
        padding: 10px 12px;
    }

    &--text-regular {
        font-weight: normal;
    }

    &--icon-right {
        #{$button}__icon {
            order: 2;
            margin: 0 0 0 4px;
        }
    }

    &__icon {
        margin: 0 4px 0 0;
    }
}
</style>
