<template>
  <div class="content">
    <div class="mb-5 mt-3">
      <div
        class="mb-5 mt-3"
      >
        <big-number
          :value="totalUnique"
          variant="info"
        >
          <template #header>
            Unique visits
          </template>
        </big-number>
        <big-number
          :value="scoredUnique"
          variant="danger"
        >
          <template #header>
            Scored visits
          </template>
        </big-number>
      </div>
    </div>
    <kmtx-filter-builder
      ref="filters"
      :filters="filtersOptions"
      @domainlist-change="fillChartData"
      @domainlist-init="fillChartData"
      @button-submit="fillChartData"
    />

    <b-row class="mb-4">
      <b-col md="12">
        <kmtx-chart
          ref="timeSeriesBlackList"
          :options="timeSeriesChartOptions"
          :width="{ width: '100%' }"
          class="time-series-chart"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="6">
        <h2 class="h2">
          Blocked traffic (Top 10)
        </h2>
        <table-component
          :id="ipsTableId"
          hover
          :per-page="ipsPagination.per_page"
          :items="topBlockedIPs"
        />
      </b-col>
      <b-col md="6">
        <h2 class="h2">
          Blocked by domain (Top 10)
        </h2>
        <table-component
          :id="bySourceTableId"
          hover
          :per-page="bySourcePagination.per_page"
          :items="topBlockedBySource"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Scoring from '../../services/Scoring';

import kmtxFilterBuilder from '../filters/builder/index.vue';
import bigNumber from '../widgets/bigNumber/index.vue';
import TableComponent from '../TableComponent.vue';
import EChartComponent from '../echart/EChartComponent.vue';
import LineChartOptions from '../echart/options/line-chart/index';

export default {
  name: 'ScoringComponent',
  components: {
    kmtxFilterBuilder,
    TableComponent,
    bigNumber,
    'kmtx-chart': EChartComponent,
  },
  data() {
    return {
      enabled: 1,
      filtersOptions: [
        {
          filter: 'datepicker',
          label: 'Dates',
        },
        {
          filter: 'domainlist',
          label: 'Domain/Label',
        },
        {
          filter: 'button',
          value: 'Filter',
        },
      ],
      topBlockedIPs: [],
      ipsPagination: {
        per_page: 10,
      },
      ipsTableId: 'top-blocked-ips-table',
      topBlockedBySource: [],
      bySourcePagination: {
        per_page: 10,
      },
      bySourceTableId: 'top-blocked-source-table',
      totalUnique: null,
      scoredUnique: null,
      timeSeriesChartOptions: {},
    };
  },
  computed: {
    ...mapGetters('filters', {
      getDate: 'getDate',
      getDateForParams: 'getDateForParams',
      getCurrentDomain: 'getCurrentDomain',
    }),
  },
  watch: {},
  methods: {
    ...mapActions('filters', {
      setDate: 'setDate',
    }),
    fillChartData() {
      const params = this.setStaticParams();
      const options = { showLoader: true };
      const methods = [
        this.fillTimeSeries,
        this.fillTopBlockedIps,
        this.fillTopBlockedSources,
        this.fillBlockedList,
      ];
      Promise.all(methods.map((method) => method(params, options))).catch((error) => {
        if (!error.cancelReason) throw error;
      });
    },
    fillTopBlockedIps(params, options) {
      return Scoring.topBlockedIp(params, options).then((resp) => {
        this.topBlockedIPs = [];
        resp.data.data.map((i) => {
          this.topBlockedIPs.push({ ip: i.ClIP, count_views: i.PageViewDepth });
        });
      });
    },
    fillTopBlockedSources(params, options) {
      return Scoring.topBlockedBySource(params, options).then((resp) => {
        this.topBlockedBySource = [];
        resp.data.data.map((i) => {
          this.topBlockedBySource.push({ source: i.Source, count_views: i.PageViewDepth });
        });
      });
    },
    fillTimeSeries(params, options) {
      return Scoring.list(params, options).then((resp) => {
        this.totalUnique = this.scoredUnique = 0;
        const data = resp.data.data.map(({ BotUnique, UniqueVisits, t }) => {
          const score = parseInt(BotUnique, 10);
          const uniq = parseInt(UniqueVisits, 10);
          this.totalUnique += uniq;
          this.scoredUnique += score;
          return { Score: score, Unique: uniq, DateTime: t };
        });

        this.timeSeriesChartOptions = new LineChartOptions(data, {}, params);
        this.timeSeriesChartOptions.series[0].name = 'Score';
        this.timeSeriesChartOptions.series[1].name = 'Unique';
      });
    },

    setStaticParams() {
      const params = Object.create(null);
      params.label_source = [this.getCurrentDomain.id];
      params.label_type = this.getCurrentDomain.labelType;
      params.dateFrom = this.getDateForParams.dateFrom;
      params.dateTo = this.getDateForParams.dateTo;

      return params;
    },
  },
};
</script>
