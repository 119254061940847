function getObjectWithOutEmptyProp(payload: { [key: string]: any }, deleteEmptyString = false): { [key: string]: any } {
  const objectToReturn = {};

  for (const [key, value] of Object.entries(payload)) {
    if (
      (!deleteEmptyString && value != null && value != undefined)
      || (deleteEmptyString && value !== '' && value != null && value != undefined)
    ) {
      objectToReturn[key] = value;
    }

    if (Array.isArray(value)) {
      objectToReturn[key] = value.map((item) => {
        if (typeof item === 'object') {
          return getObjectWithOutEmptyProp(item);
        }
        return item;
      });
    }
  }

  return objectToReturn;
}

export default getObjectWithOutEmptyProp;
