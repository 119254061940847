<template>
  <div class="content">
    <kmtx-filter-builder
      ref="filters"
      :filters="filtersOptions"
      @domainlist-init="fillCharts"
      @button-submit="fillCharts"
      @traffic-select="trafficTypeSelect"
      @traffic-origin-select="trafficTypeSelect"
    />

    <div>
      <kmtx-chart
        ref="cartsChart"
        :options="cartsChart"
        class="carts-chart"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { kFormatter } from '@/helper';
import OrderService from '../../services/Order';
import kmtxFilterBuilder from '../filters/builder/index.vue';
import EChartComponent from '../echart/EChartComponent.vue';
import { convertObjectsDataForOptions, BarChartOptions } from '../echart/options/bar-chart';

export default {
  name: 'CartComponent',
  components: {
    kmtxFilterBuilder,
    'kmtx-chart': EChartComponent,
  },
  data() {
    return {
      filtersOptions: [
        {
          filter: 'datepicker',
          label: 'Dates',
        },
        {
          filter: 'domainlist',
          label: 'Domain/Label',
        },
        {
          filter: 'traffic-origin',
        },
        {
          filter: 'button',
          value: 'Apply',
        },
      ],
      trafficTypeSelectedData: {
        trafficType: null,
        externalTrafficType: null,
        adsType: null,
        trafficOrigin: null,
      },
      cartsChart: {},
    };
  },
  computed: {
    ...mapGetters('filters', {
      getCurrentDomain: 'getCurrentDomain',
      getDate: 'getDate',
      getDateForParams: 'getDateForParams',
    }),
  },
  mounted() {
  },
  methods: {
    trafficTypeSelect(data) {
      this.trafficTypeSelectedData = data;
    },
    fillCharts() {
      const params = {
        label_source: [this.getCurrentDomain.id],
        label_type: this.getCurrentDomain.labelType,
        dateFrom: this.getDateForParams.dateFrom,
        dateTo: this.getDateForParams.dateTo,
        trafficType: this.trafficTypeSelectedData.trafficType,
        externalTrafficType: this.trafficTypeSelectedData.externalTrafficType,
        aType: this.trafficTypeSelectedData.adsType,
        trafficOrigin: this.trafficTypeSelectedData.trafficOrigin,
      };
      const showLoader = true;

      OrderService.getCartBySource(params, { showLoader }).then((r) => {
        const data = convertObjectsDataForOptions(r.data.data, 'RuleEventName', 'route', 'countAdd', 'countInit');
        const chartOptions = {
          toolbox: {
            show: true,
          },
        };
        this.cartsChart = new BarChartOptions(data, chartOptions, { sort: 'desc', stack: true });
        this.cartsChart.tooltip.formatter = (params) => {
          const body = [];
          body.push(params[0].axisValue);
          params.forEach(({
            name, seriesName, value, marker,
          }) => {
            const mappedValue = data.map[name] ? Number(data.map[name]) : 0;
            const conversion = mappedValue === 0 ? 0 : (value / mappedValue).toFixed(5);
            body.push(`${marker} ${seriesName || 'no name'}: ${kFormatter(value)} ${conversion && value
              ? `conversion(${conversion})` : ''}`);
          });
          return body.join(`<br />`);
        };
      }).catch((error) => { if (!error.cancelReason) throw error; });
    },
  },
};
</script>
