<template>
    <div>
        <KmtxFilterBuilder
            ref="filters"
            :filters="filtersOptions"
            @datepicker-change="onDateSelected"
            @domainlist-change="fillChartData"
            @domainlist-init="fillChartData"
            @step-change="setStepInterval"
            @select-change="setRefreshInterval"
            @traffic-init="trafficFilterInit"
            @traffic-select="trafficTypeSelect"
            @traffic-origin-select="trafficTypeSelect"
        />

        <div class="mb-5 mt-3">
            <BigNumbers
                class="mb-5 mt-3"
                items-wrap
                :items-count="6"
            >
                <BigNumber :value="pageVisitCount">
                    <template #header> Page view total </template>
                </BigNumber>
                <BigNumber :value="pageSessionCount">
                    <template #header> Sessions count </template>
                </BigNumber>
                <BigNumber
                    :value="pageUniqueVisitCount"
                    variant="info"
                >
                    <template #header> Unique visits </template>
                </BigNumber>
                <BigNumber
                    :value="paidOrderCount"
                    variant="success"
                >
                    <template #header> Paid Orders </template>
                </BigNumber>
                <BigNumber
                    :value="unPaidOrderCount"
                    variant="warning"
                >
                    <template #header> Unpaid Orders </template>
                </BigNumber>
                <BigNumber
                    :value="lostCartCount"
                    variant="warning"
                >
                    <template #header> Lost carts </template>
                </BigNumber>
            </BigNumbers>
        </div>

        <div class="col-md-4">
            <div id="spark1" />
        </div>
        <BRow class="mb-4">
            <BCol md="8">
                <EChartComponent
                    ref="vvChart"
                    :options="vvChart"
                    class="vv-chart"
                />
            </BCol>
            <BCol
                md="4"
                class="mt-4 col-xs-12"
            >
                <EChartComponent
                    ref="kmtx-themePieChart"
                    :options="themePieChart"
                    class="theme-pie-chart"
                />
            </BCol>
        </BRow>
        <BRow>
            <BCol
                md="6"
                mr="6"
            >
                <div class="relative">
                    <TableComponent
                        :id="tableId"
                        small
                        hover
                        responsive
                        table-title="Top 20 searches"
                        :per-page="pagination.per_page"
                        :items="topSearchesList"
                        :fields="topSearchesFields"
                    />
                </div>
            </BCol>
            <BCol md="6">
                <h3>Top 20 domains</h3>
                <EChartComponent
                    ref="topDomainsChart"
                    :options="topDomainsChart"
                    class="top-domains-chart"
                />
            </BCol>
        </BRow>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import StatService from '../../services/Statistic';
import SourceService from '../../services/Source';
import { skinList } from '../../const';
import KmtxFilterBuilder from '../filters/builder/index.vue';
import TableComponent from '../TableComponent.vue';
import BigNumbers from '../widgets/bigNumbers/index.vue';
import BigNumber from '../widgets/bigNumber/index.vue';
import EChartComponent from '../echart/EChartComponent.vue';
import LineChartOptions from '../echart/options/line-chart/index';
import { BarChartOptions } from '../echart/options/bar-chart';
import { PieChartOptions } from '../echart/options/pie-chart';
import EventBus from '../../event';

export default {
    name: 'SourceStatisticLine',

    components: {
        TableComponent,
        KmtxFilterBuilder,
        BigNumber,
        BigNumbers,
        EChartComponent,
    },

    data() {
        return {
            filtersOptions: [
                {
                    filter: 'datepicker',
                    label: 'Dates',
                },
                {
                    filter: 'domainlist',
                    label: 'Domain/Label',
                },
                {
                    filter: 'steps',
                    label: 'Step',
                },
                {
                    filter: 'traffic',
                },
                {
                    filter: 'traffic-origin',
                },
                {
                    filter: 'select',
                    label: 'Auto refresh',
                    data: [
                        { value: null, text: '' },
                        { value: 5, text: '5 minutes' },
                        { value: 10, text: '10 minutes' },
                        { value: 15, text: '15 minutes' },
                        { value: 30, text: '30 minutes' },
                        { value: 60, text: '60 minutes' },
                    ],
                },
            ],
            trafficTypeSelectedData: {
                trafficType: null,
                externalTrafficType: null,
                adsType: null,
                trafficOrigin: null,
            },
            trafficFilterInitPromise: null,
            isShowThemeSeries: [],
            skinList,
            timer: null,
            refreshInterval: null,
            series: [],
            params: {},
            labelList: [],
            minResolution: 0,
            topSearchesList: [],
            pagination: {
                per_page: 20,
            },
            tableId: 'top-searches-table',
            topSearchesFields: [
                {
                    key: 'Keyword',
                    label: 'Keyword',
                },
                {
                    key: 'SearchNumber',
                    label: 'Search Number',
                },
                {
                    key: 'Host',
                    label: 'Host',
                },
            ],
            sourcesVisitData: [],
            unPaidOrderCount: null,
            paidOrderCount: null,
            lostCartCount: null,
            pageVisitCount: null,
            pageSessionCount: null,
            pageUniqueVisitCount: null,
            vvChart: {},
            topDomainsChart: {},
            themePieChart: {},
        };
    },

    computed: {
        ...mapGetters('filters', {
            getCurrentStep: 'getCurrentStep',
            getCurrentDomain: 'getCurrentDomain',
            getDate: 'getDate',
            getDateForParams: 'getDateForParams',
        }),
    },

    methods: {
        trafficTypeSelect(data) {
            this.trafficTypeSelectedData = data;
            this.fillChartData();
        },

        trafficFilterInit(prom) {
            this.trafficFilterInitPromise = prom;
        },

        setStepInterval() {
            this.fillChartData();
        },

        setRefreshInterval(data) {
            this.refreshInterval = data.value;
            if (this.timer !== null) {
                clearInterval(this.timer);
            }
            if (this.refreshInterval) {
                this.timer = setInterval(() => {
                    this.fillChartData();
                }, this.refreshInterval * 60000);
            }
        },

        onDateSelected() {
            this.fillChartData();
        },

        fillChartData() {
            const params = this.setStaticParams();
            const showLoader = true;
            const methods = [
                this.fillVisitsLine,
                this.fillPageVisits,
                this.fillCartStatistics,
                this.fillOrderStatistics,
                this.fillTopSearches,
                this.fillTopDomains,
                this.fillThemeVisit,
            ];
            Promise.all([...methods.map((method) => method(params, { showLoader })), this.trafficFilterInitPromise]).catch((error) => {
                if (!error.cancelReason) throw error;
            });
        },

        fillVisitsLine(params, options) {
            return StatService.getSourceStatistic(params, options).then((response) => {
                if (response.data.data.length < 1) {
                    EventBus.$emit('kmtx-toast', 'Error', 'Source Statistics returned no data', 'error');
                    return;
                }

                const viewsChartTitle = 'Page views';
                const visitsChartTitle = 'Unique visits';
                const data = response.data.data.map(({ EventsNumber, UniqueVisits, t: DateTime }) => ({
                    EventsNumber,
                    UniqueVisits,
                    DateTime,
                }));
                const chartOptions = {
                    grid: {
                        left: '0',
                        bottom: '30px',
                    },
                    toolbox: {
                        right: '2%',
                        show: true,
                    },
                };
                this.vvChart = new LineChartOptions(data, chartOptions, params);
                this.vvChart.series[0].name = viewsChartTitle;
                this.vvChart.series[1].name = visitsChartTitle;
                this.vvChart.title.text = `${viewsChartTitle} vs ${visitsChartTitle}`;
                this.vvChart.legend.data = [viewsChartTitle, visitsChartTitle];

                this.minResolution = response.headers['x-grid-resolution'];
            });
        },

        setStaticParams() {
            const params = {};
            params.label_source = [this.getCurrentDomain.id];
            params.label_type = this.getCurrentDomain.labelType;
            params.dateFrom = this.getDateForParams.dateFrom;
            params.dateTo = this.getDateForParams.dateTo;
            params.r = this.getCurrentStep;

            params.trafficType = this.trafficTypeSelectedData.trafficType;
            params.externalTrafficType = this.trafficTypeSelectedData.externalTrafficType;
            params.aType = this.trafficTypeSelectedData.adsType;
            params.trafficOrigin = this.trafficTypeSelectedData.trafficOrigin;

            return params;
        },

        fillPageVisits(params, options) {
            return StatService.getPageVisits(params, options).then((resp) => {
                this.pageVisitCount = resp.data.Visits;
                this.pageUniqueVisitCount = resp.data.UniqVisists;
                this.pageSessionCount = resp.data.Sessions;
            });
        },

        fillPageUniqueVisits(params, options) {
            return StatService.getPageUniqueVisits(params, options).then((resp) => {
                this.pageUniqueVisitCount = resp.data.tVisits;
            });
        },

        fillTopSearches(params, options) {
            return StatService.getTopSearches(params, options).then((resp) => {
                this.topSearchesList = resp.data.data;
            });
        },

        fillTopDomains(params, options) {
            return StatService.getTopDomains(params, options).then((resp) => {
                const buildOptions = {
                    direction: 'yAxis',
                    sort: 'asc',
                };
                const chartOptions = {
                    yAxis: {
                        axisLabel: {
                            interval: 0,
                        },
                    },
                    toolbox: {
                        show: true,
                    },
                };
                const data = {
                    columns: [],
                    slices: {},
                };

                resp.data.data.forEach((item) => {
                    data.slices['Unique visits'] = data.slices['Unique visits'] || [];
                    data.slices['Unique visits'].push(Number(item.UniqueVisits));
                    data.slices['Page views'] = data.slices['Page views'] || [];
                    data.slices['Page views'].push(Number(item.EventsNumber));
                    data.columns.push(item.Host.replace('www.', ''));
                });

                this.topDomainsChart = new BarChartOptions(data, chartOptions, buildOptions);
            });
        },

        fillThemeVisit(params, options) {
            return this.getLabelList(options).then(() =>
                StatService.getSourceVisits(params, options).then((resp) => {
                    this.sourcesVisitData = resp.data.data;
                    const themeMap = {};
                    const themeSeries = {};
                    let total = 0;
                    this.sourcesVisitData.map((s) => {
                        total += s.Total * 1;
                    });
                    const otherIndex = total * 0.02;
                    this.labelList.map((i) => {
                        if (this.skinList.indexOf(i.name) < 0) {
                            return;
                        }
                        let isIgnored = false;
                        i.sources.map((s) => {
                            if (this.getCurrentDomain.id !== 0 && !this.getCurrentDomain.label && this.getCurrentDomain.name !== s.host) {
                                isIgnored = true;
                                return;
                            }
                            if (themeMap[s.id]) {
                                themeMap[s.id].push(i.name);
                            } else {
                                themeMap[s.id] = [i.name];
                            }
                        });
                        if (!isIgnored) {
                            themeSeries[i.name] = 0;
                        }
                    });
                    this.sourcesVisitData.map((d) => {
                        if (themeMap[d.SourceDictId]) {
                            for (const k in themeMap[d.SourceDictId]) {
                                const th = themeMap[d.SourceDictId][k];
                                if (themeSeries[th]) {
                                    themeSeries[th] += d.Total * 1;
                                } else {
                                    themeSeries[th] = d.Total * 1;
                                }
                            }
                        }
                    });
                    let otherSum = 0;
                    for (const i in themeSeries) {
                        if (themeSeries[i] <= otherIndex) {
                            otherSum += themeSeries[i];
                            delete themeSeries[i];
                        }
                    }
                    if (otherSum) {
                        themeSeries.other = 0;
                        themeSeries.other = otherSum;
                    }
                    const keysSorted = Object.keys(themeSeries).sort((a, b) => themeSeries[b] - themeSeries[a]);
                    const s = [];
                    const th = [];
                    for (const k in keysSorted) {
                        const key = keysSorted[k];
                        th.push(key);
                        s.push(themeSeries[key] * 1);
                    }

                    const data = {
                        labels: th,
                        slices: {
                            theme: s,
                        },
                    };
                    const chartOptions = {
                        toolbox: {
                            show: true,
                        },
                    };
                    this.themePieChart = new PieChartOptions(data, chartOptions);
                    this.themePieChart.title.text = 'Label visits';
                    if (!this.themePieChart.legend.data.length) {
                        this.themePieChart.title.subtext = 'No data found';
                    }
                })
            );
        },

        getLabelList(options) {
            if (this.labelList.length > 0) {
                return new Promise((resolve) => {
                    resolve();
                });
            }
            return SourceService.getLabels({ expand: 'sources' }, options).then((r) => {
                this.labelList = r.data.data;
            });
        },

        fillOrderStatistics(params, options) {
            return StatService.getOrdersStatistics(params, options).then((resp) => {
                const rd = resp.data;
                this.paidOrderCount = rd.PaidOrder;
                this.unPaidOrderCount = rd.UnPaidOrder;
            });
        },

        fillCartStatistics(params, options) {
            return StatService.getCartStatistic(params, options).then((resp) => {
                const rd = resp.data;
                this.lostCartCount = rd.LostCart;
            });
        },
    },
};
</script>

<style lang="scss">
.vv-chart .echarts.kmtx-echart {
    width: 100%;
    min-height: 365px;
}

.top-domains-chart .echarts.kmtx-echart {
    min-height: 750px;
}
</style>
