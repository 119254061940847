import { computed, ref } from 'vue';
import LMService from '../services/LabelManagement';
import deepClone from '@/shared/utils/deepClone';
import isEqual from '@/shared/utils/isEqual';
import { ILabelComponent } from '@/modules/label-management/interfaces/Label';

const defaultValue: ILabelComponent = {
    name: '',
    id: '',
    key: '',
    value: '',
};

const selectedLabelId = ref<string>('');
const backup = ref<ILabelComponent>(deepClone(defaultValue));
const label = ref<ILabelComponent>(deepClone(defaultValue));
const hasChanges = computed(() => isEqual(backup.value, label.value));

export default function useLabelComponent() {
    function selectLabel(id: string): void {
        selectedLabelId.value = id;
    }

    function setBackup(label?: ILabelComponent): void {
        backup.value = deepClone(label ?? defaultValue);
    }

    function setLabel(data?: Partial<ILabelComponent>) {
        const cloneData = deepClone(data ?? deepClone(defaultValue));

        Object.keys(cloneData).forEach((key: string): void => {
            label.value[key] = cloneData[key];
        });
    }

    const getLabel = async (id: string) => {
        try {
            const response = await LMService.getLabel(id);
            label.value = response;
            setBackup(response);
            return response;
        } catch (error) {
            return { error };
        }
    };

    const createLabel = async () => {
        try {
            return await LMService.createLabel({ key: label.value.key, value: label.value.value });
        } catch (error) {
            return { error };
        }
    };

    const updateLabel = async () => {
        try {
            return await LMService.updateLabel(label.value.id, { key: label.value.key, value: label.value.value });
        } catch (error) {
            return { error };
        }
    };

    return {
        label,
        backup: computed(() => backup.value),
        hasChanges,
        setBackup,
        setLabel,
        selectLabel,
        getLabel,
        createLabel,
        updateLabel,
        selectedLabelId: computed(() => selectedLabelId.value),
    };
}
