<template>
  <b-container
    class="routes-component"
    fluid
  >
    <kmtx-filter-builder
      ref="filters"
      :filters="filtersOptions"
      @datepicker-change="fillRouteTransition"
      @domainlist-change="fillRouteTransition"
      @domainlist-init="fillRouteTransition"
      @traffic-init="trafficFilterInit"
      @traffic-select="trafficTypeSelect"
      @button-submit="downloadCsv"
    />

    <sunburst-diag ref="routeTransitionSunburst" />
  </b-container>
</template>
<script>
import { mapGetters } from 'vuex';
import StatService from '../../services/Statistic';
import SunburstDiag from './SunburstComponent.vue';
import kmtxFilterBuilder from '../filters/builder/index.vue';

export default {
  components: {
    SunburstDiag,
    kmtxFilterBuilder,
  },
  data() {
    return {
      filtersOptions: [
        {
          filter: 'datepicker',
          label: 'Dates',
        },
        {
          filter: 'domainlist',
          label: 'Domain/Label',
        },
        {
          filter: 'traffic',
        },
        {
          filter: 'button',
          value: 'Export to CSV',
        },
      ],
      trafficTypeSelectedData: {
        trafficType: null,
        externalTrafficType: null,
        adsType: null,
      },
      trafficFilterInitPromise: null,
      title: 'Route transition',
      routeTransitionList: [],
    };
  },
  computed: {
    ...mapGetters('filters', {
      getDateForParams: 'getDateForParams',
      getCurrentDomain: 'getCurrentDomain',

    }),
  },
  mounted() {
  },
  methods: {
    trafficTypeSelect(data) {
      this.trafficTypeSelectedData = data;
      this.fillRouteTransition();
    },
    trafficFilterInit(prom) {
      this.trafficFilterInitPromise = prom;
    },
    downloadCsv() {
      const rows = [
        ['Route', 'Page views'],
      ];

      this.parseDataForExport(this.routeTransitionList, rows);

      const csvContent = `data:text/csv;charset=utf-8,${
        rows.map((e) => e.join(',')).join('\n')}`;
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement('a');
      link.setAttribute('href', encodedUri);
      const params = this.setStaticParams();

      link.setAttribute('download', `${params.dateFrom}_${params.dateTo}_route_transition.csv`);
      document.body.appendChild(link); // Required for FF

      link.click();
    },
    parseDataForExport(data, rows) {
      for (let i = 0; i < data.length; i++) {
        rows.push([
          data[i].path,
          data[i].value,
        ]);

        if (data[i].children) {
          this.parseDataForExport(data[i].children, rows);
        }
      }
    },
    setStaticParams() {
      const params = {};
      params.label_source = [this.getCurrentDomain.id];
      params.label_type = this.getCurrentDomain.labelType;

      params.trafficType = this.trafficTypeSelectedData.trafficType;
      params.externalTrafficType = this.trafficTypeSelectedData.externalTrafficType;
      params.aType = this.trafficTypeSelectedData.adsType;

      params.dateFrom = this.getDateForParams.dateFrom;
      params.dateTo = this.getDateForParams.dateTo;
      return params;
    },
    fillRouteTransition() {
      const params = this.setStaticParams();
      const showLoader = true;
      params.type = 'sunburst';
      return StatService.getRouteTransitionData(params, { showLoader }).then((resp) => {
        this.routeTransitionList = resp.data.data;
        this.$refs.routeTransitionSunburst.setData(this.routeTransitionList);
      }).catch((error) => { if (!error.cancelReason) throw error; });
    },
  },
};
</script>
