export interface IRoute {
  title: string | null;
  tabs: { text: string, routeName: string }[] | null;
  isRouteChangeAvailable: boolean;
}

const state = {
  title: null,
  tabs: null,
  isRouteChangeAvailable: true,
};

const getters = {
  currentTitle: (state: any) => state.title,
  currentTabs: (state: any) => state.tabs,
  isRouteChangeAvailable: (state: any) => state.isRouteChangeAvailable,
};

const actions = {
  setCurrentTitle: ({ commit }: any, page: any) => {
    commit('changeCurrentTitle', page);
  },
  setCurrentTabs: ({ commit }: any, tabs: any) => {
    commit('changeCurrentTabs', tabs);
  },
  setRouteChangeAvailable: ({ commit }: any, isAvailable: boolean) => {
    commit('changeRouteChangeAvailable', isAvailable);
  },
  clearTabs: ({ commit }: any) => {
    commit('changeCurrentTabs', null);
  },
};
const mutations = {
  changeCurrentTitle: (state: any, payload: any) => {
    state.title = payload;
  },
  changeCurrentTabs: (state: any, payload: any) => {
    state.tabs = payload;
  },
  changeRouteChangeAvailable: (state: any, payload: boolean) => {
    state.isRouteChangeAvailable = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
