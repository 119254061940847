<template>
  <div class="content">
    <kmtx-filter-builder
      ref="filters"
      :filters="filtersOptions"
      @datepicker-change="onDateChange"
      @domainlist-change="fillChartData"
      @domainlist-init="fillChartData"
      @input-submit="onKeywordChange"
      @step-change="onStepChange"
      @button-submit="downloadCsv"
      @traffic-select="trafficTypeSelect"
      @traffic-origin-select="trafficTypeSelect"
    />

    <b-row class="mt-4">
      <b-col md="6">
        <b-row>
          <b-col>
            <table-component
              :id="topSearchTableId"
              small
              hover
              responsive
              :per-page="topSearchPagination.per_page"
              primary-key="Index"
              title="Top searches"
              :items="topSearchesList"
              :fields="fields"
              :nested-table-data="nestedTableData"
              :sort-by="sortBy"
              :sort-desc="sortDesc"
              @row-clicked="viewKeywordData"
            />
          </b-col>
        </b-row>
      </b-col>
      <b-col md="6">
        <div class="mb-0 grid">
          <h5
            v-if="selectedKeyword"
            class="mb-0 grid__col search-statistic-keyword"
          >
            Keyword: <span class="text-info">{{ selectedKeyword }}</span>
          </h5>
          <h5 class="mb-0 grid__col grid__col--no-grow search-statistic-domain">
            Domain:
            <span
              v-if="selectedHost"
              class="text-info"
            >{{ selectedHost }}</span>
            <span
              v-else
              class="text-info"
            >All</span>
          </h5>
        </div>
        <kmtx-chart
          ref="searchStatistic"
          :options="searchOptions"
          :width="{ width: '100%' }"
          class="search-chart"
        />

        <b-row
          v-if="keywordPagination.rows > 0"
          align-v="center"
          align-h="between"
          no-gutters
          class="mt-4"
        >
          <table-component
            :id="keywordTableId"
            small
            hover
            title="Added product"
            :items="keywordProductsList"
            :fields="productsFields"
            :per-page="keywordPagination.per_page"
          />
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import StatService from '../../services/Statistic';
import TableComponent from '../TableComponent.vue';

import kmtxFilterBuilder from '../filters/builder/index.vue';
import EChartComponent from '../echart/EChartComponent.vue';
import LineChartOptions from '../echart/options/line-chart/index';

export default {
  name: 'SearchStatistics',
  components: {
    TableComponent,
    kmtxFilterBuilder,
    'kmtx-chart': EChartComponent,
  },
  data() {
    return {
      filtersOptions: [
        {
          filter: 'datepicker',
          label: 'Dates',
        },
        {
          filter: 'domainlist',
          label: 'Domain/Label',
        },
        {
          filter: 'input',
          label: 'Look For',
          placeholder: 'Keyword...',
        },
        {
          filter: 'traffic-origin',
        },
        {
          filter: 'steps',
          label: 'Step',
        },
        {
          filter: 'button',
          value: 'Export to CSV',
        },
      ],
      topSearchPagination: {
        per_page: 15,
      },
      topSearchTableId: 'top-search-table',
      keywordPagination: {
        per_page: 10,
        rows: 0,
      },
      keywordTableId: 'keyword-table',
      getRows: 100,
      sortBy: 'SearchNumber',
      sortDesc: true,
      params: {},
      labelList: [],
      topSearchesList: [],
      minResolution: 0,
      selectedKeyword: null,
      selectedHost: null,
      searchKeyword: null,
      keywordProductsList: null,
      fields: [
        {
          key: 'kmtx-expand',
          label: '',
          tdClass: 'table-cell--expand',
        },
        {
          key: 'Keyword',
          label: 'Keyword',
          sortable: true,
          sortByFormatted: true,
          formatter: (value) => (value !== '' ? value : '- empty keyword -'),
          tdClass: 'table-cell--max',
        },
        {
          key: 'SearchNumber', label: 'Search', sortable: true, tdClass: 'table-cell--min',
        },
        {
          key: 'CartsNumber', label: 'Carts', sortable: true, tdClass: 'table-cell--min',
        },
        {
          key: 'Conversion',
          label: 'Conversion',
          sortable: true,
          formatter: (value) => `${value}%`,
          tdClass: 'table-cell--min',
        },
        {
          key: 'Domains', label: 'Domains', sortable: true, tdClass: 'table-cell--min',
        },
        {
          key: 'ISI',
          label: 'ISI',
          sortable: true,
          tdClass: 'table-cell--min',
          headerTitle: 'Incorrect Search Index = (Searches / EXP(Cart / Searches * 100)) / 1000',
        },
      ],
      nestedTableData: {
        property: 'Children',
        responsive: true,
        stickyHeader: true,
        sortBy: 'SearchNumber',
        sortDesc: true,
        fields: [
          {
            key: 'Host', label: 'Host', tdClass: 'table-cell--max', sortable: true,
          },
          {
            key: 'SearchNumber', label: 'Search', tdClass: 'table-cell--min', sortable: true,
          },
          {
            key: 'CartsNumber', label: 'Carts', tdClass: 'table-cell--min', sortable: true,
          },
          {
            key: 'Conversion',
            label: 'Conversion',
            formatter: (value) => `${value}%`,
            tdClass: 'table-cell--min',
            sortable: true,
          },
          {
            key: 'ISI',
            label: 'ISI',
            tdClass: 'table-cell--min',
            headerTitle: 'Incorrect Search Index = (Searches / EXP(Cart / Searches * 100)) / 1000',
            sortable: true,
          },
        ],
      },
      productsFields: [
        { key: 'pid', label: 'Product Id', sortable: false },
        { key: 'addNum', label: 'Added to cart (qty)', sortable: false },
        { key: 'impCount', label: 'Imp. count', sortable: false },
        { key: 'impAvgPosition', label: 'Imp. avg position', sortable: false },
        { key: 'conversion', label: 'Conversion', sortable: false },
      ],
      searchOptions: {},
      trafficTypeSelectedData: {
        trafficType: null,
        externalTrafficType: null,
        adsType: null,
        trafficOrigin: null,
      },
    };
  },
  computed: {
    ...mapGetters('filters', {
      getCurrentDomain: 'getCurrentDomain',
      getCurrentStep: 'getCurrentStep',
      getDate: 'getDate',
      getDateForParams: 'getDateForParams',
    }),
  },
  watch: {
    $route(to, from) {
      if (from.name === to.name) {
        const newQuery = {
          ...from.query,
          ...to.query,
        };

        Object.keys(newQuery).forEach((key) => (newQuery[key] === '') && delete newQuery[key]);

        // if (newQuery.host === 0) {
        //     delete newQuery.host;
        // }

        this.$router.replace({
          query: newQuery,
        }).catch(() => {});
      }
    },
  },
  mounted() {
    if (this.$route.query.datefrom && this.$route.query.dateto) {
      const toDate = (dateStr) => {
        const [year, month, day] = dateStr.split('-');
        return new Date(year, month - 1, day);
      };

      const dateFrom = toDate(this.$route.query.datefrom);
      const dateTo = toDate(this.$route.query.dateto);

      this.setDate([dateFrom, dateTo]);
    }

    if (this.$route.query.searchkeyword !== this.searchKeyword) {
      this.searchKeyword = this.$route.query.searchkeyword;
    }
  },
  methods: {
    ...mapActions('filters', {
      setDate: 'setDate',
    }),
    trafficTypeSelect(data) {
      this.trafficTypeSelectedData = data;
      this.fillChartData();
    },
    changeLimit() {
      this.fillChartData();
    },
    downloadCsv() {
      const rows = [
        ['Keyword', 'SearchNumber', 'CartsNumber', 'Conversion', 'Host', 'ISI'],
      ];

      this.parseDataForExport(this.topSearchesList, rows);

      const csvContent = `data:text/csv;charset=utf-8,${rows.map((e) => e.join(';')).join('\n')}`;
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement('a');
      link.setAttribute('href', encodedUri);
      const params = this.loadGlobalParams();

      link.setAttribute('download', `${params.dateFrom}_${params.dateTo}_search_stats.csv`);
      document.body.appendChild(link); // Required for FF

      link.click();
    },
    parseDataForExport(data, rows) {
      for (let i = 0; i < data.length; i++) {
        const children = data[i].Children;
        if (children.length === 0) {
          continue;
        }

        for (let k = 0; k < children.length; k++) {
          rows.push([
            children[k].Keyword,
            children[k].SearchNumber,
            children[k].CartsNumber,
            children[k].Conversion,
            children[k].Host,
            children[k].ISI,
          ]);
        }
      }
    },
    onKeywordChange(keyword) {
      if (keyword !== this.$route.query.keyword) {
        this.$router.push({
          name: 'search-stats',
          query: {
            searchkeyword: keyword,
          },
        });

        this.searchKeyword = keyword;
        this.fillChartData();
      }
    },
    onStepChange() {
      this.loadKeywordData();
    },
    setStepInterval() {
      this.loadKeywordData();
    },
    viewKeywordData(row) {
      const prevKeyword = this.selectedKeyword;
      const prevHost = this.selectedHost;

      this.selectedKeyword = row.Keyword ? row.Keyword : '';

      if (this.$route.query.host) {
        this.selectedHost = row.Children[0].Host || null;
      } else {
        this.selectedHost = row.Host || null;
      }

      if (prevKeyword !== this.selectedKeyword || prevHost !== this.selectedHost) {
        this.loadKeywordData();
      }
    },
    loadKeywordData() {
      const showLoader = true;
      const params = this.loadGlobalParams({
        k: this.selectedKeyword,
        h: this.selectedHost,
      });

      this.fillKeywordLine(params, { showLoader });
    },

    onDateChange() {
      if (this.getDateForParams.dateFrom !== this.$route.query.datefrom
                || this.getDateForParams.dateTo !== this.$route.query.dateto) {
        this.$router.push({
          name: this.$route.name,
          query: {
            datefrom: this.getDateForParams.dateFrom,
            dateto: this.getDateForParams.dateTo,
          },
        });

        this.fillChartData();
      }
    },
    fillChartData() {
      const showLoader = true;
      const params = this.loadGlobalParams();

      return this.fillTopSearches(params, { showLoader })
        .then(() => {
          if (this.topSearchesList.length > 0) {
            this.selectedKeyword = this.topSearchesList[0].Keyword || null;
            if (this.$route.query.host) {
              this.selectedHost = this.topSearchesList[0].Children[0].Host || null;
            } else {
              this.selectedHost = this.topSearchesList[0].Host || null;
            }
            this.loadKeywordData();
          } else {
            this.selectedKeyword = null;
            this.selectedHost = this.getCurrentDomain.name || null;
          }
        }).catch((error) => { if (!error.cancelReason) throw error; });
    },
    fillTopSearches(params, options) {
      return StatService.getSearchStatsData(params, options).then((resp) => {
        this.topSearchesList = resp.data;
        this.minResolution = resp.headers['x-grid-resolution'];
      });
    },
    fillKeywordSource(item) {
      const params = this.loadGlobalParams({ k: item.Keyword });
      return StatService.getKeywordSourceData(params).then((resp) => {
        resp.data.data.forEach((item) => {
          item._rowVariant = 'info';
          item.parentKeyword = parent.Keyword;
          parent.children.push(item);
        });
      });
    },
    fillKeywordLine(params, options) {
      return StatService.getKeywordStatsData(params, options).then((resp) => {
        this.keywordProductsList = [];
        const tmpKeywordProductsList = {};
        const data = resp.data.data.map(({ CartsNumber, SearchNumber, t: DateTime }) => ({ SearchNumber, CartsNumber, DateTime }));

        const chartsOptions = {
          toolbox: {
            show: false,
          },
          series: [
            {
              id: 'SearchNumber',
              areaStyle: {},
              type: 'line',
            },
            {
              id: 'CartsNumber',
              areaStyle: {},
              type: 'line',
            },
          ],
        };
        this.searchOptions = new LineChartOptions(data, chartsOptions, params);
        this.searchOptions.series[0].name = 'Search Number';
        this.searchOptions.series[1].name = 'Carts Number';

        resp.data.data.map((i) => {
          i.Products.map((pid) => {
            pid = pid.toString();
            if (!tmpKeywordProductsList[pid]) {
              tmpKeywordProductsList[pid] = 0;
            }
            tmpKeywordProductsList[pid]++;
          });
        });

        params.product_ids = Object.keys(tmpKeywordProductsList).join(',');
        StatService.getKeywordProductImpression(params).then((response) => {
          const { data } = response.data;
          for (const k in tmpKeywordProductsList) {
            const v = tmpKeywordProductsList[k];
            const impression = data.find((i) => i.ImpressionCode === k);
            let conversion = '-';
            let impAvgPosition = '-';
            let impCount = '-';
            if (impression) {
              impAvgPosition = Math.ceil(impression.AvgPosistion);
              impCount = impression.ImpressionCount;
              conversion = (v * 1 / impCount * 1) * 100;
              conversion = `${conversion.toFixed(2)}%`;
            }
            this.keywordProductsList.push({
              pid: k,
              addNum: v,
              conversion,
              impAvgPosition,
              impCount,
            });
          }
          this.keywordProductsList.sort((a, b) => b.addNum - a.addNum);
          this.keywordProductsCurrentPage = 1;
          this.keywordPagination.rows = this.keywordProductsList.length;
        });
      });
    },
    loadGlobalParams(params) {
      params = params || {};
      params.label_source = [this.getCurrentDomain.id];
      params.label_type = this.getCurrentDomain.labelType;
      params.dateFrom = this.getDateForParams.dateFrom;
      params.dateTo = this.getDateForParams.dateTo;
      params.r = this.getCurrentStep;
      params.perPage = this.getRows;
      if (this.sortBy && !params.sortBy) {
        params.sortBy = this.sortBy;
      }
      if (this.sortDesc && !params.sortDesc) {
        params.sortDesc = this.sortDesc;
      }

      if (this.searchKeyword) {
        params.k = this.searchKeyword;
      }
      params.trafficType = this.trafficTypeSelectedData.trafficType;
      params.externalTrafficType = this.trafficTypeSelectedData.externalTrafficType;
      params.aType = this.trafficTypeSelectedData.adsType;
      params.trafficOrigin = this.trafficTypeSelectedData.trafficOrigin;
      return params;
    },
  },
};
</script>

<style scoped>
.search-statistic-domain
    {
        max-width: 50%;
        min-width: 40%;
    }

    .search-statistic-keyword
    {
        padding-right: 10px;
    }

    .search-statistic-domain,
    .search-statistic-keyword
    {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
</style>
