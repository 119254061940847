import { ApiStatusCollector } from '@/api';

export default {
  getLastEntities() {
    return ApiStatusCollector().get('/entities/last');
  },
  getDates() {
    return ApiStatusCollector().get('/entities/dates');
  },
  getEntities(params: any) {
    return ApiStatusCollector().get('/entities', { params });
  },
  getRules() {
    return ApiStatusCollector().get('/entities/rules');
  },
  getSkins() {
    return ApiStatusCollector().get('/entities/skins');
  },
  getEventTypes() {
    return ApiStatusCollector().get('/entities/event-types');
  },
  getEventNames() {
    return ApiStatusCollector().get('/entities/event-names');
  },
  getStatuses() {
    return ApiStatusCollector().get('/entities/statuses');
  },
};
