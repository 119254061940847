import menu from '@/menu';
import { computed } from 'vue';
import useStore from '@/composables/useStore';
import usePermission from '@/composables/usePermission';

function useMenu() {
    interface IMenuItem {
        title: string;
        icon: string;
        subMenu?: any;
        tools?: any;
        allowForMaster?: boolean;
        masterAccountOnly?: boolean;
        childAccountOnly?: boolean;
        hasSomePermission?: string[];
    }

    const { $can, $has } = usePermission();
    const { state, getters } = useStore();

    const currentUser = computed(() => state?.auth.currentUser);
    const masterRole = computed(() => getters['auth/masterRole']);
    const currentAccount = computed(() => getters['auth/currentAccount']);
    const canMasterAccess = computed(() => currentUser.value?.type === 1);

    function checkPermissions(item, type = 'view') {
        const available = Array.isArray(item.permission)
            ? item.permission.reduce((acc, permission) => acc + $has(permission, type), 0)
            : $has(item.permission, type);
        return available > 0;
    }

    function shouldSkipSideBarItem(sideBarItem) {
        return (
          (sideBarItem.onlyServiceMaster && !canMasterAccess.value) ||                 // only service master
          (sideBarItem.allowForMaster && masterRole.value) ||                          // only master
          (sideBarItem.permission && !checkPermissions(sideBarItem))                   // check permissions
        );
    }

    function addToolToItem(item: IMenuItem, menuItem, accessible: boolean): void {
        if(item.tools.some(t => t.name === menuItem.title))  return;

        item.tools.push({
            name: menuItem.title,
            link: menuItem.name,
            accessible,
        });
    }

    const filteredMenu = computed(() => {
        const tempMenu: IMenuItem[] = [...menu];
        const configuredMenu: IMenuItem[] = [];

        tempMenu.forEach((item: IMenuItem) => {

            if((item?.childAccountOnly && !currentAccount.value.parent_id) ||
              (item.masterAccountOnly && currentAccount.value.parent_id)) {
                item.subMenu.shift();
                return;
            }

            item.tools = [];

            // SUB MENU ITEMS
            item.subMenu.forEach((sub) => {

                // delete item if we are in other account (Master account\Child account)
                if((sub.masterAccountOnly && currentAccount.value.parent_id) ||
                  (sub?.childAccountOnly && !currentAccount.value.parent_id )) {
                    return;
                }

                // check permissions
                if (sub.hasSomePermission) {
                    const isAvailable = sub.hasSomePermission.some($can);
                    if (!isAvailable) {
                        addToolToItem(item, sub, false);
                        return;
                    }
                }

                // check allows (Master account)
                if ((sub?.masterAccountOnly && !currentAccount.value.parent_id)) {
                    addToolToItem(item, sub, true);
                    return;
                }

                // check allows (Service account)
                if ((sub?.onlyServiceMaster && !canMasterAccess.value) ||            // allow for service master
                  (sub.permission && !checkPermissions(sub))) {
                    addToolToItem(item, sub, false);
                    return;
                }

                // SIDEBAR MENU ITEMS
                const sidebarItems = sub.sidebarMenu?.items;
                if (sidebarItems) {
                    sidebarItems.forEach((sideBarItem) => {
                        addToolToItem(item, sub, !shouldSkipSideBarItem(sideBarItem));
                    });
                }

                addToolToItem(item, sub, true);

            });
            if(item.tools.length)  configuredMenu.push(item);
        });
        return configuredMenu;
    });

    return {
        filteredMenu,
    };
}

export default useMenu;
