<template>
  <div class="grid grid--direction-column policy-page">
    <h1>Privacy Policy for Visual Tagger browser extension</h1>
    <p>Last updated: 1 April 2023</p>
    <p>The Visual Tagger browser extension (“Visual Tagger”) is operated by AUTODOC SE (Josef-Orlopp-Straße 55, 10365 Berlin, Germany).</p>
    <p>
      This page informs you of our policies regarding the collection, use,
      and disclosure of personal information when you use our Visual Tagger.
    </p>
    <p>
      We understand the importance of user privacy and are committed to maintaining the confidentiality of your information.
      Visual Tagger does not collect, store, or transmit any personal information,
      and this policy is intended to provide transparency about our practices.
    </p>

    <h2>Information Collection and Use</h2>
    <p>
      Visual Tagger does not collect any personally identifiable information, including but not limited to, name,
      email address, telephone number, or other contact information.
      The sole purpose of the Visual Tagger is to visualize the flow of tracking events sent by a 3-rd party web tracking system,
      without gathering, storing, or sending any user data.
    </p>
    <h2>Log Data</h2>
    <p>We do not collect any log data from your use of the Visual Tagger.</p>

    <h2>Cookies</h2>
    <p>Visual Tagger does not use cookies or other tracking technologies to collect or store any user information.</p>

    <h2>Service Providers</h2>
    <p>
      We do not engage any third-party companies or individuals for the purpose of providing the Visual Tagger,
      and therefore, no third-party service providers have access to any personal information.
    </p>

    <h2>Security</h2>
    <p>
      The security of your personal information is important to us, and since Visual Tagger does not collect or store any user data,
      there is no risk of your personal information being compromised through Visual Tagger.
    </p>

    <h2>Changes to This Privacy Policy</h2>
    <p>
      We reserve the right to update or change our Privacy Policy at any time, and you should check this Privacy Policy periodically.
      Your continued use of the Visual Tagger after we post any modifications to the Privacy Policy on this page
      will constitute your acknowledgment of the modifications and your consent to abide and be bound by the modified Privacy Policy.
    </p>
    <p>
      If we make any material changes to this Privacy Policy,
      we will notify you by placing a prominent notice on our website or through the Visual Tagger itself.
    </p>

    <h2>Contact</h2>
    <p>
      If you have questions about this Privacy Policy, please contact our
      Data Protection Officer at <a href="mailto:dpo@autodoc.eu">dpo@autodoc.eu</a>.
      More information on data privacy can be found in our
      <a href="https://www.autodoc.co.uk/services/privacy-policy">General Privacy Policy.</a>
    </p>
  </div>
</template>

<script>
export default {
  name: 'PrivacyPolicy',
};
</script>

<style lang="scss" scoped>
.policy-page {
  width: 85%;
  max-width: 1600px;
  margin: auto;
  padding: 0 2em 2em;

  h1,
  h2 {
    text-align: center;
  }

  h1,
  h2,
  p {
    margin: .5em 0;
  }

  h2 {
    margin-top: 1em;
  }
}
</style>
