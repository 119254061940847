<template>
  <div :class="['mb-3', 'widget-putter__single-stat', blockHovered ? 'widget-putter__single-stat--hovered' : '']">
    <draggable
      v-model="singleStats"
      class="list-group"
      tag="ul"
      v-bind="dragOptions"
      @start="drag = true"
      @end="drag = false"
    >
      <transition-group
        type="transition"
        :name="!drag ? 'flip-list' : null"
      >
        <li
          v-for="(col, index) in singleStats"
          :key="col.order"
          :class="['py-2', 'px-3', 'list-group-item', col.expand ? 'list-group-item--active' : '']"
        >
          <b-row
            no-gutters
            class="grid"
          >
            <div class="grid__col--no-grow draggable">
              <icon
                image="button-with-six-dots.svg"
                class="drag-icon"
                label="Drag column to change order"
                :size="{ height: '12px', width: '12px' }"
              />
            </div>
            <div class="grid__col">
              <kmtx-auto-suggest
                ref="auto-suggest-stat"
                placeholder="Select field for single-stat"
                class="stat-field"
                label=""
                required
                :data="dataSourceFields"
                :value="col.field_name"
                :index="col.order"
                @autosuggest-selected="onSelectHandler($event, col.order)"
              />
            </div>
            <div class="grid__col--no-grow align-self-end">
              <button
                class="list-item-btn stat-configuration-btn"
                type="button"
                @click="expandField(col.order)"
              >
                <icon
                  image="configuration.svg"
                  label="Field configuration"
                  height="15"
                  width="15"
                  :size="{ height: '20px', width: '20px' }"
                />
              </button>
            </div>
          </b-row>
          <b-col
            v-if="col.expand"
            class="my-2 pl-0 list-group-item__details"
          >
            <b-form-group
              label="Title"
              :label-for="'field-name' + col.order"
            >
              <b-form-input
                :id="'field-name' + col.order"
                :value="col.label"
                placeholder="Add field label"
                trim
                autocomplete="off"
                @change="updateStatFields({ label: $event }, col.order)"
              />
            </b-form-group>

            <b-row>
              <b-col>
                <b-form-group
                  label="Aggregation function"
                  :label-for="'aggregation-func-' + col.order"
                >
                  <b-form-select
                    :id="'aggregation-func-' + col.order"
                    required
                    :value="col.aggregation_function"
                    :options="aggregationTypes"
                    value-field="value"
                    text-field="text"
                    @change="updateStatFields({ aggregation_function: $event }, col.order)"
                  >
                    <template #first>
                      <b-form-select-option
                        :value="null"
                        disabled
                      >
                        Select aggregation function
                      </b-form-select-option>
                    </template>
                  </b-form-select>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col class="d-flex justify-content-end">
                <b-button
                  :disable="singleStats.length <= 1"
                  @click="removeStat(index, col.order)"
                >
                  Remove
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </li>
      </transition-group>
    </draggable>

    <b-col>
      <b-button
        ref="filters-add"
        :disabled="singleStats.length >= maxSingleStats"
        variant="outline-primary"
        class="mt-3 px-3"
        block
        @click="addSingleStat()"
      >
        + Add single-stat
      </b-button>
    </b-col>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import { mapState } from 'vuex';
import kmtxAutoSuggest from '../../../../filters/templates/autosuggest/index.vue';
import IconsComponent from '../../../../IconsComponent.vue';

export default {
  name: 'SinglestatForm',
  components: {
    draggable,
    'kmtx-auto-suggest': kmtxAutoSuggest,
    icon: IconsComponent,
  },
  props: {
    widgetConfiguration: {
      type: Object,
      default: () => {},
    },
    dataSources: {
      type: Array,
      default: () => [],
    },
    dataSourceFields: {
      type: Array,
      default: () => [],
    },
    aggregationTypes: {
      type: Array,
      default: () => [],
    },
    blockHovered: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['touch-form', 'change'],
  data() {
    return {
      singleStats: [],
      dragOptions: {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
      },
      drag: false,
    };
  },
  computed: {
    ...mapState('dataMarts', ['selectedWidgetPosition', 'widgets']),
    dataMartId() {
      return this.widgetConfiguration?.data_mart_id;
    },
    maxSingleStats() {
      return Math.floor(8 / this.widgets[this.selectedWidgetPosition[0]].length);
    },
  },
  watch: {
    singleStats(to) {
      const statsChanges = {
        columns: to.map(({ field_name, field_id, aggregation_function }) => ({ field_name, field_id, aggregation_function })),
      };
      const labels = to.filter(({ label }) => label).map(({ field_id, label }) => ({ field_id, label }));
      if (labels.length) {
        statsChanges.labels = labels;
      }
      this.$emit('change', statsChanges);
    },
    dataMartId: {
      handler() {
        this.$refs['auto-suggest-stat'].forEach((col) => {
          col.selected = '';
        });
      },
    },
  },
  created() {
    const { columns, labels = [] } = this.widgetConfiguration;
    if (columns?.length) {
      columns.forEach((col, index) => {
        col.expand = false;
        col.order = index;
        col.label = labels.find((label) => label.field_id === col.field_id)?.label;
      });
      this.singleStats = columns;
    } else {
      this.singleStats.push({ order: 0, aggregation_function: 'uniq' });
    }
  },
  methods: {
    expandField(order) {
      this.$emit('touch-form');
      this.singleStats.forEach((stat) => {
        stat.expand = stat.order === order ? !stat.expand : false;
      });
      this.singleStats.splice(-1, 0);
    },
    removeStat(id) {
      this.singleStats.forEach((stat) => {
        stat.expand = false;
      });
      this.singleStats.splice(-1, 0);
      this.$nextTick().then(() => {
        this.singleStats.splice(id, 1);
      });
    },

    onSelectHandler({ data }, statOrder) {
      const statIndex = this.singleStats.findIndex(({ order }) => order === statOrder);
      const newStat = {
        order: statOrder,
        aggregation_function: this.singleStats[statIndex].aggregation_function,
        expand: this.singleStats[statIndex].expand,
        label: this.singleStats[statIndex].label,
      };
      if (data.name) {
        const { name, id } = this.dataSourceFields.find(({ name }) => name === data.name);
        newStat.field_name = name;
        newStat.field_id = id;
      }
      this.singleStats.splice(statIndex, 1, newStat);
    },
    updateStatFields(fields = {}, statOrder) {
      const statIndex = this.singleStats.findIndex(({ order }) => order === statOrder);
      const newCol = {
        ...this.singleStats[statIndex],
        order: statOrder,
      };
      Object.entries(fields).forEach(([key, value]) => {
        newCol[key] = value;
      });
      this.singleStats.splice(statIndex, 1, newCol);
    },
    addSingleStat() {
      this.$emit('touch-form');
      const order = this.singleStats.map(({ order }) => order).sort((a, b) => b - a)[0] + 1;
      const col = {
        order,
        expand: false,
        aggregation_function: 'uniq',
      };
      this.singleStats.push(col);
    },
  },
};
</script>

<style lang="scss">
@import "@/sass/_variables";

.widget-putter__single-stat {
    margin: 0 -1rem;

    .flip-list-move {
        transition: transform .5s;
    }

    .no-move {
        transition: transform 0s;
    }

    .ghost {
        opacity: .5;
        background: $light-blue-1;
    }

    .list-group-item {
        border: none;
        background-color: transparent;
        transition: .2s background-color;
    }

    &.widget-putter__single-stat--hovered .list-group-item--active {
        background-color: $light-grey-4;
    }

    .list-group-item__details {
        padding-right: 32px;
    }

    .stat-field {
        margin-bottom: 0;

        .kmtx-filter-autosuggest {
            min-width: 0;
        }
    }

    .stat-configuration-btn {
        border-radius: .25rem;
        border: none;
        background-color: transparent;
        transition: .2s background-color;
        color: $light-grey-2;

        &:hover {
            background-color: $light-grey-5;
        }

        svg {
            fill: $grey-1;
        }
    }

    .draggable {
        cursor: move;
        height: auto;
        width: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: -1rem;
    }

    .list-group-item i {
        cursor: pointer;
    }

    .list-item-btn {
        height: 37px;
    }
}
</style>
