<template>
  <b-form-group
    :label="label"
  >
    <b-form-input
      :ref="reference"
      v-model="input"
      v-b-tooltip.hover.bottom
      title="Press enter to apply changes"
      :placeholder="placeholder"
      :type="config.type"
      :disabled="disabled"
      :debounce="config.debounce"
      class="kmtx-filters-item input-text"
      @keyup.enter="onEnter()"
    />
  </b-form-group>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  name: 'KmtxInput',
  props: {
    index: {
      type: Number,
      required: true,
    },
    label: {
      type: String,
      default: 'Input',
    },
    placeholder: {
      type: String,
      default: 'Type something...',
    },
    reference: {
      type: String,
      default: 'kmtx-input',
    },
    filterOptions: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['input-input', 'input-change', 'input-submit', 'input-update'],
  data() {
    return {
      input: null,
    };
  },
  computed: {
    config() {
      return {
        disabled: false,
        type: 'text',
        debounce: 0,
        ...this.filter_options,
      };
    },
    disabled() {
      return this.config.disabled;
    },
  },
  methods: {
    ...mapMutations('newFilters', ['updateFilterByIndex']),
    onEnter() {
      const filter = {
        filter_value: this.input,
        filter_options: { ...this.filter_options },
      };
      this.updateFilterByIndex({ index: this.index, filter });
    },
  },
};
</script>
