<template>
  <div
    class="icon-wrapper grid grid--centered"
    :style="style"
  >
    <inline-svg
      :src="fullImage"
      :aria-label="label"
      v-bind="$attrs"
      class="icon"
      @loaded="onLoaded($event)"
      v-on="$listeners"
    />
  </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg';

const iconsPath = '/icons/';

export default {
  name: 'IconsComponent',
  components: {
    InlineSvg,
  },
  inheritAttrs: false,
  props: {
    image: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: 'icon',
    },
    size: {
      type: Object,
      default() {
        const size = Object.create(null);
        size.width = '30px';
        size.height = '30px';

        return size;
      },
    },
  },
  emits: ['svg-loaded'],
  data() {
    return {
    };
  },
  computed: {
    fullImage() {
      return `${iconsPath}${this.image}`;
    },
    style() {
      return `width: ${this.size.width}; height: ${this.size.height};`;
    },
  },
  methods: {
    onLoaded($event) {
      this.$emit('svg-loaded', $event);
    },
  },
};
</script>
