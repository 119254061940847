<template>
  <b-form-group
    :label="label"
  >
    <date-picker
      :ref="reference"
      v-model="dateOptions"
      v-b-tooltip.hover.bottom
      class="input-datepicker"
      :title="tooltipTitle"
      type="date"
      :placeholder="placeholder"
      :clearable="false"
      :lang="lang"
      :format="format"
      :value="value"
      :disabled="$attrs.disabled"
      :disabled-date="disabledDates"
      @change="onChange"
    />
  </b-form-group>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import { mapMutations, mapState } from 'vuex';
import { getFormattedDate } from '../../../../helper';

export default {
  name: 'KmtxDateSingle',
  components: { DatePicker },
  props: {
    index: {
      type: Number,
      required: true,
    },
    label: {
      type: String,
      default: 'Date',
    },
    placeholder: {
      type: String,
      default: '',
    },
    reference: {
      type: String,
      default: 'kmtx-date-single',
    },
    value: {
      type: String,
      required: false,
      default: undefined,
    },
    filterOptions: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      dateOptions: null,
      lang: {
        formatLocale: {
          firstDayOfWeek: 1,
        },
        monthBeforeYear: true,
      },
      format: 'YYYY-MM-DD',
    };
  },
  computed: {
    ...mapState('dataMarts', ['widgets']),
    disabledDates: {
      get() {
        return (date) => date > new Date();
      },
    },
    tooltipTitle() {
      return this.widgets.find((row) => {
        let line = false;
        row.forEach((widget) => {
          if (widget?.configuration?.report_type === 'line-chart' && !this.filter_options.compare) {
            line = true;
          }
        });
        return line;
      }) ? 'Also picks a week before for line-charts' : '';
    },
  },
  created() {
    if (this.value) {
      this.dateOptions = new Date(this.value);
    }
  },
  methods: {
    ...mapMutations('newFilters', ['updateFilterByIndex']),
    onChange(date) {
      const filter = {
        filter_value: getFormattedDate(date),
        filter_options: { ...this.filter_options },
      };
      this.updateFilterByIndex({ index: this.index, filter });
    },
  },
};
</script>
