import Vue from 'vue';
import Vuex from 'vuex';

import dataSources, { IDataSources } from '@/modules/data-wizard/data-sources.store';
import userManagement, { IUserManagementState } from '@/modules/user-management/user-management.store';
import source from './modules/source';
import report from './modules/report';
import variable from './modules/variable';
import apiTokens from './modules/api-tokens';
import filters from './modules/filters';
import saleDynamic from './modules/sale_dynamic';
import labelCategories from './modules/label_categories';
import router, { IRoute } from './modules/router';
import browsers from './modules/browsers';
import dataMarts from './modules/data_marts';
import request, { IRequestState } from './modules/request';
import loader from './modules/loader';
import newFilters from './modules/new-filters';
import urlRouteSkinTool from './modules/url_route_skin_tool';
import permissions from './modules/permissions';
import auth, { IAuthState } from './modules/auth';
import activityLog, { IActivityLogState } from './modules/activity-log';
import SdkDebugger from './modules/SdkDebugger';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export interface RootState {
    strict: boolean;
    auth: IAuthState;
    activityLog: IActivityLogState;
    request: IRequestState;
    userManagement: IUserManagementState;
    dataSources: IDataSources;
    router: IRoute;
}

export default new Vuex.Store<RootState>({
    modules: {
        router,
        source,
        variable,
        report,
        apiTokens,
        saleDynamic,
        filters,
        labelCategories,
        browsers,
        dataMarts,
        request,
        loader,
        newFilters,
        urlRouteSkinTool,
        userManagement,
        permissions,
        auth,
        activityLog,
        dataSources,
        SdkDebugger,
    },
    strict: debug,
});
