const defaultState = () => ({
  url: '',
  skin: '',
  route: '',
  visits: '',
  events: '',
  sessions: '',
  urlStatExtended: false,
  chartData: [],
  chartDate: null,
  urlExist: null,

  tableRoute: '',
  tableSkin: '',
  tableData: [],
  tableDate: null,
});

const state = {
  url: '',
  skin: '',
  route: '',
  visits: '',
  events: '',
  sessions: '',
  urlStatExtended: false,
  chartData: [],
  chartDate: null,
  urlExist: null,

  tableRoute: '',
  tableSkin: '',
  tableData: [],
  tableDate: null,
};

const mutations = {
  clearRouteSkin(state: any) {
    state.route = '';
    state.skin = '';
  },
  toggleUrlStatExtended(state: any) {
    state.urlStatExtended = !state.urlStatExtended;
  },
  setSingleStats(state: any, data: any = {}) {
    state.visits = data.visits;
    state.events = data.events;
    state.sessions = data.sessions;
  },
  setChartData(state: any, { data, date }: any) {
    state.chartData = data;
    if (date) {
      state.chartDate = date;
    }
  },
  setTableData(state: any, { data, date }: any) {
    state.tableData = data;
    if (date) {
      state.tableDate = date;
    }
  },
  changeValueByRef(state: any, { reference, value }: any) {
    state[reference] = value;
  },
  onUrlChanged(state: any, urlExist: any) {
    state.urlExist = urlExist;
  },
  clearState() {
    Object.assign(state, defaultState());
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
