<template>
  <b-form-group v-if="show">
    <b-button
      :ref="reference"
      :disabled="disabled"
      :variant="config.variant"
      class="kmtx-filter-button"
      @click="onClick"
    >
      <span v-if="value">{{ value }}</span>
      <span v-else>Apply</span>
    </b-button>
  </b-form-group>
</template>

<script>
export default {
  name: 'KmtxButton',
  props: {
    value: {
      type: String,
      default: 'Ok',
    },
    reference: {
      type: String,
      default: 'kmtx-button',
    },
    options: {
      type: Object,
      default: () => ({
      }),
    },
  },
  emits: ['button-submit'],
  data() {
    return {
    };
  },
  computed: {
    config() {
      return {
        variant: 'secondary',
        show: true,
        disabled: false,
        ...this.options,
      };
    },
    show() {
      return this.config.show;
    },
    disabled() {
      return this.config.disabled;
    },
  },
  methods: {
    onClick(event) {
      this.$emit('button-submit', {
        reference: this.reference,
        event,
      });
    },
  },
};
</script>

<style scoped>
    .kmtx-filter-button {
        white-space: nowrap;
    }
</style>
