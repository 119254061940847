<template>
  <div>
    <kmtx-filter-builder
      ref="filters"
      :filters="filtersOptions"
      @select-change="onSelectChange"
      @button-submit="onFilterButtonClick"
      @autosuggest-selected="onAutosuggestSelected"
      @autosuggest-input="onAutosuggestInput"
    />

    <b-row
      v-if="chartInited"
      class="mb-4"
    >
      <b-col
        md="2"
        class="grid-margin stretch-card"
      >
        <div class="card card-statistics">
          <div class="card-body">
            <div class="clearfix">
              <div class="float-left">
                <i class="fa fa-eye text-info icon-lg" />
              </div>
              <div class="float-right">
                <p class="mb-0 text-right">
                  Views
                </p>
                <div class="fluid-container">
                  <h3 class="font-weight-medium text-right mb-0">
                    {{ totalBrandView }}
                  </h3>
                </div>
              </div>
            </div>
            <p class="text-muted mt-3 mb-0">
              <i
                class="mdi mdi-alert-octagon mr-1"
                aria-hidden="true"
              />
            </p>
          </div>
        </div>
      </b-col>
      <b-col
        md="2"
        class="grid-margin stretch-card"
      >
        <div class="card card-statistics">
          <div class="card-body">
            <div class="clearfix">
              <div class="float-left">
                <i class="fa fa-money-check-alt text-success icon-lg" />
              </div>
              <div class="float-right">
                <p class="mb-0 text-right">
                  Order
                </p>
                <div class="fluid-container">
                  <h3 class="font-weight-medium text-right mb-0">
                    {{ totalBrandOrder }}
                  </h3>
                </div>
              </div>
            </div>
            <p class="text-muted mt-3 mb-0">
              <i
                class="mdi mdi-alert-octagon mr-1"
                aria-hidden="true"
              />
            </p>
          </div>
        </div>
      </b-col>
      <b-col
        md="2"
        class="grid-margin stretch-card"
      >
        <div class="card card-statistics">
          <div class="card-body">
            <div class="clearfix">
              <div class="float-left">
                <i class="fa fa-money-check-alt text-success icon-lg" />
              </div>
              <div class="float-right">
                <p class="mb-0 text-right">
                  Conversion
                </p>
                <div class="fluid-container">
                  <h3 class="font-weight-medium text-right mb-0">
                    {{ totalBrandConversion.toFixed(3) }}
                  </h3>
                </div>
              </div>
            </div>
            <p class="text-muted mt-3 mb-0">
              <i
                class="mdi mdi-alert-octagon mr-1"
                aria-hidden="true"
              />
            </p>
          </div>
        </div>
      </b-col>
    </b-row>

    <b-row v-show="chartInited">
      <b-col
        xs="12"
        md="12"
        lg="12"
      >
        <h4 class="h4 text-center">
          Product views
        </h4>
        <kmtx-chart
          ref="brandDynamicChart"
          :options="brandDynamicChartOptions"
          class="brand-dynamic-chart"
        />
      </b-col>

      <b-col
        xs="12"
        md="6"
        lg="6"
      >
        <h4 class="h4 text-center">
          Order by device
        </h4>
        <kmtx-chart
          ref="themeVisitPieChart"
          :options="pipeThemeVisitChart"
          class="visit-pie-chart"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import SaleDynamicService from '../../services/SaleDynamic';
import DictionaryService from '../../services/Dictionary';
import kmtxFilterBuilder from '../filters/builder/index.vue';
import EChartComponent from '../echart/EChartComponent.vue';
import { BarChartOptions } from '../echart/options/bar-chart';
import { PieChartOptions } from '../echart/options/pie-chart';

const debounce = require('just-debounce');

export default {
  name: 'BrandComponent',
  components: {
    // VueTagInput,
    kmtxFilterBuilder,
    'kmtx-chart': EChartComponent,
  },
  data() {
    return {
      filtersOptions: [
        {
          filter: 'datepicker',
          label: 'Dates',
        },
        {
          filter: 'autosuggest',
          label: 'Brand',
          placeholder: 'Type a brand...',
          reference: 'brand',
          options: {
            limit: 20,
          },
        },
        {
          filter: 'domainlist',
          label: 'Domain/Label',
        },
        {
          filter: 'select',
          label: 'Order status',
          reference: 'order-status',
          data: [
            'All', 'Paid', 'Unpaid',
          ],
          value: 'All',
        },
        {
          filter: 'select',
          label: 'Step',
          reference: 'step',
          data: [
            { value: 'auto', text: 'Auto' },
            { value: 'day', text: 'Day' },
            { value: 'week', text: 'Week' },
            { value: 'month', text: 'Month' },
          ],
          value: 'auto',
        },
        {
          filter: 'button',
          value: 'Filter',
          reference: 'filter',
          options: {
            variant: 'info',
          },
        },
        {
          filter: 'button',
          value: 'Export to CSV',
          reference: 'export',
          options: {
            show: false,
          },
        },
      ],
      chartInited: false,
      totalBrandView: 0,
      totalCart: 0,
      totalBrandConversion: 0,
      totalBrandOrder: 0,
      conversionIndex: 0,
      minResolution: 0,
      brands: [],
      reportData: [],
      viewOrderList: [],
      brandData: [],
      tag: '',
      brandIds: [],
      orderList: [],
      brandDynamicChartOptions: {},
      pipeThemeVisitChart: {},
    };
  },
  computed: {
    ...mapGetters('filters', {
      getDateForParams: 'getDateForParams',
      getCurrentDomain: 'getCurrentDomain',
    }),
  },
  watch: {
    reportData(value) {
      if (value.length > 0) {
        this.$refs.filters.setFilterProp(this.filtersOptions, 'export', 'options.show', true);
      } else {
        this.$refs.filters.setFilterProp(this.filtersOptions, 'export', 'options.show', false);
      }
    },
  },
  mounted() {
  },
  created() {
  },
  methods: {
    ...mapMutations('loader', ['addPendingLoader', 'subtractPendingLoader']),
    onAutosuggestSelected(selected) {
      this.brandIds = selected.data.id;
    },
    onAutosuggestInput: debounce(function (input) {
      this.searchBrand(input.data);
    }, 300),
    onSelectChange(data) {
      switch (data.reference) {
        case 'order-status':
          this.orderType = data.value;
          break;
        case 'step':
          this.selectedStep = data.value;
          break;
      }
    },
    onFilterButtonClick(data) {
      switch (data.reference) {
        case 'filter':
          this.setBrandIds();
          break;
        case 'export':
          this.exportToCSV();
          break;
      }
    },
    searchBrand(search) {
      DictionaryService.getDictData('brand', { key: 'name', value: search }).then((res) => {
        this.$refs.filters.setFilterProp(this.filtersOptions, 'brand', 'data', res.data.map((item) => ({ id: item.id, name: `${item.name} (${item.id})` })));
      }).catch((error) => { if (!error.cancelReason) throw error; });
    },
    exportToCSV() {
      const rows = [
        ['Views', 'Orders', 'Conversion', 'Date'],
      ];

      for (let i = 0; i < this.reportData.length; i++) {
        rows.push([
          this.reportData[i].Views,
          this.reportData[i].CountOrder,
          (this.reportData[i].CountOrder / this.reportData[i].Views).toFixed(3),
          this.reportData[i].t,
        ]);
      }
      const csvContent = `data:text/csv;charset=utf-8,${
        rows.map((e) => e.join(',')).join('\n')}`;
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement('a');
      link.setAttribute('href', encodedUri);
      const params = this.setStaticParams();

      link.setAttribute('download', `brand_${this.brandIds}_${params.dateFrom}_${params.dateTo}_sales_dynamic_.csv`);
      document.body.appendChild(link); // Required for FF

      link.click();
    },
    fillChartData() {
      const params = this.setStaticParams();
      if (params.entityIds.length === 0) {
        this.$bvToast.toast('Brand Ids is required', {
          title: 'Error',
          variant: 'danger',
          'no-auto-hide': true,
          solid: true,
        });
        this.subtractPendingLoader();
        return;
      }
      this.subtractPendingLoader();

      const options = { showLoader: true };

      Promise.all([
        this.getBrandData(params, options).then((resp) => {
          this.chartInited = true;
          this.reportData = resp.data.data;
          this.fillBrandColumnChart(resp.data.data);
          this.fillBrandTotal(resp.data.data);
        }),
        this.getOrderBrandByOrder(params, options).then((resp) => {
          this.fillBrandPieChart(resp.data);
        }),
      ]).catch((error) => { if (!error.cancelReason) throw error; });
    },
    fillBrandColumnChart(data) {
      let maxViews = 0;

      for (const i in data) {
        if (data[i].Views > maxViews) {
          maxViews = data[i].Views;
        }
      }
      this.conversionIndex = maxViews * 0.5;

      const chartOptions = {
        toolbox: {
          show: true,
        },
        xAxis: {
          type: 'time',
        },
        series: [
          {
            id: 'Views',
            name: 'Views',
          },
          {
            id: 'Order',
            name: 'Order',
          },
          {
            id: 'Conversion',
            name: 'Conversion',
            type: 'line',
          },
        ],
      };
      const chartData = {
        columns: [],
        slices: {

        },
      };

      data.forEach((item) => {
        chartData.slices.Views = chartData.slices.Views || [];
        chartData.slices.Views.push(item.Views);
        chartData.slices.Order = chartData.slices.Order || [];
        chartData.slices.Order.push(item.CountOrder);
        chartData.slices.Conversion = chartData.slices.Conversion || [];
        const conversion = +((item.CountOrder / item.Views) * this.conversionIndex).toFixed(5);
        chartData.slices.Conversion.push(conversion);
        chartData.columns.push(item.t);
      });

      this.brandDynamicChartOptions = new BarChartOptions(chartData, chartOptions);
    },
    fillBrandTotal(data) {
      this.totalBrandView = this.totalBrandOrder = 0;
      for (const i in data) {
        const d = data[i];
        this.totalBrandView += d.Views;
        this.totalBrandOrder += d.CountOrder;
      }
      if (this.totalBrandView > 0) {
        this.totalBrandConversion = this.totalBrandOrder / this.totalBrandView;
      } else {
        this.totalBrandConversion = 0;
      }
    },
    fillBrandPieChart(data) {
      data = {
        labels: Object.keys(data),
        slices: {
          visits: Object.values(data),
        },
      };
      this.pipeThemeVisitChart = new PieChartOptions(data);
    },
    setBrandIds() {
      this.addPendingLoader();
      setTimeout(this.fillChartData, 500);
    },
    getBrandData(params, options) {
      return SaleDynamicService.getGenericData(params, options).then((response) => {
        this.brandData = response.data.data;
        this.minResolution = response.headers['x-grid-resolution'];
        return response;
      }).catch((error) => { if (!error.cancelReason) throw error; });
    },
    getOrderBrandByOrder(params, options) {
      return SaleDynamicService.getOrderGenericByDevice(params, options).then((response) => {
        this.brandData = response.data.data;
        return response;
      }).catch((error) => { if (!error.cancelReason) throw error; });
    },
    setStaticParams(params) {
      params = params || {};
      params.label_source = [this.getCurrentDomain.id];
      params.label_type = this.getCurrentDomain.labelType;

      params.dateFrom = this.getDateForParams.dateFrom;
      params.dateTo = this.getDateForParams.dateTo;
      params.r = this.selectedStep;
      params.orderType = this.orderType;
      params.type = 'brand';
      params.entityIds = this.brandIds || '';
      return params;
    },
  },
};
</script>
