import RoutingViewContainer from '@/components/RoutingViewContainer.vue';

const LayoutListPages = () => import(/* webpackChunkName: "[LayoutListPages]" */ './pages/LayoutListPages.vue');
const LayoutUnitPage = () => import(/* webpackChunkName: "[LayoutEntityPage]" */ './pages/LayoutUnitPage.vue');
const LayoutUnitPageTabs = () => import(/* webpackChunkName: "[LayoutEntityPageTabs]" */ './pages/LayoutUnitPageTabs.vue');
const LayoutEntityEdit = () => import(/* webpackChunkName: "[LayoutEntityPage]" */ './pages/LayoutEntityEdit.vue');
const LayoutEntityCreate = () => import(/* webpackChunkName: "[LayoutEntityPage]" */ './pages/LayoutEntityCreate.vue');

export default {
    path: '/layout-demo',
    component: RoutingViewContainer,
    name: 'Layout Demo',
    redirect: { name: 'layout-list' },
    children: [
        {
            path: 'layout-list',
            component: LayoutListPages,
            name: 'layout-list',
            meta: {
                isPublic: true,
                browserTitle: 'layout-list',
            },
        },
        {
            path: 'layout-unit-page',
            component: LayoutUnitPage,
            name: 'layout-unit-page',
            meta: {
                isPublic: true,
                browserTitle: 'layout-unit',
            },
        },
        {
            path: 'layout-unit-page-tabs',
            component: LayoutUnitPageTabs,
            name: 'layout-unit-page-tabs',
            meta: {
                isPublic: true,
                browserTitle: 'layout-unit-tabs',
            },
        },

        {
            path: 'layout-entity-edit/:id?',
            component: LayoutEntityEdit,
            name: 'layout-entity-edit',
            meta: {
                isPublic: true,
                browserTitle: 'layout-entity-edit',
            },
        },
        {
            path: 'layout-entity-create',
            component: LayoutEntityCreate,
            name: 'layout-entity-create',
            meta: {
                isPublic: true,
                browserTitle: 'layout-entity-create',
            },
        },
    ],
};
