<template>
  <div :class="['mb-3', 'widget-putter__table', blockHovered ? 'widget-putter__table--hovered' : '']">
    <div :class="['py-1', 'multiselect-group', groupByExpand ? 'multiselect-group--active' : '']">
      <b-col class="grid">
        <div class="grid__col">
          <b-form-group
            id="multiselect-from-group"
            class="mb-3"
            label="Group by"
            label-for="group-by-select"
          >
            <multiselect
              id="group-by-select"
              v-model="groupBy"
              :multiple="true"
              :options="dataSourceFields"
              label="name"
              track-by="name"
              @input="$emit('touch-form')"
            >
              <template #noResult>
                <span> No elements found. </span>
              </template>
            </multiselect>
          </b-form-group>
        </div>
        <div
          class="grid__col--no-grow align-self-end mb-3"
        >
          <button
            class="list-item-btn stat-configuration-btn"
            type="button"
            @click="expandField(undefined, true)"
          >
            <icon
              image="configuration.svg"
              label="Field configuration"
              height="15"
              width="15"
              :size="{ height: '20px', width: '20px' }"
            />
          </button>
        </div>
      </b-col>

      <div
        v-if="groupByExpand"
        class="multiselect-group__details"
      >
        <b-col
          v-for="(col, index) in groupBy"
          :key="index"
        >
          <b-form-group
            :label="'Label for ' + col.name"
            :label-for="'group-label' + index"
          >
            <b-form-input
              :id="'group-label' + index"
              v-model="col.label"
              placeholder="Add column label"
              trim
              autocomplete="off"
              class="mb-1"
            />
            <b-form-checkbox
              v-if="groupBy.length > 1"
              :id="'nested-group-by-' + col.id"
              v-model="nestedGroupBy"
              :value="col.id"
              :unchecked-value="null"
              name="exportable-checkbox"
              @change="emitChanges"
            >
              Nested grouping
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </div>
    </div>

    <draggable
      v-model="tableColumns"
      class="list-group"
      tag="ul"
      v-bind="dragOptions"
      @start="drag = true"
      @end="drag = false"
    >
      <transition-group
        type="transition"
        :name="!drag ? 'flip-list' : null"
      >
        <li
          v-for="(col, index) in tableColumns"
          :key="col.order"
          :class="['py-2', 'px-3', 'list-group-item', col.expand ? 'list-group-item--active' : '']"
        >
          <b-row
            no-gutters
            class="grid"
          >
            <div class="grid__col--no-grow draggable">
              <icon
                image="button-with-six-dots.svg"
                class="drag-icon"
                label="Drag column to change order"
                :size="{ height: '12px', width: '12px' }"
              />
            </div>
            <div class="grid__col">
              <kmtx-auto-suggest
                ref="auto-suggest-column"
                placeholder="Select column field"
                class="column-field"
                label=""
                required
                :value="col.field_name"
                :data="dataSourceFields"
                :index="col.order"
                @autosuggest-selected="onSelectHandler($event, col.order)"
              />
            </div>
            <div class="grid__col--no-grow align-self-end">
              <button
                class="list-item-btn stat-configuration-btn remove-btn"
                type="button"
                @click="expandField(col.order)"
              >
                <icon
                  image="configuration.svg"
                  label="Field configuration"
                  height="15"
                  width="15"
                  :size="{ height: '20px', width: '20px' }"
                />
              </button>
            </div>
          </b-row>
          <b-col
            v-if="col.expand"
            class="my-2 pl-0 list-group-item__details"
          >
            <b-form-group
              label="Label"
              :label-for="'field-name' + col.order"
            >
              <b-form-input
                :id="'field-name' + col.order"
                :value="col.label"
                placeholder="Add column label"
                trim
                autocomplete="off"
                @change="updateStatFields({ label: $event }, col.order)"
              />
            </b-form-group>

            <b-form-group
              label="Aggregation function"
              :label-for="'aggregation-func-' + col.order"
            >
              <b-form-select
                :id="'aggregation-func-' + col.order"
                required
                :value="col.aggregation_function"
                :options="aggregationTypes"
                value-field="value"
                text-field="text"
                @change="updateStatFields({ aggregation_function: $event }, col.order)"
              >
                <template #first>
                  <b-form-select-option
                    :value="null"
                    disabled
                  >
                    Select aggregation function
                  </b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>

            <b-form-group
              v-if="nestedGroupBy"
              label="Aggregation function for nested row"
              :label-for="'row-aggregation-func-' + col.order"
            >
              <b-form-select
                :id="'row-aggregation-func-' + col.order"
                required
                :value="col.children_group_aggregation_function"
                :options="aggregationTypes"
                value-field="value"
                text-field="text"
                @change="updateStatFields({ children_group_aggregation_function: $event }, col.order)"
              >
                <template #first>
                  <b-form-select-option
                    :value="null"
                    disabled
                  >
                    Select row aggregation function
                  </b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>

            <b-row>
              <b-col class="d-flex justify-content-end">
                <b-button
                  :disabled="tableColumns.length <= 1"
                  @click="removeColumn(index)"
                >
                  Remove
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </li>
      </transition-group>
    </draggable>
    <b-col>
      <b-button
        ref="filters-add"
        variant="outline-primary"
        class="mt-3 px-3"
        block
        @click="addTableColumn()"
      >
        + Add column
      </b-button>
    </b-col>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import Multiselect from 'vue-multiselect';
import KmtxAutoSuggest from '../../../../filters/templates/autosuggest/index.vue';
import IconsComponent from '../../../../IconsComponent.vue';

export default {
  name: 'TableForm',
  components: {
    draggable,
    'kmtx-auto-suggest': KmtxAutoSuggest,
    icon: IconsComponent,
    multiselect: Multiselect,
  },
  props: {
    widgetConfiguration: {
      type: Object,
      default: () => {},
    },
    dataSources: {
      type: Array,
      default: () => [],
    },
    dataSourceFields: {
      type: Array,
      default: () => [],
    },
    aggregationTypes: {
      type: Array,
      default: () => [],
    },
    blockHovered: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['touch-form', 'change'],
  data() {
    return {
      tableColumns: [],
      dragOptions: {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
      },
      drag: false,
      groupByExpand: false,
      groupBy: [],
      nestedGroupBy: null,
    };
  },
  computed: {
    tableFields() {
      const labels = [];
      const groupColumns = this.groupBy.map(({ id: field_id, name: field_name, label }) => {
        if (label) {
          labels.push({ field_id, label });
        }
        return { field_name, field_id };
      });
      const aggregatedColumns = this.tableColumns.map(({
        field_name, field_id, aggregation_function, children_group_aggregation_function, label,
      }) => {
        if (label) {
          labels.push({ field_id, label });
        }
        const col = { field_name, field_id, aggregation_function };
        if (this.nestedGroupBy) {
          col.children_group_aggregation_function = children_group_aggregation_function;
        }
        return col;
      });
      const fields = { columns: [...groupColumns, ...aggregatedColumns] };
      if (labels.length) {
        fields.labels = labels;
      }
      return fields;
    },
    dataMartId() {
      return this.widgetConfiguration?.data_mart_id;
    },
  },
  watch: {
    tableFields: 'emitChanges',
    dataMartId: {
      handler() {
        this.tableColumns = [{ order: 0 }];
        this.$refs['auto-suggest-column'].forEach((col) => {
          col.selected = '';
        });
      },
    },
    groupBy(to) {
      if (!to.length && this.groupByExpand) {
        this.groupByExpand = false;
      }
    },
    nestedGroupBy(to, from) {
      if (!from) {
        this.tableColumns.forEach((col) => {
          col.children_group_aggregation_function = col.children_group_aggregation_function || 'uniq';
        });
      }
    },
  },
  created() {
    const { columns, labels = [] } = this.widgetConfiguration;
    if (columns?.length) {
      const aggregatedCols = [];
      const groupedCols = [];
      columns.forEach((col) => {
        if (col.aggregation_function) {
          col.expand = false;
          col.label = labels.find((label) => label.field_id === col.field_id)?.label;
          aggregatedCols.push(col);
        } else {
          groupedCols.push({
            name: col.field_name,
            id: col.field_id,
            label: labels.find((label) => label.field_id === col.field_id)?.label,
          });
        }
      });
      aggregatedCols.forEach((col, index) => col.order = index);
      this.tableColumns = aggregatedCols;
      this.groupBy = groupedCols;
      this.nestedGroupBy = this.getNestedGroupById(this.widgetConfiguration.children_group_field_id);
    } else {
      this.tableColumns.push({ order: 0, aggregation_function: 'uniq', children_group_aggregation_function: 'uniq' });
    }
  },
  methods: {
    emitChanges() {
      this.$emit('change', { ...this.tableFields, children_group_field_id: this.getNestedGroupById() });
    },
    getNestedGroupById(id) {
      this.nestedGroupBy = this.groupBy.find((field) => (field.id === id || field.id === this.nestedGroupBy) && this.groupBy.length > 1)?.id;
      return this.nestedGroupBy;
    },
    /**
         * Toggles details of table columns details and multiselect details
         *
         * @param { undefined || number } order - regular column order
         * @param { boolean } multiGroup - flag if should expand multiselect details
         */
    expandField(order = undefined, multiGroup = false) {
      this.$emit('touch-form');
      this.tableColumns.forEach((stat) => {
        stat.expand = stat.order === order && !multiGroup ? !stat.expand : false;
      });
      if (multiGroup && this.groupBy.length) {
        this.groupByExpand = !this.groupByExpand;
      }
      if (!multiGroup) {
        this.groupByExpand = false;
        this.tableColumns.splice(-1, 0);
      }
    },
    removeColumn(id) {
      this.tableColumns.forEach((stat) => {
        stat.expand = false;
      });
      this.tableColumns.splice(-1, 0);
      this.$nextTick().then(() => {
        this.tableColumns.splice(id, 1);
      });
    },
    onSelectHandler({ data }, colOrder) {
      const colIndex = this.tableColumns.findIndex(({ order }) => order === colOrder);
      const newCol = {
        order: colOrder,
        aggregation_function: this.tableColumns[colIndex].aggregation_function,
        children_group_aggregation_function: this.tableColumns[colIndex].children_group_aggregation_function,
        expand: this.tableColumns[colIndex].expand,
        label: this.tableColumns[colIndex].label,
      };
      if (data.name) {
        const { name, id } = this.dataSourceFields.find(({ name }) => name === data.name);
        newCol.field_name = name;
        newCol.field_id = id;
      }
      this.tableColumns.splice(colIndex, 1, newCol);
    },
    updateStatFields(fields = {}, colOrder) {
      const colIndex = this.tableColumns.findIndex(({ order }) => order === colOrder);
      const newCol = {
        ...this.tableColumns[colIndex],
        order: colOrder,
      };
      Object.entries(fields).forEach(([key, value]) => {
        newCol[key] = value;
      });
      this.tableColumns.splice(colIndex, 1, newCol);
    },
    addTableColumn() {
      const ordersDesc = this.tableColumns.map(({ order }) => order).sort((a, b) => b - a);
      const order = ordersDesc.length ? ordersDesc[0] + 1 : 0;
      const col = {
        order,
        expand: false,
        aggregation_function: 'uniq',
        children_group_aggregation_function: 'uniq',
      };
      this.tableColumns.push(col);
    },
  },
};
</script>

<style lang="scss">
@import "@/sass/_variables";

.widget-putter__table {
    margin: 0 -1rem;

    .flip-list-move {
        transition: transform .5s;
    }

    .no-move {
        transition: transform 0s;
    }

    .ghost {
        opacity: .5;
        background: $light-blue-1;
    }

    .list-group-item {
        border: none;
    }

    .list-group-item,
    .multiselect-group {
        background-color: transparent;
        transition: .2s background-color;
    }

    &.widget-putter__table--hovered .list-group-item--active,
    &.widget-putter__table--hovered .multiselect-group--active {
        background-color: $light-grey-4;
    }

    .list-group-item__details,
    .multiselect-group__details {
        padding-right: 32px;
    }

    .column-field {
        margin-bottom: 0;

        .kmtx-filter-autosuggest {
            min-width: 0;
        }
    }

    .stat-configuration-btn {
        border-radius: .25rem;
        border: none;
        background: transparent;
        transition: .2s background-color;
        color: $light-grey-2;

        &:hover {
            background-color: $light-grey-5;
        }

        svg {
            fill: $grey-1;
        }
    }

    .draggable {
        cursor: move;
        height: auto;
        width: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: -1rem;
    }

    .list-group-item i {
        cursor: pointer;
    }

    .list-item-btn {
        height: 37px;
    }
}
</style>
