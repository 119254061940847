import { ApiV1 } from '@/api';

export default {
  createUser(data: any, options: any = {}) {
    options.cantCancel = true;
    return ApiV1(options).post('/users', data);
  },
  createAccount(data: any) {
    return ApiV1({ cantCancel: true }).post('/accounts', data);
  },
  resetPassword(data: any, options: any = {}) {
    options.cantCancel = true;
    return ApiV1(options).post('/password/create', data);
  },
  updateUser(id: any, params: any) {
    return ApiV1({ cantCancel: true }).put(`/users/${id}`, params);
  },
  updateAccount(id: any, params: any) {
    return ApiV1({ cantCancel: true }).put(`/accounts/${id}`, params);
  },
  getUsers(params: any, options: any) {
    return ApiV1(options).get('/users', { params });
  },
  getAccounts(params: any) {
    return ApiV1().get('/accounts', { params });
  },
  getRoles(params: any) {
    return ApiV1().get('/roles', { params });
  },
  getUser(id: any) {
    return ApiV1().get(`/users/${id}`);
  },
  getCurrentUser() {
    return ApiV1({ cantCancel: true }).get(`/users/current`);
  },
};
