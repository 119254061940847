<template>
  <div
    v-if="visible"
    class="sidebar"
  >
    <div class="sidebar__wrapper">
      <div class="sidebar__navigation">
        <div class="sidebar__filter">
          <img src="/icons/menu/icon-filter.svg">
          <span>All</span>
        </div>
        <img
          src="/icons/menu/icon-search.svg"
          class="icon-search"
        >
      </div>
      <ul class="sidebar__list">
        <li
          v-for="item in menu.items"
          :key="item.id"
          class="sidebar__item"
        >
          <router-link
            :to="item.name"
            class="sidebar__link"
          >
            {{ item.title }}
          </router-link>
        </li>
      </ul>
    </div>

    <div
      v-if="menu.buttons"
      class="sidebar__buttons"
    >
      <div
        v-if="menu.buttons.altButtons"
        class="sidebar__alt-btn"
      >
        <btn
          v-for="(btn, i) in menu.buttons.altButtons"
          :key="btn.title + i"
          :class="btnClasses(btn)"
          :text="btn.title"
          :icon-file="btn.icon"
          @button-click="menu.buttons.mainButton.action()"
        />
      </div>

      <div
        v-if="menu.buttons.mainButton"
        class="sidebar__main-btn"
      >
        <btn
          :class="btnClasses(menu.buttons.mainButton)"
          :text="menu.buttons.mainButton.title"
          :icon-file="menu.buttons.mainButton.icon"
          @button-click="menu.buttons.mainButton.action()"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Button from './ui/buttons/button';

export default {
  name: 'MenuSidebar',
  components: {
    btn: Button,
  },
  props: {
    menu: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  computed: {
    visible() {
      return this.menu?.items?.length;
    },
  },
  methods: {
    btnClasses(btn) {
      const classes = ['sidebar__btn', 'w100', 'kmtx-button--sm', 'kmtx-button--text-left', 'kmtx-button--text-regular'];
      btn?.modifiers?.map((modifier) => {
        classes.push(`kmtx-button--${modifier}`);
      });
      return classes;
    },
  },
};
</script>

<style lang="scss">
@import "@/sass/_variables";

    .sidebar {
        $sidebar: &;

        display: flex;
        flex-wrap: nowrap;
        flex-direction: column;
        justify-content: space-between;
        background: $color-white;
        position: fixed;
        z-index: 900;
        left: 0;
        top: 0;
        width: 260px;
        height: 100vh;
        padding: 56px 18px 0 18px;
        border-right: 1px solid $gray200;

        &__navigation {
            height: 66px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: $color-secondary;
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;

            .icon-search {
                cursor: pointer;
            }
        }

        &__filter {
            cursor: pointer;

            img {
                margin-right: 5px;
            }

            span {
                position: relative;
                top: -2px;
                vertical-align: sub;
            }
        }

        &__list {
            padding: 0;
            list-style-type: none;
        }

        &__item {
            display: block;
            text-decoration: none;
            list-style-type: none;
            cursor: pointer;
            transition: all .3s ease;
            border-radius: 5px;

            &:hover {
                background: var(--k-tab-hover-bg);
            }
        }

        &__link {
            display: block;
            text-decoration: none;
            color: $gray700;
            font-size: 14px;
            line-height: 18px;
            padding: 10px;
            font-weight: 500;
            border-radius: 5px;

            &:hover,
            &:focus,
            &:visited {
                color: $gray700;
                text-decoration: none;
                background: var(--k-tab-hover-bg);
            }

            &.router-link-active,
            &.router-link-exact-active {
                font-weight: bold;
                color: $gray700;
                background: var(--k-tab-hover-bg);
            }
        }

        &__btn {
            margin-bottom: 18px;
        }

        &__main-btn {
            padding: 18px 18px 0 0;
            margin-right: -18px;
            border-top: 1px solid $gray200;
        }
    }
</style>
