import { ActionTree, Module, MutationTree } from 'vuex';
import User from '@/services/User';
import { RootState } from '..';
import { IUser } from './auth';

export interface IActivityLogState {
    users: IUser[];
}

const state = {
    users: [],
};

const actions: ActionTree<IActivityLogState, RootState> = {
    fetchUsers({ commit }, isLoaderShown = true) {
        User.getUsers({ perPage: 1000 }, { showLoader: isLoaderShown }).then((resp) => {
            if (resp.data.data) {
                commit('setUsers', resp.data.data);
            }
        });
    },
    clearUsers({ commit }) {
        commit('clearUsers');
    },
};

const mutations: MutationTree<IActivityLogState> = {
    setUsers(state: IActivityLogState, payload: IUser[]) {
        state.users.splice(0, state.users.length, ...payload);
    },
    clearUsers(state: IActivityLogState) {
        state.users.splice(0, state.users.length);
    },
};

const activityLogModule: Module<IActivityLogState, RootState> = {
    namespaced: true,
    state,
    actions,
    mutations,
};

export default activityLogModule;
