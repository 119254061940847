export const LABEL_LIST_SORTING = {
    sort_by: '',
    sort_direction: 'desc',
};

export const PER_PAGE_OPTIONS = [15, 25, 50];

export const LABEL_LIST_TABLE_FIELDS = [
    {
        key: 'name',
        label: 'Label Name',
        width: 30,
    },
    {
        key: 'creator',
        label: 'Creator',
        width: 30,
    },
    {
        key: 'created_at',
        label: 'Time of Creation / Last update',
        width: 30,
    },
    {
        key: 'actions',
        label: 'Actions',
        width: 10,
    },
];
