<template>
  <div class="test-monitoring">
    <kmtx-filter-builder
      ref="filters"
      :filters="filtersOptions"
      @date-single-change="changeDate"
      @autosuggest-selected="onAutosuggestSelected"
      @button-submit="fillChartData"
    />

    <b-row>
      <e-chart
        v-if="chartHasData"
        ref="eventHeatMapTimeline"
        :options="heatMapOptions"
        :init-options="initOptions"
        class="heatmap"
      />
      <big-numbers
        v-else
        class="ml-3 mb-5 mt-3"
      >
        <big-number
          value="No chart data"
          variant="danger"
        >
          <template #header>
            Error
          </template>
        </big-number>
      </big-numbers>
    </b-row>
  </div>
</template>

<script>
import ECharts from 'vue-echarts';
import { mapMutations, mapGetters } from 'vuex';
import kmtxFilterBuilder from './filters/builder/index.vue';
import TestMonitoring from '../services/TestMonitoring';
import { getFormattedDate } from '../helper';
import Toast from '../mixins/Toast.vue';
import bigNumber from './widgets/bigNumber/index.vue';
import bigNumbers from './widgets/bigNumbers/index.vue';

export default {
  name: 'TestMonitoring',
  components: {
    kmtxFilterBuilder,
    bigNumbers,
    bigNumber,
    'e-chart': ECharts,
  },
  mixins: [
    Toast,
  ],
  data() {
    return {
      filtersOptions: [
        {
          filter: 'autosuggest',
          label: 'Skin',
          placeholder: 'Select skin...',
          reference: 'skins',
          options: {
            limit: 20,
          },
        },
        {
          filter: 'domainlist',
          label: 'Domain/Label',
        },
        {
          filter: 'date-single',
          label: 'Date',
          value: null,
          type: 'datetime',
          options: {
            splitTime: true,
          },
        },
        {
          filter: 'autosuggest',
          label: 'Rule',
          placeholder: 'Type a rule...',
          reference: 'rules',
          options: {
            limit: 20,
          },
        },
        {
          filter: 'autosuggest',
          label: 'Event Name',
          placeholder: 'Type an event name...',
          reference: 'eventNames',
          options: {
            limit: 20,
          },
        },
        {
          filter: 'autosuggest',
          label: 'Event Type',
          placeholder: 'Type an event type...',
          reference: 'eventTypes',
          options: {
            limit: 20,
          },
        },
        {
          filter: 'button',
          value: 'Apply',
          reference: 'filter',
          options: {
            variant: 'info',
          },
        },
      ],
      initOptions: {
        renderer: 'canvas',
      },
      heatMapOptions: {
        title: [
          {
            top: '50%',
            left: '0',
            text: '',
          },
        ],
        tooltip: {
          position: 'top',
          triggerOn: 'mousemove',
        },
        xAxis: {
          type: 'category',
          splitArea: {
            show: true,
          },
        },
        yAxis: {
          type: 'category',
          splitArea: {
            show: true,
          },
        },
        grid: [
          {
            containLabel: true,
            left: '2%',
            right: '2%',
            bottom: '53%',
            top: '30px',
          },
          {
            containLabel: true,
            top: '53%',
            left: '2%',
            right: '2%',
          },
        ],
        visualMap: {
          orient: 'horizontal',
          left: 'center',
          top: 'top',
          type: 'piecewise',
          pieces: [
            { value: -1, label: 'undefined', color: '#999999' },
            { value: 0, label: 'failed', color: '#ff9f9f' },
            { value: 1, label: 'passed', color: '#9fdc9f' },
            { value: 2, label: 'unknown domain', color: '#ffe162' },
            { value: 3, label: 'absent rules', color: '#ffa84f' },
            { value: 4, label: 'absent events', color: '#ffa84f' },
            { value: 5, label: 'absent url', color: '#ffa84f' },
          ],
        },
        series: [
          {
            type: 'heatmap',
            data: [],
            itemStyle: {
              emphasis: {
                borderColor: '#333',
                borderWidth: 1,
              },
            },
            tooltip: {
              formatter({ data }) {
                return `${data.testRunDate ? `${data.testRunDate}<br>` : ''}
                                        Id: ${data.testId} <br>
                                        Rule: ${data.rule} <br>
                                        Event: ${data.eventType} - ${data.eventName} <br>
                                        Source: ${data.skin} - ${data.domainName} <br>`;
              },
            },
          },
        ],
      },
      maxRowWidth: 200,
      testStatuses: {
        UNDEFINED: -1,
        FAILED: 0,
        PASSED: 1,
        UNKNOWN_DOMAIN: 2,
        ABSENT_RULES: 3,
        ABSENT_EVENTS: 4,
        ABSENT_URL: 5,
      },
      rules: [],
      eventNames: [],
      eventTypes: [],
      rulesSelect: null,
      eventNamesSelect: null,
      eventTypesSelect: null,
      skinsSelect: null,
      selectedDate: null,
    };
  },
  computed: {
    ...mapGetters('filters', ['getCurrentDomain']),
    params() {
      return {
        rule: this.rulesSelect,
        date: this.selectedDate,
        event: this.eventNamesSelect,
        event_type: this.eventTypesSelect,
        domain: this.getCurrentDomain.name,
        skin: this.skinsSelect,
      };
    },
    chartHasData() {
      return this.heatMapOptions.series[0].data.length;
    },
  },
  created() {
    this.addPendingRequest();
    TestMonitoring.getLastEntities().then((resp) => {
      if (resp.data.length > 0) {
        this.getStatuses().finally(() => {
          this.setChartData(resp.data);
        });
      }
    }).catch((error) => {
      if (!error.cancelReason) throw error;
    }).finally(() => this.subtractPendingRequest());
    Promise.all([
      this.getDates(),
      this.getRules(),
      this.getEventNames(),
      this.getEventTypes(),
      this.getSkins(),
    ]).catch((error) => { if (!error.cancelReason) throw error; });
  },
  methods: {
    ...mapMutations('filters', ['updateAvailableSingleDates']),
    ...mapMutations('loader', ['addPendingRequest', 'subtractPendingRequest']),
    fillChartData() {
      const params = {};
      Object.keys(this.params).forEach((key) => {
        if ((this.params[key] !== null && key !== 'domain') || (key === 'domain' && this.params[key] !== 'All')) {
          params[key] = this.params[key];
        }
      });
      if (!params.date) {
        this.danger('Select required fields!', 'Please select date');
        return;
      }
      this.addPendingRequest();
      TestMonitoring.getEntities(params).then((resp) => {
        if (resp.data) {
          this.setChartData(resp.data);
        }
      }).catch((error) => {
        if (!error.cancelReason) throw error;
      }).finally(() => this.subtractPendingRequest());
    },
    getStatuses() {
      return TestMonitoring.getStatuses().then((resp) => {
        if (resp?.data) {
          const pieces = resp.data.map((status, ind) => ({ ...status, value: ind, label: status.value.toUpperCase() }));
          this.heatMapOptions.visualMap.pieces.splice(0, this.heatMapOptions.visualMap.pieces.length, ...pieces);
          this.testStatuses = {};
          pieces.forEach((piece) => {
            this.testStatuses[piece.label] = piece.value;
          });
        }
      }).catch((error) => {
        this.errorMessage(error, `Cant get statuses`);
      });
    },
    getDates() {
      TestMonitoring.getDates().then((resp) => {
        if (resp.data) {
          this.updateAvailableSingleDates(resp?.data);
        }
      });
    },
    getRules() {
      TestMonitoring.getRules().then((resp) => {
        this.rules = this.mapToAutoSuggest(resp?.data);
        this.updateAutosuggestData({ data: '', reference: 'rules' });
      });
    },
    getEventNames() {
      TestMonitoring.getEventNames().then((resp) => {
        this.eventNames = this.mapToAutoSuggest(resp?.data);
        this.updateAutosuggestData({ data: '', reference: 'eventNames' });
      });
    },
    getEventTypes() {
      TestMonitoring.getEventTypes().then((resp) => {
        this.eventTypes = this.mapToAutoSuggest(resp?.data);
        this.updateAutosuggestData({ data: '', reference: 'eventTypes' });
      });
    },
    getSkins() {
      TestMonitoring.getSkins().then((resp) => {
        this.skins = this.mapToAutoSuggest(resp?.data);
        this.updateAutosuggestData({ data: '', reference: 'skins' });
      });
    },
    mapToAutoSuggest(data = []) {
      return data.map((item) => ({ id: item, name: item }));
    },
    changeDate({ date }) {
      this.selectedDate = getFormattedDate(date, true);
    },
    onAutosuggestSelected({ data, reference }) {
      this[`${reference}Select`] = data.id;
    },
    updateAutosuggestData({ data, reference }) {
      this.$refs.filters.setFilterProp(this.filtersOptions, reference, 'data', this[reference].filter((item) => item.name.includes(data)));
    },
    setChartData(data) {
      let y = 0;
      let x = 0;
      data.forEach((item) => {
        if (x >= this.maxRowWidth) {
          y += 1;
          x = 0;
        }
        item.value = [x, y, this.testStatuses[item.testStatus]];
        x += 1;
      });
      this.heatMapOptions.series[0].data = data;
    },
  },
};
</script>
