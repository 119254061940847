import { render, staticRenderFns } from "./LinkWithPermission.vue?vue&type=template&id=449c67c9&scoped=true&"
import script from "./LinkWithPermission.vue?vue&type=script&lang=ts&setup=true&"
export * from "./LinkWithPermission.vue?vue&type=script&lang=ts&setup=true&"
import style0 from "./LinkWithPermission.vue?vue&type=style&index=0&id=449c67c9&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "449c67c9",
  null
  
)

export default component.exports