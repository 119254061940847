import { Ref, ref } from 'vue';

function useState<T>(initialState?: T): [Ref<T>, (newState: T) => void] {
    const state = ref<T | undefined>(initialState) as Ref<T>;
    const setState = (newState: T) => {
        state.value = newState;
    };

    return [state, setState];
}

export default useState;
