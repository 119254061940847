function setQueryParamsForDataSources(to, from, next) {
    const defaultQueryParams = {
        tab: 0,
        search_value: '',
        env: 0,
        state: 0,
        visits: 0,
        per_page: 25,
        page: 1,
        labels: '',
    };

    const queryParamsKeys = Object.keys(to.query);

    if (Object.keys(defaultQueryParams).every((key) => queryParamsKeys.includes(key))) {
        next();

        return;
    }

    if (queryParamsKeys.length) {
        next({
            name: 'data-sources',
            query: {
                ...defaultQueryParams,
                ...to.query,
            },
        });

        return;
    }

    next({
        name: 'data-sources',
        query: { ...defaultQueryParams },
    });
}

export default {
    path: '/tracking/data-sources',
    name: 'data-sources',
    beforeEnter: setQueryParamsForDataSources,
    component: () => import(/* webpackChunkName: '[DataSourcesPage]' */ './DataSourcesPage.vue'),
    meta: {
        permission: 'tracking:domains:domain',
        browserTitle: 'Data Sources - Tracking',
    },
};
