<script>
export default {
    methods: {
        convertDateFormat(date, time) {
            let [parseYear, parseMonth, parseDay] = date.split('-');
            parseMonth = parseMonth.padStart(2, '0');
            parseDay = parseDay.padStart(2, '0');

            return `${parseYear}-${parseMonth}-${parseDay} ${time}`;
        },

        getShortDateTime(date) {
            let parsedDate = date;

            if (parsedDate.includes(' ')) {
                parsedDate = date.replace(' ', 'T');
            }

            const now = new Date();
            const incomingDate = new Date(parsedDate);

            const options = {
                hourCycle: 'h24',
                day: 'numeric',
                month: 'short',
                hour: '2-digit',
                minute: '2-digit',
            };

            if (now.getFullYear() > incomingDate.getFullYear()) {
                options.year = 'numeric';
            }

            return new Intl.DateTimeFormat('en', options).format(new Date(parsedDate));
        },

        convertTimestampToDate(date) {
            const day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);
            const month = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(date);
            const year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
            return `${year}-${month}-${day}`;
        },

        getDifferenceFromDateToToday(date) {
            const differenceInMilliseconds = new Date().getTime() - new Date(date).getTime();
            const difference = Math.ceil(differenceInMilliseconds / (1000 * 60 * 60 * 24)) - 1;

            return difference > 90 ? '90+' : difference;
        },
    },
};
</script>
