<template>
  <div
    v-if="environment"
    :class="[
      'kmtx-env-label grid grid--centered',
      `kmtx-env-label--${environment === 'development' && dynamic ? 'branch' : environment}`,
    ]"
  >
    <span
      v-if="environment === 'development' && dynamic"
      v-b-tooltip.hover
      :title="dynamic"
    >
      branch env
    </span>

    <span v-else>
      {{ environment }} env
    </span>
  </div>
</template>

<script>
import LocalStorage from '@/services/LocalStorage';

export default {
  name: 'EnvLabelComponent',
  computed: {
    environment() {
      return process.env.NODE_ENV === 'production' ? '' : process.env.NODE_ENV;
    },
    dynamicAPI() {
      return (typeof localStorage !== 'undefined') ? LocalStorage.get('dynamic-api') : '';
    },
    dynamicAPIV2() {
      return (typeof localStorage !== 'undefined') ? LocalStorage.get('dynamic-api-v2') : '';
    },
    dynamic() {
      return this.dynamicAPI || this.dynamicAPIV2;
    },
  },
};
</script>

<style lang="scss">
@import "@/sass/_variables";

     .kmtx-env-label {
        padding: 2px 6px;
        border: 1px solid $gray500;
        border-radius: 4px;
        color: $gray500;
        font-weight: 500;
        font-size: $font-size-xs;
        line-height: $line-height-xs;
        font-family: $font-family-secondary;

         &--development {
             color: $green500;
             border-color: $green500;
         }

         &--branch {
             color: $orange500;
             border-color: $orange500;
         }

         &--stage {
             color: $cyan500;
             border-color: $cyan500;
         }
    }
</style>
