<template>
  <b-row
    align-v="center"
    class="bd-content__header mb-3 mt-3"
  >
    <b-col>
      <h1 class="h1">
        {{ currentTitle }}
      </h1>
    </b-col>
    <b-col
      v-if="currentTabs"
      class="text-right"
    >
      <b-nav tabs>
        <b-nav-item
          v-for="(item, key) in currentTabs"
          :key="key"
          :to="{ name: item.routeName }"
          :active="$route.name === item.routeName "
        >
          {{ item.text }}
        </b-nav-item>
      </b-nav>
    </b-col>
  </b-row>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapGetters } = createNamespacedHelpers('router');

export default {
  name: 'ContentHeader',
  computed: {
    ...mapGetters(['currentTitle', 'currentTabs']),
  },
};
</script>

<style>
    .bd-content__header .nav {
        display: inline-flex;
    }
</style>
