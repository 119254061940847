
import useToast from '@/composables/useToast';
import Vue from 'vue';
import { IToast } from './Toast';

declare module 'vue/types/vue' {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    export interface Vue extends IToast {}
}

const { success, info, warning, danger, errorMessage } = useToast();

export default Vue.extend({
    methods: {
        info(title = '', content = '', dataAttrs = {}): void {
            info(title, content, dataAttrs);
        },
        success(title = '', content = '', dataAttrs = {}): void {
            success(title, content, dataAttrs);
        },
        warning(title = '', content = '', dataAttrs = {}): void {
            warning(title, content, dataAttrs);
        },
        danger(title = '', content = '', dataAttrs = {}): void {
            danger(title, content, dataAttrs);
        },
        errorMessage(error: any, spareTitle?: string, dataAttrs = {}): void {
            errorMessage(error, spareTitle, dataAttrs);
        },
    },
});
