import LocalStorage from '@/services/LocalStorage';

const Connections = () => import(/* webpackChunkName: "[Connections]" */ '@/components/integrations/Connections.vue');
const DataStream = () => import(/* webpackChunkName: "[DataStream]" */ '@/components/integrations/DataStream.vue');
const ReportLogs = () => import(/* webpackChunkName: "[ReportLogs]" */ '@/components/integrations/ReportLogs.vue');
const ConnectionReportForm = () =>
    import(/* webpackChunkName: "[ConnectionReportForm]" */ '@/components/integrations/ConnectionReportForm.vue');
const AvailableIntegrations = () =>
    import(/* webpackChunkName: "[AvailableIntegrations]" */ '@/components/integrations/AvailableIntegrations.vue');
const KeywordToolList = () => import(/* webpackChunkName: "[KeywordToolList]" */ '@/modules/integrations/pages/KeywordToolList.vue');
const ReportCreate = () => import(/* webpackChunkName: "[ReportCreate]" */ '@/modules/integrations/pages/ReportCreate.vue');
const ReportEdit = () => import(/* webpackChunkName: "[ReportEdit]" */ '@/modules/integrations/pages/ReportEdit.vue');

// 3-rd party services integrations
export default [
    {
        path: '/integrations/connections',
        component: Connections,
        name: 'integration-connections',
        meta: {
            header: { title: 'Connections' },
            permission: 'esc:integrations:connections',
            browserTitle: 'Connections - 3-rd party services integrations',
        },
    },
    {
        path: '/integrations/data-stream',
        component: DataStream,
        name: 'integration-data-stream',
        meta: {
            header: { title: 'Data Stream' },
            permission: 'esc:integrations:data-stream',
            browserTitle: 'Data Stream - 3-rd party services integrations',
        },
    },
    {
        path: '/integrations/logs',
        component: ReportLogs,
        name: 'reports-logs',
        meta: { permission: 'esc:integrations:logs', browserTitle: 'Logs - 3-rd party services integrations' },
    },
    {
        path: '/integrations/report',
        component: ConnectionReportForm,
        name: 'connection-report',
        meta: { permission: 'esc:integrations:data-stream:view', browserTitle: 'Report - 3-rd party services integrations' },
    },
    {
        path: '/integration-connectors',
        component: AvailableIntegrations,
        name: 'integration-connectors',
        meta: { header: { title: 'Integrations' } },
    },
    ...(LocalStorage.get('seo-tool')
        ? [
              {
                  path: '/integrations/keyword-tool',
                  component: KeywordToolList,
                  name: 'keyword-tool',
                  meta: { permission: 'esc:integrations:connections', browserTitle: 'Keyword Tool - 3-rd party services integrations' },
              },
              {
                  path: '/integrations/keyword-tool/create',
                  name: 'keyword-tool-create',
                  component: ReportCreate,
                  meta: { permission: 'seotool:reports:report:create', browserTitle: 'Keyword Tool - Create' },
              },
              {
                  path: '/integrations/keyword-tool/:id',
                  name: 'keyword-tool-edit',
                  component: ReportEdit,
                  meta: { permission: 'esc:integrations:connections', browserTitle: 'Keyword Tool - Edit' },
              },
          ]
        : []),
];
