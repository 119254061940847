import RoutingViewContainer from '@/components/RoutingViewContainer.vue';

const FinTechContainer = () => import(/* webpackChunkName: "[FinTech]" */ '@/components/finTech/FinTechContainer.vue');
const UserProfiler = () => import(/* webpackChunkName: "[UserProfiler]" */ '@/modules/dddm/pages/UserProfiler.vue');

export default {
    path: '/dddm-tools',
    component: RoutingViewContainer,
    name: 'dddm-tools',
    redirect: { name: 'fintech-platform' },
    children: [
        {
            path: 'fintech-platform',
            component: FinTechContainer,
            name: 'fintech-platform',
            meta: {
                header: { tab: true },
                permission: ['dddm:redistribution:link:view', 'dddm:what-if:link:view', 'dddm:pb:redistribution-link:view'],
                browserTitle: 'FinTech Platform',
            },
        },
        {
            path: 'user-profiler',
            component: UserProfiler,
            name: 'user-profiler',
            meta: {
                permission: ['dddm:user-profiler:list:view'],
                browserTitle: 'User profiler | List',
            },
        },
    ],
};
