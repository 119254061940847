<template>
  <div class="content">
    <TableComponent
      :id="tableId"
      show-empty
      :fields="fields"
      :per-page="pagination.per_page"
      :provider-source="providerSource"
      hover
    >
      <template #cell(actions)="row">
        <BButton
          v-if="!row.item.revoked && canDelete"
          :disabled="row.item.revoked"
          variant="success"
          size="sm"
          @click="onRevoke(row.item.id)"
        >
          Revoke
        </BButton>
      </template>
    </TableComponent>

    <BRow>
      <BCol
        md="3"
        class="my-1"
      >
        <BButton
          v-if="canCreate"
          v-b-modal.new-token
        >
          Create new token
        </BButton>
        <BModal
          id="new-token"
          ref="modal"
          title="New token"
          @hidden="resetTokenCreate"
          @ok="onCreateNewToken"
        >
          <BFormInput
            v-model="tokenName"
            :state="tokenNameState"
            placeholder="Token name"
          />
        </BModal>
        <BModal
          id="created-token"
          ref="createdModal"
          :title="createdTokenModalName"
          no-close-on-esc
          no-close-on-backdrop
          ok-only
          @hidden="refreshTable"
        >
          <BRow>
            <BTextarea
              height="500px"
              :value="accessToken"
            />
          </BRow>
        </BModal>
      </BCol>
    </BRow>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import Permissions from '@/mixins/Permissions.vue';
import Toast from '@/mixins/Toast.vue';
import EventBus from '../event';
import TableComponent from './TableComponent.vue';

const { mapState, mapActions } = createNamespacedHelpers('apiTokens');

const IAM_SERVICE_URN = 'iam:api-tokens:token';

export default {
  name: 'AppIntegration',
  components: {
    TableComponent,
  },
  mixins: [Permissions, Toast],
  data() {
    return {
      fields: [
        {
          key: 'name', label: 'Name', sortable: false, class: 'text-center',
        },
        {
          key: 'events_number', label: 'Collected events number', sortable: false, class: 'text-center',
        },
        {
          key: 'expires_at', label: 'Expires at', sortable: false, class: 'text-center',
        },
        { key: 'actions', label: 'Actions' },
      ],
      tokenName: null,
      tokenNameState: null,
      accessToken: null,
      createdTokenModalName: 'Token created',
      pagination: {
        per_page: 15,
      },
      tableId: 'api-tokens-table',
    };
  },
  computed: {
    ...mapState(['tokens']),
    providerSource() {
      return this.fillApiTokens;
    },
    canCreate() {
      return this.$has(IAM_SERVICE_URN, 'create');
    },
    canDelete() {
      return this.$has(IAM_SERVICE_URN, 'delete');
    },
  },
  methods: {
    ...mapActions(['fillApiTokens', 'createApiToken', 'revokeApiToken']),
    refreshTable() {
      EventBus.$emit('kmtx-table-refresh', this.tableId);
    },
    onRevoke(ctx) {
      if (this.canDelete === false) {
        this.errorMessage(`You don't have permission for this operation`, 'Failed to revoke token.');
        return;
      }

      // eslint-disable-next-line no-alert
      const result = window.confirm('Are you sure?');
      if (!result) {
        return;
      }
      const params = { token_id: ctx };
      const options = { showLoader: true };
      this.revokeApiToken({ params, options }).then(() => {
      }).catch(() => []).finally(() => {
        this.refreshTable();
      });
    },
    onCreateNewToken(ev) {
      ev.preventDefault();

      if (this.canDelete === false) {
        this.errorMessage(`You don't have permission for this operation`, 'Failed to revoke token.');
        return;
      }

      if (!this.tokenName) {
        this.tokenNameState = 'invalid';
        return;
      }
      this.tokenNameState = 'valid';
      const options = { showLoader: true };
      const params = { app_name: this.tokenName };
      this.createApiToken({ params, options }).then(({ data }) => {
        this.accessToken = data.access_token;
      }).catch(() => {
        this.accessToken = 'Error happen. Please rty again.';
        return [];
      }).finally(() => {
        this.$nextTick(() => {
          this.$refs.modal.hide();
          this.$refs.createdModal.show();
        });
      });
    },
    resetTokenCreate() {
      this.tokenName = null;
      this.tokenNameState = null;
    },
  },
};
</script>
