import { ApiV1 } from '@/api';

export default {
    getVariables(params: any) {
        return ApiV1().get('/variables', { params });
    },
    getVariable(id: any, params: any) {
        return ApiV1().get(`/variables/${id}`, { params });
    },
    createVariable(data: any) {
        return ApiV1({ cantCancel: true }).post('/variables', data);
    },
    getStorageVariables() {
        return ApiV1().get('/storage-variables');
    },
    updateVariable(data: any) {
        return ApiV1({ cantCancel: true }).put(`/variables/${data.id}`, data);
    },
    createDraftVariable(data: any) {
        return ApiV1({ cantCancel: true }).put(`/draft/variable/${data.id}`, data);
    },
    deleteVariable(params: { id: number; isForce: boolean }) {
        const { id, isForce } = params;
        const force = isForce ? '?force=1' : '';

        return ApiV1({ cantCancel: true }).delete(`/variables/${id}${force}`);
    },
    publishVariable(id: number) {
        return ApiV1({ cantCancel: true }).post(`/draft/variable/${id}`);
    },
};
