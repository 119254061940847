<template>
  <div class="content">
    <table-component
      :id="tableId"
      show-empty
      hover
      fixed
      responsive
      stacked="md"
      :per-page="pagination.per_page"
      :fields="fields"
      :provider-params="providerParams"
      :provider-source="providerSource"
      :filter="filter"
    >
      <template #table-colgroup="scope">
        <col
          v-for="field in scope.fields"
          :key="field.key"
          :style="{ width: field.key === 'description' ? '30%' : '' }"
        >
      </template>

      <template #cell(created_at)="row">
        {{ dateFormat(row.item.created_at) }}
      </template>
    </table-component>
  </div>
</template>
<script>
import ActivityService from '@/modules/activity-log/services/Activity';
import TableComponent from '../TableComponent.vue';

export default {
  name: 'ActivityComponent',
  components: {
    TableComponent,
  },
  data() {
    return {
      pagination: {
        per_page: 15,
      },
      tableId: 'activity-table',
      filter: '',
      fields: [
        {
          key: 'created_at', label: 'Date', sortable: true, class: 'text-center', sortDirection: 'desc',
        },
        {
          key: 'log_name', label: 'Name', sortable: false, class: 'text-center',
        },
        {
          key: 'user', label: 'User', sortable: false, class: 'text-center',
        },
        {
          key: 'subject_type', label: 'Object', sortable: false, class: 'text-center',
        },
        {
          key: 'subject_id', label: 'ObjectId', sortable: false, class: 'text-center',
        },
        {
          key: 'description', label: 'Description', sortable: false, class: 'text-center',
        },
      ],
    };
  },
  computed: {
    providerParams() {
      return {
        sortBy: 'created_at',
        sortDesc: true,
      };
    },
    providerSource() {
      return ActivityService.getActivities;
    },
  },
  methods: {
    dateFormat(str) {
      return new Date(str).toLocaleString();
    },
  },
};
</script>
