import RoutingViewContainer from '@/components/RoutingViewContainer.vue';

const DataSourcesList = () =>
    import(/* webpackChunkName: "[DataSourcesList]" */ '@/modules/data-wizard/pages/connections/DataSourcesList.vue');
const DataSourceCreate = () =>
    import(/* webpackChunkName: "[DataSourceCreate]" */ '@/modules/data-wizard/pages/connections/DataSourceCreate.vue');
const DataSourceEdit = () =>
    import(/* webpackChunkName: "[DataSourceEdit]" */ '@/modules/data-wizard/pages/connections/DataSourceEdit.vue');
const DSUserList = () => import(/* webpackChunkName: "[DSUserList]" */ '@/modules/data-wizard/pages/users/DSUserList.vue');
const DSUserCreate = () => import(/* webpackChunkName: "[DSUserCreate]" */ '@/modules/data-wizard/pages/users/DSUserCreate.vue');
const DSUserEdit = () => import(/* webpackChunkName: "[DSUserEdit]" */ '@/modules/data-wizard/pages/users/DSUserEdit.vue');
const DSServiceEdit = () => import(/* webpackChunkName: "[DSServiceEdit]" */ '@/modules/data-wizard/pages/services/DSServiceEdit.vue');
const DSServiceCreate = () =>
    import(/* webpackChunkName: "[DSServiceCreate]" */ '@/modules/data-wizard/pages/services/DSServiceCreate.vue');
const DSServiceList = () => import(/* webpackChunkName: "[DSServiceList]" */ '@/modules/data-wizard/pages/services/DSServiceList.vue');
const AccessRequestCreate = () =>
    import(/* webpackChunkName: "[AccessRequestCreate]" */ '@/modules/data-wizard/pages/access-request/AccessRequestCreate.vue');
const AccessRequestEdit = () =>
    import(/* webpackChunkName: "[AccessRequestEdit]" */ '@/modules/data-wizard/pages/access-request/AccessRequestEdit.vue');
const AccessRequestList = () =>
    import(/* webpackChunkName: "[AccessRequestList]" */ '@/modules/data-wizard/pages/access-request/AccessRequestList.vue');
const AccessRequestView = () =>
    import(/* webpackChunkName: "[AccessRequestView]" */ '@/modules/data-wizard/pages/access-request/AccessRequestView.vue');
const MyAccessPage = () => import(/* webpackChunkName: "[MyAccess]" */ '@/modules/data-wizard/pages/connections/MyAccess.vue');
const DataMartCreate = () =>
    import(/* webpackChunkName: "[DataMartCreate]" */ '@/modules/data-wizard/pages/connections/DataMartCreate.vue');

export default [
    {
        path: '/dsm',
        name: 'dsm',
        redirect: { name: 'data-sources-list' },
    },
    {
        path: '/dsm/data-sources',
        component: RoutingViewContainer,
        name: 'data-sources',
        redirect: { name: 'data-sources-list' },
        children: [
            {
                path: '',
                component: DataSourcesList,
                name: 'data-sources-list',
                meta: {
                    permission: 'dw:dsm:data-sources',
                    browserTitle: 'Data Sources',
                },
            },
            {
                path: 'create',
                component: DataSourceCreate,
                name: 'data-source-create',
                meta: {
                    permission: 'dw:dsm:data-sources:create',
                    browserTitle: 'Create Data Source',
                },
            },
            {
                path: ':dataSourceId',
                component: RoutingViewContainer,
                redirect: { name: 'data-source-edit-general' },
                name: 'data-source-edit',
                meta: {
                    permission: 'dw:dsm:data-sources',
                    browserTitle: 'Edit Data Source',
                },
                children: [
                    {
                        path: 'general',
                        component: DataSourceEdit,
                        name: 'data-source-edit-general',
                        meta: {
                            permission: 'dw:dsm:data-sources:view',
                            browserTitle: 'Edit Data Source | General',
                            tab: 0,
                        },
                    },
                    {
                        path: 'databases',
                        component: DataSourceEdit,
                        name: 'data-source-edit-databases',
                        meta: {
                            permission: 'dw:dsm:data-sources:view',
                            browserTitle: 'Edit Data Source | Databases',
                            tab: 1,
                        },
                    },
                    {
                        path: 'users',
                        component: DataSourceEdit,
                        name: 'data-source-edit-users',
                        meta: {
                            permission: 'dw:dsm:data-sources:view',
                            browserTitle: 'Edit Data Source | Users',
                            tab: 2,
                        },
                    },
                    {
                        path: 'analytics',
                        component: DataSourceEdit,
                        name: 'data-source-edit-analytics',
                        meta: {
                            permission: 'dw:dsm:data-sources:view',
                            browserTitle: 'Edit Data Source | Analytics',
                            tab: 3,
                        },
                    },
                    {
                        path: 'roles',
                        component: DataSourceEdit,
                        name: 'data-source-edit-roles',
                        meta: {
                            permission: 'dw:dsm:data-sources:view',
                            browserTitle: 'Edit Data Source | Roles',
                            tab: 4,
                        },
                        children: [
                            {
                                path: ':roleId',
                                component: DataSourceEdit,
                                name: 'data-source-edit-role',
                                meta: {
                                    permission: 'dw:dsm:data-sources:view',
                                    browserTitle: 'Edit Data Source | Users',
                                    tab: 4,
                                },
                            },
                        ],
                    },
                    {
                        path: 'data-marts',
                        component: DataSourceEdit,
                        name: 'data-source-edit-data-marts',
                        meta: {
                            permission: 'dw:dsm:data-sources:view',
                            browserTitle: 'Edit Data Source | Data marts',
                            tab: 5,
                        },
                    },
                    {
                        path: 'data-marts/create',
                        component: DataMartCreate,
                        name: 'data-marts-create',
                        meta: {
                            permission: 'dw:dsm:data-sources:create',
                            browserTitle: 'Edit Data Source | Data marts',
                            tab: 5,
                        },
                    },
                ],
            },
        ],
    },
    {
        path: '/dsm/users',
        component: RoutingViewContainer,
        name: 'data-source-users',
        redirect: { name: 'data-source-users-list' },
        children: [
            {
                path: '',
                component: DSUserList,
                name: 'data-source-users-list',
                meta: {
                    permission: 'dw:dsm:users',
                    browserTitle: 'DSM Users Management',
                },
            },
            {
                path: 'edit',
                component: DSUserEdit,
                name: 'data-source-user-edit',
                meta: {
                    permission: 'dw:dsm:users',
                    browserTitle: 'DSM User Edit',
                },
            },
            {
                path: 'create',
                component: DSUserCreate,
                name: 'data-source-user-create',
                meta: {
                    permission: 'dw:dsm:users:create',
                    browserTitle: 'DSM User Create',
                },
            },
        ],
    },
    {
        path: '/dsm/access-requests',
        component: RoutingViewContainer,
        name: 'access-requests',
        redirect: { name: 'access-requests-list' },
        children: [
            {
                path: '',
                component: AccessRequestList,
                name: 'access-requests-list',
                meta: {
                    browserTitle: 'Access requests',
                    isPublic: true,
                },
            },
            {
                path: 'create',
                component: AccessRequestCreate,
                name: 'access-request-create',
                meta: {
                    browserTitle: 'Create Access Request',
                    isPublic: true,
                    noAuthCheck: true,
                },
            },
            {
                path: ':id',
                component: AccessRequestView,
                name: 'access-request-view',
                meta: {
                    browserTitle: 'View Access Request',
                    isPublic: true,
                },
            },
            {
                path: ':id/edit',
                component: AccessRequestEdit,
                name: 'access-request-edit',
                meta: {
                    browserTitle: 'Edit Access Request',
                    isPublic: true,
                },
            },
        ],
    },
    {
        path: '/dsm/my-accesses',
        component: RoutingViewContainer,
        name: 'my-data-source',
        redirect: { name: 'data-source-my-accesses' },
        children: [
            {
                path: '',
                component: MyAccessPage,
                name: 'user-profile-data-sources',
                meta: {
                    allowForMaster: true,
                    childAccountOnly: true,
                    browserTitle: 'My Accesses',
                },
            },
        ],
    },
    {
        path: '/dsm/services',
        component: RoutingViewContainer,
        name: 'data-source-services',
        redirect: { name: 'data-source-services-list' },
        children: [
            {
                path: '',
                component: DSServiceList,
                name: 'data-source-services-list',
                meta: {
                    permission: 'dw:dsm:users',
                    browserTitle: 'DSM Services Management',
                },
            },
            {
                path: 'edit',
                component: DSServiceEdit,
                name: 'data-source-service-edit',
                meta: {
                    permission: 'dw:dsm:users',
                    browserTitle: 'DSM Service Edit',
                },
            },
            {
                path: 'create',
                component: DSServiceCreate,
                name: 'data-source-service-create',
                meta: {
                    permission: 'dw:dsm:users:create',
                    browserTitle: 'DSM Service Create',
                },
            },
        ],
    },
];
