<template>
  <big-numbers
    class="mb-5 mt-3"
    items-wrap
    :items-count="stats.length"
  >
    <big-number
      v-for="([key, value], id) in stats"
      :key="id"
      :value="getFormattedValue(value)"
      :reference="'single-stat-' + id"
    >
      <template #header>
        {{ getLabelByKey(key) }}
      </template>
    </big-number>
  </big-numbers>
</template>

<script>
import { mapState } from 'vuex';
import BigNumbers from '../bigNumbers';
import BigNumber from '../bigNumber';
import { kFormatter } from '../../../helper';

export default {
  name: 'SinglestatWidget',
  components: {
    'big-numbers': BigNumbers,
    'big-number': BigNumber,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    widget: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState('dataMarts', ['widgets']),
    statItemWidth() {
      return `${100 / (4 / this.widgets[this.widget.dashboard_position[0]]?.length)}%`;
    },
    stats() {
      if (this.data[0]) {
        return Object.entries(this.data[0]);
      }
      return [];
    },
  },
  methods: {
    getLabelByKey(key) {
      const stat = this.widget.configuration.columns.find((stat) => stat.field_name === key);
      return this.widget.configuration.labels?.find((label) => label.field_id === stat.field_id)?.label || stat.field_name;
    },
    getFormattedValue(value) {
      return kFormatter(value, 1e5);
    },
  },
};
</script>
