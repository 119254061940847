import { reactive } from 'vue';

import useForm from '@/composables/useForm';
import {
  helpers,
  integer,
  maxValue, minLength, minValue, required,
} from '@/shared/utils/validators';
import validationMessages from '@/shared/utils/validationMessages';
import { IABTestForm } from '../services/Cockpit';

const shouldBeLeastOneValueMessage = 'At least one value in Planned PI should be filled';
function shouldBeLeastOneValue(...params) {
  const [value, siblings] = params;

  if (Object.values(siblings).every((val) => val === '')) {
    return false;
  }
  return true;
}

function useABTestForm(initValue: IABTestForm) {
  const value = reactive<IABTestForm>(initValue);
  const rules = {
    price_setting: {
      planned_pi: {
        planned_pi_under: {
          shouldBeLeastOneValue: helpers.withMessage(shouldBeLeastOneValueMessage, shouldBeLeastOneValue),
          minValue: minValue(0.5, 'Planned PI Underpriced'),
          maxValue: maxValue(1.5, 'Planned PI Underpriced'),
        },
        planned_pi_over: {
          shouldBeLeastOneValue: helpers.withMessage(shouldBeLeastOneValueMessage, shouldBeLeastOneValue),
          minValue: minValue(0.5, 'Planned PI Overpriced'),
          maxValue: maxValue(1.5, 'Planned PI Overpriced'),
        },
        multiplier: {
          shouldBeLeastOneValue: helpers.withMessage(shouldBeLeastOneValueMessage, shouldBeLeastOneValue),
          minValue: minValue(0),
          maxValue: maxValue(0.5),
        },
      },
      guardrail: {
        min_marginality: {
          required: required('Min Marginality'),
          minValue: minValue(1, 'Min Marginality'),
          maxValue: maxValue(50, 'Min Marginality'),
        },
        pi_jump_up: {
          minValue: minValue(1, 'PI Jump Up'),
          maxValue: maxValue(50, 'PI Jump Up'),
        },
        pi_jump_down: {
          minValue: minValue(1, 'PI Jump Down'),
          maxValue: maxValue(50, 'PI Jump Down'),
        },
        price_jump_up: {
          minValue: minValue(1, 'Price Jump Up'),
          maxValue: maxValue(50, 'Price Jump Up'),
        },
        price_jump_down: {
          minValue: minValue(1, 'Price Jump Down'),
          maxValue: maxValue(50, 'Price Jump Down'),
        },
      },
      project: {
        platform: {
          required: required(),
        },
        market: {
          $each: helpers.forEach({
            country: {
              required: helpers.withMessage(validationMessages.validations.required
                .replace('{property}', 'Field'), required('field')),
            },
            category: {
              required: helpers.withMessage(validationMessages.validations.required
                .replace('{property}', 'Field'), required('field')),
              $each: helpers.forEach({
                value: {
                  integer: helpers.withMessage(validationMessages.validations.integer, integer),
                },
              }),
            },
          }),
        },
      },
    },
    ab_setting: {
      key_metric: {
        required: required('Key Metric'),
      },
      key_metric_percent: {
        minValue: minValue(1, 'Key Metric Percent'),
        maxValue: maxValue(100, 'Key Metric Percent'),
      },
      fp_error: {
        minValue: minValue(1, 'FP Error'),
        maxValue: maxValue(100, 'FP Error'),
      },
      fn_error: {
        minValue: minValue(1, 'FN Error'),
        maxValue: maxValue(100, 'FN Error'),
      },
    },
  };

  return useForm(value, rules);
}

export default useABTestForm;
