import { render, staticRenderFns } from "./Account.vue?vue&type=template&id=2b772126&scoped=true&"
import script from "./Account.vue?vue&type=script&setup=true&lang=ts&"
export * from "./Account.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./Account.vue?vue&type=style&index=0&id=2b772126&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b772126",
  null
  
)

export default component.exports