<template>
  <b-form-group :label="label">
    <vue-autosuggest
      :ref="reference"
      v-model="selected"
      :component-attr-id-autosuggest="'kmtx-routelist-' + index"
      :input-props="{
        id: 'autosuggest-' + index + '__input',
        class: 'form-control',
        type: 'search',
        placeholder: placeholder,
      }"
      :suggestions="filteredData"

      :limit="options.limit"

      class="route-list"
      @selected="onSelectHandler"
      @input="onInputChange"
    >
      <template #default="{ suggestion }">
        {{ suggestion.item.name }}
      </template>
    </vue-autosuggest>
  </b-form-group>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { VueAutosuggest } from 'vue-autosuggest';

export default {
  name: 'KmtxRoutelist',
  components: {
    VueAutosuggest,
  },
  props: {
    index: {
      type: Number,
      default: 1,
    },
    label: {
      type: String,
      default: 'Routes',
    },
    placeholder: {
      type: String,
      default: 'Type route...',
    },
    reference: {
      type: String,
      default: 'kmtx-routelist',
    },
    options: {
      type: Object,
      default: () => ({
        limit: Infinity,
      }),
    },
  },
  emits: ['routelist-init', 'routelist-input', 'routelist-change'],
  data() {
    return {
      filteredData: [],
    };
  },
  computed: {
    selected: {
      get() {
        return this.getCurrentRoute.name;
      },
      set(newValue) {
        return newValue;
      },
    },
    ...mapGetters('filters', {
      getRoutes: 'getRoutes',
      getCurrentRoute: 'getCurrentRoute',
    }),
    ...mapState('source', {
      routeList: (state) => state.routes,
    }),
  },
  created() {
    if (this.getRoutes.length === 0) {
      this.$store.dispatch('source/fillRoutes').then(() => {
        this.setRoutes(this.routeList);
        this.init();
      }).catch((error) => { if (!error.cancelReason) throw error; });
    } else {
      this.init();
    }
  },
  methods: {
    onSelectHandler(selected) {
      if (selected !== null && selected.item.id !== this.getCurrentRoute.id) {
        this.setCurrentRoute(selected.item);
        this.changeRouteQueryParams(selected.item.id === 1);
        this.$emit('routelist-change', {
          reference: this.reference,
        });
      }
    },
    changeRouteQueryParams(isDefault) {
      if (isDefault) {
        delete this.$route.query.route;
      } else {
        this.$router.push({
          name: this.$route.name,
          query: {
            ...this.$route.query,
            route: isDefault ? undefined : this.getCurrentRoute.id,
          },
        });
      }
    },
    onInputChange(route) {
      route = route.trim().toLowerCase();

      if (route !== null && route.length > 0 && this.getRoutes.length > 0) {
        let filteredData = this.getRoutes.filter((item) => {
          if (item.name !== undefined) {
            return item.name.trim().toLowerCase().indexOf(route) > -1;
          }
        });

        filteredData = this.sortData(filteredData, route);

        this.filteredData = [{ data: filteredData }];
      }

      this.$emit('routelist-input', {
        reference: this.reference,
        data: route,
      });
    },
    sortData(data, input) {
      const alphabetSorted = data.sort((a, b) => a.name.localeCompare(b.name));

      const preSort = [];
      const postSort = [];

      alphabetSorted.forEach((item) => {
        if (item.name.toLowerCase().indexOf(input) === 0) {
          preSort.push(item);
        } else if (item.name.toLowerCase().indexOf(input) > 0) {
          postSort.push(item);
        }
      });

      return preSort.concat(postSort);
    },
    init() {
      // if route query exist and not match same current route in store
      if (!!this.$route.query.route && this.$route.query.route !== this.getCurrentRoute.id.toString()) {
        this.setCurrentRouteById(this.$route.query.route);
      }

      if (this.$route.query.route !== this.getCurrentRoute.id.toString() || this.$route.query.route === '1') {
        this.changeRouteQueryParams(true);
      }

      this.$emit('routelist-init', {
        reference: this.reference,
      });
    },
    ...mapActions('filters', {
      setRoutes: 'setRoutes',
      setCurrentRoute: 'setCurrentRoute',
      setCurrentRouteById: 'setCurrentRouteById',
    }),
  },
};
</script>

<style>
.route-list {
  min-width: 210px;
}
</style>
