import { ApiV1 } from '@/api';

export default {
  getTimeSeries(params: any) {
    params = params || {};
    return ApiV1().get('/stat/source/sale-dynamic', { params });
  },
  getGenericData(params: any, options: any) {
    params = params || {};
    return ApiV1(options).get('/stat/source/sale-dynamic-generic', { params });
  },
  getOrderGenericByDevice(params: any, options: any) {
    params = params || {};
    return ApiV1(options).get('/stat/source/sale-dynamic-generic-by-device', { params });
  },
};
