import UserManagement from '@/modules/user-management/services/UserManagement';
import { ApiV2 } from '@/api';
import { IAccount, IUser } from '@/store/modules/auth';

export interface IUserManagementState {
    users: IUser[];
    accounts: IAccount[];
    accountStatuses: { name: string; value: number }[];
    userStatuses: { name: string; value: number }[];
    statuses: {
        0: 'not_yet_activated';
        1: 'active';
        2: 'inactive';
        3: 'pending';
        4: 'deactivated';
        5: 'archived';
    };
    statusIcons: {
        0: 'clock-fill.svg';
        1: 'eye-fill.svg';
        2: 'eye-fill.svg';
        3: 'eye-slash-fill.svg';
        4: 'eye-slash-fill.svg';
        5: 'eye-slash-fill.svg';
    };
    marketTypes: {
        1: 'service';
        2: 'business';
    };
    scopes: [
        {
            name: 'master owner';
            value: 1;
        },
        {
            name: 'master admin';
            value: 2;
        },
        {
            name: 'owner';
            value: 3;
        },
        {
            name: 'admin';
            value: 4;
        },
        {
            name: 'manager';
            value: 5;
        },
        {
            name: 'viewer';
            value: 6;
        }
    ];
    roleIcons: {
        1: 'crown-filled.svg';
        2: 'crown-outline.svg';
    };
}

// initial state
const state = {
    users: [],
    accounts: [],
    accountStatuses: null,
    userStatuses: null,
    statuses: {
        0: 'not_yet_activated',
        1: 'active',
        2: 'inactive',
        3: 'pending',
        4: 'deactivated',
        5: 'archived',
    },
    statusIcons: {
        0: 'clock-fill.svg',
        1: 'eye-fill.svg',
        2: 'eye-fill.svg',
        3: 'eye-slash-fill.svg',
        4: 'eye-slash-fill.svg',
        5: 'eye-slash-fill.svg',
    },
    marketTypes: {
        1: 'service',
        2: 'business',
    },
    scopes: [
        {
            name: 'master owner',
            value: 1,
        },
        {
            name: 'master admin',
            value: 2,
        },
        {
            name: 'owner',
            value: 3,
        },
        {
            name: 'admin',
            value: 4,
        },
        {
            name: 'manager',
            value: 5,
        },
        {
            name: 'viewer',
            value: 6,
        },
    ],
    roleIcons: {
        1: 'crown-filled.svg',
        2: 'crown-outline.svg',
    },
};

const getters = {
    statusName: (state: any) => (status: any, type: any, parsed: any) => {
        status = state[type]?.find((item: any) => +item.value === +status);
        if (status?.name && parsed) {
            status = status.name.split('_').join(' ');
            return `${status.charAt(0).toUpperCase()}${status.slice(1)}`;
        }
        if (status?.name && !parsed) {
            return status?.name;
        }
        return '-';
    },
    scopeByName: (state: any) => {
        const scopes: any = {};
        state.scopes.forEach((scope: any) => (scopes[scope.name] = scope.value));
        return scopes;
    },
    scopeByValue: (state: any) => {
        const scopes: any = {};
        state.scopes.forEach((scope: any) => (scopes[scope.value] = scope.name));
        return scopes;
    },
};

// actions
const actions = {
    fillUsers({ commit }: any, data: any = {}) {
        data.params = data.params || {};
        // const account_id = data.account_id || rootGetters['auth/currentAccount']?.id;
        const params = { limit: 10000, ...data.params };

        const options = {
            showLoader: true,
            ...(data.options && data.options),
        };

        return UserManagement.getUsers(params, options).then((resp) => {
            const data = resp?.data?.data;
            if (data) {
                commit('setUsers', data);
            }
        });
    },
    fillAccounts({ commit }: any, params: any) {
        params = { limit: 10000, ...params };
        return UserManagement.getAccountsList(params).then((resp) => {
            const data = resp?.data?.data;
            if (data) {
                commit('setAccounts', data);
            }
        });
    },
    fillAccountStatuses({ commit }: any) {
        return UserManagement.getAccountStatuses().then((resp) => {
            const data = resp?.data?.data;
            if (data) {
                commit('setAccountStatuses', data);
            }
        });
    },
    fillUserStatuses({ commit }: any) {
        return UserManagement.getUserStatuses().then((resp) => {
            const data = resp?.data?.data;
            if (data) {
                commit('setUserStatuses', data);
            }
        });
    },
    addCustomRole({ commit }: any, { payload }: any) {
        return ApiV2().post(`iam/roles/`, payload);
    },
    updateCustomRole({ commit }: any, { payload, pageId }: any) {
        return ApiV2({ showLoader: true }).patch(`iam/roles/${pageId}`, payload);
    },
    deleteCustomRole({ commit }: any, pageId: any) {
        return ApiV2().delete(`iam/roles/${pageId}`);
    },
    restoreCustomRole({ commit }: any, id: any) {
        return ApiV2().patch(`iam/roles/${id}/restore`);
    },
    getRoleInfo({ commit }: any, id: any) {
        return ApiV2().get(`iam/roles/${id}`);
    },
};

const mutations = {
    setUsers(state: any, payload: any) {
        state.users.splice(0);
        state.users.push(...payload);
    },
    setAccounts(state: any, payload: any) {
        state.accounts.splice(0);
        state.accounts.push(...payload);
    },
    clearAccounts(state: any) {
        state.accounts.splice(0);
    },
    clearUsers(state: any) {
        state.users.splice(0);
    },
    setAccountStatuses(state: any, payload: any) {
        state.accountStatuses = payload;
    },
    setUserStatuses(state: any, payload: any) {
        state.userStatuses = payload;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
