<template>
  <kmtx-chart
    ref="sanKeyContainer"
    :options="chartOptions"
    class="routes-diagrams-container"
  />
</template>
<script>
import EChartComponent from '@/components/echart/EChartComponent.vue';

export default {
  name: 'SankeyComponent',
  components: {
    'kmtx-chart': EChartComponent,
  },
  props: {
    transitionList: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['select'],
  data() {
    return {
      chart: null,
      chartOptions: {
        series: {
          type: 'sankey',
          layout: 'none',
          draggable: false,
          focusNodeAdjacency: 'allEdges',
          data: [],
          links: [],
        },
        tooltip: {
          trigger: 'item',
          formatter: (params) => {
            const rows = [params.name, params.data.label].filter((i) => i);
            return rows.join(`<br />`);
          },
        },
      },
    };
  },
  watch: {
    transitionList: {
      immediate: true,
      handler(to) {
        this.setupData(to);
      },
    },
  },
  mounted() {
    const container = document.querySelector('.routes-diagrams-container');
    const containerTopOffset = Math.round(container.getBoundingClientRect().top);
    container.style.height = `calc(100vh - ${containerTopOffset}px)`;
  },
  methods: {
    setupData(data) {
      this.chartOptions.series.data.splice(0, this.chartOptions.series.data.length);
      this.chartOptions.series.links.splice(0, this.chartOptions.series.links.length);
      const names = [];
      const links = [];
      data.forEach((item) => {
        names.push(item.from, item.to);
        const link = {
          source: item.from,
          target: item.to,
          value: item.value,
          label: item.labelText,
        };
        links.push(link);
      });
      const uniqNames = [...new Set(names)].map((item) => ({ name: item }));
      this.chartOptions.series.data.push(...uniqNames);
      this.chartOptions.series.links.push(...links);
    },
  },

};
</script>
