<template>
  <b-form-group :label="label">
    <date-picker
      ref="datepicker"
      :dates="dateOptions"
      range
      :placeholder="placeholder"
      :lang="lang"
      :disabled="disabled"
      :clearable="false"
      :disabled-date="disabledDates"
      :format="format"
      @change="onChange"
    />
  </b-form-group>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import DatePickerWithOptions from '@/components/DatePickerWithOptions.vue';
import EventBus from '../../../../event';

export default {
  name: 'KmtxDatepicker',
  components: { DatePicker: DatePickerWithOptions },
  props: {
    label: {
      type: String,
      default: 'Choose date',
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: () => ({
        disabledDates: new Date(),
        dontDisable: false,
      }),
    },
  },
  emits: ['kmtx-filters-datepicker-created', 'datepicker-change'],
  data() {
    return {
      lang: {
        formatLocale: {
          firstDayOfWeek: 1,
        },
        monthBeforeYear: true,
      },
      format: 'YYYY-MM-DD',
    };
  },
  computed: {
    ...mapGetters('filters', {
      storeDate: 'getDate',
    }),
    dateOptions: {
      get() {
        return this.storeDate;
      },
      set(newDate) {
        return newDate;
      },
    },
    disabledDates: {
      get() {
        if (this.options.dontDisable) {
          return () => false;
        }
        return (date) => date > this.options.disabledDates;
      },
    },
  },
  created() {
    this.setDate(this.dateOptions);
  },
  mounted() {
    EventBus.$emit('kmtx-filters-datepicker-created');
    EventBus.$on('kmtx-filters-datepicker-set-week', this.selectLastSevenDayOnCreated);
  },
  beforeDestroy() {
    EventBus.$off('kmtx-filters-datepicker-set-week', this.selectLastSevenDayOnCreated);
    this.clearDate();
  },
  methods: {
    onChange(date) {
      this.setDate(date);
      this.$emit('datepicker-change', date);
    },
    ...mapActions('filters', {
      setDate: 'setDate',
      clearDate: 'clearDate',
    }),
  },
};
</script>
