<template>
  <div class="content">
    <kmtx-filter-builder
      ref="filters"
      :filters="filtersOptions"
      @datepicker-change="onDateChange"
    />

    <kmtx-chart
      ref="countEventChart"
      :options="countEventChart"
      class="count-event-chart"
      @click="clickColumn"
    />

    <div
      v-if="warningList"
      class="mt-2 mb-2"
    >
      <b-form-group
        label="Search zero events"
        class="mb-0"
      >
        <b-input-group>
          <b-button-group class="mx-1">
            <b-button
              variant="info"
              @click="filterList = []"
            >
              All
            </b-button>
            <b-button
              v-for="(item, key) in warningList"
              :key="key"
              variant="light"
              @click="filterList = warningList[key]"
            >
              {{ filters.capitalize(key) }}
              ({{ warningList[key].length }})
            </b-button>
          </b-button-group>
        </b-input-group>
      </b-form-group>
    </div>

    <table-component
      :id="tableId"
      hover
      :items="filteredItems"
      :fields="fields"
      :per-page="pagination.per_page"
    >
      <template #subtitle>
        <b-form-input
          v-model="filterSearch"
          size="sm"
          placeholder="Filter sources"
          style="width: 200px;"
        />
      </template>
      <template #cell(Action)="row">
        <b-button
          size="sm"
          variant="info"
          :to="{ name: 'monitoring-source', query: { host: sourceIdMap[row.item.Source], labeltype: 'source' } }"
          class="mr-1"
        >
          Details
        </b-button>
      </template>
    </table-component>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import kmtxFilterBuilder from './filters/builder/index.vue';
import MonitoringService from '../services/Monitoring';
import TableComponent from './TableComponent.vue';
import EChartComponent from './echart/EChartComponent.vue';
import { BarChartOptions } from './echart/options/bar-chart';

export default {
  name: 'MonitoringComponent',
  components: {
    kmtxFilterBuilder,
    TableComponent,
    'kmtx-chart': EChartComponent,
  },
  data() {
    return {
      filtersOptions: [
        {
          filter: 'datepicker',
          label: 'Dates',
        },
      ],
      sourceId: this.$route.params.id,
      filterList: [],
      sourceIdMap: {},
      ruleEventIdMap: [],
      groupBySkin: false,
      skinWarningList: {},
      skinMap: {},
      filterSearch: '',
      warningList: {},
      fields: [],
      items: [],
      pagination: {
        per_page: 15,
      },
      tableId: 'monitoring-events-table',
      countEventChart: {},
    };
  },
  computed: {
    ...mapState('source', {
      themeMap: (state) => state.themeMap,
    }),
    ...mapGetters('filters', {
      getDateForParams: 'getDateForParams',
    }),
    filteredItems() {
      let { items } = this;
      if (this.filterSearch.length > 0) {
        items = items.filter((i) => i.Source.indexOf(this.filterSearch) >= 0);
      }
      if (this.filterList.length > 0) {
        items = items.filter((i) => this.filterList.indexOf(i.Source) >= 0);
      }
      return items;
    },
  },
  created() {
    this.fillChartData();
    this.$store.dispatch('source/fillThemeMap').then(() => {
      for (const i in this.themeMap) {
        this.skinMap[i] = this.themeMap[i][0];
      }
    }).catch((error) => { if (!error.cancelReason) throw error; });
  },
  methods: {
    onDateChange() {
      this.fillChartData({
        ...this.getDateForParams,
      });
    },
    fillChartData(params) {
      const showLoader = true;
      Promise.all([
        this.fillTable(params, { showLoader }),
        this.fillColumn(params, { showLoader }),
      ]).catch((error) => { if (!error.cancelReason) throw error; });
    },
    clickColumn(event) {
      const id = this.ruleEventIdMap[event.dataIndex];
      this.$router.push({
        name: 'monitoring-unmarked',
        query: {
          host: id,
          dateFrom: this.getDateForParams.dateFrom,
          dateTo: this.getDateForParams.dateTo,
        },
      });
    },
    fillColumn(params, options) {
      return MonitoringService.getSourceEventBySite(params, options).then((r) => {
        const sourceCount = [];
        const labeledCount = [];
        const categories = [];
        r.data.data.forEach((dt) => {
          sourceCount.push(dt.SourceCount);
          labeledCount.push(dt.CountLabled);
          categories.push(dt.RuleEventName);
          this.ruleEventIdMap.push(dt.RuleEventId);
        });
        const sourceCountPercent = [];
        const labeledCountPercent = [];
        categories.forEach((category, index) => {
          sourceCount[index] = sourceCount[index] || 0;
          labeledCount[index] = labeledCount[index] || 0;
          const total = sourceCount[index] + labeledCount[index];
          sourceCountPercent[index] = +((sourceCount[index] / total) * 100).toFixed();
          labeledCountPercent[index] = +((labeledCount[index] / total) * 100).toFixed();
        });

        const chartData = {
          slices: {
            'Count sources': sourceCountPercent,
            'Count labeled': labeledCountPercent,
          },
          columns: categories,
        };

        const chartOptions = {
          tooltip: {
            trigger: 'item',
          },
        };

        const buildOptions = {
          direction: 'yAxis',
          stack: true,
        };

        this.countEventChart = new BarChartOptions(chartData, chartOptions, buildOptions);
        this.countEventChart.color = ['#00e396', '#F44336'];
        this.countEventChart.series.forEach((series) => {
          series.label = {
            show: true,
            position: 'inside',
            formatter: ({ value }) => `${value > 0 ? `${value}%` : ''}`,
          };
        });
      }).catch((error) => { if (!error.cancelReason) throw error; });
    },
    fillTable(params, options) {
      return MonitoringService.getSourceEvent(params, options).then((r) => {
        const items = r.data.data;
        const eventMap = {};
        const uniqEvent = {};
        for (const i in items) {
          uniqEvent[i] = 1;
          if (!eventMap[items[i].RuleName]) {
            eventMap[items[i].RuleName] = {};
          }
          eventMap[items[i].RuleName][items[i].Source] = items[i].eventCount;
          this.sourceIdMap[items[i].Source] = items[i].SourceDictId;
        }
        this.fields.push({
          key: 'Source',
          sortable: true,
        });
        for (const i in eventMap) {
          this.fields.push({
            key: i,
            sortable: true,
          });
        }
        this.fields.push({
          key: 'Action',
          sortable: false,
        });
        for (const site in this.sourceIdMap) {
          const object = {
            Source: site,
          };
          for (const event in eventMap) {
            object[event] = eventMap[event][site] || 0;
            if (object[event] === 0) {
              // if (skin) {
              //     this.skinWarningList[skin] = this.skinWarningList[skin] || {};
              //     this.skinWarningList[skin][event] = this.skinWarningList[skin][event] || 0;
              //     this.skinWarningList[skin][event]++;
              // }
              this.warningList[event] = this.warningList[event] || [];
              this.warningList[event].push(site);
            }
          }
          object.Action = '';
          this.items.push(object);
        }
      }).catch((error) => { if (!error.cancelReason) throw error; });
    },
  },
};
</script>

<style>
.count-event-chart .echarts.kmtx-echart
{
    min-height: 1500px;
}
</style>
