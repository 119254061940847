<template>
  <b-form-group :label="label">
    <b-form-input
      list="my-list-id"
      :placeholder="placeholder"
    />

    <datalist id="my-list-id">
      <option
        v-for="(option, key) in data"
        :key="key"
      >
        {{ option.value }}
      </option>
    </datalist>
  </b-form-group>
</template>

<script>
export default {
  name: 'KmtxDatalist',
  components: {
  },
  props: {
    label: {
      type: String,
      default: 'Select',
    },
    placeholder: {
      type: String,
      default: 'Select',
    },
    data: {
      type: Array,
      default: () => [],
    },
    value: {
      type: String,
      default: 'Default Value',
    },
  },
  emits: ['datalist-changed'],
  methods: {
    selectHandler(event) {
      this.$emit('datalist-changed', event.item);
    },
  },
};
</script>
