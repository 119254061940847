<template>
    <Transition name="kmtx-modal">
        <Portal to="modal">
            <div class="kmtx-modal__mask">
                <div class="kmtx-modal__wrapper">
                    <div class="kmtx-modal__container">
                        <h3 class="kmtx-modal__header">
                            {{ title }}
                        </h3>

                        <div class="kmtx-modal__content">
                            <slot name="content">
                                {{ textContent }}
                            </slot>
                        </div>
                        <div class="kmtx-modal__actions">
                            <slot name="actions">
                                <KButton
                                    level="tertiary"
                                    size="xs"
                                    :label="cancelButtonText"
                                    @click.prevent="closePopup"
                                />
                                <KButton
                                    level="secondary"
                                    size="xs"
                                    :label="continueButtonText"
                                    @click.prevent="savePopup"
                                />
                            </slot>
                        </div>
                    </div>
                </div>
            </div>
        </Portal>
    </Transition>
</template>
<script>
import { KButton } from '@kilometrix/polyomino';
// TODO: @p.kozda move to Polyomino
export default {
    components: {
        KButton,
    },
    props: {
        title: {
            type: String,
            default: '',
        },
        textContent: {
            type: String,
            default: '',
        },
        cancelButtonText: {
            type: String,
            default: 'Cancel',
        },
        continueButtonText: {
            type: String,
            default: 'Continue',
        },
    },
    emits: ['close', 'save'],
    methods: {
        closePopup() {
            this.$emit('close');
        },
        savePopup() {
            this.$emit('save');
        },
    },
};
</script>
<style lang="scss" scoped>
.kmtx-modal {
    &__mask {
        position: fixed;
        z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, rgba(42, 47, 52, 0.3) 0%, rgba(42, 47, 52, 0) 100%);

        display: table;
        transition: opacity 0.1s ease;
    }

    &__container {
        width: 300px;
        margin: 150px auto;
        padding: 20px 30px;
        border-radius: 10px;
        background-color: #fff;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
        transition: all 0.1s ease;
        font-family: Helvetica, Arial, sans-serif;
    }

    &__header {
        color: #303340;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
    }

    &__content {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        word-break: break-word;
    }

    &__actions {
        margin-top: 11px;
        display: flex;
        justify-content: end;
        gap: 8px;
    }
}

.modal-enter {
    opacity: 0;
}

.modal-leave-active {
    opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}
</style>
