<template>
  <div class="content">
    <b-row>
      <b-col
        class="mb-3"
        md="12"
      >
        <b-button
          v-if="canCreate"
          v-b-modal.modal-1
          variant="primary"
        >
          Create
        </b-button>
      </b-col>
      <b-col md="12">
        <table-component
          :id="tableId"
          hover
          :per-page="pagination.per_page"
          :items="staticBlackList"
          :fields="fields"
        >
          <template #cell(actions)="row">
            <b-button
              v-if="canDelete"
              size="sm"
              variant="danger"
              @click="deleteFromBlockList(row.item.Id)"
            >
              delete
            </b-button>
          </template>
        </table-component>
      </b-col>
    </b-row>
    <b-modal
      id="modal-1"
      ref="modal"
      title="Add IP to block list"
      @ok="addToBlockList"
    >
      <b-form
        ref="form"
        @submit.stop.prevent="addToBlockList"
      >
        <b-form-group
          label-cols="4"
          label-cols-lg="2"
          label="IP"
          label-for="input-sm"
        >
          <b-form-input
            id="input-sm"
            v-model="ip"
            size="sm"
          />
        </b-form-group>

        <b-form-group
          label-cols="4"
          label-cols-lg="2"
          label="Comment"
          label-for="input-default"
        >
          <b-form-input
            id="input-default"
            v-model="comment"
          />
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import Permissions from '@/mixins/Permissions.vue';
import Toast from '@/mixins/Toast.vue';
import Scoring from '../../services/Scoring';
import TableComponent from '../TableComponent.vue';

const IAM_SERVICE_URN = 'service-tool:scoring:static-ips';

export default {
  name: 'StaticBlackListComponent',
  components: {
    TableComponent,
  },
  mixins: [Permissions, Toast],
  data() {
    return {
      ip: '',
      comment: '',
      staticBlackList: [],
      pagination: {
        per_page: 30,
      },
      tableId: 'static-black-list-table',
      fields: ['IP', 'Comment', 'CreatedAt', 'Actions'],
    };
  },
  computed: {
    canCreate() {
      return this.$has(IAM_SERVICE_URN, 'create');
    },
    canDelete() {
      return this.$has(IAM_SERVICE_URN, 'delete');
    },
  },
  created() {
    this.getList();
  },
  methods: {
    addToBlockList() {
      if (this.canCreate === false) {
        this.errorMessage(`You don't have permission for this operation`, 'Failed add to block list.');
        return;
      }

      Scoring.addIpToScoringBlockList({ ip: this.ip, comment: this.comment }).then(() => {
        this.getList();
      }).catch((error) => { if (!error.cancelReason) throw error; });
    },
    deleteFromBlockList(id) {
      if (this.canDelete === false) {
        this.errorMessage(`You don't have permission for this operation`, 'Failed delete from block list.');
        return;
      }

      Scoring.deleteFRomScoringBlockList(id).then(() => {
        this.getList();
      }).catch((error) => { if (!error.cancelReason) throw error; });
    },
    getList() {
      Scoring.scoringStaticList({}).then((res) => {
        this.staticBlackList = res.data;
      }).catch((error) => { if (!error.cancelReason) throw error; });
    },
  },
};
</script>
