<template>
  <div
    class="echarts-wrapper loader-frame"
    @mouseover="$emit('hover')"
    @mouseout="$emit('blur')"
  >
    <kmtx-chart
      ref="kmtx-echart"
      :option="options"
      :init-options="chartInitOptions"
      :autoresize="true"
      class="kmtx-echart"
      v-bind="$attrs"
      v-on="$listeners"
    />
    <loader :is-loading="isLoading" />
  </div>
</template>

<script>
import ECharts from 'vue-echarts';
import { use } from 'echarts/core';
import {
  CanvasRenderer,
} from 'echarts/renderers';
import {
  BarChart,
  PieChart,
  LineChart,
  SankeyChart,
} from 'echarts/charts';
import {
  GridComponent,
  TooltipComponent,
  ToolboxComponent,
  LegendComponent,
  LegendScrollComponent,
} from 'echarts/components';

import Loader from '@/components/Loader.vue';

use([
  CanvasRenderer,
  ToolboxComponent,
  GridComponent,
  TooltipComponent,
  BarChart,
  PieChart,
  LineChart,
  SankeyChart,
  LegendComponent,
  LegendScrollComponent,
]);

export default {
  name: 'EChartComponent',
  components: {
    'kmtx-chart': ECharts,
    loader: Loader,
  },
  watch: {
    options() {
      this.restoreChart(this.$refs['kmtx-echart']);
    },
  },
  props: {
    options: {
      type: Object,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['blur', 'hover'],
  data() {
    return {
      chartInitOptions: {
        renderer: 'canvas',
      },
    };
  },
  mounted() {
    this.$refs['kmtx-echart'].chart.on('restore', () => {
      this.restoreChart(this.$refs['kmtx-echart']);
    });
  },
  methods: {
    restoreChart(chart) {
      chart.setOption(this.$props.options, true);
    },
  },
};
</script>

<style lang="scss" scoped>
    .kmtx-echart {
        width: 100%;
        height: 400px;
    }
    .echarts-wrapper
    {
        &--height-60 .kmtx-echart
        {
            height: 60px;
        }
    }
</style>
