function deepClone(obj) {
  if (obj === null || typeof obj !== 'object') {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map((item) => deepClone(item));
  }

  return Object.keys(obj).reduce((clonedObj, key) => ({ ...clonedObj, [key]: deepClone(obj[key]) }), {});
}

export default deepClone;
