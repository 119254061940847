<template>
  <div class="content">
    <kmtx-filter-builder
      ref="filters"
      :filters="filtersOptions"
      @domainlist-change="fillData"
      @domainlist-init="fillData"
      @datepicker-change="fillData"
      @input-input="onSearchInput"
    />

    <b-row class="mt-4">
      <b-col>
        <table-component
          :id="tableId"
          show-empty
          hover
          responsive
          :per-page="pagination.per_page"
          :filter="filter"
          :sort-by="sortBy"
          :sort-desc="sortDesc"
          :fields="fields"
          :nested-table-data="nestedTableData"
          :items="browsersList"
          class="details-no-padding"
        >
          <template #cell(pageViewsCount)="row">
            <span class="font-weight-bold"> {{ row.item.pageViewsRatio }} </span>
            <span class="text-secondary"> ({{ kFormatter(row.item.pageViewsCount) }}) </span>
          </template>
          <template #cell(sessionsCount)="row">
            <span class="font-weight-bold"> {{ row.item.sessionsRatio }} </span>
            <span class="text-secondary"> ({{ kFormatter(row.item.sessionsCount) }}) </span>
          </template>
        </table-component>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { kFormatter } from '@/helper';
import TableComponent from './TableComponent.vue';
import kmtxFilterBuilder from './filters/builder/index.vue';

export default {
  name: 'BrowsersStatistic',
  components: {
    TableComponent,
    kmtxFilterBuilder,
  },
  data() {
    return {
      selectedDate: new Date(),
      filtersOptions: [
        {
          filter: 'datepicker',
          label: 'Dates',
        },
        {
          filter: 'input',
          label: 'Browser',
          placeholder: 'Type to Search...',
        },
        {
          filter: 'domainlist',
          label: 'Domain',
        },
      ],
      pagination: {
        per_page: 15,
      },
      tableId: 'browsers-table',
      browsersList: [],
      sortBy: 'sessionsCount',
      sortDesc: true,
      filter: '',
      fields: [
        {
          key: 'kmtx-expand', label: '', tdClass: 'table-cell--expand', thStyle: { width: '5%', 'min-width': '35px' },
        },
        {
          key: 'browserEngine', label: 'Browser', sortDirection: 'desc', sortable: true, thStyle: { width: '35%' },
        },
        {
          key: 'sessionsCount', label: 'Sessions count', sortable: true, thStyle: { width: '30%' },
        },
        {
          key: 'pageViewsCount', label: 'Page views count', sortable: true, thStyle: { width: '30%' },
        },
      ],
      nestedTableData: {
        property: 'browserStat',
        responsive: true,
        stickyHeader: true,
        sortBy: 'count',
        sortDesc: true,
        fields: [
          {
            key: 'kmtx-expand', label: '', tdClass: 'table-cell--expand', thStyle: { width: '5%', 'min-width': '35px' },
          },
          {
            key: 'v', label: 'Major version', sortable: true, thStyle: { width: '35%' },
          },
          {
            key: 'count', label: 'Sessions Count', sortable: true, thStyle: { width: '60%' },
          },
        ],
        nestedTableData: {
          property: 'list',
          responsive: true,
          stickyHeader: false,
          sortBy: 'count',
          sortDesc: true,
          fields: [
            {
              key: 'kmtx-expand', label: '', tdClass: 'table-cell--expand', thStyle: { width: '5%', 'min-width': '35px' },
            },
            {
              key: 'v', label: 'Operation System', sortable: true, thStyle: { width: '35%' },
            },
            {
              key: 'count', label: 'Sessions Count', sortable: true, thStyle: { width: '60%' },
            },
          ],
        },
      },
    };
  },
  computed: {
    ...mapGetters('filters', ['getDateForParams', 'getCurrentDomain']),
    dateFrom() {
      return this.getFormattedDate(this.selectedDate);
    },
  },
  methods: {
    ...mapActions('browsers', ['getBrowsers']),
    onSearchInput({ value }) {
      this.filter = value;
    },
    kFormatter(count) {
      return kFormatter(count);
    },
    getFormattedDate(date) {
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    },
    fillData() {
      const options = { showLoader: true };
      const params = {};
      params.label_source = [this.getCurrentDomain.id];
      params.label_type = this.getCurrentDomain.labelType;
      params.dateFrom = this.getDateForParams.dateFrom;
      params.dateTo = this.getDateForParams.dateTo;
      params.perPage = 1000;

      this.getBrowsers({ params, options }).then((browsersList) => {
        this.browsersList = browsersList;
      }).catch((error) => { if (!error.cancelReason) throw error; });
    },
  },
};
</script>
