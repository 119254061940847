<template>
  <div class="filters-putter px-3 py-2">
    <draggable
      v-model="filtersList"
      class="list-group"
      tag="ul"
      v-bind="dragOptions"
      @start="drag = true"
      @end="drag = false"
    >
      <transition-group
        type="transition"
        :name="!drag ? 'flip-list' : null"
      >
        <li
          v-for="(filter, index) in filtersList"
          :key="filter.order"
          class="list-group-item filter"
        >
          <b-row
            no-gutters
            class="grid"
          >
            <div class="grid__col grid--direction-row">
              <div class="filter__title font-weight-bold">
                {{ filterTitle(filter) }}
              </div>

              <template
                v-if="filter.filter_options && filter.filter_value === 'date'"
              >
                <b-form-checkbox
                  v-model="filter.filter_options.compare"
                  name="checkbox"
                  @change="setFiltersFormDirtyTo(true)"
                >
                  Compare
                </b-form-checkbox>
              </template>
            </div>
            <div class="grid__col--no-grow">
              <b-button
                size="sm"
                variant="outline-secondary"
                @click="removeFilter(index)"
              >
                <i
                  class="fa fa-trash"
                  aria-hidden="true"
                />
              </b-button>
            </div>
          </b-row>
        </li>
      </transition-group>
    </draggable>

    <div
      v-if="addNewFilter"
      class="filter-container mt-1 py-3"
    >
      <b-container fluid>
        <b-form
          @submit="addFilter"
        >
          <b-row class="mb-2">
            <b-col cols="9">
              <b-form-select
                id="filter"
                :options="availableFilters"
                @change="chooseFilter($event)"
              >
                <template #first>
                  <b-form-select-option
                    :value="null"
                    disabled
                  >
                    Please select filter
                  </b-form-select-option>
                </template>
              </b-form-select>
            </b-col>
            <b-col cols="3">
              <b-button
                ref="filter-add"
                variant="outline-primary"
                block
                type="submit"
              >
                Ok
              </b-button>
            </b-col>
          </b-row>
          <b-row
            v-if="newFilter && newFilter.filter_options"
            class="mt-4"
          >
            <b-col>
              <b-form-group
                label-for="label"
                label="Label"
              >
                <b-form-input
                  id="label"
                  v-model="newFilter.filter_options.label"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="newFilter && newFilter.filter_options && filterOptionsComponent">
            <b-col>
              <component
                :is="filterOptionsComponent"
                :filter="newFilter"
                class="mt-2"
                @change="filterOptionsChange($event)"
              />
            </b-col>
          </b-row>

          <template v-if="showWidgetsList">
            <b-row class="mt-4 mb-2">
              <b-col class="font-weight-bold">
                Select the field that should be filtered by each widget
              </b-col>
            </b-row>

            <div
              v-for="(widget, id) of widgets"
              :key="widget.id"
              class="widget-field"
            >
              <b-row>
                <b-col>
                  <b-form-group
                    :label="widget.text"
                  >
                    <kmtx-auto-suggest
                      :id="'widgetField-' + widget.id"
                      placeholder="Search for field for filtering"
                      label=""
                      :data="widget.fields.map(({ text: name, value }) => { return { name, value }; })"
                      :value="selectedFields[id]"
                      :in-form-group="false"
                      :filter-by-value="false"
                      @autosuggest-selected="selectedFields[id] = $event.data.value || null"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
          </template>
        </b-form>
      </b-container>
    </div>

    <b-col class="mt-3">
      <b-button
        ref="filters-add"
        variant="outline-primary"
        block
        :disabled="addNewFilter"
        @click="addNewFilter = true; newFilter = {}"
      >
        + Add new filter
      </b-button>
    </b-col>
    <b-row class="mt-5">
      <b-col>
        <b-button
          ref="filters-submit"
          type="submit"
          variant="primary"
          block
          @click="onSave()"
        >
          Apply
        </b-button>
      </b-col>
      <b-col>
        <b-button
          ref="filters-cancel"
          variant="secondary"
          block
          @click="onCancel()"
        >
          Cancel
        </b-button>
      </b-col>
      <b-col v-if="false">
        <b-button
          ref="filters-remove"
          variant="danger"
          block
          @click="clearList()"
        >
          Remove
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import clone from 'rfdc';
import {
  mapMutations, mapGetters, mapActions, mapState,
} from 'vuex';
import { leaveModalOptions } from '@/helper';
import DataMarts from '@/services/DataMarts';
import Toast from '@/mixins/Toast.vue';
import KmtxAutoSuggest from '@/components/filters/templates/autosuggest/index.vue';

const deepmerge = require('deepmerge');

export default {
  name: 'FiltersPutter',
  components: {
    draggable,
    'kmtx-auto-suggest': KmtxAutoSuggest,
  },
  mixins: [Toast],
  props: {
    items: {
      type: Array,
      required: false,
      default: () => [],
    },
    dashboardId: {
      type: Number,
      required: true,
    },
  },
  emits: ['save-filters'],
  data() {
    return {
      filtersList: [],
      drag: false,
      newFilter: {},
      addNewFilter: false,
      availableFilters: [],
      textAliases: [],
      widgets: [],
      selectedFields: [],
      filterOptionsComponent: null,
    };
  },
  computed: {
    ...mapState('dataMarts', ['filtersFormDirty']),
    ...mapGetters('dataMarts', ['getWidgetsKeyValue']),
    dragOptions() {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
      };
    },
    allFieldsSelected() {
      return this.selectedFields.length === this.widgets.length && this.selectedFields.every((field) => field?.widgetId);
    },
    filterOptionsLoader() {
      if (!this.newFilter.filter_value
                || !Object.keys(this.newFilter.filter_options).length
                || this.newFilter.filter_value === 'input'
                || this.newFilter.filter_value === 'datepicker'
                || this.newFilter.filter_value === 'step'
                || this.newFilter.static
      ) {
        return null;
      }
      return () => import(/* webpackMode: "eager" */`./filter-options/${this.newFilter.filter_value}`);
    },
    showWidgetsList() {
      return this.widgets?.length && this.newFilter.filter_value && this.newFilter.filter_value !== 'step';
    },
  },
  created() {
    this.getFilters();
    this.filtersList = clone()(this.items);
    this.filtersList.map((item, index) => { item.order = index; });
  },
  beforeDestroy() {
    this.setFiltersFormDirtyTo(false);
  },
  methods: {
    ...mapMutations('dataMarts', ['setFiltersBarVisibleTo', 'updateFiltersList', 'setFiltersFormDirtyTo']),
    ...mapActions('dataMarts', ['fetchDataMart']),
    // todo remove filter static flag when will add logic for editing filter
    getFilters() {
      DataMarts.getFilters().then(({ data: { data } }) => {
        DataMarts.getDictionariesList().then((list = {}) => {
          if (list.data?.data) {
            const { id: dictId } = data.find(({ value }) => value === 'dict');
            data.push(...list.data.data.map(({ name }) => ({
              id: dictId,
              value: 'dict',
              options: {
                name,
              },
              name,
              static: true,
            })));
          }
        }).finally(() => {
          this.availableFilters = data.map((filter) => {
            this.textAliases[filter.value] = filter.name;
            this.textAliases.splice(0, 1, { ...this.textAliases || {}, [filter.value]: filter.name });

            Object.keys(filter.options).forEach((option) => {
              if (filter.options[option] === 'bool') {
                filter.options[option] = false;
              } else if (!filter.static) {
                filter.options[option] = undefined;
              }
            });

            const mappedFilter = {
              value: {
                filter_type_id: filter.id,
                filter_options: filter.options,
                text: filter.name,
                filter_value: filter.value,
                static: filter.static,
              },
              text: filter.name,
            };

            if (filter.static) {
              mappedFilter.value.filter_name = filter.name;
            }

            return mappedFilter;
          }).sort((a, b) => a.text.localeCompare(b.text));
        });
      });
    },
    loadFilterOptions() {
      if (this.filterOptionsLoader) {
        this.filterOptionsLoader().then(() => {
          this.filterOptionsComponent = () => this.filterOptionsLoader();
        });
      }
    },
    onSave() {
      if (this.addNewFilter && this.allFieldsSelected) {
        this.addFilter();
      }

      if (!this.addNewFilter) {
        const list = this.filtersList.map(({
          filter_type_id, filter_options, filter_value, filter_fields, filter_name,
        }) => ({
          filter_fields, filter_type_id, filter_options, filter_value, filter_name,
        }));
        this.updateFiltersList({ dashboardId: this.dashboardId, list });
        this.$emit('save-filters');
      }
    },
    onCancel() {
      if (this.filtersFormDirty) {
        this.$bvModal.msgBoxConfirm('You can\'t undo this operation', leaveModalOptions()).then((doLeave) => {
          if (doLeave) {
            this.setFiltersBarVisibleTo(false);
          }
        });
      } else {
        this.setFiltersBarVisibleTo(false);
      }
    },
    clearList() {
      this.setFiltersFormDirtyTo(true);
      this.filtersList = [];
    },
    removeFilter(id) {
      this.setFiltersFormDirtyTo(true);
      this.filtersList.splice(id, 1);
    },
    addFilter() {
      if (this.newFilter && this.allFieldsSelected || this.newFilter.filter_value === 'step') {
        this.filterOptionsComponent = null;
        const filter = clone()(this.newFilter);
        if (this.selectedFields.length) {
          filter.filter_fields = clone()(this.selectedFields);
        }
        filter.order = this.filtersList.length;
        this.filtersList.push(filter);

        this.addNewFilter = false;
        this.newFilter = {};
        this.selectedFields = [];
      }
    },
    filterOptionsChange(value) {
      this.newFilter = deepmerge(this.newFilter, value);
    },
    chooseFilter(value) {
      this.setFiltersFormDirtyTo(true);
      this.filterOptionsComponent = null;
      this.newFilter = value;
      this.loadFilterOptions();
      this.selectedFields = [];
      if (!this.widgets.length) {
        this.getFieldsByWidgets();
      }
    },
    getFieldsByWidgets() {
      return Promise.all(this.getWidgetsKeyValue.map((item) => {
        const params = { expand: 'fields' };
        this.fetchDataMart({ id: item.dataMartId, params }).then(({ fields }) => {
          const widget = {
            id: item.value,
            text: item.key,
            fields: fields.map(({ id, name: text }) => ({
              text,
              value: {
                fieldId: id,
                widgetId: item.value,
              },
            })),
          };
          this.widgets.push(widget);
        });
      }));
    },
    filterTitle(filter) {
      if (filter.filter_options.label) {
        return filter.filter_options.label;
      }
      if (this.textAliases[0]) {
        return filter.filter_name || this.textAliases[0][filter.filter_value];
      }
      return filter.filter_value;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables";

.flip-list-move {
    transition: transform .5s;
}

.no-move {
    transition: transform 0s;
}

.ghost {
    opacity: .5;
    background: $light-blue-1;
}

.list-group {
    min-height: 20px;
}

.list-group-item {
    cursor: move;
}

.list-group-item i {
    cursor: pointer;
}

.filter-container {
    display: flex;
    background: $color-white;
    justify-content: center;
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: 5px;
    padding: 10px 0;
}
</style>
