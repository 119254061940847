<template>
  <table-component
    :id="widget.id + ''"
    show-empty
    hover
    fixed
    responsive
    stacked="md"
    :per-page="pagination.per_page"
    :fields="fields"
    :items="tableData"
    class="widget-table"
    :nested-table-data="nestedTableData"
  />
</template>

<script>
import TableComponent from '../../TableComponent.vue';

export default {
  name: 'TableWidget',
  components: {
    TableComponent,
  },
  props: {
    data: {
      type: Object,
      default: () => ({
        data: [],
      }),
    },
    widget: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      pagination: {
        per_page: 15,
      },
      fields: [],
      tableData: [],
      nestedTableData: {
        property: 'Children',
        responsive: true,
        stickyHeader: true,
        sortDesc: true,
        fields: [],
      },
      tableTitle: '',
    };
  },
  watch: {
    async data(to) {
      this.clearTable();
      this.setTableData(to);
    },
  },
  methods: {
    setTableData({ data = [] }) {
      this.widget.configuration?.columns?.forEach((col) => {
        const label = this.widget.configuration.labels?.find((label) => label.field_id === col.field_id)?.label || col.field_name;
        const field = {
          key: col.field_name, label, class: ['overflow-auto'], sortable: true,
        };
        this.fields.push(field);
        const isFieldNested = this.widget.configuration.children_group_field_id
                    && (col.field_id === this.widget.configuration.children_group_field_id || col.aggregation_function);
        if (isFieldNested) {
          this.setNestedItem(field, col);
        }
      });
      if (this.nestedTableData.fields.length) {
        const expandBtn = {
          key: 'kmtx-expand',
          label: '',
          tdClass: 'table-cell--expand',
          thStyle: { width: '5%', 'min-width': '35px' },
        };
        this.fields.unshift(expandBtn);
      }
      this.tableData = data || this.data;
    },
    setNestedItem(field, col) {
      if (col.field_id === this.widget.configuration.children_group_field_id) {
        this.nestedTableData.sortBy = col.field_name;
        this.nestedTableData.fields.unshift(field);
      } else {
        this.nestedTableData.fields.push(field);
      }
    },
    clearTable() {
      this.tableData = [];
      this.fields = [];
      this.nestedTableData.fields = [];
      this.nestedTableData.sortBy = undefined;
    },
  },
};
</script>

<style lang="scss">
.widget-table
{
    display: flex;
    flex-flow: column;
    .table-container__body
    {
        overflow: auto;
    }
}
</style>
