import { ApiV1 } from '@/api';

export default {
    getSourceStatistic(params: any, options: any) {
        params = params || {};
        return ApiV1(options).get('/stat/source', { params });
    },
    getRssBy(params: any, options: any) {
        return ApiV1(options).get('/stat/rss/by', { params });
    },
    getRssStat(params: any, options: any) {
        return ApiV1(options).get('/stat/rss/stat', { params });
    },
    getPageVisits(params: any, options: any) {
        params = params || {};
        return ApiV1(options).get('/stat/source/page-visits', { params });
    },
    getPageUniqueVisits(params: any, options: any) {
        params = params || {};
        return ApiV1(options).get('/stat/source/page-unique-visits', { params });
    },
    getOrdersStatistics(params: any, options: any) {
        params = params || {};
        return ApiV1(options).get('/stat/source/orders', { params });
    },
    getSourceVisits(params: any, options: any) {
        params = params || {};
        return ApiV1(options).get('/stat/source/source-count-visits', { params });
    },
    getCartStatistic(params: any, options: any) {
        params = params || {};
        return ApiV1(options).get('/stat/source/cart', { params });
    },
    getTopSearches(params: any, options: any) {
        params = params || {};
        return ApiV1(options).get('/stat/source/top-search', { params });
    },
    getTopDomains(params: any, options: any) {
        params = params || {};
        return ApiV1(options).get('/stat/source/top-domains', { params });
    },
    getRouteTransitionData(params: any, options: any) {
        params = params || {};
        return ApiV1(options).get('/stat/source/route-transition', { params });
    },
    getSearchStatsData(params: any, options: any) {
        params = params || {};
        return ApiV1(options).get('/stat/search', { params });
    },
    getKeywordStatsData(params: any, options: any) {
        params = params || {};
        return ApiV1(options).get('/stat/search/keyword', { params });
    },
    getKeywordProductImpression(params: any) {
        params = params || {};
        return ApiV1().get('/stat/search/keyword-impression', { params });
    },
    getKeywordSourceData(params: any) {
        params = params || {};
        return ApiV1().get('/stat/search/source', { params });
    },
    getEventSelectorStats(params: any, options: any) {
        return ApiV1(options).get('/stat/event-selector-stats', { params });
    },
};
