import UserManagement from '@/modules/user-management/services/UserManagement';
import ServicesManagement from '@/modules/service-management/services/ServicesManagement';

const state = {
  permissionsList: [],
  permissionsCategories: [],
  services: [],
};

const getters = {
  permissionsMap(state: any) {
    return (category: any) => {
      const dataList: any = {};
      let permissions: any = [];
      state.services.forEach((el: any) => {
        permissions = [...permissions, ...el.permissions];
      });
      permissions.forEach((el: any) => {
        if (el.service_id == category) {
          if (dataList[el.group]) {
            dataList[el.group].push(el);
          } else {
            dataList[el.group] = [el];
          }
        }
      });
      return dataList;
    };
  },

  permissionsByCategory(state: any) {
    return (category: any) => {
      const filtered = state.services?.find((item: any) => item.id === category) || [];
      return filtered.permissions;
    };
  },

  visiblePermissionsCategories(state: any) {
    return state.permissionsCategories?.filter((item: any) => item.visibility === true) || [];
  },

  visibleServices(state: any) {
    return state.services?.filter((item: any) => item.visibility === true && item.permissions.length) || [];
  },
};

const actions = {
  getPermissionsList({ commit }: any, params: any) {
    const requests: any[] = [];
    requests.push(UserManagement.getPermissions(params));
    requests.push(UserManagement.getPermissionsCategories(params));

    Promise.all(requests).then(([permissionsRequest, categoriesRequest]) => {
      if (categoriesRequest?.status === 200) {
        commit('setPermissionsCategories', categoriesRequest?.data?.data || []);
      }

      if (permissionsRequest?.status === 200) {
        commit('setPermissionsList', permissionsRequest?.data?.data || []);
      }
    });
  },

  getServices({ commit }: any, params: any) {
    const { options } = params;
    ServicesManagement.getServices({ limit: 1000 }, options).then((res: any) => {
      commit('setServices', res?.data?.data || []);
    });
  },

  clearPermissionsList({ commit }: any) {
    commit('clearPermissionsList');
  },

  clearServices({ commit }: any) {
    commit('clearServices');
  },

  clearPermissionsCategories({ commit }: any) {
    commit('clearPermissionsCategories');
  },
};

const mutations = {
  setPermissionsList(state: any, payload: any) {
    state.permissionsList = payload;
  },
  setPermissionsCategories(state: any, payload: any) {
    state.permissionsCategories = payload;
  },
  clearPermissionsList(state: any) {
    state.permissionsList.splice(0, state.permissionsList.length, []);
  },
  clearServices(state: any) {
    state.services.splice(0, state.services.length, []);
  },
  clearPermissionsCategories(state: any) {
    state.permissionsList.splice(0, state.permissionsCategories.length, []);
  },
  setServices(state: any, payload: any) {
    state.services = payload.map((el: any) => {
      const service = el;
      service.permissions = service.permissions.map((item: any) => ({ ...item, service_id: service.id }));
      return service;
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
