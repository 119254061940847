export const AB_TEST_LIST_SORTING = {
  sort_by: '',
  sort_direction: 'desc',
};

export const PER_PAGE_OPTIONS = [15, 25, 50];

export const AB_TEST_LIST_TABLE_FIELDS = [
  {
    key: 'status', label: 'Status', width: 8,
  },
  {
    key: 'id', label: 'Test ID', width: 10,
  },
  {
    key: 'markets', label: 'Markets', width: 15,
  },
  {
    key: 'date', label: 'Start/End Date', width: 15,
  },
  {
    key: 'creator', label: 'Creator', width: 15,
  },
  {
    key: 'actions', label: 'Actions', width: 5,
  },
];
