import axios, { AxiosHeaders } from 'axios';

import Auth, { refreshUrl } from '@/services/Auth';
import router from './router';
import store from './store';
import { RefreshingCall } from './store/modules/auth';
import LocalStorage from './services/LocalStorage';

let kmtxDynamicAPI;
let kmtxDynamicAPIv2;
let kmtxDynamicDataWizardAPI;
let kmtxDynamicValidationServiceAPI;

if (typeof localStorage !== 'undefined' && process.env.NODE_ENV === 'development') {
    kmtxDynamicAPI = LocalStorage.get('dynamic-api');
    kmtxDynamicAPIv2 = LocalStorage.get('dynamic-api-v2');
    kmtxDynamicDataWizardAPI = LocalStorage.get('dynamic-datawizard-api');
    kmtxDynamicValidationServiceAPI = LocalStorage.get('dynamic-validation-service-api');
}

const baseURL = `${kmtxDynamicAPI || process.env.VUE_APP_API_BASE}/api/1.0`;

function resolveUnauthorized(errorMessage = 'Error') {
    store.commit('auth/destroyTokens');
    if (router.currentRoute.name !== 'login') {
        router.replace({ name: 'login' });
        return Promise.resolve({ data: {} });
    }
    return Promise.reject({ message: errorMessage });
}

/**
 * Start refreshing token and return promise of that refreshing request.
 */
function toRefreshToken(): RefreshingCall {
    if (store.state.auth.isRefreshing && store.state.auth.refreshingCall) {
        return store.state.auth.refreshingCall;
    }
    const refreshToken = LocalStorage.get('refresh_token');
    if (!refreshToken) {
        return resolveUnauthorized();
    }
    store.commit('auth/setRefreshingState', true);
    const refreshingCall = Auth.refreshTokens(refreshToken)
        .then(({ data }) => {
            store.commit('auth/storeTokens', data);
            store.commit('auth/setRefreshingState', false);
            store.commit('auth/setRefreshingCall');
            return Promise.resolve(true);
        })
        .catch((error) => {
            store.commit('auth/setRefreshingState', false);
            store.commit('auth/setRefreshingCall');
            return resolveUnauthorized(error);
        });
    store.commit('auth/setRefreshingCall', refreshingCall);
    return refreshingCall;
}

/**
 * Checks if error relates to authentication, and if it is - try to refresh token.
 * If refresh fails - resolve as unauthorized.
 * @param error - interceptor callback error.
 */
function checkAuthentication(error: any): Promise<any> | null {
    if (error.config.url === refreshUrl) {
        return Promise.reject(error.response?.data?.message || `Can't refresh authentication token.`);
    }

    const isInvalidToken = error.response.data?.message ? error.response.data?.message.includes('Invalid JWT Token') : false;

    if (error.response.status === 401 || isInvalidToken) {
        return toRefreshToken()
            .then(() =>
                axios.request({ ...error.config, headers: { ...error.config.headers, Authorization: `Bearer ${store.state.auth.token}` } })
            )
            .catch((err) => {
                if (error.response.status === 401) {
                    const errorMsg = error.response?.data?.message || 'Unauthorized.';
                    return resolveUnauthorized(errorMsg);
                }
                throw err.response.data;
            });
    }
    return null;
}

export interface IApiOptions {
    showLoader: boolean;
    cantCancel: boolean;
    withoutAccountId: boolean;
    withoutVersion: boolean;
    notUseBearer: boolean;
    iamAccountId: string;
    baseUrl: string;
}

interface IApiConfig {
    accountIdParam: boolean;
    withCredentials: boolean;
    baseURL: string;
    headers: { [key: string]: string };
}

const getApiInstance =
    (
        baseUrl = '',
        config: Partial<IApiConfig> = {},
        withOutInterceptors = false,
        withOldErrorInterceptors = false,
        withoutAccountId = false,
    ) => (
        options: Partial<IApiOptions> = {
            showLoader: false,
            cantCancel: false,
            withoutVersion: false,
            notUseBearer: false,
            withoutAccountId,
        },
        params = { headers: {} }
    ) => {
        const baseConfig = {
            accountIdParam: false,
            withCredentials: false,
            baseURL: '',
            headers: {},
            ...config,
        };

        const ax = axios.create({
            baseURL: options.baseUrl || baseConfig.baseURL || `${baseUrl}/api/${options?.withoutVersion ? '' : 'v1/'}`,
            withCredentials: baseConfig.withCredentials,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
                ...baseConfig.headers,
                ...params.headers,
            },
        });

        if (!withOutInterceptors) {
            ax.interceptors.request.use((request) => {
                if (!request) {
                    request = {
                        headers: new AxiosHeaders(),
                    };
                }

                const token = LocalStorage.get('token');

                if (token) {
                    const notUseBearer = options?.notUseBearer;
                    request.headers.Authorization = `${notUseBearer ? '' : 'Bearer '}${token}`;
                }

                if (options?.showLoader) {
                    store.commit('loader/addPendingRequest');
                }
                if (!options?.cantCancel) {
                    request.cancelToken = store.getters['request/cancelToken'];
                }

                const iamAccountId =
                    options?.iamAccountId || sessionStorage.getItem('context_account_id') || LocalStorage.get('account_id');

                if (!options?.withoutAccountId && iamAccountId) {
                    request.headers['X-IAM-ACCOUNT-ID'] = iamAccountId;
                }

                const accountId = LocalStorage.get('account_incremental_id');
                if (baseConfig.accountIdParam && accountId) {
                    request.params = request.params || {};
                    request.params.account_id = accountId;
                }

                return request;
            });

            ax.interceptors.response.use(
                (response) => {
                    if (options?.showLoader) {
                        store.commit('loader/subtractPendingRequest');
                    }
                    if (store.state.request.connectionFailed) {
                        store.commit('request/setConnectionFailed', { [String(response.config.url)]: true });
                    }
                    return response;
                },
                (error) => {
                    if (options?.showLoader) {
                        store.commit('loader/subtractPendingRequest');
                    }

                    if (error.code === 'ERR_NETWORK') {
                        store.commit('request/setConnectionFailed', { [String(error.config.url)]: false });
                    }

                    if (axios.isCancel(error)) {
                        return Promise.reject({ cancelReason: error.message });
                    }

                    if (error.response) {
                        const authenticationError = checkAuthentication(error);

                        if (authenticationError) {
                            return authenticationError;
                        }

                        if (error.response.status === 406) {
                            return Promise.reject(error.response.data.detail);
                        }

                        if (error.response.status === 423) {
                            if (error.response.data.message.toLowerCase().includes('user is deactivated')) {
                                store.commit('auth/destroyTokens');
                            }
                            return Promise.reject(error.response.data.errors ? error.response.data : error.response.data.message);
                        }

                        if (withOldErrorInterceptors) {
                            const response = JSON.parse(error.response.request.response);

                            if (error.response.status === 400 || response.error === 'invalid_credentials') {
                                return Promise.reject(response.hint || response.message);
                            }

                            if (error.response.status === 422) {
                                return Promise.reject({ message: response.message, errors: response.errors || [] });
                            }

                            return Promise.reject(
                                response.hint || {
                                    message: error.message,
                                    errors: [error.response.statusText, response.exception, response.message],
                                }
                            );
                        }

                        return Promise.reject(error.response.data.errors ? error.response.data : error.response.data.message);
                    }
                    return Promise.reject(error);
                }
            );
        }
        return ax;
    };

const ApiV1 = getApiInstance(
    baseURL,
    {
        baseURL,
        withCredentials: true,
        accountIdParam: true,
        headers: {
            'x-iam-authenticate': '1',
        },
    },
    false,
    true
);
const ApiV2 = getApiInstance(kmtxDynamicAPIv2 || process.env.VUE_APP_API_KMTX_API_V2);

const DataWizardBaseUrl = kmtxDynamicDataWizardAPI || process.env.VUE_APP_API_DATA_WIZARD;
const ValidationServiceUrl = kmtxDynamicValidationServiceAPI || process.env.VUE_APP_API_VALIDATION_SERVICE;

const ApiDataWizard = getApiInstance(DataWizardBaseUrl);
const ApiEmitter = getApiInstance(process.env.VUE_APP_API_EMITTER, { accountIdParam: true });
const ApiPrometheus = getApiInstance(process.env.VUE_APP_API_PROMETHEUS, {}, true);
const ApiV1Scoring = getApiInstance(process.env.VUE_APP_API_SCORING, {
    accountIdParam: true,
    headers: {
        'X-Api-Token': process.env.VUE_APP_API_SCORING_TOKEN || '',
    },
}, false, false, true);
const externalBaseURL = kmtxDynamicAPI || process.env.VUE_APP_API_BASE;
const ApiV1External = getApiInstance('', {
    withCredentials: true,
    accountIdParam: true,
    baseURL: `${externalBaseURL}/api/1.0/esc-proxy`,
    headers: {
        'x-iam-authenticate': '1',
    },
});
const ApiStatusCollector = getApiInstance(process.env.VUE_APP_API_STATUS_COLLECTOR, {}, true);
const ApiValidationService = getApiInstance(ValidationServiceUrl, { baseURL: `${ValidationServiceUrl}/api/` });

export {
    baseURL,
    ApiV1,
    ApiV2,
    ApiEmitter,
    ApiPrometheus,
    ApiV1Scoring,
    ApiStatusCollector,
    ApiV1External,
    ApiDataWizard,
    ApiValidationService,
};
