import { ApiV2 } from '@/api';
import convertEmptyStringToNull from '@/shared/utils/convertEmptyStringToNull';

export interface IABTestForm {
    price_setting: {
        planned_pi: {
            multiplier: number | '';
            planned_pi_over: number | '';
            planned_pi_under: number | '';
        };
        guardrail: {
            min_marginality: number | '';
            pi_jump_down: number | '';
            pi_jump_up: number | '';
            price_jump_down: number | '';
            price_jump_up: number | '';
        };
        project: {
            market: {
                country: string;
                category: {value: string}[];
            }[];
            platform: string[];
        };
    };
    ab_setting: {
        fn_error: number | '';
        fp_error: number | '';
        key_metric: number;
        key_metric_percent: number | '';
    };
}
export interface IABTestModel {
    price_setting: {
        planned_pi: {
            multiplier: number | null;
            planned_pi_over: number | null;
            planned_pi_under: number | null;
        };
        guardrail: {
            min_marginality: number | null;
            pi_jump_down: number | null;
            pi_jump_up: number | null;
            price_jump_down: number | null;
            price_jump_up: number | null;
        };
        project: {
            market: {
              country: {
                      [key: string]: {
                          category: number[];
                      }
                  }
              },
            platform: string[];
        };
    };
    ab_setting: {
        fn_error: number | null;
        fp_error: number | null;
        key_metric: number;
        key_metric_percent: number | null;
    }
}

export interface IABTestItem {
  id: number;
  test_id: number;
  duration: number;
  algo_setting: IABTestModel;
  status: string;
  creator_id: string;
  created_at: string;
  updater_id?: string;
  updated_at?: string;
}
export interface IABTestRequest {
    algo_setting: IABTestModel
}

const apiPrefix = 'proxy/pricing-cockpit';
const apiBaseOptions = { withoutVersion: true };

function convertToABTestModel(ABTest: IABTestForm) {
  const marketArray = ABTest.price_setting.project.market;
  const marketAfterConvert = {};

  marketArray.forEach((market) => {
    marketAfterConvert[market.country] = {
      category: market.category.map((category) => category.value),
    };
  });

  return {
    price_setting: {
      planned_pi: {
        multiplier: null,
        planned_pi_over: ABTest.price_setting.planned_pi.planned_pi_over,
        planned_pi_under: ABTest.price_setting.planned_pi.planned_pi_under,
      },
      guardrail: {
        min_marginality: ABTest.price_setting.guardrail.min_marginality,
        pi_jump_down: ABTest.price_setting.guardrail.pi_jump_down,
        pi_jump_up: ABTest.price_setting.guardrail.pi_jump_up,
        price_jump_down: ABTest.price_setting.guardrail.price_jump_down,
        price_jump_up: ABTest.price_setting.guardrail.price_jump_up,
      },
      project: {
        market: {
          country: marketAfterConvert,
        },
        platform: ABTest.price_setting.project.platform,
      },
    },
    ab_setting: {
      fn_error: ABTest.ab_setting.fn_error,
      fp_error: ABTest.ab_setting.fp_error,
      key_metric: ABTest.ab_setting.key_metric,
      key_metric_percent: ABTest.ab_setting.key_metric_percent,
    },
  };
}

export default {
  getTestList(params: {[key: string]: string | number}, options?: any) {
    let query = '';
    if (params) {
      query = `?current_page=${params.page}&per_page=${params.limit}`;
    }
    return ApiV2(apiBaseOptions).get(`${apiPrefix}/tests/${query}`, params).then((resp) => resp.data).catch((error:any) => {
      if (!error.cancelReason) throw error;
    });
  },
  createABTest(ABTest: IABTestForm) {
    return ApiV2(apiBaseOptions).post(
      `${apiPrefix}/tests/`,
      { algo_setting: convertEmptyStringToNull(convertToABTestModel(ABTest)) },
    ).catch((error:any) => {
      throw error;
    });
  },
  launchABTest(id: string | number) {
    return ApiV2(apiBaseOptions).patch(
      `${apiPrefix}/tests/${id}/launch/`,
    ).catch((error:any) => {
      throw error;
    });
  },
  getCountryOptions() {
    return ApiV2(apiBaseOptions).get(`${apiPrefix}/countries/`).then((resp) => resp.data.data).catch((error:any) => {
      if (!error.cancelReason) throw error;
    });
  },
  stopABTest(id: number) {
    return ApiV2(apiBaseOptions).patch(`${apiPrefix}/tests/${id}/stop`).catch((error:any) => {
      if (!error.cancelReason) throw error;
    });
  },
  deleteABTest(id: number) {
    return ApiV2(apiBaseOptions).delete(`${apiPrefix}/tests/${id}`).catch((error:any) => {
      if (!error.cancelReason) throw error;
    });
  },
};
