<template>
  <b-form-group
    :label="label"
  >
    <div
      @mouseover="formGroupHovered = true"
      @mouseleave="formGroupHovered = false"
    >
      <vue-autosuggest
        :ref="reference"
        v-model="selected"
        :component-attr-id-autosuggest="'kmtx-domainlist-' + index"
        :input-props="{
          id: 'autosuggest-' + index + '__input',
          class: 'form-control',
          placeholder: placeholder,
        }"
        :suggestions="filteredData"
        :limit="options.limit"
        class="domain-list"
        @selected="onSelectHandler"
        @input="onInputChange"
      >
        <template
          v-if="showClearBtn"
          #before-input
        >
          <b-button
            class="domain-list__clear-btn"
            size="sm"
            variant="outline-light"
            @click="onSelectHandler(defaultSuggestion)"
          >
            <i class="fa fa-times" />
          </b-button>
        </template>
        <template #default="{ suggestion }">
          {{ suggestion.item.name }}
        </template>
      </vue-autosuggest>
    </div>
  </b-form-group>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { VueAutosuggest } from 'vue-autosuggest';

export default {
  name: 'KmtxDomainlist',
  components: {
    VueAutosuggest,
  },
  props: {
    index: {
      type: Number,
      default: 1,
    },
    label: {
      type: String,
      default: 'Domains',
    },
    placeholder: {
      type: String,
      default: 'Type an domain...',
    },
    reference: {
      type: String,
      default: 'kmtx-domainlist',
    },
    options: {
      type: Object,
      default: () => ({
        limit: Infinity,
      }),
    },
  },
  emits: ['domainlist-change', 'domainlist-input', 'domainlist-init'],
  data() {
    return {
      filteredData: [],
      formGroupHovered: false,
    };
  },
  computed: {
    selected: {
      get() {
        return this.getCurrentDomain.name;
      },
      set(newValue) {
        return newValue;
      },
    },
    ...mapGetters('filters', {
      getDomains: 'getDomains',
      getCurrentDomain: 'getCurrentDomain',
    }),
    ...mapState('source', {
      sourceLabels: (state) => state.sourceLabels,
    }),
    showClearBtn() {
      return this.formGroupHovered && this.selected && this.selected !== 'All';
    },
    defaultSuggestion() {
      return { item: this.getDomains.find((domain) => domain.id === 0) };
    },
  },
  created() {
    if (this.getDomains.length === 0) {
      this.$store.dispatch('source/fillSourceLabels').then(() => {
        this.setDomains(this.sourceLabels);
        this.init();
      }).catch((error) => { if (!error.cancelReason) throw error; });
    } else {
      this.init();
    }
  },
  beforeDestroy() {
    this.setCurrentDomainByQueryParams();
  },
  methods: {
    onSelectHandler(selected) {
      if (selected !== null && selected.item.name !== undefined
                && this.getCurrentDomain.name !== selected.item.name) {
        this.setCurrentDomain(selected.item);
        this.changeQueryParams(selected.item.id === 0);
        this.$emit('domainlist-change', selected.item);
        this.formGroupHovered = false;
      }
    },
    onInputChange(domain) {
      domain = domain.trim().toLowerCase();

      if (domain !== null && domain.length > 0 && this.getDomains.length > 0) {
        let filteredData = this.getDomains.filter((item) => {
          if (item.name !== undefined) {
            return item.name.trim().toLowerCase().indexOf(domain) > -1 && item.is_active;
          }
        });

        filteredData = this.sortData(filteredData, domain);

        this.filteredData = [{ data: filteredData }];
      }

      this.$emit('domainlist-input', {
        reference: this.reference,
        data: domain,
      });
    },
    sortData(data, input) {
      const alphabetSorted = data.sort((a, b) => a.name.localeCompare(b.name));

      const preSort = [];
      const postSort = [];

      alphabetSorted.forEach((item) => {
        if (item.name.toLowerCase().indexOf(input) === 0) {
          preSort.push(item);
        } else if (item.name.toLowerCase().indexOf(input) > 0) {
          postSort.push(item);
        }
      });

      return preSort.concat(postSort);
    },
    changeQueryParams(isDefault) {
      if (isDefault) {
        delete this.$route.query.host;
        delete this.$route.query.labeltype;
      } else {
        this.$router.replace({
          name: this.$route.name,
          query: {
            ...this.$route.query,
            host: this.getCurrentDomain.id,
            labeltype: this.getCurrentDomain.labelType,
          },
        });
      }
    },
    init() {
      // if host and labelType exist and not match same params in store
      const changeDomain = (!!this.$route.query.host && !!this.$route.query.labeltype)
                && (Number(this.$route.query.host) !== this.getCurrentDomain.id
                || this.$route.query.labeltype !== this.getCurrentDomain.labelType);
      if (changeDomain) {
        this.setCurrentDomainByQueryParams(this.$route.query);
      }
      // if query had invalid data => remove host and labeltype from query string
      if ((Number(this.$route.query.host) !== this.getCurrentDomain.id) || this.getCurrentDomain.id === 0) {
        this.changeQueryParams(true);
      }

      this.$emit('domainlist-init');
    },
    ...mapActions('filters', {
      setDomains: 'setDomains',
      setCurrentDomain: 'setCurrentDomain',
      setCurrentDomainByQueryParams: 'setCurrentDomainByQueryParams',
    }),
  },
};
</script>

<style lang="scss">
    .domain-list {
        min-width: 210px;
        position: relative;
    }

    .domain-list__clear-btn {
        position: absolute;
        height: calc(100% - 2px);
        bottom: 1px;
        right: 1px;

        &.btn {
            background-color: #fff;
        }

        .fa-times {
            color: #212529;
        }
    }
</style>
