<template>
    <div
        class="content orders-n-sessions-charts mb-3"
        id="orders-n-sessions-charts"
    >
        <KFlexContainer class="orders-n-sessions-charts__filters">
            <KFlexContainer direction="column">
                <KText
                    size="lg"
                    weight="extra-light"
                >
                    Date
                </KText>
                <KDatePicker
                    :dates-range="datesRange"
                    :is-date-range-type="false"
                    :append-to-body="false"
                    :clearable="false"
                    shown-format="YYYY-MM-DD"
                    @change="onActualDateChange"
                />
            </KFlexContainer>

            <KFlexContainer direction="column">
                <KText
                    size="lg"
                    weight="extra-light"
                >
                    Compare with date
                </KText>
                <KDatePicker
                    :dates-range="datesRangeCompare"
                    :is-date-range-type="false"
                    :append-to-body="false"
                    shown-format="YYYY-MM-DD"
                    @change="onCompareDateChange"
                />
            </KFlexContainer>

            <KFlexContainer direction="column">
                <KText
                    size="lg"
                    weight="extra-light"
                >
                    Traffic origin
                </KText>
                <KDropdown
                    :options="trafficTypes"
                    :selected="trafficTypes[0]"
                    :unselect="false"
                    @updated="onTrafficTypeChange"
                />
            </KFlexContainer>

            <KFlexContainer
                direction="column"
                class="orders-n-sessions-charts__filters-sources"
            >
                <KFlexContainer wrap="nowrap">
                    <KRadio
                        size="sm"
                        label="Domains"
                        value="source"
                        :checked="trafficSourceTypesSelected"
                        @input="onSourceTypeChange"
                    />
                    <KText
                        size="lg"
                        weight="extra-light"
                    >
                        /
                    </KText>
                    <KRadio
                        size="sm"
                        label="Projects, Platforms, Countries"
                        value="label"
                        :checked="trafficSourceTypesSelected"
                        @input="onSourceTypeChange"
                    />
                </KFlexContainer>
                <KSearchField
                    :options="labelsList"
                    label="Select a label..."
                    :value="selectedLabel.name"
                    :show-search-icon="false"
                    class="orders-n-sessions-charts__filters-item"
                    :class="{ 'orders-n-sessions-charts__filters-item--visible': trafficSourceTypesSelected === 'label' }"
                    @updated="onLabelChange"
                    @cleared="onLabelClear"
                />
                <Multiselect
                    multiple
                    label="name"
                    placeholder="Select domain(s)..."
                    :close-on-select="false"
                    :clear-on-select="false"
                    :hide-selected="true"
                    :options="domainsList"
                    :value="selectedDomains"
                    class="orders-n-sessions-charts__filters-item"
                    :class="{ 'orders-n-sessions-charts__filters-item--visible': trafficSourceTypesSelected === 'source' }"
                    @select="onDomainChange"
                    @remove="onDomainChange"
                />
            </KFlexContainer>
        </KFlexContainer>

        <div>
            <h5>Total Orders</h5>
            <BigNumbers
                class="mb-5 mt-3"
                items-wrap
                items-count="3"
            >
                <BigNumber
                    :value="actualDateTotalOrders"
                    variant="info"
                >
                    <template #header> Today </template>
                </BigNumber>
                <BigNumber
                    :value="offsetDateTotalOrders"
                    variant="success"
                >
                    <template #header> Week Ago </template>
                </BigNumber>
                <BigNumber
                    :value="compareDateTotalOrders"
                    default-text="Compare Date not set"
                    variant="warning"
                >
                    <template #header> By Compare Date </template>
                </BigNumber>
            </BigNumbers>
        </div>
        <BRow class="mb-4">
            <BCol>
                <KmtxChart
                    ref="ordersStatistic"
                    :options="ordersChart"
                    :is-loading="isLoading.orders"
                    class="orders-chart"
                />
            </BCol>
        </BRow>

        <BRow>
            <BCol>
                <KmtxChart
                    ref="sessionsStatistic"
                    :options="sessionsChart"
                    :is-loading="isLoading.sessions"
                    class="sessions-chart"
                />
            </BCol>
        </BRow>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { KDatePicker, KDropdown, KFlexContainer, KRadio, KSearchField, KText } from '@kilometrix/polyomino';
import Multiselect from 'vue-multiselect';
import OrderService from '../services/Order';
import SessionsService from '../services/Sessions';
import EChartComponent from './echart/EChartComponent.vue';
import LineChartOptions from './echart/options/line-chart';
import BigNumber from './widgets/bigNumber/index.vue';
import BigNumbers from './widgets/bigNumbers/index.vue';

const DEFAULT_INTERVAL = 1000 * 60 * 60;
const DEFAULT_DAY_OFFSET = 7;
const charts = {
    orders: {
        service: OrderService,
        method: 'getOrdersTimeline',
        title: 'Orders',
        header: 'Orders compared with previous week',
    },
    sessions: {
        service: SessionsService,
        method: 'getSessionsTimeline',
        title: 'Sessions',
        header: 'Sessions compared with previous week',
    },
};

export default {
    name: 'OrdersComponent',
    components: {
        BigNumber,
        BigNumbers,
        KmtxChart: EChartComponent,
        Multiselect,
        KDatePicker,
        KDropdown,
        KFlexContainer,
        KRadio,
        KSearchField,
        KText,
    },
    data() {
        return {
            startDate: null,
            actualDate: null,
            compareDate: null,
            datesRange: null,
            datesRangeCompare: null,
            sourceId: this.$route.params.id || 0,
            actualDateTotalOrders: 0,
            offsetDateTotalOrders: 0,
            compareDateTotalOrders: 0,
            ordersChart: {},
            sessionsChart: {},
            trafficTypeSelectedData: {
                trafficType: null,
                externalTrafficType: null,
                adsType: null,
                trafficOrigin: null,
            },
            isLoading: {
                orders: false,
                sessions: false,
            },
            allDomainsLabelsItem: { id: 0, name: 'All', labelType: 'all_sources' },
            trafficSourceTypesSelected: 'source',
            labelsList: [],
            domainsList: [],
            allActiveSources: null,
            selectedLabel: null,
            selectedDomains: [],
            trafficTypes: [
                { id: null, name: 'All' },
                { id: 1, name: 'Mobile Web' },
                { id: 0, name: 'Desktop' },
            ],
        };
    },
    computed: {
        ...mapGetters('filters', {
            getCurrentDomain: 'getCurrentDomain',
        }),
        ...mapState('source', { sourceLabels: 'sourceLabels' }),
        offsetDate() {
            return new Date(this.actualDate.getTime() - DEFAULT_DAY_OFFSET * 24 * 60 * 60 * 1000);
        },
        isAllSourcesSelected() {
            return (
                (this.trafficSourceTypesSelected === 'source' &&
                    ((this.selectedDomains.length === 1 && this.selectedDomains[0].id === 0) || this.selectedDomains.length === 0)) ||
                (this.trafficSourceTypesSelected === 'label' && this.selectedLabel.id === 0)
            );
        },
    },
    async created() {
        this.selectedLabel = this.allDomainsLabelsItem;
        this.selectedDomains = [this.allDomainsLabelsItem];
        this.labelsList = [this.allDomainsLabelsItem];
        this.domainsList = [this.allDomainsLabelsItem];

        if (!this.sourceLabels.length) {
            await this.fillSourceLabels({}).catch((error) => {
                if (!error.cancelReason) {
                    this.errorMessage(error, 'Failed to get all sources');
                }
            });
        }

        this.allActiveSources = this.sourceLabels
            .filter(({ is_active, label }) => label || is_active)
            .map(({ id, host, label }) => ({
                id,
                name: host,
                labelType: label ? 'label' : 'source',
            }));

        this.labelsList = this.allActiveSources.filter(({ labelType }) => labelType === 'label');
        this.domainsList = this.allActiveSources.filter(({ labelType }) => labelType === 'source');

        this.labelsList.unshift(this.allDomainsLabelsItem);
        this.domainsList.unshift(this.allDomainsLabelsItem);

        this.setActualDate();
        this.setSourceByQueryParams();
        this.fillChartsData();
    },
    mounted() {
        const firstChartTop = document.querySelector('.kmtx-echart').getBoundingClientRect().top + window.scrollY;

        document.querySelector('.orders-n-sessions-charts').style.setProperty('--chartTop', `${firstChartTop}px`);

        // update Charts in the beginning of next interval period
        const now = new Date();
        const start = DEFAULT_INTERVAL - (now.getMinutes() * 60 + now.getSeconds()) * 1000 + now.getMilliseconds();
        setTimeout(this.updateCharts, start);
    },
    methods: {
        ...mapActions('source', ['fillSourceLabels']),
        updateCharts() {
            this.fillChartsData();
            setTimeout(this.updateCharts, DEFAULT_INTERVAL);
        },
        setActualDate() {
            const date = new Date();
            this.startDate = date.toDateString();
            this.actualDate = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), 0, 0);
            this.datesRange = { dateFrom: this.getFormattedDate(this.actualDate) };
        },
        getFormattedDate(date) {
            function pad(n) {
                return n < 10 ? `0${n}` : n;
            }
            return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}`;
        },
        fillChartsData() {
            const params = this.getParams();

            for (const service in charts) {
                const taskApi = charts[service].service;
                const taskMethod = charts[service].method;
                const title = charts[service].title.toLowerCase();
                this.isLoading[title] = true;
                this.getTimelineData(service, taskApi, taskMethod, params)
                    .then((tasksResponse) => {
                        //   const actualDate = this.actualDate.toDateString();
                        //   const nowDate = new Date().toDateString();
                        //   const nowDateFormatted = new Date().toISOString().split('T')[0]; // YYYY-MM-DD
                        //   const nowHours = new Date().getUTCHours();
                        //   const actualTimezoneName = 'Europe/Kiev';// this.currentUser.timezone;
                        //   const timezoneOptions = { timeZone: actualTimezoneName, timeZoneName: 'longOffset' };
                        //   const dateText = Intl.DateTimeFormat([], timezoneOptions).format(new Date());
                        //   const timezoneString = dateText.split(' ')[1].slice(3) || '0';
                        //   const actualTimezone = parseInt(timezoneString);
                        //   let nowActualHours = nowHours + actualTimezone;
                        //   if (nowActualHours >= 24) { nowActualHours = nowHours - 24; }
                        //   if (nowActualHours < 0) { nowActualHours = nowHours + 24; }
                        //   if (actualDate === nowDate) {
                        //     tasksResponse.data[nowActualHours][nowDateFormatted] = 0;
                        //   }
                        this.isLoading[title] = false;
                        this.setChartData(tasksResponse.data, tasksResponse.service, params);
                        if (service === 'orders') {
                            this.setTotalsData(tasksResponse.data);
                        }
                    })
                    .catch((error) => {
                        if (!error.cancelReason) {
                            this.isLoading[title] = false;
                            throw error;
                        }
                    });
            }
        },
        setTotalsData(data) {
            const [actual, offset, compared] = this.getParams().dates;
            let actualTotal = 0;
            let offsetTotal = 0;
            let comparedTotal = 0;
            data.forEach((item) => {
                actualTotal += item[actual];
                offsetTotal += item[offset];
                if (compared) {
                    comparedTotal += item[compared];
                }
            });
            this.actualDateTotalOrders = actualTotal;
            this.offsetDateTotalOrders = offsetTotal;
            this.compareDateTotalOrders = comparedTotal;
        },
        setChartData(data, service) {
            const chart = `${service}Chart`;
            const chartOptions = {
                toolbox: {
                    show: true,
                },
            };
            this[chart] = new LineChartOptions(data, chartOptions);

            this[chart].title.text = charts[service].header;
            this[chart].legend.data = [];
            this[chart].series.forEach((item) => {
                item.name = `${charts[service].title} date: ${item.id}`;
                if (item.data && item.data.length) {
                    this[chart].legend.data.push(item.name);
                }
            });
        },
        getTimelineData(service, api, method, params) {
            return api[method](params).then((response) => ({
                service,
                data: response.data,
            }));
        },
        getDateFromString(date) {
            const dateParts = date.split('-');

            if (dateParts.length !== 3) {
                return null;
            }

            return new Date(Number(dateParts[0]), Number(dateParts[1]) - 1, Number(dateParts[2]), 0, 0);
        },
        getParams() {
            const params = Object.create(null);

            params.label_source = this.trafficSourceTypesSelected === 'label' ? [this.selectedLabel.id] : this.getSelectedDomainsId();
            params.label_type = this.isAllSourcesSelected ? 'all_sources' : this.trafficSourceTypesSelected;

            params.dates = [this.getFormattedDate(this.actualDate), this.getFormattedDate(this.offsetDate)];

            if (this.compareDate !== null && this.compareDate !== this.actualDate) {
                params.dates.push(this.getFormattedDate(this.compareDate));
            }

            params.trafficType = this.trafficTypeSelectedData.trafficType;
            params.externalTrafficType = this.trafficTypeSelectedData.externalTrafficType;
            params.aType = this.trafficTypeSelectedData.adsType;
            params.trafficOrigin = this.trafficTypeSelectedData.trafficOrigin;

            return params;
        },

        changeQueryParams() {
            if (this.isAllSourcesSelected) {
                if (!this.$route.query.host && !this.$route.query.labeltype) return;

                this.$router.replace({ query: null });
            } else {
                this.$router.push({
                    name: this.$route.name,
                    query: {
                        ...this.$route.query,
                        host: this.trafficSourceTypesSelected === 'label' ? this.selectedLabel.id : this.getSelectedDomainsIdString(),
                        labeltype: this.trafficSourceTypesSelected,
                    },
                });
            }
        },

        setSourceByQueryParams() {
            if (!this.$route.query.host && !this.$route.query.labeltype) return;

            const { host, labeltype } = this.$route.query;

            if (labeltype !== 'label' && labeltype !== 'source') return;

            this.trafficSourceTypesSelected = labeltype;

            if (labeltype === 'label') {
                if (Number(host) !== this.selectedLabel.id) {
                    const label = this.labelsList.find(({ id }) => id === Number(host));
                    this.selectedLabel = label || this.allDomainsLabelsItem;
                }
            } else if (host !== this.getSelectedDomainsIdString()) {
                const hostIds = host.split(',');
                const domains = this.domainsList.filter(({ id }) => hostIds.includes(String(id)));
                this.selectedDomains = domains.length ? domains : [this.allDomainsLabelsItem];
            }
        },

        getSelectedDomainsId() {
            return this.selectedDomains.map(({ id }) => id);
        },

        getSelectedDomainsIdString() {
            return this.getSelectedDomainsId().join(',');
        },

        onActualDateChange({ date }) {
            this.actualDate = this.getDateFromString(date);
            this.fillChartsData();
        },

        onCompareDateChange({ date }) {
            if (!date) {
                this.compareDate = null;
                this.datesRangeCompare = null;
                this.fillChartsData();
                return;
            }

            this.compareDate = this.getDateFromString(date);
            this.datesRangeCompare = { dateFrom: this.getFormattedDate(this.compareDate) };
            this.fillChartsData();
        },

        onTrafficTypeChange({ id }) {
            this.trafficTypeSelectedData.trafficOrigin = id;
            this.fillChartsData();
        },

        onSourceTypeChange(value) {
            this.trafficSourceTypesSelected = value;
            this.changeQueryParams();
            this.fillChartsData();
        },

        onLabelChange(value) {
            this.selectedLabel = value;
            this.changeQueryParams();
            this.fillChartsData();
        },

        onLabelClear() {
            this.selectedLabel = this.allDomainsLabelsItem;
            this.changeQueryParams();
            this.fillChartsData();
        },

        onDomainChange(option) {
            if (this.selectedDomains.find(({ id }) => id === option.id)) {
                this.selectedDomains = this.selectedDomains.filter(({ id }) => id !== option.id);
            } else {
                this.selectedDomains.push(option);
            }

            this.changeQueryParams();
            this.fillChartsData();
        },
    },
};
</script>

<style lang="scss" scoped>
.orders-n-sessions-charts {
    &__filters {
        gap: 15px;
        margin-bottom: 40px;

        &-item {
            display: none;

            &--visible {
                display: block;
            }
        }

        .k-flex-item {
            gap: 15px;
        }

        .k-text {
            color: var(--k-secondary-light);
        }

        .k-container {
            gap: 8px;
            width: 210px;
        }

        &-sources.k-container {
            width: fit-content;
            min-width: 360px;
            max-width: calc(100% - 234 * 3px);

            .k-container {
                width: 360px;
            }
        }

        :deep(.k-multi-select) {
            width: 100%;
            min-height: 40px;

            .k-multi-select__field {
                min-height: 38px;
                border-color: var(--textfield-default-border-color);

                &:hover {
                    border-color: var(--k-secondary-light);
                }
            }

            .k-multi-select__placeholder {
                margin-block: 9px;
            }

            .k-multi-select__selected-options {
                margin-block: 7px;
            }
        }

        :deep(.k-radio) {
            margin-block: 4px;

            .k-radio__label {
                font-size: 16px;
                color: var(--k-secondary-light);
                font-weight: 200;
            }
        }
    }

    :deep(.echarts) {
        width: 100%;
        min-height: 250px;
        height: calc((100vh - var(--chartTop)) / 2);
    }
}
#orders-n-sessions-charts {
    :deep(.multiselect) {
        .multiselect__tags {
            padding-inline: 8px;
            border-radius: 10px;
            border-color: var(--textfield-default-border-color);

            &:hover {
                border-color: var(--k-secondary-light);
            }
        }

        .multiselect__tag {
            font-size: 14px;
        }

        .multiselect__placeholder {
            color: var(--textfield-default-placeholder-color);
            padding-block: 1.5px;
        }

        .multiselect__input {
            padding-block: 1.5px;
            color: var(--k-text-primary);

            &::placeholder {
                color: var(--textfield-default-placeholder-color);
            }
        }

        .multiselect__select {
            display: none;
        }
    }
}
</style>
