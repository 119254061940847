<template>
    <div
        class="content-wrapper"
        :class="{
            'content-wrapper--header-collapsed': headerCollapsed,
            'content-wrapper--with-connection-error': isConnectionFailed,
            'content-wrapper--sidebar-collapsed': sidebarCollapsed,
        }"
    >
        <AppHeader v-if="isLoggedIn" />
        <div class="container-fluid">
            <div class="row flex-xl-nowrap2">
                <KmtxSidebar :menu="sidebarMenu" />

                <div class="col kmtx-content">
                    <Loader
                        :is-loading="isLoading"
                        :message="loadingMessage"
                    />

                    <ContentHeader v-if="$route.meta.header" />
                    <ConnectionWarning v-if="isConnectionFailed" />
                    <RouterView />
                </div>
            </div>
        </div>
        <ToastContainer />
        <PortalTarget name="modal" />
        <PortalTarget name="toasts" />
    </div>
</template>
<script>
import { startKmtxWebTracker3rdParty } from '@kmtx-cltr/integration';
import { mapGetters, mapState } from 'vuex';
import EventBus from '@/event';
import CancelAxios from '@/cancel';
import ConnectionWarning from '@/components/ConnectionWarning.vue';
import ToastContainer from '@/components/ToastContainer.vue';
import ToastsService from '@/services/Toasts';
import KmtxSidebar from './MenuSidebar.vue';
import ContentHeader from './ContentHeader.vue';
import Loader from './Loader.vue';
import AppHeader from './Header.vue';

export default {
    components: {
        KmtxSidebar,
        ContentHeader,
        Loader,
        AppHeader,
        ToastContainer,
        ConnectionWarning,
    },

    data() {
        return {
            loadingMessage: 'Loading...',
            sidebarCollapsed: false,
            headerCollapsed: false,
            sidebarMenu: {},
            axiosCancel: null,
        };
    },
    computed: {
        ...mapState('auth', { currentUser: 'currentUser', isLoggedIn: 'token' }),
        ...mapGetters('request', ['isConnectionFailed']),
        ...mapGetters('loader', ['isLoading']),
        ...mapGetters('dataMarts', ['getDashboardsMenuItems', 'getRoutesFromDashboards']),
    },

    created() {
        startKmtxWebTracker3rdParty(`${process.env.VUE_APP_API_EMITTER}/static`);
        this.axiosCancel = new CancelAxios();
        EventBus.$on('kmtx-cancel-requests', () => this.axiosCancel.cancelRequests());

        EventBus.$on('kmtx-toast', (heading, message, type) => {
            ToastsService.add({
                type,
                heading,
                message,
            });
        });

        EventBus.$on('kmtx-header-collapsed', (value) => {
            this.headerCollapsed = value;
        });

        EventBus.$on('kmtx-sidebar-collapsed', (menu) => {
            if (menu?.invisible) return;

            this.sidebarCollapsed = menu?.items?.length;
            this.sidebarMenu = menu || {};
        });

        EventBus.$on('kmtx-toast-error', (error, spareTitle = '') => {
            this.errorMessage(error, spareTitle, null, true);
        });
    },
};
</script>
