<template>
  <div :class="['widget-putter__chart', blockHovered ? 'widget-putter__chart--hovered' : '']">
    <b-form-group
      label="Axis field"
      label-for="x-axis-field"
    >
      <b-form-select
        id="x-axis-field"
        v-model="chartForm.x_axes_field_id"
        text-field="name"
        value-field="id"
        :options="axisFields"
        required
        :disabled="!widgetConfiguration.data_mart_id || !axisFields.length"
        @change="setFieldNameById('x_axes_field_name', chartForm.x_axes_field_id, axisFields)"
      >
        <template #first>
          <b-form-select-option
            :value="null"
            disabled
          >
            Please select Axis field
          </b-form-select-option>
        </template>
      </b-form-select>
    </b-form-group>

    <div
      :class="['configurable-field', yFieldExpanded ? 'configurable-field--active' : '']"
    >
      <b-row>
        <b-col class="grid">
          <div class="grid__col">
            <b-form-group
              label="Series"
              label-for="y-axis"
            >
              <b-form-select
                id="y-axis"
                v-model="chartForm.y_axes_field_id"
                text-field="name"
                value-field="id"
                :options="aggregationFields"
                required
                :disabled="!widgetConfiguration.data_mart_id || !dataSources.length"
                @change="setFieldNameById('y_axes_field_name', chartForm.y_axes_field_id, aggregationFields)"
              >
                <template #first>
                  <b-form-select-option
                    :value="null"
                    disabled
                  >
                    Please select series
                  </b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </div>
          <div
            v-if="widgetConfiguration.report_type !== 'pie-chart'"
            class="grid__col--no-grow align-self-end mb-3"
          >
            <button
              class="list-item-btn stat-configuration-btn"
              type="button"
              :disabled="!widgetConfiguration.data_mart_id || !dataSources.length"
              @click="yFieldExpanded = !yFieldExpanded"
            >
              <icon
                image="configuration.svg"
                label="Field configuration"
                height="15"
                width="15"
                :size="{ height: '20px', width: '20px' }"
              />
            </button>
          </div>
        </b-col>
      </b-row>
      <b-row v-if="yFieldExpanded">
        <b-col>
          <b-form-group
            label="Label"
            label-for="y-axis-label"
          >
            <b-form-input
              id="y-axis-label"
              v-model="chartForm.label"
              placeholder="Add y-axis label"
              trim
              autocomplete="off"
            />
          </b-form-group>

          <b-row>
            <b-col>
              <b-form-group
                label="Aggregation function"
                label-for="aggregation-function"
              >
                <b-form-select
                  id="aggregation-function"
                  v-model="chartForm.y_axes_aggregation_function"
                  required
                  :options="aggregationTypes"
                  :disabled="!widgetConfiguration.data_mart_id || !dataSources.length"
                  @change="$emit('touch-form')"
                >
                  <template #first>
                    <b-form-select-option
                      :value="null"
                      disabled
                    >
                      Select aggregation function
                    </b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import IconsComponent from '../../../../IconsComponent.vue';

export default {
  name: 'ChartForm',
  components: {
    icon: IconsComponent,
  },
  props: {
    widgetConfiguration: {
      type: Object,
      default: () => {},
    },
    dataSources: {
      type: Array,
      default: () => [],
    },
    dataSourceFields: {
      type: Array,
      default: () => [],
    },
    aggregationTypes: {
      type: Array,
      default: () => [],
    },
    blockHovered: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['touch-form', 'change'],
  data() {
    return {
      chartForm: {},
      yFieldExpanded: false,
    };
  },
  computed: {
    axisFields() {
      return this.dataSourceFields.filter((field) => {
        const lineChart = this.widgetConfiguration?.report_type === 'line-chart';
        if (lineChart) {
          return field.type === 'DateTime' || field.type === 'Date';
        }
        return true;
      });
    },
    aggregationFields() {
      return this.dataSourceFields.filter((field) => {
        const lineChart = this.widgetConfiguration?.report_type === 'line-chart';
        if (lineChart) {
          return !(field.type === 'DateTime' || field.type === 'Date');
        }
        return true;
      });
    },
  },
  watch: {
    chartForm: {
      handler() {
        const {
          x_axes_field_id,
          y_axes_field_id,
          x_axes_field_name,
          y_axes_field_name,
          y_axes_aggregation_function,
          label,
        } = this.chartForm;
        const configChanges = {
          x_axes_field_id,
          y_axes_field_id,
          x_axes_field_name,
          y_axes_field_name,
          y_axes_aggregation_function,
        };
        if (this.widgetConfiguration?.report_type !== 'pie-chart' && label) {
          configChanges.labels = [{
            field_id: y_axes_field_id,
            label: label || undefined,
          }];
        }
        this.$emit('change', configChanges);
      },
      deep: true,
    },
  },
  created() {
    const {
      x_axes_field_id,
      y_axes_field_id,
      x_axes_field_name,
      y_axes_field_name,
      y_axes_aggregation_function = 'uniq',
      labels = [],
    } = this.widgetConfiguration;
    this.chartForm = {
      x_axes_field_id,
      y_axes_field_id,
      y_axes_aggregation_function,
      x_axes_field_name,
      y_axes_field_name,
      label: labels[0]?.label,
    };
  },
  methods: {
    setFieldNameById(field, id, source) {
      this.$emit('touch-form');
      this.chartForm[field] = source.find((item) => item.id === id)?.name || '';
    },
  },
};
</script>

<style lang="scss">
@import "@/sass/_variables";

.widget-putter__chart {
    .configurable-field {
        margin: 0 -1rem;
        padding: 0 1rem 0;
        transition: background-color .2s, padding .3s;
        background-color: transparent;
    }

    &--hovered .configurable-field--active {
        background-color: $light-grey-4;
        padding: 1rem 1rem 0;
    }

    .list-item-btn {
        height: 37px;
    }

    .stat-configuration-btn {
        border-radius: 0.25rem;
        border: none;
        background: transparent;
        transition: .2s background-color;

        &:hover {
            background-color: $light-grey-5;
        }

        svg {
            fill: $grey-1;
        }
    }
}
</style>
