<template>
  <BlockUI
    v-if="isLoading"
    class="loader kmtx-loader"
  >
    <div class="loading-icon__container">
      <svg
        class="loading-icon__icon"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 225 245"
        preserveAspectRatio="xMidYMid"
      ><rect
        width="100%"
        height="100%"
        fill="none"
      /><g fill="#46ab9a"><path d="M135 81h-7.7l-39 46.5V170H70.3V69h-6v107h29.8v-46.3z" /><path d="M152.8 69h-31l-2.1 2.6-37.4 44.6V75h6v24.9l5.9-7.1V69H76.3v95h6v-38.6L124.6 75h23.2l-47.7 56.8 27 32.3h7.8l-27-32.3L155.6 75l1.6-2 3.3-4z" /><path d="M158.2 173.3l-2.8-3.3-36-42.8-3.8 4.6 32 38.2h-23.4l-24.1-28.8v9.3l21.4 25.5H160.4z" /></g><path
        fill="#6d6d6d"
        d="M112.5 32.6a88 88 0 0126.8 4.3l-6.7 11.7h47.7l-12-20.7-11.9-20.6-6.2 10.8A110.4 110.4 0 0025 189.1l16.8-13a89 89 0 0170.7-143.5zm87.5 22l-16.8 13a89.4 89.4 0 01-95.8 139.8l6.3-11H46.1L58 217.1l11.9 20.6 6.6-11.5A110.5 110.5 0 00200 54.7z"
      ><animateTransform
        attributeName="transform"
        attributeType="auto"
        type="rotate"
        values="0 112 122;360 112 122"
        calcMode="spline"
        keyTimes="0;1"
        keySplines="0 0 0 0"
        dur="1s"
        begin="0s"
        repeatCount="indefinite"
        additive="sum"
        accumulate="none"
        fill="freeze"
      /></path></svg>
      <div
        v-if="message"
        class="loading-icon__message"
      >
        {{ message }}
      </div>
    </div>
  </BlockUI>
</template>

<script>
export default {
  name: 'LoaderComponent',
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: 'Loading',
    },
    responsive: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.kmtx-loader.loading-container.loader {
    $sm: 80px;
    $md: 120px;
    .loading {
        max-width: $md;
        width: 15%;
        padding: 0;
    }

    &--sm .loading {
        max-width: $sm;
    }

    &--md .loading {
        max-width: $md;
    }

    .loading-icon {
        margin-bottom: 0!important;
        display: flex;
        justify-content: center;
    }

    .loading-icon__container {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 50%;
        padding: 5% 0;
    }

    @media screen and (max-width: 840px) {
        .loading-icon__message {
            display: none;
        }
    }
}
</style>
