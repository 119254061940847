import DataMarts from '../../services/DataMarts';
import Dashboard from '../../components/dashboards/Dashboard.vue';
import { getKeyTextArray } from '@/helper';

const state = {
  dashboards: [],
  widgets: [],
  selectedWidgetPosition: [],
  selectedWidgetBody: {},
  editState: false,
  widgetFormDirty: false,
  filtersFormDirty: false,
  widgetBarVisible: false,
  filtersBarVisible: false,
  independentFilters: ['step', 'export'],
};

const getters = {
  getDashboardsMenuItems(state: any) {
    // convert dashboards to menu items interface
    return state.dashboards.map((dashboard: any) => ({
      href: `/dashboards/${dashboard.id}`,
      title: dashboard.name,
      names: [`dashboard-${dashboard.id}`],
    }));
  },
  getRoutesFromDashboards(state: any) {
    return state.dashboards.map((dashboard: any) => ({
      path: `/dashboards/${dashboard.id}`,
      component: Dashboard,
      name: `dashboard-${dashboard.id}`,
      meta: {
        title: dashboard.name,
        permission: dashboard.type === 2 ? 'dbc:dashboards:own-private:view' : 'dbc:dashboards:own-public:view',
      },
    }));
  },
  getDashboardById(state: any) {
    return (id: any) => state.dashboards.find((dashboard: any) => dashboard.id === id);
  },
  // Created dashboard or even its mock can be saved
  canBeSaved(state: any) {
    return state.widgets.length || state.editState;
  },

  getWidgetsKeyValue(state: any) {
    const widgets: any = [];
    state.widgets.forEach((row: any) => {
      row.forEach((widget: any) => {
        if (widget.id) {
          widgets.push({ key: widget.name, value: widget.id, dataMartId: widget.configuration?.data_mart_id });
        }
      });
    });
    return widgets;
  },
};

const actions = {
  fetchDashboards({ commit }: any, { params, options }: any) {
    params.perPage = params.perPage || 1000;
    return DataMarts.getDashboards(params, options).then((resp) => {
      commit('setDashboards', resp.data.data);
      return resp.data.data;
    });
  },
  fetchDashboard({ commit }: any, { id, params }: any) {
    return DataMarts.getDashboard(id, params).then(({ data: { data } }) => {
      commit('clearWidgets');
      commit('updateDashboard', data);

      return {
        widgets: data.widgets,
        configuration: data.configuration,
      };
    });
  },
  editDashboard({ commit }: any, { dashboard, params }: any) {
    return DataMarts.editDashboard(dashboard, params).then(({ data: { data } }) => {
      commit('clearWidgets');
      commit('updateDashboard', data);

      return {
        widgets: data.widgets,
        configuration: data.configuration,
      };
    });
  },
  fetchDataMarts({ commit }: any, params: any) {
    return DataMarts.getDataMarts(params).then((resp) => resp.data.data.map((item: any) => {
      const result: any = { value: item.id, text: item.name };
      if (item.connection?.host) {
        result.secondary_text = item.connection.host;
      }
      return result;
    }));
  },
  fetchDataMart({ commit }: any, { id, params }: any) {
    return DataMarts.getDataMart(id, params).then((resp) => resp.data.data);
  },
  fetchWidgetTypes({ commit }: any) {
    return DataMarts.getWidgetTypes().then((resp) => getKeyTextArray(resp.data.data, 'value'));
  },
  fetchAggregationTypes({ commit }: any) {
    return DataMarts.getAggregationTypes().then((resp) => getKeyTextArray(resp.data.data, 'value'));
  },
  validateWidget(state: any, widget: any) {
    return DataMarts.validateWidget(widget);
  },
  createWidget({ commit }: any, widget: any) {
    return DataMarts.createWidget(widget).then((resp) => resp.data.data);
  },
  editWidget({ commit }: any, widget: any) {
    return DataMarts.editWidget(widget).then((resp) => resp.data.data);
  },
  getWidgetData(context: any, { id, ...params }: any) {
    return DataMarts.getWidgetData(id, params).then((resp) => resp.data);
  },
};

const mutations = {
  setEditStateTo(state: any, newState: any) {
    state.editState = newState;
  },
  setFiltersFormDirtyTo(state: any, newState: any) {
    state.filtersFormDirty = newState;
  },
  setWidgetFormDirtyTo(state: any, newState: any) {
    state.widgetFormDirty = newState;
  },
  setWidgetBarVisibleTo(state: any, isVisible: any) {
    state.widgetBarVisible = isVisible;
  },
  setFiltersBarVisibleTo(state: any, isVisible: any) {
    state.filtersBarVisible = isVisible;
  },
  setDashboards(state: any, payload: any) {
    state.dashboards = payload;
  },
  setWidgets(state: any, { widgets, configuration }: any) {
    if (configuration.row?.length) {
      configuration.row.forEach(({ cols }: any) => {
        const row = [...getObjectsFromCount(cols)];
        state.widgets.push(row);
      });
      widgets.forEach((widget: any) => {
        state.widgets[widget.dashboard_position[0]].splice(widget.dashboard_position[1], 1, widget);
      });
    }
  },
  clearWidgets(state: any) {
    state.widgets.splice(0, state.widgets.length);
  },
  clearSelectedWidget(state: any) {
    state.selectedWidgetPosition = null;
    state.selectedWidgetBody = Object.create(null);
  },
  changeColsCount(state: any, { rowId, cols }: any) {
    state.widgets = state.widgets.map((item: any, id: any) => {
      if (id === rowId) {
        return getObjectsFromCount(cols);
      }
      return item;
    });
  },
  deleteRow(state: any, rowId: any) {
    state.widgets.splice(rowId, 1);
    state.widgets.forEach((row: any, index: any) => {
      if (index >= rowId) {
        row.forEach((col: any) => {
          if (col.dashboard_position) {
            col.dashboard_position[0] -= 1;
          }
        });
      }
    });
  },
  addWidgetRow(state: any, colsCount: any) {
    state.widgets.push(getObjectsFromCount(colsCount));
  },

  setSelectedWidget(state: any, position: any) {
    state.selectedWidgetPosition = position;
    state.selectedWidgetBody = state.widgets[position[0]][position[1]];
  },
  attachSelectedWidget(state: any, widget: any) {
    state.selectedWidgetBody = widget;
    state.selectedWidgetBody.dashboard_position = state.selectedWidgetPosition;
    state.widgets[state.selectedWidgetPosition[0]].splice(state.selectedWidgetPosition[1], 1, widget);
  },
  updateDashboard(state: any, updatedDashboard: any) {
    state.dashboards = state.dashboards.map((dashboard: any) => {
      if (dashboard.id === updatedDashboard.id) {
        dashboard = updatedDashboard;
        return dashboard;
      }
      return dashboard;
    });
  },
  updateFiltersFields(state: any, {
    dashboardId, widgetId, fields, newWidget,
  }: any) {
    state.dashboards.forEach((dashboard: any) => {
      if (dashboard.id === dashboardId) {
        dashboard.configuration.filters_list
          .filter(({ filter_value }: any) => !state.independentFilters.includes(filter_value))
          .forEach((filter: any, index: any) => {
            filter.filter_fields = filter.filter_fields || [];
            if (newWidget) {
              filter.filter_fields.push({ widgetId, fieldId: fields[index] });
            } else {
              filter.filter_fields.map((field: any) => {
                if (field.widgetId === widgetId) {
                  field.fieldId = fields[index];
                }
                return field;
              });
            }
          });
      }
    });
  },
  updateFiltersList(state: any, { dashboardId, list }: any) {
    state.dashboards.forEach((dashboard: any, index: any) => {
      if (dashboard.id === dashboardId) {
        dashboard.configuration.filters_list = list;
        state.dashboards.splice(index, 1, dashboard);
      }
    });
  },
};

function getObjectsFromCount(count: any) {
  const objects: {}[] = [];
  for (let i = 1; i <= count; i++) {
    objects.push({});
  }
  return objects;
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
