import useVuelidate from '@vuelidate/core';
import { reactive, ref } from 'vue';
import deepClone from '@/shared/utils/deepClone';
import convertServerError from '@/shared/utils/convertServerErrors';

function useForm<T extends object>(initValue: T, rules, customGlobal: {[key: string]: boolean} = {}) {
  const formValue = reactive<T>(initValue);

  const serverErrors = ref({});

  const form = useVuelidate(rules, formValue, {
    $externalResults: serverErrors, $lazy: false, ...customGlobal,
  });

  function getFieldWithNesting(prop: string) {
    const propNames = prop.split('.'); // Split the property into nested property names
    let currentObj = form.value;

    for (const propName of propNames) {
      if (currentObj && Object.prototype.hasOwnProperty.call(currentObj, propName)) {
        currentObj = currentObj[propName];
      } else {
        return false; // Property doesn't exist, so it's considered invalid
      }
    }

    return currentObj;
  }
  function isRequired(prop: string): boolean {
    const field = getFieldWithNesting(prop);
    if (!field) return false;

    if (Object.hasOwn(field, 'required') && Object.hasOwn(field.required.$params, 'prop')) {
      return typeof field.required.$params.prop === 'function' ? field.required.$params.prop() : field.required.$params.prop;
    }
    return Object.hasOwn(field, 'required');
  }

  function touchFormItem(prop: string):void {
    const field = getFieldWithNesting(prop);
    if (!field) return;
    field.$touch();
  }

  function isInvalidProp(prop: string): boolean {
    const field = getFieldWithNesting(prop);
    if (!field) return false;
    return field.$invalid;
  }

  function isInvalidAndTouched(prop: string): boolean {
    const field = getFieldWithNesting(prop);
    if (!field) return false;
    return field.$invalid && field.$dirty;
  }

  function setFormData(data: Partial<T>) {
    const cloneData = deepClone(data);

    Object.keys(cloneData).forEach((key) => {
      formValue[key] = cloneData[key];
    });
  }

  function setServerErrors(errors: {[key: string]: string[]}) {
    serverErrors.value = convertServerError(errors);
  }

  return {
    form, formValue, serverErrors, isRequired, setFormData, isInvalidProp, isInvalidAndTouched, touchFormItem, setServerErrors,
  };
}

export default useForm;
