interface InputErrors {
  [key: string]: string[];
}

interface ConvertedErrors {
  [key: string]: string | ConvertedErrors;
}

function convertServerErrors(errors: InputErrors): ConvertedErrors {
  const result: ConvertedErrors = {};
  for (const key in errors) {
    if (Object.hasOwn(errors, key)) {
      const value = errors[key];

      if (/\[.*\]/.test(key)) {
        const keys = key.split(/\[|\]\[|\]/).filter(Boolean);

        const buildNestedStructure = (obj: any, index: number) => {
          const currentKey = keys[index];
          if (!obj[currentKey]) {
            obj[currentKey] = {};
          }

          if (index === keys.length - 1) {
            obj[currentKey] = value.join(', ');
          } else {
            buildNestedStructure(obj[currentKey], index + 1);
          }
        };

        buildNestedStructure(result, 0);
      }
    }
  }

  return result;
}

export default convertServerErrors;
