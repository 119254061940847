<template>
  <div class="content">
    <kmtx-filter-builder
      ref="filters"
      :filters="filtersOptions"
      @domainlist-change="getDataAndDrawChart"
      @domainlist-init="getDataAndDrawChart"
      @select-change="onPeriodChange"
    />

    <b-row>
      <v-chart
        ref="eventHeatMapTimeline"
        :options="heatMapOptions"
        :init-options="initOptions"
        class="heatmap"
        @click="onChartClick"
      />
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import ECharts from 'vue-echarts';
import Event from '../../services/Event';
import 'echarts/lib/chart/line';
import 'echarts/lib/chart/heatmap';
import 'echarts/lib/component/title';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/visualMap';
import kmtxFilterBuilder from '../filters/builder/index.vue';

export default {
  name: 'EventHeatMapComponent',
  components: {
    kmtxFilterBuilder,
    'v-chart': ECharts,
  },
  data() {
    return {
      filtersOptions: [
        {
          filter: 'domainlist',
          label: 'Domain/Label',
        },
        {
          filter: 'select',
          label: 'Period',
          data: ['30m', '1h', '3h', '5h', '12h', '24h'],
          value: '3h',
        },
      ],
      granularity: '3h',
      selectedSource: 0,
      eventsData: {},
      domainData: {},
      initOptions: {
        renderer: 'canvas',
      },
      heatMapOptions: {
        title: [
          {},
          {
            top: '50%',
            left: '0',
            text: '',
          },
        ],
        tooltip: {
          position: 'top',
          triggerOn: 'mousemove',
        },
        xAxis: [
          {
            type: 'category',
            scale: true,
            gridIndex: 0,
            axisLine: {
              show: false,
            },
            axisLabel: {
              show: false,
              rotate: 90,
            },
            axisTick: {
              show: false,
            },
          },
          {
            type: 'category',
            scale: true,
            gridIndex: 1,
            axisLabel: {
              rotate: 90,
              formatter(value) {
                const date = new Date(value * 1);
                const hours = date.getHours();
                const minutes = `0${date.getMinutes()}`;
                return `${hours}:${minutes.substr(-2)}`;
              },
            },
          },
        ],
        yAxis: [
          {
            gridIndex: 0,
            axisLine: {
              show: true,
            },
            type: 'category',
          },
          {
            gridIndex: 1,
            axisLine: {
              show: true,
            },
            type: 'category',
          },
        ],
        grid: [
          {
            containLabel: true,
            left: '2%',
            // left: '12%',
            right: '2%',
            bottom: '53%',
            top: '30px',
          },
          {
            containLabel: true,
            top: '53%',
            left: '2%',
            // left: '12%',
            right: '2%',
          },
        ],
        visualMap: {
          min: -1,
          max: 1,
          calculable: true,
          orient: 'horizontal',
          left: 'center',
          top: 'top',
          type: 'piecewise',
          splitNumber: 3,
          inRange: {
            color: ['#999999', '#ff9f9f', '#9fdc9f'],
          },
          pieces: [
            { value: -1, label: 'unassigned' },
            { value: 0, label: 'ko' },
            { value: 1, label: 'ok' },
          ],
          itemWidth: 8,
          itemHeight: 8,
        },
        series: [
          {
            type: 'heatmap',
            itemStyle: {
              // borderColor: '#fff',
              // borderWidth: 1,
              emphasis: {
                borderColor: '#333',
                borderWidth: 1,
              },
            },
            xAxisIndex: 0,
            yAxisIndex: 0,
            tooltip: {
              formatter(params) {
                return `Event: ${params.data.label}<br/>Domain: ${params.name}<br>Value: ${params.data.count}`;
              },
            },
          },
          {
            type: 'heatmap',
            label: {
            },
            itemStyle: {
              // borderColor: '#fff',
              // borderWidth: 1,
              emphasis: {
                borderColor: '#333',
                borderWidth: 1,
              },
            },
            xAxisIndex: 1,
            yAxisIndex: 1,
            tooltip: {
              formatter(params) {
                const date = new Date(params.name * 1);
                const day = `0${date.getDate()}`;
                const month = `0${date.getMonth()}`;
                const year = date.getFullYear();
                const hours = date.getHours();
                const minutes = `0${date.getMinutes()}`;
                const seconds = `0${date.getSeconds()}`;
                const formattedTime = `${day.substr(-2)}-${month.substr(-2)}-${year}, ${hours}:${minutes.substr(-2)}:${seconds.substr(-2)}`;

                return `Event: ${params.data.label}<br/>Date: ${formattedTime}<br>Value: ${params.data.count}`;
              },
            },
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters('filters', {
      getCurrentDomain: 'getCurrentDomain',
    }),
  },
  created() {
  },
  mounted() {
    // const container = document.querySelector('.echarts');
    // const containerTopOffset = Math.round(container.getBoundingClientRect().top);
    // container.style.height = `calc(100vh - ${containerTopOffset}px)`;
    // this.$refs.eventHeatMapTimeline.resize();
  },
  methods: {
    onChartClick(event) {
      if (this.selectedSource !== event.data.source_id && event.seriesIndex === 0) {
        this.selectedSource = event.data.source_id;

        const that = this;

        this.getDomainData(this.selectedSource).then(() => {
          that.setBottomChartOptions();
        }).catch((error) => { if (!error.cancelReason) throw error; });
      }
    },
    getEventsData(options = { showLoader: true }) {
      const that = this;
      return new Promise((resolve, reject) => {
        Event.getEventStat(that.getCurrentDomain.hosts, that.getCurrentDomain.labelType, that.granularity, options).then((r) => {
          resolve(that.eventsData = r.data);
        }).catch((error) => {
          reject(error);
        }).finally(() => {
        });
      });
    },
    getDomainData(source = 14, options = { showLoader: true }) {
      // suppress until sourceId will have fixed type
      // eslint-disable-next-line eqeqeq
      if ((this.selectedSource === 0 && this.getCurrentDomain.id !== 0)
                || this.selectedSource === this.getCurrentDomain.id) {
        source = this.getCurrentDomain.id;
      }

      // suppress until sourceId will have fixed type
      // eslint-disable-next-line eqeqeq
      if (source == 0) {
        source = 14;
      }
      if (typeof source === 'string') {
        source = this.getCurrentDomain.id;
      }

      const that = this;
      return new Promise((resolve, reject) => {
        Event.getSourceEventTimeSeries(source, that.granularity, options).then((r) => {
          resolve(that.domainData = r.data);
        }).catch((error) => {
          reject(error);
        }).finally(() => {
        });
      });
    },
    setChartOptions() {
      this.setTopChartOptions();
      this.setBottomChartOptions();
    },
    setTopChartOptions() {
      this.heatMapOptions.xAxis[0].data = this.eventsData.xaxis;
      this.heatMapOptions.yAxis[0].data = this.eventsData.yaxis;
      this.heatMapOptions.series[0].data = this.eventsData.data;
    },
    setBottomChartOptions() {
      this.heatMapOptions.xAxis[1].data = this.domainData.xaxis;
      this.heatMapOptions.yAxis[1].data = this.domainData.yaxis;
      this.heatMapOptions.series[1].data = this.domainData.data;

      this.heatMapOptions.title[1].text = this.domainData.source.value;
    },
    getDataAndDrawChart() {
      const that = this;
      Promise.all([this.getEventsData(), this.getDomainData()]).then(() => {
        that.setChartOptions();
      }).catch((error) => { if (!error.cancelReason) throw error; });
    },
    onPeriodChange(data) {
      this.granularity = data.value;
      this.getDataAndDrawChart();
    },
  },
};
</script>

<style>
    .echarts.heatmap
    {
        width: 100%;
        height: 140vh;
        min-height: 1300px;
    }
</style>
