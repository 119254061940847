var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.KFlexContainer,{attrs:{"direction":"column"}},[_vm._l((_vm.form.price_setting.project.market.$model),function(market,index){return _c(_setup.KFlexContainer,{key:index,staticClass:"mb-4 gap-1 market-group-container"},[_c(_setup.KFlexItem,[_c(_setup.FormControlWithError,{attrs:{"id":'market-country' + index},scopedSlots:_vm._u([{key:"control",fn:function(){return [_c(_setup.KExtendedDropdown,{class:{
              'invalid-input': _setup.key > 0 && _setup.isMarketFieldTouched(index, 'country')
                && _setup.getMarketErrors(index, 'country').length > 0,
            },attrs:{"label":_setup.FORM_LABELS.ABFormLabels.price_setting.country,"disabled":_setup.props.disabled,"selected":_setup.selectedCountry(market.country),"options":_setup.countryOptions,"unselect":false,"floating":""},on:{"updated":function($event){return _setup.updateCountry($event, index)}}})]},proxy:true},{key:"error",fn:function(){return [(_setup.key > 0 && _setup.isMarketFieldTouched(index, 'country'))?_c(_setup.ErrorsMessage,{attrs:{"errors":_setup.getMarketErrors(index, 'country')}}):_vm._e()]},proxy:true}],null,true)})],1),_c(_setup.KFlexItem,[_c(_setup.FormControlWithError,{attrs:{"id":'market-category' + index},scopedSlots:_vm._u([{key:"control",fn:function(){return [_c(_setup.Tagsarea,{class:{
              'invalid-input': _setup.key > 0 && _setup.isMarketFieldTouched(index, 'category')
                && _setup.getMarketErrors(index, 'category').length > 0,
            },attrs:{"disabled":_setup.props.disabled,"data":market.category,"header-text":_setup.FORM_LABELS.ABFormLabels.price_setting.categories,"input-placeholder":_setup.FORM_LABELS.ABFormLabels.price_setting.categories,"required":"","separators":[' ', ','],"invalid-index":_setup.getIndexInvalidChip(index),"errors":[]},on:{"onAdd":(chips) => _setup.addChip(chips, index),"onUpdate":(idx) => _setup.updateChip(idx, index)}})]},proxy:true},{key:"error",fn:function(){return [(_setup.key > 0 && _setup.isMarketFieldTouched(index, 'category'))?_c(_setup.ErrorsMessage,{attrs:{"errors":_setup.getMarketErrors(index, 'category')}}):_vm._e()]},proxy:true}],null,true)})],1),_c(_setup.KFlexItem,[_c(_setup.KIconButton,{attrs:{"icon":"cross","size":"md","disabled":_vm.form.price_setting.project.market.$model.length === 1},on:{"click":function($event){return _setup.removeMarket(market.country, index)}}})],1)],1)}),_c(_setup.KButton,{attrs:{"level":"secondary","label":"Add Market"},on:{"click":_setup.addMarket}}),(_setup.warning.isShown)?_c(_setup.WarningModal,{attrs:{"title":"Please confirm","text-content":`Are you sure you want to delete the ${_setup.warning.name} entry?`},on:{"close":function($event){_setup.warning.isShown = false},"save":function($event){return _setup.deleteMarket(_setup.warning.index)}}}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }