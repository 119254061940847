import { ApiV1 } from '@/api';

export default {
  getSourceEvent(params: any, options: any) {
    return ApiV1(options).get('/monitoring/event', { params });
  },
  getSourceEventBySite(params: any, options: any) {
    return ApiV1(options).get('/monitoring/event-count', { params });
  },
  getSourceDetails(id: any, options: any) {
    return ApiV1(options).get(`/monitoring/source/${id}`);
  },
  getUnmarketEventList(id: any, options: any) {
    return ApiV1(options).get(`/monitoring/unmarket-event/${id}`);
  },
};
